import React from "react";
import cart from "../assets/img/cart.svg";

import styles from "./Cart.module.css";

function EmptyCart() {
  return (
    <>
      <div className={styles.emptyCart}>
        <img className={styles.cartImg} src={cart} alt="" />
        <p className={styles.cartText}>
          Your cart seems to be empty add products to your cart
        </p>
      </div>
    </>
  );
}

export default EmptyCart;
