import React from "react";
// import {BrowserRouter} from "react-router-dom";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import {AuthProvider} from "./auth";
import {OrdersProvider} from "./Context/OrdersContext";
import {UserProvider} from "./Context/UserContext";
import {ClinicProvider} from "./Context/ClinicContext";
import {CartProvider} from "./Context/CartContext";
import {ScheduleProvider} from "./Context/ScheduleContext";
// import firebase from "firebase/app";
// import analytics from "./firebase";

// firebase.analytics().logEvent("load-website");

ReactDOM.render(
  <AuthProvider>
    <ClinicProvider>
      <UserProvider>
        <CartProvider>
          <OrdersProvider>
            <ScheduleProvider>
              <App />
            </ScheduleProvider>
          </OrdersProvider>
        </CartProvider>
      </UserProvider>
    </ClinicProvider>
  </AuthProvider>,

  document.getElementById("root")
);
