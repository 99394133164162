import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import clinic from "../assets/img/clinicPic.jfif";
import { getAllBlogs } from "../services/firebaseServices";

import styles from "./BlogsList.module.css";

function BlogsList() {
  const [blogs, setBlogs] = useState([]);

  const history = useHistory();

  useEffect(() => {
    getAllBlogs().then((res) => setBlogs(res));
  }, []);
  return (
    <>
      {console.log(blogs)}
      <div className={styles.blogsContainer}>
        <div className={styles.searchContainer}>
          <input
            className={styles.search}
            type="text"
            placeholder="Search doctors, clinics and more..."
          />
        </div>
        {blogs.map((item, index) => (
          <div
            onClick={() => history.push(`/blog-detail/${item.blogId}`)}
            key={index}
            className={styles.blogCard}
          >
            <img className={styles.blogImg} src={clinic} alt="" />
            <div className={styles.details}>
              <h2 className={styles.blogHeader}>
                {item.blog.blocks[0].data.text}
              </h2>
              <div className={styles.blogInfo}>
                <p className={styles.author}>By {item.blog.author}</p>
                <p className={styles.date}>{item.blog.date}</p>
              </div>
            </div>
            <div className={styles.content}>
              {item.blog.blocks[1].data.text}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default BlogsList;
