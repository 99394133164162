import React from "react";
import folder from "../assets/img/folder.svg";

import styles from "./ProfileAttachment.module.css";

function ProfileAttachments() {
  return (
    <>
      <div className={styles.attachmentContainer}>
        <select className={styles.selectMenu}>
          <option className={styles.selectOption} value="">
            User Name
          </option>
          <option className={styles.selectOption} value="">
            User Name
          </option>
          <option className={styles.selectOption} value="">
            User Name
          </option>
        </select>
        <div className={styles.date}>12/04/2021</div>
        <div className={styles.filesContainer}>
          <div className={styles.name}>Patient Name</div>
          <div className={styles.files}>
            <div className={styles.file}>
              <img src={folder} width={45} alt="" />
              <p className={styles.fileName}>Prescription.pdf</p>
            </div>
            <div className={styles.file}>
              <img src={folder} width={45} alt="" />
              <p className={styles.fileName}>Prescription.pdf</p>
            </div>
            <div className={styles.file}>
              <img src={folder} width={45} alt="" />
              <p className={styles.fileName}>Prescription.pdf</p>
            </div>
            <div className={styles.file}>
              <img src={folder} width={45} alt="" />
              <p className={styles.fileName}>Prescription.pdf</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileAttachments;
