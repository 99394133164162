import React, {useContext} from "react";
import {Redirect, Route} from "react-router";
import {AuthContext} from "../auth";

const PrivateRoute = ({component: Component, ...rest}) => {
  const {currentUser} = useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(routeProps) =>
        !!currentUser ? (
          <Component {...routeProps} />
        ) : (
          <Redirect to={"/login"} />
        )
      }
    />
  );
};

export default PrivateRoute;
