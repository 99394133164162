import React from "react";
import ReactStars from "react-rating-stars-component";
import phoneTalk from "../assets/img/phoneTalk.svg";

import styles from "./ClinicCard.module.css";
function ClinicCard({
  image,
  name,
  rating,
  place,
  facilities,
  button,
  phoneNumber,
}) {
  return (
    <>
      <div className={styles.cardContainer}>
        <div className="clinic-img">
          <img
            className={styles.cardImg}
            style={{borderRadius: "8px 8px 0px 0px"}}
            src={image}
            alt=""
          />
        </div>
        <div className={styles.clinicDetails}>
          <div>
            <h1>{name}</h1>
          </div>
          <div>
            <ReactStars
              classNames={styles.rating}
              count={5}
              value={parseFloat(rating)}
              edit={false}
              size={24}
              isHalf={true}
              activeColor="#ffd700"
            />
          </div>
          <div className={styles.locationDetails}>
            <p>
              {facilities?.map((item, index) => (
                <span key={index}>{item}, </span>
              ))}
            </p>
            <p>{place}</p>
          </div>
        </div>
        {button ? (
          <a
            style={{textDecoration: "none"}}
            className={styles.contactUsText}
            href={`tel:${phoneNumber}`}
          >
            <button className={styles.callButton}>
              {button}
              <img
                className={styles.callIcon}
                src={phoneTalk}
                width={15}
                alt=""
              />
            </button>
          </a>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default ClinicCard;
