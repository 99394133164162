import React from "react";

import instaIcon from "../../assets/img/instaIconWeb.png";
import whatsappIcon from "../../assets/img/whatsappIconWeb.png";
import phoneIcon from "../../assets/img/phoneIconWeb.png";
import mailIcon from "../../assets/img/mailIconWeb.png";
import fbIcon from "../../assets/img/fbIconWeb.png";
import linkedinIcon from "../../assets/img/linkedinIconWeb.png";

import styles from "./FooterNew.module.css";
import { Link } from "react-router-dom";
import { HashLink, NavHashLink } from "react-router-hash-link";

function FooterNew() {
	return (
		<>
			<div className={styles.footerContainer}>
				<div className={styles.flexCliniker}>
					<h4 className={styles.titleText}>Cliniker</h4>
					<Link to="/about" className={styles.paraText}>
						About
					</Link>
					<Link to="/blogs" className={styles.paraText}>
						Blogs
					</Link>
					<Link to="/faq" className={styles.paraText}>
						FAQ
						{/* <p className={styles.paraText}>FAQ</p> */}
					</Link>
					<Link to="/enquiry" className={styles.paraText}>
						Partners
					</Link>
					<Link to="/contactus" className={styles.paraText}>
						Contact Us
					</Link>
				</div>

				<div className={styles.flexServices}>
					<h4 className={styles.titleText}>Our services</h4>

					<Link to="/consult" className={styles.paraText}>
						Consult Doctors
					</Link>

					<Link to="/book" className={styles.paraText}>
						Book lab test
					</Link>

					<Link to="/pharmacy" className={styles.paraText}>
						Pharmacy near me
					</Link>

					<HashLink className={styles.paraText} to="/#vault">
						Medical Repository
					</HashLink>

					{/* <Link
            to={{
              pathname: "/",
              hash: "#vault",
            }}
            className={styles.paraText}
          >
            Medical Repository
          </Link> */}
				</div>

				<div className={styles.flexRegister}>
					<h4 className={styles.titleText}>Register as</h4>

					<Link to="/enquiry" className={styles.paraText}>
						Doctor
					</Link>

					<Link to="/enquiry" className={styles.paraText}>
						Clinic
					</Link>

					{/* <Link to="/enquiry" className={styles.paraText}>
            Pharmacy
          </Link> */}
				</div>

				<div className={styles.flexMore}>
					<h4 className={styles.titleText}>More</h4>

					<p className={styles.paraText}>Clinicoins</p>

					<Link to="/blogs" className={styles.paraText}>
						Read health articles
					</Link>

					<Link to="/privacy-policy" className={styles.paraText}>
						Privacy and policy
					</Link>

					<Link to="/terms-and-conditions" className={styles.paraText}>
						Terms and conditions
					</Link>

					<Link to="/delivery-and-shipping" className={styles.paraText}>
						Delivery and Shipping
					</Link>

					<HashLink
						to="/terms-and-conditions#refund"
						className={styles.paraText}
					>
						Refund policy
					</HashLink>

					{/* <p className={styles.paraText}>Feedback</p>

          <p className={styles.paraText}>Sitemaps</p> */}
				</div>

				<div className={styles.flexDownload}>
					<h4 className={styles.titleText}>Download App</h4>

					<a
						href="https://play.google.com/store/apps/details?id=com.dtscliniker"
						className={styles.paraText}
						target="_blank"
						rel="noreferrer"
					>
						<button className={styles.footerBtn}>PLAY STORE</button>
					</a>

					<Link to="/coming" className={styles.paraText}>
						<button className={styles.footerBtn}>APP STORE</button>
					</Link>
				</div>

				<div className={styles.flexSocial}>
					<h4 className={styles.titleText}>Follow us on</h4>

					<div className={styles.iconContainer}>
						<a
							className="f-links"
							target="_blank"
							rel="noreferrer"
							href="https://www.instagram.com/cliniker_india/"
						>
							<img className={styles.icon} src={instaIcon} alt="instagram" />
						</a>
						<a
							className="f-links"
							target="_blank"
							rel="noreferrer"
							href="https://www.facebook.com/cliniker.india/"
						>
							<img className={styles.icon} src={fbIcon} alt="facebook" />
						</a>
						<a
							className="f-links"
							target="_blank"
							rel="noreferrer"
							href="https://www.linkedin.com/company/cliniker/"
						>
							<img className={styles.icon} src={linkedinIcon} alt="linkedin" />
						</a>
					</div>

					<h4 className={styles.titleText}>Contact us</h4>

					<div className={styles.contactDetails}>
						<div className={styles.flexCol}>
							<div className={styles.contactItem}>
								<img className={styles.icon} src={mailIcon} alt="" />
								<a
									className={styles.contactUsText}
									href="mailto:support@cliniker.com"
								>
									support@cliniker.com
								</a>
								{/* <p className={styles.contactUsText}>support@Cliniker.com</p> */}
							</div>

							<div className={styles.contactItem}>
								<img className={styles.icon} src={phoneIcon} alt="" />
								<a className={styles.contactUsText} href="tel:8826264333">
									+91-8826264333
								</a>
								{/* <p className={styles.contactUsText}>+91-8826264333</p> */}
							</div>

							<div className={styles.contactItem}>
								<img className={styles.icon} src={whatsappIcon} alt="" />
								<a
									className={styles.contactUsText}
									href="http://wa.me/918826264333"
									target="_blank"
								>
									WhatsApp us
								</a>
								{/* <p className={styles.contactUsText}>WhatsApp us</p> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default FooterNew;
