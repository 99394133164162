import React, {createContext, useEffect, useState} from "react";
import Spinner from "./components/Spinner";
import app from "./firebase";

export const AuthContext = createContext();

export const AuthProvider = ({children}) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userPending, setUserPending] = useState(true);

  useEffect(() => {
    app.auth().onAuthStateChanged((user) => {
      setCurrentUser(user);
      setUserPending(false);
    });
  }, []);

  if (userPending) {
    return <Spinner />;
  }

  return (
    <AuthContext.Provider value={{currentUser}}>
      {children}
    </AuthContext.Provider>
  );
};
