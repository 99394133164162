import React from "react";

import Loader from "react-loader-spinner";

function Spinner() {
  return (
    <>
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loader
          type="TailSpin"
          color="#7549B1"
          height={80}
          width={80}
          timeout={3000} //3 secs
        />
      </div>
    </>
  );
}

export default Spinner;
