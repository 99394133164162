import React, { useState } from "react";

import arrow from "../assets/img/arrow.svg";

import styles from "./FAQ.module.css";

function FAQ() {
  const [faqs, setFaqs] = useState([
    {
      question: "What can I use this app for?",
      answer: `-You can book appointments with physicians, gynaecologists, paediatrician, physiotherapist and doctors across different specialities near you.
        -Book Lab tests for Home Collection with the best labs near you with accurate results.
        -Connect with Cliniker trusted nearest pharmacies and get it delivered.
        `,
      open: false,
    },
    {
      question: "How do I use this app?",
      answer: `It's really easy! All you have to do is select the service, search your requirements and fill in the quick form that comes up. You can check the 'How to use' section to know more that we cover. 
      At any point in time, you can always contact our support team through call or mail.
      `,
      open: false,
    },
    {
      question: "Is this app safe?",
      answer: `Yes, all your information within the app and website is kept private. We have a team of trusted and experienced doctors from across India. Once you choose a  doctor near you he/she is allocated to you, his/her profile can be viewed by tapping on their profile picture. Your medical information will remain completely private.`,
      open: false,
    },
    {
      question: "Why do you need my personal details?",
      answer:
        "We need your mobile number, email to ensure that you can be immediately informed when your doctor responds or when your medicines ship or to schedule your tests. We will never share your information with anyone else.",
      open: false,
    },
    {
      question: "Do you have specialists (Gynecologists, Psychologists, etc.)?",
      answer: `Yes, we understand the patient’s concern very well and strive to offer the best in-clinic consultation experience across all specialities. Quality is what we never make a compromise with. `,
      open: false,
    },
    {
      question: "Can I use this app for an emergency?",
      answer: `No, this app is for booking primary healthcare services. In case of a life-threatening emergency, please call an ambulance or head to a hospital.`,
      open: false,
    },
    {
      question: "I want to talk to someone at Cliniker ",
      answer: "Please mail us at support@cliniker.com",
      open: false,
    },
  ]);

  const toggleFaq = (index) => {
    // console.log("clicked", index, faqs);
    setFaqs(
      faqs.map((item, i) => {
        if (i === index) {
          item.open = !item.open;
        }
        // else {
        //   item.open = false;
        // }
        return item;
      })
    );
  };

  return (
    <>
      <div className={styles.faqContainer}>
        <h2 className={styles.header}>FAQ</h2>
        {faqs.map((item, index) => {
          return (
            <div
              onClick={() => {
                toggleFaq(index);
                // console.log(faqs);
              }}
              className={styles.faqItem}
              key={index}
            >
              <div className={styles.faqQuestion}>
                <div>{item.question}</div>
                <img className={styles.arrowImg} src={arrow} alt="" />
              </div>
              <div
                className={item.open ? styles.faqAnswerOpen : styles.faqAnswer}
              >
                {item.answer.includes("support@cliniker") ? (
                  <p>
                    Please mail us at{" "}
                    <a
                      style={{ color: "orange", textDecoration: "none" }}
                      href="mailto:support@cliniker.com"
                    >
                      support@cliniker.com
                    </a>
                  </p>
                ) : (
                  item.answer
                )}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default FAQ;
