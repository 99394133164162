import React from "react";

// import aboutBanner from "../assets/img/aboutBanner.png";
import bannerRightImg from "../assets/img/aboutBannerRight.png";
import bannerLeftImg from "../assets/img/aboutBannerLeft.png";

import styles from "./About.module.css";

function About() {
	return (
		<>
			<div className={styles.aboutContainer}>
				<section className={styles.bannerSection}>
					<div className={styles.bannerImgsDiv}>
						<img className={styles.bannerLeftImg} src={bannerLeftImg} alt="" />
						<div className={styles.description}>
							<h2 className={styles.bannerTitle}>About US</h2>
							<p className={styles.bannerText}>
								Cliniker is a health-tech aggregator which helps you get access
								to best clinics near you, providing multitude of quality assured
								facilities like Doctor Consultations, Lab tests and other
								healthcare services.
							</p>
						</div>
						<img
							className={styles.bannerRightImg}
							src={bannerRightImg}
							alt=""
						/>
					</div>
				</section>
				{/* <img className={styles.bannerImg} src={aboutBanner} alt="" /> */}
				<div className={styles.grid}>
					<div className={styles.about}>
						<h2 className={styles.titleText}>About Us</h2>
						<p className={styles.paraText}>
							Cliniker is a health-tech aggregator which helps you get access to
							best clinics near you, providing multitude of quality assured
							facilities like Doctor Consultations, Lab tests and other
							healthcare services.
						</p>
					</div>
					<div className={styles.mission}>
						<h2 className={styles.titleText}>Our Mission</h2>
						<p className={styles.paraText}>
							We aim to provide the best healthcare facility near you with
							Cliniker verified Doctors, Labs, Medicine Delivery, Child
							Vaccination and other medical facilities like dressing and
							injection dosages.
						</p>
						<p className={styles.paraText}>
							We also ensure minimum wait time, provide preferred slot
							availability with rescheduling facility, Free home sample
							collection, medical repository and many more to fulfill all your
							healthcare needs.
						</p>
					</div>
				</div>
			</div>
		</>
	);
}

export default About;
