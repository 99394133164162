import React, { useState } from "react";
import vitamin from "../assets/img/vitamin-pill.svg";
import SearchCard from "./SearchCard";
import LabTestCard from "./LabTestCard";

import styles from "./Search.module.css";

const inputSearch = () => {
  return (
    <>
      <div className={styles.flex}>
        <h2 className={styles.header}>Doctors</h2>
        <a className={styles.seeAll} href="#">
          See All
        </a>
      </div>
      <SearchCard
        image={vitamin}
        name={"Dr. Name Sharma"}
        subtext={"Specialised in Diabetes"}
      />
      <SearchCard
        image={vitamin}
        name={"Dr. Name Sharma"}
        subtext={"Specialised in Diabetes"}
      />
      <div className={styles.flex}>
        <h2 className={styles.header}>Clinics</h2>
        <a className={styles.seeAll} href="#">
          See All
        </a>
      </div>
      <SearchCard
        image={vitamin}
        name={"MVC Sharma Diabetic Clinic"}
        subtext={"Main Road, New Delhi"}
        location={"3 km from you"}
      />
      <SearchCard
        image={vitamin}
        name={"MVC Sharma Diabetic Clinic"}
        subtext={"Main Road, New Delhi"}
        location={"3 km from you"}
      />
      <div className={styles.flex}>
        <h2 className={styles.header}>Lab Tests</h2>
        <a className={styles.seeAll} href="#">
          See All
        </a>
      </div>
      <SearchCard
        image={vitamin}
        name={"Full Body Check Up"}
        subtext={"Clinic Name(Lab Name)"}
        location={"25 tests included"}
      />
      <SearchCard
        image={vitamin}
        name={"Full Body Check Up"}
        subtext={"Clinic Name(Lab Name)"}
        location={"25 tests included"}
      />
    </>
  );
};

const generalSearch = () => {
  return (
    <>
      <h2 className={styles.header}>Search by Disease Name</h2>
      <div className={styles.diseases}>
        <div className={styles.disease}>
          <img src={vitamin} width={50} alt="" />
          <h3 style={{ color: "#7549B1" }} className={styles.name}>
            Headache
          </h3>
        </div>
        <div className={styles.disease}>
          <img src={vitamin} width={50} alt="" />
          <h3 style={{ color: "#7549B1" }} className={styles.name}>
            Cold
          </h3>
        </div>
        <div className={styles.disease}>
          <img src={vitamin} width={50} alt="" />
          <h3 style={{ color: "#7549B1" }} className={styles.name}>
            Cold
          </h3>
        </div>
        <div className={styles.disease}>
          <img src={vitamin} width={50} alt="" />
          <h3 style={{ color: "#7549B1" }} className={styles.name}>
            Cold
          </h3>
        </div>
        <div className={styles.disease}>
          <img src={vitamin} width={50} alt="" />
          <h3 style={{ color: "#7549B1" }} className={styles.name}>
            Cold
          </h3>
        </div>
        <div className={styles.disease}>
          <img src={vitamin} width={50} alt="" />
          <h3 style={{ color: "#7549B1" }} className={styles.name}>
            Cold
          </h3>
        </div>
        <div className={styles.disease}>
          <img src={vitamin} width={50} alt="" />
          <h3 style={{ color: "#7549B1" }} className={styles.name}>
            Cold
          </h3>
        </div>
        <div className={styles.disease}>
          <img src={vitamin} width={50} alt="" />
          <h3 style={{ color: "#7549B1" }} className={styles.name}>
            Cold
          </h3>
        </div>
      </div>
      <h2 className={styles.header}>Search by Specialisation</h2>
      <div className={styles.specialisation}>
        <div className={styles.cards}>
          <img src={vitamin} width={50} alt="" />
          <h3 style={{ color: "#fff" }} className={styles.name}>
            Cardiologist
          </h3>
        </div>
        <div className={styles.cards}>
          <img src={vitamin} width={50} alt="" />
          <h3 style={{ color: "#fff" }} className={styles.name}>
            General Physician
          </h3>
        </div>
        <div className={styles.cards}>
          <img src={vitamin} width={50} alt="" />
          <h3 style={{ color: "#fff" }} className={styles.name}>
            General Physician
          </h3>
        </div>
        <div className={styles.cards}>
          <img src={vitamin} width={50} alt="" />
          <h3 style={{ color: "#fff" }} className={styles.name}>
            General Physician
          </h3>
        </div>
      </div>
    </>
  );
};

function Search() {
  const [search, setSearch] = useState("");
  return (
    <>
      <div className={styles.page}>
        <div className={styles.searchContainer}>
          <input
            onChange={(e) => {
              setSearch(e.target.value);
              //   console.log(search);
            }}
            className={styles.search}
            type="text"
            placeholder="Search doctors, clinics and more..."
            value={search}
          />
        </div>
        {search ? inputSearch() : generalSearch()}
      </div>
    </>
  );
}

export default Search;
