import React, {useContext, useEffect, useRef, useState} from "react";
import {Link, NavLink, useLocation} from "react-router-dom";
import {ReactComponent as Logo} from "../assets/img/asset-1.svg";
import profilePic from "../assets/img/docPP.jfif";
import app from "../firebase";
import {AuthContext} from "../auth";
import {UserContext} from "../Context/UserContext";

import styles from "./LoggedNav.module.css";

function LoggedNav() {
  const [navToggle, setNavToggle] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const {user} = useContext(UserContext);
  const {currentUser} = useContext(AuthContext);

  const navRef = useRef(null);
  useOutsideAlerter(navRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // alert("You clicked outside of me!");
          setNavToggle(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const updateMedia = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const location = useLocation();

  return (
    <>
      {/* {console.log("User from LN: ", user)} */}
      {screenSize <= 450 ||
      (!(location.pathname === "/") && screenSize > 450) ? (
        <nav ref={navRef} className={styles.navbarContainer}>
          <Link to="/home">
            <Logo className={styles.navbarLogo} width={46} />
          </Link>
          <ul className={navToggle ? styles.navMenuActive : styles.navMenu}>
            <li>
              <div className={styles.userContainer}>
                <div>
                  <img
                    style={{borderRadius: "50%"}}
                    src={profilePic}
                    width={50}
                    alt=""
                  />
                </div>
                <div>
                  <h1 className={styles.userName}>{user?.Info.name}</h1>
                  {/* <a className={styles.editProfile} href="/">
                    View and Edit Profile
                  </a> */}
                  <Link
                    onClick={() => setNavToggle(false)}
                    className={styles.editProfile}
                    to={"/profile"}
                  >
                    View and Edit Profile
                  </Link>
                </div>
              </div>
            </li>
            <li className={styles.navLink}>
              {/* <a className={styles.navlinksText} href="/">
                Upcoming Orders
              </a> */}
              <Link
                onClick={() => setNavToggle(false)}
                className={styles.navlinksText}
                to={"/upcoming-orders"}
              >
                Upcoming Orders
              </Link>
            </li>
            <li className={styles.navLink}>
              {/* <a className={styles.navlinksText} href="/">
                My Orders
              </a> */}
              <Link
                onClick={() => setNavToggle(false)}
                className={styles.navlinksText}
                to="/orders"
              >
                My Orders
              </Link>
            </li>
            <li className={styles.navLink}>
              {/* <a className={styles.navlinksText} href="/">
                Manage Records
              </a> */}
              <Link
                onClick={() => setNavToggle(false)}
                className={styles.navlinksText}
                to={"/manage-records"}
              >
                Manage Records
              </Link>
            </li>
            <li className={styles.navLink}>
              {/* <a className={styles.navlinksText} href="/">
                All Clinics
              </a> */}
              <Link
                onClick={() => setNavToggle(false)}
                className={styles.navlinksText}
                to={"/clinics"}
              >
                All Clinics
              </Link>
            </li>
            <li className={styles.navLink}>
              {/* <a className={styles.navlinksText} href="/">
                Pharmacies
              </a> */}
              <Link
                onClick={() => setNavToggle(false)}
                className={styles.navlinksText}
                to={"/pharmacies"}
              >
                Pharmacies
              </Link>
            </li>
            <li className={styles.navLink}>
              {/* <a className={styles.navlinksText} href="/">
                Blogs
              </a> */}
              <Link
                onClick={() => setNavToggle(false)}
                className={styles.navlinksText}
                to={"/blog-list"}
              >
                Blogs
              </Link>
            </li>
            <li className={styles.navLink}>
              <a className={styles.navlinksText} href="/">
                Contact Us
              </a>
            </li>
            <hr />
            <li className={styles.navLink}>
              <a className={styles.navlinksText} href="/">
                Settings
              </a>
            </li>
            <li className={styles.navLink}>
              <a className={styles.navlinksText} href="/">
                Privacy Policy
              </a>
            </li>
            {currentUser ? (
              <div onClick={() => app.auth().signOut()}>
                <li className={styles.navLink}>
                  <p className={styles.navlinksText}>Logout</p>
                </li>
              </div>
            ) : (
              ""
            )}
          </ul>
          <div
            onClick={() => setNavToggle(!navToggle)}
            className={styles.burger}
          >
            <div className={styles.line}></div>
            <div className={styles.line}></div>
            <div className={styles.line}></div>
          </div>
        </nav>
      ) : (
        ""
      )}
    </>
  );
}

export default LoggedNav;
