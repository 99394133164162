import React from "react";

import circleImg from "../../assets/img/circleWebTemp.png";

import loyaltyPoints from "../../assets/img/loyaltyPoints.svg";
import lp1 from "../../assets/img/lp-1.png";
import lp2 from "../../assets/img/lp-2.png";
import lp3 from "../../assets/img/lp-3.png";
import lp4 from "../../assets/img/lp-4.png";
import lp5 from "../../assets/img/lp-5.png";

import styles from "./LoyaltyPoints.module.css";

function LoyaltyPoints() {
  return (
    <>
      <div className={styles.LoyaltyPointsContainer}>
        <h1 className={styles.titleText}>Loyalty Points</h1>

        {/* <img

          style={{ width: "70%", marginTop: "80px" }}

          src={loyaltyPoints}

          alt=""

        /> */}

        <div className={styles.flex}>
          <div className={styles.line}></div>

          <div className={styles.itemsContainer}>
            <div className={styles.listItem}>
              <img className={styles.itemImg} src={lp1} alt="" />

              <p className={styles.paraText}>Sign-up and get X as bonus</p>
            </div>

            <div className={styles.listItem}>
              <img className={styles.itemImg} src={lp2} alt="" />

              <p className={styles.paraText}>Confirm your booking</p>
            </div>

            <div className={styles.listItem}>
              <img className={styles.itemImg} src={lp3} alt="" />

              <p className={styles.paraText}>
                Use clinicoins for additional discount
              </p>
            </div>

            <div className={styles.listItem}>
              <img className={styles.itemImg} src={lp4} alt="" />

              <p className={styles.paraText}>Earn more coins</p>
            </div>

            <div className={styles.listItem}>
              <img className={styles.itemImg} src={lp5} alt="" />

              <p className={styles.paraText}>Redeem</p>
            </div>
          </div>
        </div>

        {/* <div className={styles.line}></div>

        <ul className={styles.listContainer}>

          <div className={styles.itemsContainer}>

            <div className={styles.listItem}>

              <img className={styles.itemImg} src={circleImg} alt="" />

              <p className={styles.paraText}>

                Nulla Lorem mollit cupidatat irure.

              </p>

            </div>

            <div className={styles.listItem}>

              <img className={styles.itemImg} src={circleImg} alt="" />

              <p className={styles.paraText}>

                Nulla Lorem mollit cupidatat irure.

              </p>

            </div>

            <div className={styles.listItem}>

              <img className={styles.itemImg} src={circleImg} alt="" />

              <p className={styles.paraText}>

                Nulla Lorem mollit cupidatat irure.

              </p>

            </div>

            <div className={styles.listItem}>

              <img className={styles.itemImg} src={circleImg} alt="" />

              <p className={styles.paraText}>

                Nulla Lorem mollit cupidatat irure.

              </p>

            </div>

            <div className={styles.listItem}>

              <img className={styles.itemImg} src={circleImg} alt="" />

              <p className={styles.paraText}>

                Nulla Lorem mollit cupidatat irure.

              </p>

            </div>

          </div>

        </ul> */}
      </div>
    </>
  );
}

export default LoyaltyPoints;
