import React from "react";

import aniket from "../assets/img/aniket.png";
import jai from "../assets/img/jai.png";
import kartik from "../assets/img/kartik.png";
import rudra from "../assets/img/rudra.png";
import amit from "../assets/img/amit.png";

import styles from "./OurTeam.module.css";

function OurTeam() {
  return (
    <>
      <div className={styles.ourTeamContainer}>
        <section className={styles.headerSection}>
          <h1 style={{textAlign: "center", marginBottom: 20}}>
            <span className={styles.headerText}>Meet Our </span>
            <span className={styles.boldText}>Team</span>
          </h1>
          <h3 className={styles.quoteText}>
            If Everyone is moving forward together, then success can take care
            of itself
          </h3>
        </section>

        <div className={styles.membersContainer}>
          <div className={styles.person}>
            <img className={styles.img} src={aniket} alt="" />
            <h3 className={styles.name}>Aniket Modi</h3>
            <p className={styles.designation}>Co-Founder</p>
          </div>
          <div className={styles.person}>
            <img className={styles.img} src={amit} alt="" />
            <h3 className={styles.name}>Amit Negi</h3>
            <p className={styles.designation}>Web Developer</p>
          </div>
          <div className={styles.person}>
            <img className={styles.img} src={kartik} alt="" />
            <h3 className={styles.name}>Kartik Srivastava</h3>
            <p className={styles.designation}>UX/UI Designer</p>
          </div>
          <div className={styles.person}>
            <img className={styles.img} src={jai} alt="" />
            <h3 className={styles.name}>Jai Kishan</h3>
            <p className={styles.designation}>Creative Designer</p>
          </div>
          <div className={styles.person}>
            <img className={styles.img} src={rudra} alt="" />
            <h3 className={styles.name}>Rudra Singh</h3>
            <p className={styles.designation}>Social Media Manager</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurTeam;
