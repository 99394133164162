import React, {useContext, useEffect, useLayoutEffect, useState} from "react";
import {AuthContext} from "../auth";
import {OrdersContext} from "../Context/OrdersContext";
import {getAllFamilyProfiles} from "../services/firebaseServices";

import styles from "./MyOrders.module.css"; //uses stylesheet of MyOrders component
import UpcomingDocOrderCard from "./UpcomingDocOrderCard";
import UpcomingLabOrderCard from "./UpcomingLabOrderCard";

function OrdersUpcoming() {
  // const {docOrders, labOrders} = useContext(OrdersContext);
  const {docOrders} = useContext(OrdersContext);
  const {currentUser} = useContext(AuthContext);

  const [upcomingDocOrders, setUpcomingDocOrders] = useState(null);
  const [upcomingLabOrders, setUpcomingLabOrders] = useState(null);
  const [filteredDocArray, setFilteredDocArray] = useState();

  const [familyNames, setFamilyNames] = useState();
  const [selectedName, setSelectedName] = useState();

  // const [loading, setLoading] = useState(true);
  const [toggle, setToggle] = useState(1);

  const filterDocOrders = () => {
    const filteredOrderArray = docOrders?.filter((item) => {
      if (item.type === "checkup") {
        console.log("checkup item", item);
        if (
          item.status.booked.confirmed === true &&
          item.status.consultation.confirmed === false
        ) {
          return item;
        } else {
          return null;
        }
      }
      if (item.type === "labtest") {
        console.log("labtest item", item);
        if (
          (item.status.order_placed.confirmed === true ||
            item.status.professional_assigned === false) &&
          item.status.report_uploaded.confirmed === false &&
          item.status.sample_collected.confirmed === false
        ) {
          return item;
        }
      }
    });
    // console.log("filtered Doc array: ", filteredOrderArray);

    if (filteredOrderArray?.length > 0) {
      setUpcomingDocOrders(filteredOrderArray);
    }

    // let filteredOrderArray = [];
    // docOrders?.schedule.map((item) => {
    //   // console.log(item);
    //   if (
    //     item.status.booked.confirmed === true &&
    //     item.status.consultation.confirmed === !false //change this !
    //   ) {
    //     filteredOrderArray.push(item);
    //     // setUpcomingDocOrders([...upcomingDocOrders, item]);
    //     // console.log(filteredOrderArray);
    //   }
    // });
    // setUpcomingDocOrders(filteredOrderArray);
    // setLoading(false);
    // console.log("Upcoming order state", upcomingDocOrders);
  };
  // const filterLabOrders = () => {
  //   const filteredOrderArray = labOrders?.schedule.filter((item) => {
  //     if (
  //       (item.status.order_placed.confirmed === true ||
  //         item.status.professional_assigned === false) &&
  //       item.status.report_uploaded.confirmed === false &&
  //       item.status.sample_collected.confirmed === false
  //     ) {
  //       return item;
  //     }
  //   });

  //   // console.log("filtered Lab array: ", filteredOrderArray);

  //   if (filteredOrderArray?.length > 0) {
  //     setUpcomingLabOrders(filteredOrderArray);
  //   }

  //   // setLoading(false)
  //   // let filteredOrderArray = [];
  //   // labOrders?.schedule.map((item) => {
  //   //   // console.log(item);
  //   //   if (
  //   //     item.status.order_placed.confirmed === true &&
  //   //     item.status.professional_assigned.confirmed === true && (item.status.report_uploaded.confirmed && item.status.sample_collected.confirmed) //change this !
  //   //   ) {
  //   //     filteredOrderArray.push(item);
  //   //     // setUpcomingDocOrders([...upcomingDocOrders, item]);
  //   //     // console.log(filteredOrderArray);
  //   //   }
  //   // });
  //   // setUpcomingDocOrders(filteredOrderArray);
  //   // setLoading(false);
  //   // console.log("Upcoming order state", upcomingDocOrders);
  // };

  const fetchFamilyMembers = async () => {
    let names = [];
    const response = await getAllFamilyProfiles(currentUser.uid);
    response.map((item) => {
      names.push(item.name);
    });
    setFamilyNames(names);
    // console.log("names: ", names);
  };

  const filterOrdersByName = (name) => {
    // console.log("filterfunc", name);
    let tempDocArr = [];
    upcomingDocOrders?.map((item) => {
      if (Object.values(item).includes(name)) {
        // console.log(`${name} is in ${item} object`);
        tempDocArr.push(item);
      }
    });
    // console.log(tempDocArr);
    setFilteredDocArray(tempDocArr);
  };

  useEffect(() => {
    filterDocOrders();
    // filterLabOrders();
    fetchFamilyMembers();
  }, []);

  return (
    <>
      {/* {console.log(upcomingDocOrders)} */}
      <div className={styles.myOrderContainer}>
        <div className={styles.upperSection}>
          <h1 className={styles.orderHeader}>UPCOMING ORDERS</h1>
          <ul className={styles.tabList}>
            <li onClick={() => setToggle(1)}>
              <span className={toggle === 1 ? styles.active : styles.tab}>
                DOCTOR CONSULTATION
              </span>
            </li>
            <li onClick={() => setToggle(2)}>
              <span className={toggle === 2 ? styles.active : styles.tab}>
                LAB TESTS
              </span>
            </li>
          </ul>
        </div>
        <select
          onChange={(e) => filterOrdersByName(e.target.value)}
          className={styles.selectMenu}
        >
          <option key={"default"} className={styles.selectOption} value={""}>
            All Upcoming Orders
          </option>
          {familyNames?.map((item, index) => (
            <option key={index} className={styles.selectOption} value={item}>
              {item}
            </option>
          ))}
          {/* <option className={styles.selectOption} value="">
            User Name
          </option>
          <option className={styles.selectOption} value="">
            User Name
          </option>
          <option className={styles.selectOption} value="">
            User Name
          </option> */}
        </select>

        {/* <div className={styles.date}>12/4/2021</div> */}

        {toggle === 1 && !filteredDocArray
          ? upcomingDocOrders?.map((item, index) => {
              if (item.type === "checkup") {
                return (
                  <UpcomingDocOrderCard
                    key={index}
                    patientName={item.patient_name}
                    clinicName={item.clinic_name}
                    docName={item.doctor.name}
                    time={item.time}
                    date={item.date.dateString}
                    // address={item.address}
                    address={"Static Address"}
                  />
                );
              }
            })
          : ""}
        {toggle === 1 && filteredDocArray
          ? filteredDocArray?.map((item, index) => {
              if (item.type === "checkup") {
                return (
                  <UpcomingDocOrderCard
                    key={index}
                    patientName={item.patient_name}
                    clinicName={item.clinic_name}
                    docName={item.doctor.name}
                    time={item.time}
                    date={item.date.dateString}
                    // address={item.address}
                    address={"Static Address"}
                  />
                );
              }
            })
          : ""}
        {toggle === 2 && !filteredDocArray
          ? upcomingDocOrders?.map((item, index) => {
              if (item.type === "labtest") {
                return (
                  <UpcomingLabOrderCard
                    key={index}
                    patientName={item.patient_name}
                    labTestName={item.labtest_name}
                    // packageName={item.package_name}
                    packageName={"staticPackage"}
                    collection={item.collection_option}
                    // address={item.patient_address}
                    address={"static address"}
                  />
                );
              }
            })
          : ""}

        {toggle === 2 && filteredDocArray
          ? filteredDocArray?.map((item, index) => {
              if (item.type === "labtest") {
                return (
                  <UpcomingLabOrderCard
                    key={index}
                    patientName={item.patient_name}
                    labTestName={item.labtest_name}
                    // packageName={item.package_name}
                    packageName={"staticPackage"}
                    collection={item.collection_option}
                    // address={item.patient_address}
                    address={"static address"}
                  />
                );
              }
            })
          : ""}

        {/* {console.log("UP", upcomingDocOrders)} */}

        {/* {toggle === 1 ? doctorConsultations() : labTest()} */}
        {/* {toggle === 1 ? <DoctorConsultations /> : <LabTest />} */}
      </div>
    </>
  );
}

export default OrdersUpcoming;
