import React from "react";

import styles from "./MyOrders.module.css";

function UpcomingLabOrderCard({
  patientName,
  labTestName,
  packageName,
  collection,
  address,
}) {
  return (
    <>
      <div className={styles.orderDiv}>
        <div className={styles.patientName}>
          <h2 className={styles.header}>{patientName}</h2>
        </div>
        <div className={styles.flex}>
          <div className={styles.detailsDiv}>
            <h2 className={styles.header}>{labTestName}</h2>
            <h2 className={styles.header}>{packageName}</h2>
            {/* <p className={styles.location}>Location of Clinic, City</p> */}
          </div>
          <div className={styles.timingDiv}>
            <p>
              <span className={styles.boldHead}>Report In - </span>5 Days
            </p>
            {/* <p>
              <span className={styles.boldHead}>Day - </span>Monday
            </p> */}
          </div>
        </div>
        <div className={styles.addressSection}>
          <h2 className={styles.header}>Slected collection option</h2>
          <p className={styles.address}>
            {collection.clinic ? "Clinic" : "Home"}
          </p>
          <h2 className={styles.header}>Address</h2>
          <p className={styles.address}>{address}</p>
        </div>
      </div>
    </>
  );
}

export default UpcomingLabOrderCard;
