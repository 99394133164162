import React, { useEffect, useRef, useState } from "react";
import EditorJs from "react-editor-js";

import blogPic1 from "../assets/img/blog1pic1.png";
import blogPic2 from "../assets/img/blog1pic2.png";
import { EDITOR_JS_TOOLS } from "../components/WebComponents/BlogTools";
import { getABlog } from "../services/firebaseServices";
import { useLocation } from "react-router-dom";

import styles from "./BlogDetailWeb.module.css";

function BlogDetailWeb() {
  const instanceRef = useRef(null);

  // const [blog, setblog] = useState(null);
  const location = useLocation();
  // console.log(location.state);

  // useEffect(() => {
  //   setblog(location.state);
  // }, []);
  return (
    <>
      <EditorJs
        instanceRef={(instance) => (instanceRef.current = instance)}
        readOnly={true}
        data={location.state}
        tools={EDITOR_JS_TOOLS}
      />
      {/* <div>hello</div> */}
    </>
  );
}

export default BlogDetailWeb;
