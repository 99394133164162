import React, {useContext, useState} from "react";
import doctorImg from "../assets/img/docPP.jfif";
import noOrderImg from "../assets/img/no-orders.png";
import pills from "../assets/img/vitamin-pill.svg";
import Card from "./Card";
import docAnimated from "../assets/img/doctor.svg";
import labReport from "../assets/img/LabReport.svg";
import clinicImg from "../assets/img/clinicPic.jfif";
import calendar from "../assets/img/calendar.svg";
import styles from "./Home.module.css";
import HomeCard from "./HomeCard";
import {ClinicContext} from "../Context/ClinicContext";
import {Link, useHistory} from "react-router-dom";
import {OrdersContext} from "../Context/OrdersContext";
function Home() {
  const [scrollPosition, setScrollPosition] = useState(0);
  const {clinics} = useContext(ClinicContext);

  const {docOrders, labOrders} = useContext(OrdersContext);

  let history = useHistory();

  const onScroll = (e) => {
    // console.log("scroll position", e.target.scrollLeft / 300);
    setScrollPosition(e.target.scrollLeft);
  };

  return (
    <>
      {/* <ClinicContext.Provider> */}
      <div className={styles.homeContainer}>
        <div className={styles.flex}>
          <h2 className={styles.header}>Upcoming Schedule</h2>
          {/* <a className={styles.seeAll} href="#">
            See All
          </a> */}
          <Link className={styles.seeAll} to="/upcoming-orders">
            See All
          </Link>
        </div>
        <div onScroll={onScroll} className={styles.upcomingCardsList}>
          {docOrders?.length ? (
            docOrders?.map((item, index) => {
              if (item.type === "checkup") {
                return (
                  <div key={index} className={styles.consultationCard}>
                    <img className={styles.docPic} src={doctorImg} alt="" />
                    <div>
                      <h2 className={styles.name}>{item?.doctor.name}</h2>
                      <p className={styles.speciality}>
                        {item?.doctor.specialist[0]}
                      </p>
                    </div>

                    <div className={styles.date}>
                      <img src={calendar} alt="" width={20} />
                      {`${item?.date.dateString}, ${item?.time}`}
                    </div>
                  </div>
                );
              }
              if (item.type === "labtest") {
                return (
                  <div key={index} className={styles.consultationCard}>
                    <img className={styles.docPic} src={doctorImg} alt="" />
                    <div>
                      <h2 className={styles.name}>{item?.lab_name}</h2>
                      <p className={styles.speciality}>{item?.labtest_name}</p>
                    </div>

                    <div className={styles.date}>
                      <img src={calendar} alt="" width={20} />
                      {`${item?.date.dateString}, ${item?.time}`}
                    </div>
                  </div>
                );
              }
            })
          ) : (
            <div
              style={{width: "100%", flexDirection: "column"}}
              className={styles.consultationCard}
            >
              <img src={noOrderImg} alt="" />
              <div className={styles.name}>No Upcoming Orders</div>
            </div>
          )}

          {/* {labOrders?.schedule.map((item, index) => (
            <div key={index} className={styles.consultationCard}>
              <img className={styles.docPic} src={doctorImg} alt="" />
              <div>
                <h2 className={styles.name}>{item?.lab_name}</h2>
                <p className={styles.speciality}>{item?.labtest_name}</p>
              </div>

              <div className={styles.date}>
                <img src={calendar} alt="" width={20} />
                {`${item?.date.dateString}, ${item?.time}`}
              </div>
            </div>
          ))} */}
        </div>
        <div className={styles.dotContainer}>
          {docOrders?.length
            ? docOrders?.map((item, index) => (
                <span
                  key={index}
                  className={
                    parseInt(scrollPosition / 250) === index
                      ? styles.active
                      : styles.slickdot
                  }
                ></span>
              ))
            : ""}
        </div>
        <h2 className={styles.header}>Let's find what you need</h2>
        <div className={styles.cardList}>
          <div onClick={() => history.push("/doctors")}>
            <HomeCard
              image={docAnimated}
              title={"Consult Doctor"}
              text={"Specialised and Experienced Doctors"}
              width={"90px"}
            />
          </div>
          <div onClick={() => history.push("/labs")}>
            <HomeCard
              image={labReport}
              title={"Lab and Clinic"}
              text={"Specialised and Experienced Doctors"}
              width={"90px"}
            />
          </div>
        </div>
        <div className={styles.viewPharmacy}>
          <img className={styles.pills} src={pills} alt="" />
          <div className={styles.info}>
            <h2 className={styles.headText}>View Pharmacy</h2>
            <p className={styles.subText}>Contact and place order.</p>
          </div>
        </div>
        <div className={styles.flex}>
          <h2 className={styles.header}>Our Packages</h2>
          <a className={styles.seeAll} href="#">
            See All
          </a>
        </div>
        <div className={styles.cardList}>
          <HomeCard title={"Full Body Check"} text={"51+ Tests"} />
          <HomeCard title={"Full Body Check"} text={"51+ Tests"} />
          <HomeCard title={"Full Body Check"} text={"51+ Tests"} />
        </div>
        <div className={styles.flex}>
          <h2 className={styles.header}>Nearby Clinics</h2>
          {/* <a className={styles.seeAll} href="#">
            See All
          </a> */}
          <Link className={styles.seeAll} to="/clinics">
            See All
          </Link>
        </div>
        <div className={styles.cardList}>
          {/* {console.log("Loging clinics from home: ", clinics)} */}

          {clinics?.map((item, index) => (
            <div key={index} onClick={() => history.push(`/clinic/${item.id}`)}>
              <HomeCard
                image={clinicImg}
                title={item.name}
                text={item.location.place}
                width={"155px"}
                radius={"10px"}
              />
            </div>
          ))}

          {/* <HomeCard
            image={clinicImg}
            title={"Clinic Name"}
            text={"location, Delhi pincode 110088"}
            width={"155px"}
            radius={"10px"}
          />
          <HomeCard
            image={clinicImg}
            title={"Clinic Name"}
            text={"location, Delhi pincode 110088"}
            width={"155px"}
            radius={"10px"}
          />
          <HomeCard
            image={clinicImg}
            title={"Clinic Name"}
            text={"location, Delhi pincode 110088"}
            width={"155px"}
            radius={"10px"}
          /> */}
        </div>
      </div>
      {/* </ClinicContext.Provider> */}
    </>
  );
}

export default Home;
