import React, {useContext, useState} from "react";
import isEmail from "validator/lib/isEmail";

import {useHistory} from "react-router-dom";
import {ReactComponent as Logo} from "../assets/img/asset-1.svg";
import {AuthContext} from "../auth";
import {createUser} from "../services/firebaseServices";

import styles from "./SignUpDetails.module.css";
// import "./SignUp.css";

function SignUpDetails() {
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("Male");
  const [error, setError] = useState();

  const {currentUser} = useContext(AuthContext);

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(name, age, email, gender);
    const isValidEmail = isEmail(email);
    if (isValidEmail) {
      setError();
      let data = {
        Info: {
          addresses: "",
          age: age,
          email: email,
          gender: gender,
          img: "",
          loc: {lat: 28.489436, lon: 77.2967215},
          loyalty_points: 0,
          name: name,
          phone_no: currentUser.phoneNumber,
        },
        active_address: [],
        active_family: [],
      };
      createUser(currentUser.uid, data).then((res) => history.push("/home"));
    } else {
      setError("Invalid email!");
    }
    // .then((res) => history.push("/home"));
  };
  return (
    <>
      <div className={styles.signupContainer}>
        <Logo className={styles.logo} />
        <h1 className={styles.formHeading}>Enter Your Details</h1>
        <form className={styles.formContainer} onSubmit={handleSubmit}>
          <input
            required
            className={styles.input}
            type="text"
            name="name"
            placeholder="Full Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            required
            className={styles.input}
            type="text"
            name="age"
            placeholder="Age"
            value={age}
            onChange={(e) => setAge(e.target.value)}
          />
          <select
            name="gender"
            value={gender}
            onChange={(e) => setGender(e.target.value)}
            className={styles.selectMenu}
          >
            <option value="Male">Male</option>
            <option value="Female">Female</option>
            <option value="Other">Other</option>
          </select>
          <input
            required
            className={styles.input}
            type="email"
            name="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {error ? (
            <p style={{textAlign: "center", color: "orange"}}>{error}</p>
          ) : (
            ""
          )}
          <button className={styles.inputBtn} type="submit">
            Proceed
          </button>
        </form>
      </div>
    </>
  );
}

export default SignUpDetails;
