import React, {useEffect, useState} from "react";
import ClinicCard from "./ClinicCard";
import clinicPic from "../assets/img/clinicPic.jfif";

import styles from "./AllPharmacies.module.css";
import {getAllPharmacies} from "../services/firebaseServices";

function AllPharmacies() {
  const [pharmacies, setPharmacies] = useState();

  const fetchAllPharmacies = async () => {
    const response = await getAllPharmacies();
    setPharmacies(response);
    console.log(response);
  };

  const sortWithRating = () => {
    setPharmacies((prevPharmacies) =>
      prevPharmacies.slice().sort((a, b) => {
        return b.rating - a.rating;
        // console.log(a, b);
      })
    );
    console.log(pharmacies, "pharmastate");
  };

  useEffect(() => {
    fetchAllPharmacies();
    console.log("effect runs");
  }, []);

  return (
    <>
      <div className={styles.pharmaciesContainer}>
        <div className={styles.searchContainer}>
          {/* <img src={search} alt="" /> */}
          <input
            className={styles.search}
            type="text"
            placeholder="Search doctors, clinics and more..."
          />
        </div>
        <div className={styles.sortContainer}>
          <span className={styles.sortLabel}>Sort</span>
          <button
            onClick={() => sortWithRating()}
            className={styles.sortButton}
          >
            Rating
          </button>
          <button className={styles.sortButton}>Distance</button>
        </div>
        <div className={styles.pharmaciesList}>
          {pharmacies?.map((item) => (
            <ClinicCard
              key={item.number}
              image={clinicPic}
              name={item.name}
              rating={item.rating}
              place={item.location.city}
              button={"Make a Call"}
              phoneNumber={item.number}
            />
          ))}
        </div>
      </div>
    </>
  );
}

export default AllPharmacies;
