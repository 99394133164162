import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
import logo from "../../assets/img/asset-1.svg";
import instaIcon from "../../assets/img/insta.png";
import fbIcon from "../../assets/img/fb.png";
import linkedinIcon from "../../assets/img/linkedin.png";
import mapIcon from "../../assets/img/map.png";
import mailIcon from "../../assets/img/mail.svg";
import phoneIcon from "../../assets/img/phone.svg";

function Footer() {
  return (
    <>
      <div className="footer-container">
        <div className="logo">
          <img src={logo} alt="cliniker-logo" />
        </div>
        <div className="social">
          <h3 className="ft-head-text">Follow Us On</h3>
          <table className="table">
            <tbody>
              <tr>
                <td>
                  <img
                    className="social-icon"
                    src={instaIcon}
                    alt="Instagram"
                  />
                </td>
                <td>
                  <a
                    className="f-links"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.instagram.com/cliniker_india/"
                  >
                    Instagram
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <img className="social-icon" src={fbIcon} alt="Facebook" />
                </td>
                <td>
                  <a
                    className="f-links"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.facebook.com/cliniker.india/"
                  >
                    Facebook
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    className="social-icon"
                    src={linkedinIcon}
                    alt="Linkedin"
                  />
                </td>
                <td>
                  <a
                    className="f-links"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.linkedin.com/company/cliniker/"
                  >
                    LinkedIn
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="contact">
          <h3 className="ft-head-text">Get In Touch</h3>
          <table className="table">
            <tbody>
              <tr>
                <td>
                  <a
                    className="f-links"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.google.com/maps/place/MAXcare+Multispeciality+Clinic/@28.6198535,77.4243184,17z/data=!3m1!4b1!4m5!3m4!1s0x390cef4d250f78fb:0x7905ede25cb16586!8m2!3d28.6198535!4d77.4265071"
                  >
                    <img className="contact-icon" src={mapIcon} alt="Map" />
                  </a>
                </td>
                <td>
                  <a
                    className="f-links"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.google.com/maps/place/MAXcare+Multispeciality+Clinic/@28.6198535,77.4243184,17z/data=!3m1!4b1!4m5!3m4!1s0x390cef4d250f78fb:0x7905ede25cb16586!8m2!3d28.6198535!4d77.4265071"
                  >
                    Mahagun Mart, Gaur City-2, Sector-16C, Greater Noida West -
                    201306
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a className="f-links" href="mailto: support@cliniker.com">
                    <img className="contact-icon" src={mailIcon} alt="Mail" />
                  </a>
                </td>
                <td>
                  <a className="f-links" href="mailto: support@cliniker.com">
                    support@cliniker.com
                  </a>
                </td>
              </tr>
              <tr>
                <td>
                  <a className="f-links" href="tel:8826264333">
                    <img
                      className="contact-icon"
                      src={phoneIcon}
                      alt="Contact"
                    />
                  </a>
                </td>
                <td>
                  <a className="f-links" href="tel:8826264333">
                    8826264333
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className="policy-container">
        <Link
          className="privacy-policy"
          to="/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </Link>
      </div>
    </>
  );
}

export default Footer;
