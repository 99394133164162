import React from "react";

import partnerLogo from "../assets/img/asset-1.svg";

import styles from "./OurPartners.module.css";

function OurPartners() {
  return (
    <>
      <div className={styles.partnersContainer}>
        <div className={styles.partnersCard}>
          <h2 className={styles.header}>Our Partners</h2>

          <div className={styles.grid}>
            <div className={styles.partnerInfo}>
              <img className={styles.logo} src={partnerLogo} alt="" />
              <p className={styles.name}>Clinic Name</p>
            </div>
            <div className={styles.partnerInfo}>
              <img className={styles.logo} src={partnerLogo} alt="" />
              <p className={styles.name}>Clinic Name</p>
            </div>
            <div className={styles.partnerInfo}>
              <img className={styles.logo} src={partnerLogo} alt="" />
              <p className={styles.name}>Clinic Name</p>
            </div>
            <div className={styles.partnerInfo}>
              <img className={styles.logo} src={partnerLogo} alt="" />
              <p className={styles.name}>Clinic Name</p>
            </div>
            <div className={styles.partnerInfo}>
              <img className={styles.logo} src={partnerLogo} alt="" />
              <p className={styles.name}>Clinic Name</p>
            </div>
            <div className={styles.partnerInfo}>
              <img className={styles.logo} src={partnerLogo} alt="" />
              <p className={styles.name}>Clinic Name</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OurPartners;
