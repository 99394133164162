import React, {useContext, useState, useEffect} from "react";
import {Link} from "react-router-dom";
import profilePic from "../assets/img/docPP.jfif";
import {AuthContext} from "../auth";
import {UserContext} from "../Context/UserContext";
import {
  changeActiveAddress,
  getAllAddresses,
} from "../services/firebaseServices";

import styles from "./FamilyProfilesList.module.css";

function MyAddressesList() {
  const [addressess, setAddressess] = useState();
  const {currentUser} = useContext(AuthContext);
  const {user} = useContext(UserContext);

  const fetchAllAddress = async () => {
    const response = await getAllAddresses(currentUser.uid);
    // console.log("fetch func", response);
    setAddressess(response);
  };

  const makeActiveAddress = (item) => {
    // console.log(item);
    changeActiveAddress(item, currentUser.uid).then((res) => {
      window.location.reload();
    });
  };

  useEffect(() => {
    fetchAllAddress();
  }, []);

  return (
    <>
      <div className={styles.listContainer}>
        {addressess?.map((item, index) => (
          <div key={index} className={styles.profileCard}>
            <p className={styles.address}>{item.address_line1}</p>
            <p className={styles.address}>{item.address_line2}</p>
            <p className={styles.address}>{item.address_line3}</p>
            <p className={styles.address}>{item.pincode}</p>
            <div className={styles.btnContainer}>
              <Link
                to={{
                  pathname: "/add-address",
                  state: item,
                }}
              >
                <button className={styles.editBtn}>EDIT ADDRESS</button>
              </Link>
              <button
                disabled={item.address_id === user?.active_address[0]}
                onClick={() => makeActiveAddress(item)}
                className={styles.setBtn}
                style={{
                  backgroundColor:
                    item.address_id === user?.active_address.address_id
                      ? "#444049"
                      : "",
                }}
              >
                {item.address_id === user?.active_address.address_id
                  ? "ACTIVE"
                  : "SET AS ACTIVE"}
              </button>
            </div>
          </div>
        ))}

        <Link style={{textDecoration: "none"}} to="/add-address">
          <button className={styles.addBtn}>ADD ADDRESS</button>
        </Link>
      </div>
    </>
  );
}

export default MyAddressesList;
