import React from "react";
import BookAppointment from "./LandingPage/BookAppointment";
import ClinicsSection from "./LandingPage/ClinicsSection";
import MainSection from "./LandingPage/MainSection";
import Footer from "./Footer/Footer";

import FooterNew from "./Footer/FooterNew";
// import About from "./LandingPage/About";
import DownloadSection from "./LandingPage/DownloadSection";
import PharmaciesSection from "./LandingPage/PharmaciesSection";
import LabTestSection from "./LandingPage/LabTestSection";
import DocConsultSection from "./LandingPage/DocConsultSection";
import HeroSection from "./LandingPage/HeroSection";
import VaultSection from "./LandingPage/VaultSection";
import LoyaltyPoints from "./LandingPage/LoyaltyPoints";
// import ConsultDoctorWeb from "../Pages/ConsultDoctorWeb";

function LandingPage() {
  return (
    <>
      {/* <MainSection />
      <ClinicsSection />
      <BookAppointment /> */}
      {/* <Footer /> */}
      {/* <ConsultDoctorWeb /> */}
      <HeroSection />
      <DocConsultSection />
      <LabTestSection />
      <PharmaciesSection />
      <DownloadSection />
      {/* <About /> */}
      <VaultSection />
      <LoyaltyPoints />
      {/* <FooterNew /> */}
    </>
  );
}

export default LandingPage;
