import React, {useContext, useEffect, useRef, useState} from "react";
import app from "../../firebase";
import {AuthContext} from "../../auth";
import logo from "../../assets/img/logo.png";
import navItemImg from "../../assets/img/userImgTemp.png";
// import userImgTemp from "../../assets/img/profileImgTemp.png";
import userImgTemp from "../../assets/img/profileImg.png";

import heroCD from "../../assets/img/heroCD.png";
import heroLT from "../../assets/img/heroLT.png";
import heroCovid from "../../assets/img/heroCovid.png";
import heroPharmacy from "../../assets/img/heroPharmacy.png";
import heroAbout from "../../assets/img/heroAbout.png";
import heroBusiness from "../../assets/img/heroBusiness.png";

import styles from "./NavBarWeb.module.css";
import {Link, NavLink, useLocation} from "react-router-dom";

function NavBarWeb() {
  const [navToggle, setNavToggle] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const {currentUser} = useContext(AuthContext);

  const handleNavToggle = () => {
    setNavToggle(!navToggle);
  };

  const navRef = useRef(null);
  useOutsideAlerter(navRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          // alert("You clicked outside of me!");
          setNavToggle(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const updateMedia = () => {
    setScreenSize(window.innerWidth);
  };

  useEffect(() => {
    // document.addEventListener("mousedown", (event) => {
    //   console.log("clicked");
    //   if (navRef.current.contains(event.target)) {
    //     setNavToggle(!navToggle);
    //   }
    // });
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const location = useLocation();

  return (
    <>
      {/* {console.log(screenSize)} */}
      {screenSize <= 450 ||
      (!(location.pathname === "/") && screenSize > 450) ? (
        <div ref={navRef} className={styles.navbarContainer}>
          <Link onClick={() => setNavToggle(false)} to="/">
            <img className={styles.logoImg} src={logo} alt="" />
          </Link>
          <div className={navToggle ? styles.active : styles.navItemsContainer}>
            <NavLink
              onClick={() => setNavToggle(false)}
              style={{textDecoration: "none"}}
              to="/consult"
            >
              <div className={styles.navItem}>
                <img className={styles.navItemImg} src={heroCD} alt="" />
                <p className={styles.navText}>Consult Doctor</p>
              </div>
            </NavLink>
            <NavLink
              onClick={() => setNavToggle(false)}
              style={{textDecoration: "none"}}
              to="/book"
            >
              <div className={styles.navItem}>
                <img className={styles.navItemImg} src={heroLT} alt="" />
                <p className={styles.navText}>Book Lab Test</p>
              </div>
            </NavLink>
            <NavLink
              onClick={() => setNavToggle(false)}
              style={{textDecoration: "none"}}
              to="/covid"
            >
              <div className={styles.navItem}>
                <img className={styles.navItemImg} src={heroCovid} alt="" />
                <p className={styles.navText}>Covid 19</p>
              </div>
            </NavLink>
            <NavLink
              onClick={() => setNavToggle(false)}
              style={{textDecoration: "none"}}
              to="/pharmacy"
            >
              <div className={styles.navItem}>
                <img className={styles.navItemImg} src={heroPharmacy} alt="" />
                <p className={styles.navText}>Pharmacy</p>
              </div>
            </NavLink>
            <NavLink
              onClick={() => setNavToggle(false)}
              style={{textDecoration: "none"}}
              to="/about"
            >
              <div className={styles.navItem}>
                <img className={styles.navItemImg} src={heroAbout} alt="" />
                <p className={styles.navText}>About Us</p>
              </div>
            </NavLink>
            <NavLink
              onClick={() => setNavToggle(false)}
              style={{textDecoration: "none"}}
              to="/enquiry"
            >
              <div className={styles.navItem}>
                <img className={styles.navItemImg} src={heroBusiness} alt="" />
                <p className={styles.navText}>Buisness</p>
              </div>
            </NavLink>
            <NavLink
              onClick={() => setNavToggle(false)}
              style={{textDecoration: "none"}}
              to="/team"
            >
              <div className={styles.navItem}>
                <img className={styles.navItemImg} src={heroAbout} alt="" />
                <p className={styles.navText}>Our Team</p>
              </div>
            </NavLink>
            {currentUser ? (
              <div
                onClick={() => app.auth().signOut()}
                className={styles.logoutItem}
              >
                <img className={styles.navItemImg} src={navItemImg} alt="" />
                <p className={styles.navText}>LogOut</p>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className={styles.userTab}>
            <img className={styles.profileImg} src={userImgTemp} alt="" />
            <p className={styles.navText}>Login / Signup</p>
          </div>
          <div onClick={() => handleNavToggle()} className={styles.burger}>
            <div className={styles.line}></div>
            <div className={styles.line}></div>
            <div className={styles.line}></div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default NavBarWeb;
