import React from "react";
import bellIcon from "../assets/img/bell.svg";
import reportIcon from "../assets/img/report.svg";

import styles from "./UserProfile.module.css";

function Notifications() {
  return (
    <>
      <div className={styles.profileContainer}>
        <div className={styles.date}>12/04/2021</div>
        <div className={styles.menu}>
          <div className={styles.flex}>
            <img className={styles.icon} width={15} src={bellIcon} alt="" />
            <h2 className={styles.header}>Attachment and Reports</h2>
          </div>
          <div className={styles.flex}>
            <img className={styles.icon} width={15} src={reportIcon} alt="" />
            <h2 className={styles.header}>Notifications/Reminders</h2>
          </div>
          <div className={styles.flex}>
            <img className={styles.icon} width={15} src={reportIcon} alt="" />
            <h2 className={styles.header}>Family Profiles</h2>
          </div>
          <div className={styles.flex}>
            <img className={styles.icon} width={15} src={bellIcon} alt="" />
            <h2 className={styles.header}>My Addresses</h2>
          </div>
        </div>

        <div className={styles.date}>12/04/2021</div>
        <div className={styles.menu}>
          <div className={styles.flex}>
            <img className={styles.icon} width={15} src={bellIcon} alt="" />
            <h2 className={styles.header}>Attachment and Reports</h2>
          </div>
          <div className={styles.flex}>
            <img className={styles.icon} width={15} src={reportIcon} alt="" />
            <h2 className={styles.header}>Notifications/Reminders</h2>
          </div>
          <div className={styles.flex}>
            <img className={styles.icon} width={15} src={reportIcon} alt="" />
            <h2 className={styles.header}>Family Profiles</h2>
          </div>
          <div className={styles.flex}>
            <img className={styles.icon} width={15} src={bellIcon} alt="" />
            <h2 className={styles.header}>My Addresses</h2>
          </div>
        </div>
      </div>
    </>
  );
}

export default Notifications;
