import React, {useContext, useEffect, useState} from "react";
import ReactStars from "react-rating-stars-component";
import clinicPic from "../assets/img/clinicPic.jfif";
import arrow from "../assets/img/arrow.svg";

import styles from "./ClinicDetails.module.css";
import {
  addItemToDocCart,
  addItemToLabCart,
  getClinic,
  getClinicDocs,
} from "../services/firebaseServices";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Carousel} from "react-responsive-carousel";
import {useHistory} from "react-router-dom";
import {ScheduleContext} from "../Context/ScheduleContext";

function ClinicDetails({match}) {
  const [clinic, setClinic] = useState();
  const [loading, setLoading] = useState(true);
  const [openDC, setOpenDC] = useState(false);
  const [openLT, setOpenLT] = useState(false);
  const [openIDD, setOpenIDD] = useState(false);
  const [clinicDocs, setClinicDocs] = useState();

  const history = useHistory();

  const {order, setOrder} = useContext(ScheduleContext);

  // console.log(match.params.clinicId);

  const fetchClinic = async () => {
    const response = await getClinic(match.params.clinicId);
    // console.log(response);
    setClinic(response);
    setLoading(false);
  };

  const fetchClinicDocs = async () => {
    let cId = parseInt(match.params.clinicId);
    const response = await getClinicDocs(cId);
    setClinicDocs(response);
    // console.log(response);
  };

  useEffect(() => {
    fetchClinic();
    fetchClinicDocs();
  }, []);

  const addLabTestToCart = async () => {
    const response = await addItemToLabCart();
    history.push("/cart");
  };

  return (
    <>
      {/* <button onClick={addDocToCart}>add</button> */}
      {!loading && clinic && (
        <div className={styles.clinicContainer}>
          {/* {console.log(clinic)} */}
          <h1 className={styles.clinicName}>{clinic.name}</h1>
          <Carousel showThumbs={false} autoPlay={true}>
            <div>
              <img className={styles.clinicImg} src={clinicPic} alt="" />
            </div>
            <div>
              <img className={styles.clinicImg} src={clinicPic} alt="" />
            </div>
          </Carousel>
          <h3 className={styles.clinicAddress}>
            HOUSE NO., STREE NO., PINCODE- 100045
          </h3>
          <p className={styles.subText}>Doctor Consultations, Lab Tests</p>

          <div className={styles.ddWrapper}>
            <div
              className={styles.ddHeader}
              onClick={() => setOpenDC(!openDC)}
              role="button"
            >
              <div className={styles.ddTitle}>
                <h2>Doctor Consultation</h2>
              </div>
              <div className={styles.ddAction}>
                <img src={arrow} width={20} alt="" />
              </div>
            </div>
            {/* {console.log(clinicDocs)} */}
            {openDC && (
              <ul className={styles.ddClinicList}>
                {console.log("clinic Docs", clinicDocs)}
                {clinicDocs?.map((item, index) => (
                  <li key={index} className={styles.ClinicItem}>
                    <div className={styles.docCard}>
                      <div className={styles.name}>
                        <h3 className={styles.cardHeader}>{item.name}</h3>
                        <p className={styles.cardPara}>{item.specialist[0]}</p>
                      </div>
                      {/* <div className={styles.rating}>⭐⭐⭐⭐⭐</div> */}
                      <ReactStars
                        classNames={styles.rating}
                        count={5}
                        value={item.rating}
                        edit={false}
                        size={24}
                        isHalf={true}
                        activeColor="#ffd700"
                      />
                      <div className={styles.expertise}>
                        <h3 className={styles.cardHeader}>Area of Expertise</h3>
                        <p className={styles.cardPara}>
                          {item.expertise.map((item) => `${item},`)}
                        </p>
                      </div>
                      <div className={styles.timing}>
                        <div style={{display: "inline-flex"}}>
                          <h3
                            style={{color: "#373737"}}
                            className={styles.cardPara}
                          >
                            Timing-
                          </h3>
                          <h3 className={styles.cardPara}>7:00am-9:00pm</h3>
                        </div>
                        <div style={{display: "inline-flex", marginLeft: 0}}>
                          <h3
                            style={{color: "#373737"}}
                            className={styles.cardPara}
                          >
                            Days-
                          </h3>
                          {/* <h3 className={styles.cardPara}>Mon, Tue, Wed</h3> */}
                          <h3 className={styles.cardPara}>
                            {item.clinics.map((item) => {
                              if (item.id === parseInt(clinic.id)) {
                                let days = "";
                                item.days.map((day) => {
                                  days += day + ", ";
                                });
                                return days;
                                // console.log("days: ", days);
                              }
                            })}
                          </h3>
                        </div>
                      </div>
                      <div className={styles.fee}>
                        <p className={styles.cardHeader}>
                          Consultation Fee - <b>₹ {item.fees}</b>
                        </p>
                      </div>
                      <div className={styles.btn}>
                        <button
                          onClick={() => {
                            setOrder({
                              order_type: "consultation",
                              active_order: {
                                doctor: item,
                                clinic_id: clinic.id,
                              },
                              // appointment_id: Math.random(),
                              // clinic_id: clinic.id,
                              // clinic_location: clinic.location,
                              // clinic_name: clinic.name,
                              // doctor_id: item.doc_id,
                              // doctor_name: item.name,
                              // consultation_fee: item.fees,
                              // doctor_specialist: item.specialist[0],
                              // clinics: item.clinics,
                            });
                            // console.log(clinic);
                            history.push("/schedule");
                          }}
                        >
                          Book Appointment
                        </button>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            )}
          </div>

          <div className={styles.ddWrapper}>
            <div
              className={styles.ddHeader}
              onClick={() => setOpenLT(!openLT)}
              role="button"
            >
              <div className={styles.ddTitle}>
                <h2>Lab Tests</h2>
              </div>
              <div className={styles.ddAction}>
                <img src={arrow} width={20} alt="" />
              </div>
            </div>
            {openLT && (
              <>
                {console.log(clinic)}
                <div className={styles.searchContainer}>
                  <input
                    className={styles.search}
                    type="text"
                    placeholder="Search doctors, clinics and more..."
                  />
                </div>
                {/* {console.log("clinic", clinic)} */}
                <ul className={styles.ddClinicList}>
                  {clinic?.lab_services?.map((item, index) => (
                    <li key={index} className={styles.ClinicItem}>
                      <div className={styles.docCard}>
                        <div className={styles.name}>
                          <h3 className={styles.cardHeader}>
                            {item.lab_test_name}
                          </h3>
                          <p className={styles.cardPara}>Package Name</p>
                        </div>
                        {/* <div className={styles.rating}>⭐⭐⭐⭐⭐</div> */}
                        <ReactStars
                          classNames={styles.rating}
                          count={5}
                          value={5}
                          edit={false}
                          size={24}
                          isHalf={true}
                          activeColor="#ffd700"
                        />
                        <div className={styles.expertise}>
                          <h3 className={styles.cardHeader}>Facilities</h3>
                          <p className={styles.cardPara}>
                            Doctor Consultation, Lab Test
                          </p>
                        </div>
                        <div className={styles.timing}>
                          <div style={{display: "inline-flex"}}>
                            <h3
                              style={{color: "#373737"}}
                              className={styles.cardPara}
                            >
                              Report in -
                            </h3>
                            <h3 className={styles.cardPara}>5 Hours</h3>
                          </div>
                        </div>
                        <div className={styles.fee}>
                          <p className={styles.cardHeader}>
                            Test Fee - <b>₹ {item.fees}</b>
                          </p>
                        </div>
                        <div className={styles.btn}>
                          <button
                            onClick={() => {
                              // console.log(item);
                              setOrder({
                                type: "labtest",
                                clinic: clinic,
                                labtest_name: item.lab_test_name,
                                labtest_type: "type",
                                address: {
                                  address_id: "3p0YR4LQQ9DUo0DzUF1s",
                                  address_line1: "A 52, Building 20",
                                  address_line2: "Gaur City, Noida Extension",
                                  address_line3: "Noida, UP",
                                  pincode: "121009",
                                },
                                lab_id: clinic.id,
                                fees: item.fees,
                                collection_option: {clinic: true, home: false},
                                patient_name: "Test Name",
                                // time: clinic.time,
                              });
                              // console.log("O", order);
                              history.push("/schedule");
                            }}
                          >
                            Add TO Cart
                          </button>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default ClinicDetails;
