import React from "react";
import SearchBar from "../components/WebComponents/SearchBar";

import userImg from "../assets/img/userImgTemp.png";

import bannerImg from "../assets/img/consultDocBanner.png";

import styles from "./ListingPage.module.css";

function ListingPage() {
  return (
    <>
      <div className={styles.listingPageContainer}>
        <section className={styles.bannerSection}>
          <div className={styles.bannerSection}>
            <img className={styles.bannerImg} src={bannerImg} alt="" />
            <div className={styles.searchComponent}>
              <SearchBar />
            </div>
          </div>
        </section>
        <div className={styles.listingDiv}>
          <div className={styles.listContainer}>
            <div className={styles.listHeader}>
              <h3 className={styles.titleText}>Doctors</h3>
              <button className={styles.viewAllBtn}>View All</button>
            </div>
            <div className={styles.cardsContainer}>
              <div className={styles.card}>
                <img className={styles.cardImg} src={userImg} alt="" />
                <div className={styles.cardInfo}>
                  <h3 className={styles.titleText}>Dr. Name</h3>
                  <p className={styles.paraText}>Specialised in Diabetes</p>
                  <p className={styles.paraText}>Area of expertise</p>
                </div>
              </div>
              <div className={styles.card}>
                <img className={styles.cardImg} src={userImg} alt="" />
                <div className={styles.cardInfo}>
                  <h3 className={styles.titleText}>Dr. Name </h3>
                  <p className={styles.paraText}>Specialised in Diabetes</p>
                  <p className={styles.paraText}>Area of expertise</p>
                </div>
              </div>
              <div className={styles.card}>
                <img className={styles.cardImg} src={userImg} alt="" />
                <div className={styles.cardInfo}>
                  <h3 className={styles.titleText}>Dr. Name </h3>
                  <p className={styles.paraText}>Specialised in Diabetes</p>
                  <p className={styles.paraText}>Area of expertise</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.listContainer}>
            <div className={styles.listHeader}>
              <h3 className={styles.titleText}>Clinics</h3>
              <button className={styles.viewAllBtn}>View All</button>
            </div>
            <div className={styles.cardsContainer}>
              <div className={styles.card}>
                <img className={styles.cardImg} src={userImg} alt="" />
                <div className={styles.cardInfo}>
                  <h3 className={styles.titleText}>Clinic Name</h3>
                  <p className={styles.paraText}>Specialised in Diabetes</p>
                  {/* <p className={styles.paraText}>Area of expertise</p> */}
                </div>
              </div>
              <div className={styles.card}>
                <img className={styles.cardImg} src={userImg} alt="" />
                <div className={styles.cardInfo}>
                  <h3 className={styles.titleText}>Clinic Name</h3>
                  <p className={styles.paraText}>Specialised in Diabetes</p>
                  {/* <p className={styles.paraText}>Area of expertise</p> */}
                </div>
              </div>
              <div className={styles.card}>
                <img className={styles.cardImg} src={userImg} alt="" />
                <div className={styles.cardInfo}>
                  <h3 className={styles.titleText}>Clinic Name</h3>
                  <p className={styles.paraText}>Specialised in Diabetes</p>
                  {/* <p className={styles.paraText}>Area of expertise</p> */}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.listContainer}>
            <div className={styles.listHeader}>
              <h3 className={styles.titleText}>Lab Tests</h3>
              <button className={styles.viewAllBtn}>View All</button>
            </div>
            <div className={styles.cardsContainer}>
              <div className={styles.card}>
                <img className={styles.cardImg} src={userImg} alt="" />
                <div className={styles.cardInfo}>
                  <h3 className={styles.titleText}>Test Name</h3>
                  <p className={styles.paraText}>Specialised in Diabetes</p>
                  {/* <p className={styles.paraText}>Area of expertise</p> */}
                </div>
              </div>
              <div className={styles.card}>
                <img className={styles.cardImg} src={userImg} alt="" />
                <div className={styles.cardInfo}>
                  <h3 className={styles.titleText}>Test Name</h3>
                  <p className={styles.paraText}>Specialised in Diabetes</p>
                  {/* <p className={styles.paraText}>Area of expertise</p> */}
                </div>
              </div>
              <div className={styles.card}>
                <img className={styles.cardImg} src={userImg} alt="" />
                <div className={styles.cardInfo}>
                  <h3 className={styles.titleText}>Test Name</h3>
                  <p className={styles.paraText}>Specialised in Diabetes</p>
                  {/* <p className={styles.paraText}>Area of expertise</p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ListingPage;
