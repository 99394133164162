import React from "react";

import styles from "./TnC.module.css";

function TnC() {
  return (
    <>
      <div className={styles.container}>
        <h1 className={styles.header}>Terms and Conditions</h1>
        <div className={styles.line}></div>
        <p>
          Any accessing or browsing of the Platform and using the Services (as
          defined in these Terms and Conditions) implies that users agree to all
          the terms and conditions. If a user disagrees with any part of the
          Terms and Conditions, then the user may discontinue access or use of
          the Platform.
        </p>
        <br />
        <p>
          The Agreement is published in compliance of, and is governed by the
          provisions of Indian law, including but not limited to the Indian
          Contract Act, 1872, and the (Indian) Information Technology Act, 2000
        </p>
        <br />
        <p>
          The Platform only facilitates connections between the user and service
          provider and bears no responsibility for the outcome of any such
          medical consultation or other Services obtained by user.
        </p>
        <br />
        <p>
          Under no circumstances shall Cliniker be responsible for any loss or
          damage, including personal injury or death, resulting from use of
          theServices, from any User Content or Cliniker Content posted on or
          through the Services, or from the conduct of any Users of the Services
          orThird Party Service Provider, whether online or offline. Cliniker
          cannot guarantee and does not promise any specific results from use of
          theServices.
        </p>
        <br />
        <p>
          This Portal is controlled and operated from India and Cliniker makes
          no representation that the content, information or materials made
          available herein are appropriate or will be available for use in other
          locations. Access And use of this Portal from outside India is
          entirely at your sole risk and the user agree and undertake to be
          responsible for compliance with all applicable local laws and agree to
          release, discharge and absolve the Cliniker Parties from any liability
          or loss in this respect.
        </p>
        <br />
        <p>
          Cliniker reserves the right to introduce and initiate new features,
          functionalities and components to the Portal and/or change, alter,
          modify, suspend, discontinue or remove the existing ones without any
          prior notice to you. Further, Cliniker is entitled to discontinue
          (either permanently or temporarily) one or more of the Services
          provided or terminate the Portal Without any prior notice to you.
        </p>

        <br />
        <h3>Sign-In:</h3>
        <br />
        <p>
          To use our Services, Users need to register on the Platform in order
          to use the Platform or the Services provided through the Platform. In
          order to register, user have to provide certain personal details
          including but not limited to user name, phone number, email address,
          birth date, gender, family member details, etc.
        </p>
        <br />
        <h3>Medical Consultation:</h3>
        <br />
        <ul>
          <li>
            Your medical records may be viewed by the service provider on the
            chat facility provided on the Platform in the course of medical
            consultations and will remain accessible to user for a specific
            number of days after a particular consultation concludes, as
            determined by the service provider.
          </li>
          <br />
          <li>
            The Services are not meant in any way for emergency and
            life-threatening conditions. It is advised to take the patient to
            the nearest hospital in such cases.
          </li>
          <br />

          <li>
            Users shall be prepared to share all relevant documents or reports
            to the Practitioner promptly upon request.
          </li>
          <br />

          <li>
            For every paid consultation on the Consult platform, user shall not
            obtain consultation for more than one user. In the event, user
            attempt to obtain consultation for more than one user through a
            single paid consultation on the Consult platform, such consultations
            will not be addressed by the relevant Practitioner.
          </li>
          <br />

          <li>
            While We take reasonable efforts to ensure that relevant information
            on Medical Experts such as their specialisation, qualification, area
            of practice, experience, fees, location, visiting hours, etc.
            provided under the Consultation Service is accurate and updated at
            frequent intervals, we shall not be liable for any inaccuracies or
            incompleteness of such information or any direct or indirect losses
            or injuries caused on account of such inaccuracies or incompleteness
          </li>
          <br />
          <li>
            Cliniker shall not be liable for deficiency or shortfall in Services
            / misdiagnosis / faulty judgment / interpretation error / perception
            error / adverse events / inefficacy of prescribed treatment or
            advice or investigation reports / validity of the advice or
            prescription or investigation reports provided by the practitioner /
            unavailability of the recommended or prescribed treatment or
            medication under any condition or circumstances. Users are advised
            to use their discretion for following the advice obtained post
            medical consultation with the practitioner via the Platform or post
            Services.
          </li>
        </ul>
        <br />
        <h2>Health Vault:</h2>
        <br />
        <ul>
          <li>
            Users may use this service to manage all medical cases,
            appointments, and other medical records and map a digital overview
            of the user's medical journey. the user agrees that by usage of this
            Service, Portal may receive the user's medical records including the
            user's diagnostic & other lab reports and doctor prescriptions from
            both Third PartyService Providers and/or directly from the user(s).
          </li>
          <br />
          <li>
            The accuracy, integrity and the completeness of the information
            provided in the HealthVault depend on the information provided by
            the user or the user authorised Third Party Service Provider. Portal
            is merely a facilitator communicating or storing information and
            does not in any manner alter the information uploaded on the said
            HealthVault. Cliniker shall in no event be liable for any losses or
            injuries suffered by user or arising out of any actions or omissions
            made by user or any other person including a Third Party Service
            Provider relying on the information provided in the HealthVault.
          </li>
          <br />
        </ul>
        <h2>Diagnostic test booking:</h2>
        <br />
        <ul>
          <li>
            Users may book an appointment for seeking the diagnostic tests and
            packages offered on the Platform. the user may book a diagnostic
            test(s) and package(s) on the Platform and visit the concerned
            diagnostic centre or schedule the sample pickup from home.
          </li>
          <br />
          <li>
            Users will receive e-prescription from Cliniker based on the medical
            consultation. Accessing your medical records on the Platform.
          </li>
          <br />
          <li>
            Cliniker itself does not provide any diagnostic services. If the
            user receives any medical advice / investigation reports from a
            third party the user has contacted through the Platform, the users
            are responsible for assessing such advice, and reports the
            consequences of acting on such advice or reports, and all
            post-consultation follow-up action.
          </li>
          <br />
          <li>
            Additional charges shall be applicable for home sample collection
            facilities and shall be based on distance from the diagnostic
            service provider.
          </li>
          <br />
        </ul>
        <h2>Rescheduling and cancellation Policy:</h2>
        <br />
        <ul>
          <li>
            User will receive a confirmation of appointment for a medical
            consultation or diagnostic tests with a service provider of your
            choice, on the Platform and / or via SMS and / or email and / or an
            online communication or messaging service.
          </li>
          <br />
          <li>
            Cliniker reserves the right to reschedule or cancel an appointment
            without any prior notice.
          </li>
          <br />
          <li>
            The time provided for consultation to the user is indicative and
            actual consultation time may change depending on the consulting
            service provider’s discretion.
          </li>
          <br />
          <li>
            Consultations or test appointments can be booked, rescheduled or
            cancelled free of cost up to one hour prior to the start of the
            appointment. The user is allowed to reschedule a physical
            consultation once for free.
          </li>
          <br />
        </ul>
        <div id="refund" className="refund">
          <h2>Refund Policy:</h2>
          <br />
          <ul>
            <li>
              In the event that, the Practitioner with whom User has booked a
              paid appointment via the Website, has not been able to meet the
              User, User will need to write to us at support@cliniker.com, in
              which case, the entire consultation amount as mentioned on the
              Website will be refunded to the User within the next five (5) to
              six (6) business days in the original mode of payment done by the
              User while booking. Refunds will only be made against genuine and
              trackable cancellation requests generated by the user by clicking
              on the cancellation button provided in the App’s user dashboard.
            </li>
            <br />
            <li>
              Refund request against delays, nature of advice obtained, efficacy
              of treatment, health outcomes of consultation will not be
              entertained.
            </li>
            <br />
            <li>
              If the service provider rescheduled or cancelled the appointment,
              the user is liable to raise a refund request if he does not find
              any other suitable schedule.
            </li>
            <br />
          </ul>
        </div>
      </div>
    </>
  );
}

export default TnC;
