import React from "react";
import SearchBar from "../components/WebComponents/SearchBar";

import fever from "../assets/img/fever.png";
import cough from "../assets/img/cold.png";
import fatigue from "../assets/img/fatigue.png";
import headache from "../assets/img/headache.png";

// import bannerImg from "../assets/img/consultDocBanner.png";
import bannerLeftImg from "../assets/img/covidBannerLeft.png";
import bannerRightImg from "../assets/img/covidBannerRight.png";

import mask from "../assets/img/covidMask.png";
import vaccine from "../assets/img/covidVaccine.png";
import covidCheckup from "../assets/img/covidCheck.png";
import avoidCrowd from "../assets/img/covidCrowd.png";
import socialDistancing from "../assets/img/covidSocialDist.png";
import handWash from "../assets/img/covidHandWash.png";

// import packageImg from "../assets/img/packageImgTemp.png";
import covidTest1 from "../assets/img/covidTest1.png";
import covidTest2 from "../assets/img/covidTest2.png";
import covidTest3 from "../assets/img/covidTest3.png";
import covidTest4 from "../assets/img/covidTest4.png";

import styles from "./Covid19.module.css";
import DownloadSection from "../components/LandingPage/DownloadSection";

function Covid19() {
  return (
    <>
      <div className={styles.covid19Container}>
        <section className={styles.bannerSection}>
          <div className={styles.bannerImgsDiv}>
            <img className={styles.bannerLeftImg} src={bannerLeftImg} alt="" />
            <div className={styles.description}>
              <h2 className={styles.bannerTitle}>
                Prevent the spread of coronavirus
              </h2>
              <p className={styles.bannerText}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem
                aspernatur sapiente non, error maxime esse magnam vero ab quis
                cumque impedit mollitia suscipit, debitis accusamus?
              </p>
            </div>
            <img
              className={styles.bannerRightImg}
              src={bannerRightImg}
              alt=""
            />
          </div>
          <div className={styles.searchComponent}>
            <SearchBar />
          </div>
          {/* <div className={styles.bannerSection}>
            <img className={styles.bannerImg} src={bannerImg} alt="" />
            <div className={styles.searchComponent}>
              <SearchBar />
            </div>
          </div> */}
        </section>
        <section className={styles.tilesSection}>
          <div>
            {/* <div className={styles.specialisationsContainer}> */}
            <div className={styles.headingDiv}>
              <h3 className={styles.titleText}>
                Preferred lab test for Covid-19
              </h3>
              {/* <button className={styles.viewAllBtn}>view all</button> */}
            </div>
            <div className={styles.packageContainer}>
              <div className={styles.packageSquare}>
                <img className={styles.packageImg} src={covidTest1} alt="" />
                <div className={styles.packageInfo}>
                  <p className={styles.cardTitle}>Interleukin(IL)-6</p>
                  <div className={styles.flex}>
                    <span>80 tests</span>
                    <span>Know more</span>
                  </div>
                  <div className={styles.price}>
                    <span>₹1599</span>
                    <span className={styles.smallText}>+25 % off</span>
                  </div>
                  {/* <p>Earn cashback</p> */}
                  <button className={styles.bookBtn}>Book Now</button>
                </div>
              </div>
              <div className={styles.packageSquare}>
                <img className={styles.packageImg} src={covidTest2} alt="" />
                <div className={styles.packageInfo}>
                  <p className={styles.cardTitle}>C-Reactive Protein (CRP)</p>
                  <div className={styles.flex}>
                    <span>80 tests</span>
                    <span>Know more</span>
                  </div>
                  <div className={styles.price}>
                    <span>₹1599</span>
                    <span className={styles.smallText}>+25 % off</span>
                  </div>
                  {/* <p>Earn cashback</p> */}
                  <button className={styles.bookBtn}>Book Now</button>
                </div>
              </div>
              <div className={styles.packageSquare}>
                <img className={styles.packageImg} src={covidTest3} alt="" />
                <div className={styles.packageInfo}>
                  <p className={styles.cardTitle}>Ferritin</p>
                  <div className={styles.flex}>
                    <span>80 tests</span>
                    <span>Know more</span>
                  </div>
                  <div className={styles.price}>
                    <span>₹1599</span>
                    <span className={styles.smallText}>+25 % off</span>
                  </div>
                  {/* <p>Earn cashback</p> */}
                  <button className={styles.bookBtn}>Book Now</button>
                </div>
              </div>
              <div className={styles.packageSquare}>
                <img className={styles.packageImg} src={covidTest4} alt="" />
                <div className={styles.packageInfo}>
                  <p className={styles.cardTitle}>D-Dimer</p>
                  <div className={styles.flex}>
                    <span>80 tests</span>
                    <span>Know more</span>
                  </div>
                  <div className={styles.price}>
                    <span>₹1599</span>
                    <span className={styles.smallText}>+25 % off</span>
                  </div>
                  {/* <p>Earn cashback</p> */}
                  <button className={styles.bookBtn}>Book Now</button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.symptomsSection}>
          <div className={styles.headingDiv}>
            <h3 className={styles.titleText}>Covid 19 Symptoms</h3>
          </div>
          <div className={styles.symptomCard}>
            <img className={styles.cardImg} src={fever} alt="" />
            <h3 className={styles.cardTitle}>Fever or chills</h3>
            <p className={styles.paraText}>
              Fever causes the body temperature to rise above normal levels.
              This is part of the body’s immune response to infection.
            </p>
          </div>
          <div className={styles.symptomCard}>
            <img className={styles.cardImg} src={cough} alt="" />
            <h3 className={styles.cardTitle}>Cough</h3>
            <p className={styles.paraText}>
              People who develop COVID-19 symptoms experience a dry cough as an
              initial symptom.
            </p>
          </div>
          <div className={styles.symptomCard}>
            <img className={styles.cardImg} src={fever} alt="" />
            <h3 className={styles.cardTitle}>Breathing ailments</h3>
            <p className={styles.paraText}>
              It can be hard to breathe deeply. You may feel winded, or as if
              you can’t get enough air into your lungs.
            </p>
          </div>
          <div className={styles.symptomCard}>
            <img className={styles.cardImg} src={fatigue} alt="" />
            <h3 className={styles.cardTitle}>Fatigue</h3>
            <p className={styles.paraText}>
              Feeling tired, rundown and fatigue along with other symptoms might
              be signal to get yourself tested
            </p>
          </div>
          <div className={styles.symptomCard}>
            <img className={styles.cardImg} src={fever} alt="" />
            <h3 className={styles.cardTitle}>Muscle or body aches</h3>
            <p className={styles.paraText}>
              Muscle pain is often caused due to muscle inflammation at the time
              of viral infection
            </p>
          </div>
          <div className={styles.symptomCard}>
            <img className={styles.cardImg} src={headache} alt="" />
            <h3 className={styles.cardTitle}>Headache</h3>
            <p className={styles.paraText}>
              Headache has been reported as both early and late in the infection
              phase of the illness
            </p>
          </div>
        </section>
        <section className={styles.preventionSection}>
          <div className={styles.headingDiv}>
            <h3 className={styles.titleText}>
              Preventive measures to stay safe
            </h3>
          </div>
          <div className={styles.cardsContainer}>
            <div className={styles.preventionCard}>
              <img src={mask} alt="" />
              <p className={styles.paraText}>Wear mask</p>
            </div>
            <div className={styles.preventionCard}>
              <img src={handWash} alt="" />
              <p className={styles.paraText}>Wash/Sanitize your hands often</p>
            </div>
            <div className={styles.preventionCard}>
              <img src={socialDistancing} alt="" />
              <p className={styles.paraText}>Mantain Social distancing</p>
            </div>
            <div className={styles.preventionCard}>
              <img src={avoidCrowd} alt="" />
              <p className={styles.paraText}>
                Avoid crowds and poorly ventilated spaces.
              </p>
            </div>
            <div className={styles.preventionCard}>
              <img src={covidCheckup} alt="" />
              <p className={styles.paraText}>Monitor your health regularly</p>
            </div>
            <div className={styles.preventionCard}>
              <img src={vaccine} alt="" />
              <p className={styles.paraText}>
                Get COVID-19 vaccine when it is available to you.
              </p>
            </div>
          </div>
        </section>
        <DownloadSection />
      </div>
    </>
  );
}

export default Covid19;
