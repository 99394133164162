import React, {useEffect, useState} from "react";
import clinic from "../assets/img/clinicPic.jfif";
import {getABlog} from "../services/firebaseServices";

import EditorJs from "react-editor-js";
import {EDITOR_JS_TOOLS} from "./WebComponents/BlogTools";

import styles from "./BlogsList.module.css";

function BlogDetail({match}) {
  const [blog, setBlog] = useState();

  useEffect(() => {
    getABlog(match.params.blogId).then((res) => setBlog(res));
  }, []);

  return (
    <>
      <div className={styles.blogsContainer}>
        {blog ? (
          <EditorJs readOnly={true} data={blog} tools={EDITOR_JS_TOOLS} />
        ) : (
          ""
        )}
        {/* <div className={styles.blogCard}>
          <img className={styles.blogImg} src={clinic} alt="" />
          <div className={styles.details}>
            <h2 className={styles.blogHeader}>
              Scientist have discovered a new method for something
            </h2>
            <div className={styles.blogInfo}>
              <p className={styles.author}>By Raymond BlackBerry</p>
              <p className={styles.date}>12/05/2021</p>
            </div>
          </div>
          <div className={styles.content}>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum,
              natus impedit. Exercitationem maiores autem quibusdam accusamus,
              dignissimos nisi? Nam, hic! Iste eligendi, facilis expedita beatae
              libero vel vitae voluptates accusantium.
            </p>

            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Officiis
              cupiditate ducimus, ea quaerat eligendi quod molestiae reiciendis
              recusandae vel soluta amet animi ullam, consectetur velit omnis,
              inventore quasi facere similique.
            </p>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default BlogDetail;
