import React, {useContext, useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import {subDays, addDays} from "date-fns";

import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import styles from "./Schedule.module.css";
import {ScheduleContext} from "../Context/ScheduleContext";
import {
  addItemToDocCart,
  rescheduleDocOrder,
  rescheduleLabOrder,
} from "../services/firebaseServices";
import {Link, Redirect, useHistory} from "react-router-dom";
import {AuthContext} from "../auth";

import addedImg from "../assets/img/addedToCart.png";

function Schedule() {
  const [startDate, setStartDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState();
  // const [activeItem, setActiveItem] = useState({});
  const [timings, setTimings] = useState();
  const [tempOrder, setTempOrder] = useState();

  const [added, setAdded] = useState(false);

  const {order, setOrder} = useContext(ScheduleContext);
  const {currentUser} = useContext(AuthContext);

  const oldOrder = order.active_order;
  // const oldLabOrder = order;

  var slots = [];

  useEffect(() => {
    // was not rendering the first time a day was selected. TO fix that
    if (order) {
      // console.log(order);
      createSlots(days[startDate.getDay()]);
      modifyOrder(selectedTime);
    }
  }, [startDate, selectedTime]);

  const createSlots = (day) => {
    // console.log("day", day);
    if (order.type === "labtest") {
      // console.log("labtest order", order);
      setSlots(order?.clinic.time);
      setTimings(slots);
      return 1;
    } else if (order.order_type === "reschedule-labtest") {
      // console.log("labtest reschedule", order);
      setSlots(order?.active_order.clinic.time);
      setTimings(slots);
      return 1;
    } else {
      // console.log("consult order");
      for (let x = 0; x < order.active_order.doctor.clinics?.length; x++) {
        if (order?.active_order.doctor.clinics[x].days.includes(day)) {
          setSlots(order?.active_order.doctor.clinics[x].timing);
        }
      }
      setTimings(slots);
    }

    // console.log("timimgs after creating", timings);
  };

  const setSlots = (timing) => {
    let date = new Date();
    let currentTime = date.getHours();
    let nextHour = currentTime + 1;
    let inTime = timing.intime;
    let outTime = timing.outime;

    if (startDate.getDate() === date.getDate()) {
      console.log("matched", startDate.getDate(), date.getDate());
      inTime = nextHour;
    } else {
      inTime = timing.intime;
      console.log(
        "not matched",
        startDate.getDate(),
        date.getDate(),
        timing.intime
      );
    }

    let span = timing.outime - inTime;
    for (let x = 0; x < span; x++) {
      slots.push(
        {
          time: `${inTime + x}:00 - ${inTime + x}:10`,
          //   time:
          //     (timing.intime > 12 ? timing.intime + x - 12 : timing.intime + x) +
          //     ":00" +
          //     (timing.intime >= 12 ? "pm" : "am") +
          //     "-" +
          //     (timing.intime > 12 ? timing.intime + x - 12 : timing.intime + x) +
          //     ":10" +
          //     (timing.intime >= 12 ? "pm" : "am"),
          taken: false,
        },
        {
          time: `${inTime + x}:10 - ${inTime + x}:20`,
          taken: false,
        },
        {
          time: `${inTime + x}:20 - ${inTime + x}:30`,
          taken: false,
        },
        {
          time: `${inTime + x}:30 - ${inTime + x}:40`,
          taken: false,
        },
        {
          time: `${inTime + x}:40 - ${inTime + x}:50`,
          taken: false,
        },
        {
          time: `${inTime + x}:50 - ${inTime + x + 1}:00`,
          taken: false,
        }
      );
    }
    // console.log(timing);
    // let span = timing.outime - timing.intime;
    // console.log("span time: ", span);
    // for (let x = 0; x < span; x++) {
    //   slots.push(
    //     {
    //       time:
    //         (timing.intime > 12 ? timing.intime + x - 12 : timing.intime + x) +
    //         ":00" +
    //         (timing.intime >= 12 ? "pm" : "am") +
    //         "-" +
    //         (timing.intime > 12 ? timing.intime + x - 12 : timing.intime + x) +
    //         ":10" +
    //         (timing.intime >= 12 ? "pm" : "am"),
    //       taken: false,
    //     },
    //     {
    //       time:
    //         (timing.intime > 12 ? timing.intime + x - 12 : timing.intime + x) +
    //         ":10" +
    //         (timing.intime >= 12 ? "pm" : "am") +
    //         "-" +
    //         (timing.intime > 12 ? timing.intime + x - 12 : timing.intime + x) +
    //         ":20" +
    //         (timing.intime >= 12 ? "pm" : "am"),
    //       taken: false,
    //     },
    //     {
    //       time:
    //         (timing.intime > 12 ? timing.intime + x - 12 : timing.intime + x) +
    //         ":20" +
    //         (timing.intime >= 12 ? "pm" : "am") +
    //         "-" +
    //         (timing.intime > 12 ? timing.intime + x - 12 : timing.intime + x) +
    //         ":30" +
    //         (timing.intime >= 12 ? "pm" : "am"),
    //       taken: false,
    //     },
    //     {
    //       time:
    //         (timing.intime > 12 ? timing.intime + x - 12 : timing.intime + x) +
    //         ":30" +
    //         (timing.intime >= 12 ? "pm" : "am") +
    //         "-" +
    //         (timing.intime > 12 ? timing.intime + x - 12 : timing.intime + x) +
    //         ":40" +
    //         (timing.intime >= 12 ? "pm" : "am"),
    //       taken: false,
    //     },
    //     {
    //       time:
    //         (timing.intime > 12 ? timing.intime + x - 12 : timing.intime + x) +
    //         ":40" +
    //         (timing.intime >= 12 ? "pm" : "am") +
    //         "-" +
    //         (timing.intime > 12 ? timing.intime + x - 12 : timing.intime + x) +
    //         ":50" +
    //         (timing.intime >= 12 ? "pm" : "am"),
    //       taken: false,
    //     },
    //     {
    //       time:
    //         (timing.intime > 12 ? timing.intime + x - 12 : timing.intime + x) +
    //         ":50" +
    //         (timing.intime >= 12 ? "pm" : "am") +
    //         "-" +
    //         (timing.intime > 12
    //           ? timing.intime + x - 12 + 1
    //           : timing.intime + x + 1) +
    //         ":00" +
    //         (timing.intime >= 12 ? "pm" : "am"),
    //       taken: false,
    //     }
    //   );
    // }
  };

  // const timings = [
  //   {id: 1, slot: "7:00am-9:00pm"},
  //   {id: 2, slot: "6:00am-4:00pm"},
  //   {id: 3, slot: "12:00am-2:00pm"},
  //   {id: 4, slot: "3:00am-6:00pm"},
  // ];

  const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const history = useHistory();

  const modifyOrder = (pickedTime) => {
    // completes the order field
    let stime = pickedTime;
    if (order.order_type === "reschedule-consultation") {
      let temp = {
        ...order.active_order,
        date: {
          dateString: `${startDate.getFullYear()}-${startDate.getMonth()}-${startDate.getDate()}`,
          day: startDate.getDate(),
          month: startDate.getMonth(),
          year: startDate.getFullYear(),
        },
        time: stime,
        // day: days[startDate.getDay()],
      };
      setTempOrder(temp);
    } else if (order.order_type === "reschedule-labtest") {
      // console.log("modify reschedule-labtest");
      let temp = {
        ...order.active_order,
        date: {
          dateString: `${startDate.getFullYear()}-${startDate.getMonth()}-${startDate.getDate()}`,
          day: startDate.getDate(),
          month: startDate.getMonth(),
          year: startDate.getFullYear(),
        },
        time: stime,
        // day: days[startDate.getDay()],
      };
      // console.log("temp", temp);
      setTempOrder(temp);
    } else if (order.order_type === "consultation") {
      // console.log("type consultation", order);
      let temp = {
        ...order.active_order,
        date: {
          day: startDate.getDate(),
          month: startDate.getMonth(),
          year: startDate.getUTCFullYear(),
          dateString: `${startDate.getUTCFullYear()}-${startDate.getMonth()}-${startDate.getDate()}`,
          timestamp: Date.now(),
        },
        patient_name: "Test Name",
        time: selectedTime,
        type: "checkup",
        slot: 7,
        subslot: 2,
      };
      // console.log("temp", temp);
      setTempOrder(temp);
      // setOrder({
      //   ...order.active_order,
      //   date: {
      //     day: startDate.getDay(),
      //     month: startDate.getMonth(),
      //     year: startDate.getUTCFullYear(),
      //     dateString: `${startDate.getUTCFullYear()}-${startDate.getMonth()}-${startDate.getDate()}}`,
      //     timestamp: Date.now(),
      //   },
      //   patient_name: "Test Name",
      // });
    } else if (order.type === "labtest") {
      // console.log("modified order", order);
      let temp = {
        ...order,
        date: {
          day: startDate.getDate(),
          month: startDate.getMonth(),
          year: startDate.getUTCFullYear(),
          dateString: `${startDate.getUTCFullYear()}-${startDate.getMonth()}-${startDate.getDate()}`,
          timestamp: Date.now(),
        },
        time: selectedTime,
      };
      setTempOrder(temp);
      // console.log(tempOrder);
    }
    // console.log(pickedTime, order);
  };

  const rescheduleAppointment = () => {
    if (order.order_type === "reschedule-labtest") {
      // console.log("old", oldOrder);
      // let docId = order.activeItem.order_id;
      rescheduleDocOrder(tempOrder, oldOrder, currentUser.uid).then((res) =>
        history.push("/orders")
      ); //firebase func
    }
    // console.log("RS APT FUNC");
    if (order.order_type === "reschedule-consultation") {
      // console.log("old", oldOrder);
      // let docId = order.activeItem.order_id;
      rescheduleDocOrder(tempOrder, oldOrder, currentUser.uid).then((res) =>
        history.push("/orders")
      ); //firebase func
    }
  };

  const addToCart = () => {
    if (order.type === "labtest") {
      addItemToDocCart(tempOrder, currentUser.uid).then((res) => {
        // console.log(res);
        setAdded(true);
        setTimeout(() => {
          history.push("/cart");
        }, 2000);
      });
    }

    // adds the order made previously to the cart
    // console.log("type", order.order_type);
    if (order.order_type === "consultation") {
      // console.log(tempOrder);
      addItemToDocCart(tempOrder, currentUser.uid).then((res) => {
        // console.log(res);
        setAdded(true);
        setTimeout(() => {
          history.push("/cart");
        }, 2000);
      });

      // history.push("/cart");                // BUG: cart screen not re rendering when we visit cart after this.
      // <Redirect to="/cart" />;
      // console.log("order", order);
    }
    // if (order.order_type === "labtest") {
    //   // console.log(tempOrder);
    //   addItemToDocCart(tempOrder).then((res) => {
    //     // console.log(res);
    //     history.push("/cart");
    //   });
    // }
  };

  return (
    <>
      {/* {console.log("order from schedule", order)} */}
      {/* {console.log(slots)} */}
      <div className={styles.scheduleContainer}>
        <div className={styles.customDatePickerWidth}>
          <DatePicker
            wrapperClassName="datepicker"
            // onSelect={(item) => console.log("onselect", item)}
            selected={startDate}
            onSelect={(date) => {
              let selectedDate = date;
              setStartDate(selectedDate);
              // let day = days[startDate.getDay()];
              // createSlots(day);
            }}
            minDate={subDays(new Date(), 0)}
            maxDate={addDays(new Date(), 3)}
            inline
          />
          {/* {console.log(startDate.getDate())} */}
        </div>
        <div className={styles.slotsContainer}>
          <h2 className={styles.slotHeader}>Slots</h2>
          <div className={styles.slots}>
            {/* {console.log("time", startDate.getHours(), startDate.getMinutes())} */}
            {timings?.map((item, index) => {
              return (
                <button
                  onClick={() => {
                    let pickedTime = item.time;
                    setSelectedTime(pickedTime);
                    // setActiveItem(item);
                    // modifyOrder(pickedTime);
                    // console.log("details: ", selectedTime, startDate);
                  }}
                  key={index}
                  className={
                    item.time === selectedTime
                      ? styles.active
                      : styles.individualSlot
                  }
                >
                  {item.time}
                </button>
              );
            })}
          </div>
          {!timings?.length ? (
            <div
              style={{
                width: "100%",
                textAlign: "center",
                font: "normal normal normal 12px/18px Poppins",
              }}
            >
              Slots are not available for the selected date
            </div>
          ) : (
            ""
          )}
        </div>

        {order?.order_type === "reschedule-consultation" ||
        order?.order_type === "reschedule-labtest" ? (
          <button
            onClick={() => rescheduleAppointment()}
            className={styles.cartBtn}
          >
            RESCHEDULE
          </button>
        ) : (
          <button onClick={addToCart} className={styles.cartBtn}>
            ADD TO CART
          </button>
        )}

        {added ? (
          <div
            style={{
              marginTop: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img style={{marginBottom: 10}} src={addedImg} alt="" />
            <button
              style={{
                backgroundColor: "#27C33E",
                border: "none",
                borderRadius: 4,
                padding: 8,
              }}
            >
              ADDED TO CART
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default Schedule;
