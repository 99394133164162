import React, { useContext } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LandingPage from "./components/LandingPage";
import Navbar from "./components/Navbar/Navbar";
import PrivacyPolicy from "./components/LandingPage/PrivacyPolicy";

import LoggedNav from "./components/LoggedNav";
import DeliveryAndShipping from "./Pages/DeliveryAndShipping";
import Home from "./components/Home";
import DoctorDetails from "./components/DoctorDetails";
import AllClinics from "./components/AllClinics";
import AllPharmacies from "./components/AllPharmacies";
import Login from "./components/Login";
import SignUpDetails from "./components/SignUpDetails";
import ClinicDetails from "./components/ClinicDetails";
import Search from "./components/Search";
import DoctorSearchCard from "./components/DoctorSearchCard";
import DoctorListing from "./components/DoctorListing";
import LabTestListing from "./components/LabTestListing";
import Schedule from "./components/Schedule";
import MyOrders from "./components/MyOrders";
import OrdersUpcoming from "./components/OrdersUpcoming";
import ManageRecords from "./components/ManageRecords";
import AddRecord from "./components/AddRecord";
import BlogsList from "./components/BlogsList";
import BlogDetail from "./components/BlogDetail";
import UserProfile from "./components/UserProfile";
import FamilyProfilesList from "./components/FamilyProfilesList";
import MyAddressesList from "./components/MyAddressesList";
import AddProfile from "./components/AddProfile";
import AddAddress from "./components/AddAddress";
import ProfileAttachments from "./components/ProfileAttachments";
import Notifications from "./components/Notifications";
import Cart from "./components/Cart";
import CartSuccess from "./components/CartSuccess";
import CartFailiure from "./components/CartFailiure";
import NavBarWeb from "./components/Navbar/NavBarWeb";
import ConsultDoctorWeb from "./Pages/ConsultDoctorWeb";
import FooterNew from "./components/Footer/FooterNew";
import BookLabTest from "./Pages/BookLabTest";
import Covid19 from "./Pages/Covid19";
import { AuthContext, AuthProvider } from "./auth";
import PrivateRoute from "./components/PrivateRoute";
import Pharmacy from "./Pages/Pharmacy";
import BlogsListWeb from "./Pages/BlogsListWeb";
import BlogDetailWeb from "./Pages/BlogDetailWeb";
import { ClinicProvider } from "./Context/ClinicContext";
import { UserContext, UserProvider } from "./Context/UserContext";
import BlogInput from "./components/WebComponents/BlogInput";
import { OrdersProvider } from "./Context/OrdersContext";
import ListingPage from "./Pages/ListingPage";
import PartnerEnquiry from "./Pages/PartnerEnquiry";
import ContactUs from "./Pages/ContactUs";
import CartCheckout from "./components/CartCheckout";
import { CartProvider } from "./Context/CartContext";
import About from "./Pages/About";
import EditUserProfile from "./components/EditUserProfile";
import ScrollToTop from "./services/ScrollToTop";
import Contact from "./components/Contact";
import OurPartners from "./components/OurPartners";
import FAQ from "./components/FAQ";
import Coming from "./Pages/Coming";

import LimitWidth from "./LimitWidth";
import OurTeam from "./Pages/OurTeam";
import TnC from "./Pages/TnC";
import RefundPolicy from "./Pages/RefundPolicy";

function App() {
	const { user } = useContext(UserContext);

	const { currentUser } = useContext(AuthContext);

	const url = window.location.href;

	return (
		// <AuthProvider>
		//   <UserProvider>
		//     <OrdersProvider>
		<Router>
			<ScrollToTop />
			<div className="App">
				<>
					{/* <Route exact path={"/:a([A-Za-z]+)"}>
            <NavBarWeb />
          </Route> */}
					{/* <Route
            path={
              "/(about|consult|book|covid|pharmacy|blogs|blog|listing|enquiry)"
            }
            component={NavBarWeb}
          /> */}
					<Route
						path="/"
						render={() => (currentUser ? <LoggedNav /> : <NavBarWeb />)}
					/>
					{/* <Route
            path="/(orders|upcoming-orders|home|clinics|doctors|clinic/:clinicId|doctor/:doctorId/pharmacies|)"
            render={() => (currentUser ? <LoggedNav /> : "")}
          /> */}

					{/* {currentUser ? <LoggedNav /> : <NavBarWeb />} */}
					<>
						<Route exact path="/" component={LandingPage} />
						<Route exact path="/privacy-policy" component={PrivacyPolicy} />
						<Route path="/about" component={About} />
						<Route exact path="/consult" component={ConsultDoctorWeb} />
						<Route exact path="/book" component={BookLabTest} />
						<Route exact path="/covid" component={Covid19} />
						<Route exact path="/pharmacy" component={Pharmacy} />
						<Route exact path="/blogs" component={BlogsListWeb} />
						<Route exact path="/blog" component={BlogDetailWeb} />
						<Route exact path="/listing" component={ListingPage} />
						<Route exact path="/enquiry" component={PartnerEnquiry} />
						<Route exact path="/contactus" component={ContactUs} />
						<Route
							exact
							path="/delivery-and-shipping"
							component={DeliveryAndShipping}
						/>
						<Route exact path="/team" component={OurTeam} />
						<Route exact path="/terms-and-conditions" component={TnC} />
						<Route exact path="/refund-policy" component={RefundPolicy} />
						<Route exact path="/faq" component={FAQ} />
						<Route exact path="/coming" component={Coming} />
					</>
				</>

				<>
					{/* <PrivateRoute exact path="/labs" component={LabTestListing} /> */}

					{/* //Test Input Blog */}
					<Route exact path="/blog-input" component={BlogInput} />

					<LimitWidth />
					{/* <PrivateRoute exact path="/doctors" component={DoctorListing} />
          <PrivateRoute
            exact
            path="/doctor/:doctorId"
            component={DoctorDetails}
          /> */}
					<Route exact path="/search" component={Search} />
					{/* <Route exact path="/login" component={Login} /> */}
					{/* <PrivateRoute exact path="/blog-list" component={BlogsList} />
          <PrivateRoute exact path="/blog-detail/:blogId" component={BlogDetail} /> */}
					{/* <PrivateRoute
            exact
            path="/manage-records"
            component={ManageRecords}
          />
          <PrivateRoute
            exact
            path="/attachments"
            component={ProfileAttachments}
          /> */}
					{/* <PrivateRoute exact path="/schedule" component={Schedule} /> */}
					{/* <Route exact path="/orders" component={MyOrders} /> */}
					{/* <Route exact path="/upcoming-orders" component={OrdersUpcoming} /> */}
					{/* <Route exact path="/cart" component={Cart} /> */}
					{/* <PrivateRoute exact path="/pharmacies" component={AllPharmacies} /> */}
					{/* <PrivateRoute
            exact
            path="/signup-details"
            component={SignUpDetails}
          />
          <PrivateRoute exact path="/our-partners" component={OurPartners} />
          <PrivateRoute exact path="/contact" component={Contact} />
          <PrivateRoute exact path="/addresses" component={MyAddressesList} />
          <PrivateRoute exact path="/add-address" component={AddAddress} />
          <PrivateRoute exact path="/profile" component={UserProfile} />
          <PrivateRoute exact path="/add-profile" component={AddProfile} />
          <PrivateRoute exact path="/faq" component={FAQ} />
          <PrivateRoute
            exact
            path="/edit-profile"
            component={EditUserProfile}
          />
          <PrivateRoute
            exact
            path="/family-profiles"
            component={FamilyProfilesList}
          />
          <PrivateRoute exact path="/cart" component={Cart} />
          <PrivateRoute exact path="/cart-checkout" component={CartCheckout} />
          <PrivateRoute exact path="/orders" component={MyOrders} />
          <PrivateRoute
            exact
            path="/upcoming-orders"
            component={OrdersUpcoming}
          />
          <PrivateRoute exact path="/home" component={Home} /> */}
					{/* <PrivateRoute exact path="/clinics" component={AllClinics} />
          <PrivateRoute
            exact
            path="/clinic/:clinicId"
            component={ClinicDetails}
          /> */}
				</>
				<FooterNew />
			</div>
		</Router>
		//     </OrdersProvider>
		//   </UserProvider>
		// </AuthProvider>
	);
}

export default App;

{
	/* <FooterNew /> */
}
{
	/* <LoggedNav /> */
}
{
	/* <CartSuccess /> */
}
{
	/* <CartFailiure /> */
}
{
	/* <Cart /> */
}
{
	/* <Notifications /> */
}
{
	/* <ProfileAttachments /> */
}
{
	/* <AddAddress /> */
}
{
	/* <AddProfile /> */
}
{
	/* <MyAddressesList /> */
}
{
	/* <FamilyProfilesList /> */
}
{
	/* <UserProfile /> */
}
{
	/* <BlogDetail /> */
}
{
	/* <BlogsList /> */
}
{
	/* <AddRecord /> */
}
{
	/* <MyOrders /> */
}
{
	/* <OrdersUpcoming /> */
}
{
	/* <ManageRecords /> */
}
{
	/* <Schedule /> */
}
{
	/* <LabTestListing /> */
}
{
	/* <DoctorListing /> */
}
{
	/* <Search /> */
}
{
	/* <ClinicDetails /> */
}
{
	/* <Home /> */
}
{
	/* <DoctorDetails /> */
}
{
	/* <AllClinics /> */
}
{
	/* <AllPharmacies /> */
}
{
	/* <PrivacyPolicy /> */
}
{
	/* <Login /> */
}
{
	/* <SignUp /> */
}
