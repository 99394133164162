// import firebase from "firebase/app";
// import "firebase/auth";
// import "firebase/firestore";
// import "firebase/database";
// import "firebase/analytics";

// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyCc_480D-AB3Otr3gzFrvwBBGVtaKo9J5w",
//   authDomain: "cliniker-7995a.firebaseapp.com",
//   databaseURL: "https://cliniker-7995a-default-rtdb.firebaseio.com",
//   projectId: "cliniker-7995a",
//   storageBucket: "cliniker-7995a.appspot.com",
//   messagingSenderId: "70374055988",
//   appId: "1:70374055988:web:f4bef3c89d9b1d53d6ea61",
//   measurementId: "G-MJL30NCBSP",
// };

// firebase.initializeApp(firebaseConfig);

// export const auth = firebase.auth();
// export const firestore = firebase.firestore();
// export const firebaseDb = firebase.database().ref();
// export const analytics = firebase.analytics();
// export default firebase;

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import "firebase/database";

// mobile app config
const firebaseConfig = {
  // apiKey: "AIzaSyD_LtZlsLRJnGfcspZLv7VnBEFy2tLMFAc",
  // authDomain: "cliniker-app.firebaseapp.com",
  // databaseURL: "https://cliniker-app-default-rtdb.firebaseio.com",
  // projectId: "cliniker-app",
  // storageBucket: "cliniker-app.appspot.com",
  // messagingSenderId: "958944529049",
  // appId: "1:958944529049:web:49736ce268b20a8b950608",

  apiKey: "AIzaSyD_LtZlsLRJnGfcspZLv7VnBEFy2tLMFAc",
  authDomain: "cliniker-app.firebaseapp.com",
  databaseURL: "https://cliniker-app-default-rtdb.firebaseio.com",
  projectId: "cliniker-app",
  storageBucket: "cliniker-app.appspot.com",
  messagingSenderId: "958944529049",
  appId: "1:958944529049:web:3f1d7ac847c3e230950608",
};

const app = firebase.initializeApp(firebaseConfig);

export const db = app.firestore();
export const firestore = firebase.firestore;

export default app;

// let db = firebase.firestore();

// export default {
//   firebase,
//   db,
// };

// import firebase from "firebase";
// import "firebase/auth";
// import "firebase/firestore";

// // mobile app config
// const firebaseConfig = {
//   apiKey: "AIzaSyD_LtZlsLRJnGfcspZLv7VnBEFy2tLMFAc",
//   authDomain: "cliniker-app.firebaseapp.com",
//   databaseURL: "https://cliniker-app-default-rtdb.firebaseio.com",
//   projectId: "cliniker-app",
//   storageBucket: "cliniker-app.appspot.com",
//   messagingSenderId: "958944529049",
//   appId: "1:958944529049:web:49736ce268b20a8b950608",
// };

// const app = firebase.initializeApp(firebaseConfig);

// export const auth = app.auth();
// export const firestore = app.firestore();

// export default app;
