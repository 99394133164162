import {logDOM} from "@testing-library/react";
import {useEffect} from "react";
import {withRouter} from "react-router-dom";

function ScrollToTop({history}) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      if (!history.location.pathname.includes("#")) {
        // console.log(history.location.pathname);
        window.scrollTo(0, 0);
      }
      // console.log("cjakhjsd", history);
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
}

export default withRouter(ScrollToTop);
