import React from "react";
import ReactStars from "react-rating-stars-component";
import styles from "./Review.module.css";

function Review({ image, name }) {
  return (
    <>
      <div className={styles.reviewContainer}>
        <div className={styles.reviewUsr}>
          <img
            className={styles.reviewerImg}
            style={{ borderRadius: "50%" }}
            src={image}
            width="50px"
            height="50px"
            alt=""
          />
          <p className={styles.reviewerName}>{name}</p>
        </div>
        {/* <p className={styles.docRating}>⭐⭐⭐</p> */}
        <ReactStars
          classNames={styles.docRating}
          count={5}
          value={5}
          edit={false}
          size={20}
          isHalf={true}
          activeColor="#ffd700"
        />
        <h4 className={styles.cmntTitle}>Title of the comment</h4>
        <p className={styles.cmnt}>
          The doctor was really professional and it was a good experience
        </p>
      </div>
    </>
  );
}

export default Review;
