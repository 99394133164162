import React, {useEffect, useState} from "react";
import ReactStars from "react-rating-stars-component";
import docPic from "../assets/img/docPP.jfif";
import clinicImg from "../assets/img/clinicPic.jfif";
import hospital from "../assets/img/hospital.svg";
import Review from "./Review";
import Card from "./Card";

import styles from "./DoctorDetails.module.css";
import {getDoctor} from "../services/firebaseServices";

function DoctorDetails({match}) {
  const [doctor, setDoctor] = useState();
  const [loading, setLoading] = useState(true);

  const fetchDoctor = async () => {
    const doc = await getDoctor(parseInt(match.params.doctorId));
    console.log(doc);
    setDoctor(doc);
    setLoading(false);
  };

  useEffect(() => {
    fetchDoctor();
  }, []);

  return (
    <>
      {!loading && doctor && (
        <div className={styles.docDetailsContainer}>
          <div className={styles.docImageContainer}>
            <img className={styles.profileImage} src={docPic} alt="" />
          </div>
          <div className={styles.doctorInfo}>
            <h2>Dr {doctor.name}</h2>
            <p>
              {doctor.specialist.map((item) => {
                return item + " ";
              })}
            </p>
          </div>
          <div className={styles.grid}>
            <div>
              <p>Experience</p>
              <p>
                <b style={{color: "#835CB9"}}>{doctor.experience} Years</b>
              </p>
            </div>
            <div>
              <p>Ratings</p>
              {/* <p>⭐⭐⭐⭐⭐</p> */}
              <ReactStars
                classNames={styles.rating}
                count={5}
                value={doctor.rating}
                edit={false}
                size={20}
                isHalf={true}
                activeColor="#ffd700"
              />
            </div>
            <div>
              <p>Fee</p>
              <p>
                <b style={{color: "#835CB9"}}>₹ {doctor.fees}</b>
              </p>
            </div>
          </div>
          <div className={styles.cardList}>
            {doctor.clinics.map((item) => (
              <Card
                key={item.id}
                image={clinicImg}
                clinicName={"Clinic Name"}
                location={item.location}
                inTime={item.timing.intime}
                outTime={item.timing.outime}
                days={item.days}
                // width={"155px"}
                // radius={"10px"}
                button={"BOOK APPOINTMENT"}
              />
            ))}
            {/* <Card
              image={clinicImg}
              title={"Clinic Name"}
              text={"location, Delhi pincode 110088"}
              width={"155px"}
              radius={"10px"}
              button={"BOOK APPOINTMENT"}
            />
            <Card
              image={clinicImg}
              title={"Clinic Name"}
              text={"location, Delhi pincode 110088"}
              width={"155px"}
              radius={"10px"}
              button={"BOOK APPOINTMENT"}
            /> */}
          </div>
          {console.log(doctor)}
          <div className={styles.details}>
            <div className={styles.expertise}>
              <h2 className={styles.detailsHeader}>Area of Expertise</h2>
              <p className={styles.detailsPara}>
                {/* Child/Infant Care, New Born Child Growth, Child care treatment */}
                {doctor.expertise.map((item) => {
                  return item + ", ";
                })}
              </p>
            </div>
            <div className={styles.experience}>
              <h2 className={styles.detailsHeader}>Past Affiliation</h2>
              <ul className={styles.expList}>
                {doctor.pastaff.map((item, index) => (
                  <li key={index}>
                    <img
                      className={styles.hospitalIcon}
                      src={hospital}
                      alt=""
                    />
                    <p className={styles.detailsPara}>{item}</p>
                  </li>
                ))}
                {/* <li>
                  <img className={styles.hospitalIcon} src={hospital} alt="" />
                  <p className={styles.detailsPara}>
                    10 Years experience in Appolo Hospital
                  </p>
                </li> */}
              </ul>
            </div>
            <div className={styles.reviews}>
              <h2 className={styles.detailsHeader}>Reviews</h2>
              <Review image={docPic} name={"Vibhor"} />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default DoctorDetails;
