import React from "react";

import styles from "./MyOrders.module.css";

function UpcomingDocOrderCard({
  patientName,
  clinicName,
  docName,
  time,
  date,
  address,
}) {
  return (
    <>
      <div className={styles.orderDiv}>
        <div className={styles.patientName}>
          <h2 className={styles.header}>{patientName}</h2>
        </div>
        <div className={styles.flex}>
          <div className={styles.detailsDiv}>
            <h2 className={styles.header}>{docName}</h2>
            <h2 className={styles.header}>{clinicName}</h2>
            <p className={styles.location}>{address}</p>
          </div>
          <div className={styles.timingDiv}>
            <p>
              <span className={styles.boldHead}>Timing - {time}</span>
              {/* {timing.intime}-{timing.outime} */}
              {/* {console.log("intime", timing)} */}
            </p>
            <p>
              <span className={styles.boldHead}>Day - </span>
              {/* {`${date.day}th ${date.month} ${date.year}`} */}
              {date}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpcomingDocOrderCard;
