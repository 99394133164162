import React, {useContext, useEffect, useState} from "react";
import EmptyCart from "./EmptyCart";
import LabTestForCart from "./LabTestForCart";
import DocConsultForCart from "./DocConsultForCart";
import Modal from "react-modal";

import clock from "../assets/img/clock.svg";

import Stepper from "react-stepper-js";
import "react-stepper-js/dist/index.css";

// import whiteCircle from "../assets/img/whiteCircle.svg";
// import wallet from "../assets/img/wallet.png";

import styles from "./Cart.module.css";
import {
  getAllAddresses,
  getAllFamilyProfiles,
  getDocCart,
  getLabCart,
  updateCartInfo,
} from "../services/firebaseServices";
import {useHistory} from "react-router-dom";
import {CartContext} from "../Context/CartContext";
import {AuthContext} from "../auth";

function Cart() {
  // const [docCartProducts, setDocCartProducts] = useState();
  // const [labCartProducts, setLabCartProducts] = useState();
  // const [loading, setLoading] = useState(true);
  const [formStep, setFormStep] = useState(1);
  const [nameModal, setNameModal] = useState(false);
  const [addressModal, setAddressModal] = useState(false);
  const [familyNames, setFamilyNames] = useState();
  const [addressList, setAddressList] = useState();
  const [selectedName, setSelectedName] = useState();
  const [selectedAddress, setSelectedAddress] = useState();
  const [checked, setChecked] = useState(false);
  const [addressChecked, setAddressChecked] = useState(false);
  const [index, setIndex] = useState();

  const {currentUser} = useContext(AuthContext);

  const [reload, setReload] = useState(false);

  const reloadCartOnRemove = () => {
    setReload(!reload);
    // console.log("reload runs", reload);
  };

  const changeAddress = () => {
    if (!addressChecked) {
      let tempArray = docCartProducts;
      for (let i = 0; i <= index; i++) {
        if (i === index) {
          console.log("SA", selectedAddress);
          tempArray[i].address = JSON.parse(selectedAddress);
        }
      }
      console.log("not checked arr", tempArray);
      // console.log("not checked add", JSON.parse(selectedAddress));
      updateCartInfo(tempArray, currentUser.uid).then((res) =>
        setReload(!reload)
      );
    } else if (addressChecked) {
      let tempArray = docCartProducts;
      console.log("checked", selectedAddress);
      tempArray?.map((item) => {
        if (item.address) {
          item.address = JSON.parse(selectedAddress);
        }
      });
      console.log("checked after", tempArray);
      updateCartInfo(tempArray, currentUser.uid).then((res) =>
        setReload(!reload)
      );
    }
  };

  const changePatientName = () => {
    if (!checked) {
      // console.log(checked);
      let tempArray = docCartProducts;
      for (let i = 0; i <= index; i++) {
        if (i === index) {
          tempArray[i].patient_name = selectedName;
        }
      }
      updateCartInfo(tempArray, currentUser.uid).then((res) =>
        setReload(!reload)
      );
      setDocCartProducts(tempArray);

      // console.log("change name only for index", index, selectedName);
      // console.log("change name only for index", index, tempArray);
      // setDocCartProducts((prevState) => ({
      //   ...prevState,

      // }));
      // docCartProducts[index].patient_name = selectedName;
    } else if (checked) {
      // console.log(checked);
      let tempArray = docCartProducts;
      for (let i = 0; i < docCartProducts?.length; i++) {
        tempArray[i].patient_name = selectedName;
      }
      updateCartInfo(tempArray, currentUser.uid).then((res) =>
        setReload(!reload)
      );
      setDocCartProducts(tempArray);
      // console.log("change name for every item", index, tempArray);
    }
  };

  const {
    docCartProducts,
    setDocCartProducts,
    labCartProducts,
    setLabCartProducts,
  } = useContext(CartContext);

  const fetchFamilyMembers = async () => {
    let names = [];
    const response = await getAllFamilyProfiles(currentUser.uid);
    response.map((item) => {
      names.push(item.name);
    });
    setFamilyNames(names);
    // console.log("names: ", names);
  };

  const fetchAddresses = async () => {
    let addresses = [];
    const response = await getAllAddresses(currentUser.uid);
    setAddressList(response);
    // console.log("list: ", response);
  };

  const fetchDocCart = async () => {
    const response = await getDocCart(currentUser.uid);
    setDocCartProducts(response.appointments);
    // console.log("doc cart products fetched");
  };

  // const fetchLabCart = async () => {
  //   const response = await getLabCart("jRzPsJlUYRQ9jut2Ls31qrddMNJ2");
  //   // console.log(response);
  //   setLabCartProducts(response.appointments);
  // };

  useEffect(() => {
    fetchDocCart();
    // fetchLabCart();
    fetchFamilyMembers();
    fetchAddresses();
    // setLoading(false);
  }, [reload]);

  const triggerNameModal = (index) => {
    setNameModal(!nameModal);
    setIndex(index);
  };
  const triggerAddressModal = (index) => {
    setAddressModal(!addressModal);
    setIndex(index);
  };

  // const [cartItems, setCartItems] = useState({
  //   checkup: ["1"],
  //   lab: ["1"],
  // });

  let history = useHistory();

  const proceedButton = () => {
    if (docCartProducts?.length) {
      return (
        <button
          // onClick={() => setFormStep(formStep + 1)}
          onClick={() => history.push("/cart-checkout")}
          className={styles.proceedBtn}
        >
          PROCEED
        </button>
      );
    }
  };

  return (
    <>
      <div className={styles.cartContainer}>
        <Stepper
          color="#835cb9"
          fontSize="15px"
          fontColor="#7f8386"
          steps={[
            {label: "Details"},
            {label: "Place Order"},
            {label: "Confirmation"},
          ]}
          currentStep={1}
        />
        <Modal
          isOpen={addressModal}
          //   onAfterOpen={afterOpenModal}
          onRequestClose={() => setAddressModal(false)}
          //   style={customStyles}
          className={styles.modal}
          ariaHideApp={false}
          contentLabel="Example Modal"
        >
          <h1 className={styles.header}>Select Address -</h1>
          <select
            onChange={(e) => {
              setSelectedAddress(e.target.value);
              // console.log(e.target.value);
            }}
            // value={selectedAddress}
            className={styles.selectMenu}
          >
            {addressList?.map((item, index) => (
              <option
                key={index}
                className={styles.selectOption}
                value={JSON.stringify(item)}
              >
                {item.address_line1}
              </option>
            ))}
            {/* <option className={styles.selectOption} value="">
              Address 1
            </option>
            <option className={styles.selectOption} value="">
              Address 2
            </option>
            <option className={styles.selectOption} value="">
              Address 3
            </option> */}
          </select>
          <div
            onClick={() => history.push("/add-address")}
            className={styles.addProfile}
          >
            <img style={{marginRight: 3}} src={clock} width={15} alt="" />
            <p>Add New Address</p>
          </div>
          <label style={{display: "block"}}>
            <input
              type="checkbox"
              defaultChecked={addressChecked}
              onChange={() => setAddressChecked(!addressChecked)}
            />{" "}
            Apply to all tests
          </label>
          <button onClick={() => changeAddress()} className={styles.proceedBtn}>
            CONFIRM
          </button>
        </Modal>
        <Modal
          isOpen={nameModal}
          //   onAfterOpen={afterOpenModal}
          onRequestClose={() => setNameModal(false)}
          //   style={customStyles}
          className={styles.modal}
          ariaHideApp={false}
          contentLabel="Example Modal"
        >
          <h1 className={styles.header}>Select Profile -</h1>
          <select
            className={styles.selectMenu}
            onChange={(e) => setSelectedName(e.target.value)}
            value={selectedName}
          >
            {familyNames?.map((item, index) => (
              <option className={styles.selectOption} key={index}>
                {item}
              </option>
            ))}
            {/* <option className={styles.selectOption} value="">
              User Name
            </option>
            <option className={styles.selectOption} value="">
              User Name
            </option>
            <option className={styles.selectOption} value="">
              User Name
            </option> */}
          </select>
          <div
            onClick={() => history.push("/add-profile")}
            className={styles.addProfile}
          >
            <img style={{marginRight: 3}} src={clock} width={15} alt="" />
            <p>Add New Profile</p>
          </div>
          <label style={{display: "block"}}>
            <input
              type="checkbox"
              defaultChecked={checked}
              onChange={() => setChecked(!checked)}
            />{" "}
            Apply to all tests
          </label>
          <button
            className={styles.proceedBtn}
            onClick={() => changePatientName()}
          >
            CONFIRM
          </button>
        </Modal>

        {formStep === 1 && docCartProducts?.length ? (
          docCartProducts?.map((item, index) => {
            if (item.type === "checkup") {
              return (
                <DocConsultForCart
                  key={index}
                  item={item}
                  index={index}
                  triggerNameModal={triggerNameModal}
                  reload={reloadCartOnRemove}
                  cart={docCartProducts}
                />
              );
            }
            if (item.type === "labtest") {
              return (
                <LabTestForCart
                  key={index}
                  item={item}
                  index={index}
                  triggerNameModal={triggerNameModal}
                  triggerAddressModal={triggerAddressModal}
                  reload={reloadCartOnRemove}
                  cart={docCartProducts}
                />
              );
            }
          })
        ) : (
          <EmptyCart />
        )}

        {/* {formStep === 1
          ? docCartProducts?.map((item, index) => (
              <DocConsultForCart
                key={index}
                item={item}
                index={index}
                triggerNameModal={triggerNameModal}
                reload={reloadCartOnRemove}
              />
            ))
          : ""} */}

        {/* {formStep === 1
          ? labCartProducts?.map((item, index) => (
              <LabTestForCart
                key={index}
                triggerNameModal={triggerNameModal}
                triggerAddressModal={triggerAddressModal}
                labName={item.labtest_name}
                packageName={item.labtest_type}
                collection={item.collection_option}
                fees={item.consultation_fee}
                patientName={item.patient_name}
                optedSlot={item.opted_slot}
              />
            ))
          : ""} */}

        {/* {formStep === 2 && (
          <>
            <div className={styles.couponSection}>
              <h2 className={styles.header}>Have a Code?</h2>
              <form className={styles.couponForm}>
                <input
                  className={styles.couponInput}
                  type="text"
                  placeholder="Apply Coupon Code"
                />
                <h2 className={styles.formHeader}>
                  Referal by Staff Redeem Here
                </h2>
                <input
                  className={styles.couponInput}
                  type="text"
                  placeholder="Apply Coupon Code"
                />
              </form>
            </div>

            <div className={styles.walletSection}>
              <img
                className={styles.walletImg}
                src={wallet}
                width={25}
                alt=""
              />
              <div className={styles.walletDetails}>
                <h3 className={styles.walletHeader}>Redeem Loyalty Points</h3>
                <p className={styles.walletPara}>
                  Balance - <b style={{color: "#835cb9"}}>300</b>
                </p>
              </div>
            </div>
            <p className={styles.additionalText}>
              ⭐ Get 10 Loyalty points for every Rs 100 order
            </p>

            <div className={styles.billSection}>
              <h1 className={styles.header}>Bill Details</h1>
              <div className={styles.gridBill}>
                <h2 className={styles.billHeader}>Doctor consultation</h2>
                <h2 className={styles.billAmount}>₹ 250</h2>
                <h2 className={styles.billHeader}>Lab Test</h2>
                <h2 className={styles.billAmount}>₹ 250</h2>
                <h2 className={styles.billHeader}>Loyalty Points</h2>
                <h2 className={styles.billAmount}>₹ 250</h2>
              </div>
              <div className={styles.line}></div>
              <div className={styles.gridBill}>
                <h2 className={styles.billHeader}>Total</h2>
                <h2 className={styles.billAmount}>₹ 250</h2>
              </div>
            </div>
          </>
        )} */}

        {/* {cartItems.checkup.length && cartItems.lab.length && (
          <button
            onClick={() => setFormStep(formStep + 1)}
            className={styles.proceedBtn}
          >
            PROCEED
          </button>
        )} */}

        {proceedButton()}
      </div>
    </>
  );
}

export default Cart;
