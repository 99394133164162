import React, {useContext, useEffect, useState} from "react";

import isMobilePhone from "validator/lib/isMobilePhone";
import isEmail from "validator/lib/isEmail";

import styles from "./AddProfile.module.css";
import {addFamilyMember, editFamilyMember} from "../services/firebaseServices";
import {AuthContext} from "../auth";
import {Redirect, useHistory, useLocation} from "react-router-dom";

function AddProfile() {
  const [name, setName] = useState("");
  const [age, setAge] = useState("");
  const [contact, setContact] = useState("");
  const [relation, setRelation] = useState("");
  const [gender, setGender] = useState("Male");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  const {currentUser} = useContext(AuthContext);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.state !== undefined) {
      console.log("something is there", location);
      const {name, age, email, gender, relation, contact} = location.state;
      setName(name);
      setContact(contact);
      setRelation(relation);
      setAge(age);
      setGender(gender);
      setEmail(email);
    } else {
      // console.log("nothing");
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValidPhone = isMobilePhone(contact);
    const isValidEmail = isEmail(email);

    if (isValidEmail && isValidPhone) {
      console.log("looks good");
      let data = {
        id: Math.random(),
        name: name,
        age: age,
        contact: contact,
        relation: relation,
        gender: gender,
        email: email,
      };
      if (location.state === undefined) {
        addFamilyMember(data, currentUser.uid).then((res) =>
          // console.log("res after add: ", res)
          history.push("/family-profiles")
        );
      } else {
        console.log("else", location);
        editFamilyMember(data, currentUser.uid, location.state.doc_id).then(
          (res) => history.push("/family-profiles")
        );
      }
    } else {
      setError("Error in email or contact");
      console.log(error);
    }

    // console.log(name, age, contact, relation, gender, email);
  };

  return (
    <>
      {/* {console.log(location)} */}
      <div className={styles.addProfileContainer}>
        <div className={styles.formContainer}>
          <form onSubmit={handleSubmit}>
            <label className={styles.label}>Name</label>
            <input
              required
              placeholder="Enter Name"
              className={styles.input}
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <label className={styles.label}>Contact Information</label>
            <input
              required
              placeholder="Enter Contact Information"
              className={styles.input}
              type="tel"
              value={contact}
              onChange={(e) => setContact(e.target.value)}
            />
            <label className={styles.label}>Relation</label>
            <input
              required
              placeholder="Relation with user"
              className={styles.input}
              type="text"
              value={relation}
              onChange={(e) => setRelation(e.target.value)}
            />
            <div className={styles.flex}>
              <div className={styles.flexCol}>
                <label className={styles.label}>Age</label>
                <input
                  required
                  className={styles.inputAge}
                  placeholder="Enter Age"
                  type="text"
                  value={age}
                  onChange={(e) => setAge(e.target.value)}
                />
              </div>
              <div className={styles.flexCol}>
                <label className={styles.label}>Gender</label>
                <select
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                  className={styles.input}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            <label className={styles.label}>Email</label>
            <input
              required
              placeholder="Enter Email Address"
              className={styles.input}
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <button
              type="submit"
              // onClick={handleSubmit}
              className={styles.saveBtn}
            >
              SAVE PROFLIE
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddProfile;
