import React from "react";

import styles from "./DeliveryAndShipping.module.css";

function DeliveryAndShipping() {
	return (
		<>
			<div className={styles.container}>
				<h1 className={styles.header}>Delivery and Shipping</h1>
				<div className={styles.line}></div>
				<h2>Shipping Policy:</h2>
				<br />
				<p>
					At Cliniker, we are committed to providing seamless medical services
					with convenience at the forefront. This Shipping Policy outlines the
					procedures and guidelines regarding blood sample collection and Doctor
					Consultations for our valued customers.
				</p>
				<br />
				<h2>Pathology Collection:</h2>
				<br />
				<ul>
					<li>
						Home Collection: Upon booking an appointment for pathology
						collection at home, our team ensures timely collection of blood
						samples from your doorstep. Our proficient phlebotomists will visit
						your location at the scheduled time to collect the samples,
						maintaining strict adherence to safety protocols and hygiene
						standards.
					</li>
					<br />
					<li>
						Clinic Collection: For those preferring to visit our clinic for
						sample collection, we guarantee availability of skilled phebotomists
						for sample collection at our facilities. You can conveniently choose
						your preferred clinic location while scheduling your appointment
						through our platform.
					</li>
					<br />
				</ul>
				<br />
				<h2>Report Delivery:</h2>
				<br />
				<ul>
					<li>
						Digital Reports: Once your samples have been collected and
						processed, samples will be sent to specialized labs for testings The
						finalized reports will be promptly uploaded to your Cliniker app
						account, ensuring secure access to your medical records at your
						fingertips.
					</li>
					<br />
					<li>
						Physical Reports: Should you require physical copies of your
						reports, we offer the option to print and collect them from our
						designated clinic locations. Please allow sufficient time for
						processing and printing, and kindly coordinate with our customer
						service team for collection details.
					</li>
					<br />
					<li>
						Shipping Charges: Home Collection: We offer complimentary blood
						sample collection from your home address within our service areas in
						Noida. Clinic Collection: There are no collection charges associated
						with clinic-based sample collection. Report Delivery: Digital report
						delivery through the Cliniker app is free of charge. For physical
						report requests, nominal printing and handling fees may apply.
					</li>
					<br />
				</ul>
				<h2>Contact Us:</h2>
				<br />
				<p>
					Should you have any inquiries or require further assistance, please
					don't hesitate to reach out to our dedicated customer support team. We
					are here to ensure your experience with Cliniker is seamless and
					satisfactory.
				</p>
				<br />
				<p>Thank you for choosing Cliniker for your medical needs.</p>
				<br />
			</div>
		</>
	);
}

export default DeliveryAndShipping;
