import React, { useState, useEffect } from "react";
import { firestore } from "../firebase";
import { db } from "../firebase";
import packageImg from "../assets/img/packageImgTemp.png";
import { getAllBlogs } from "../services/firebaseServices";
import { useHistory } from "react-router-dom";
import logo from "../assets/img/cardLogo.png";

import styles from "./BlogsListWeb.module.css";

function BlogsListWeb() {
  const [blogs, setBlogs] = useState([]);
  const history = useHistory();

  // const blogref = db.collection("blog");
  // const data = await blogref.get();
  // console.log()
  useEffect(() => {
    getAllBlogs().then((res) => setBlogs(res));
  }, []);

  return (
    <>
      <div className={styles.listContainer}>
        <div className={styles.headingDiv}>
          <h3 className={styles.titleText}>Health Blogs</h3>
        </div>
        <a
          href="https://play.google.com/store/apps/details?id=com.dtscliniker"
          target="_blank"
          rel="noreferrer"
          className={styles.viewAllBtn}
        >
          view all
        </a>
        <div className={styles.packageContainer}>
          {blogs.length != 0 ? (
            blogs.map((item) => {
              {
                console.log(item.blog.author);
              }
              return (
                <div className={styles.packageSquare} key={item.blogId}>
                  <img
                    className={styles.packageImg}
                    src={item.blog.image == null ? logo : item.blog.image}
                    alt=""
                  />
                  <div className={styles.packageInfo}>
                    <h4>{item.blog.author}</h4>
                    <h3 className={styles.cardTitle}>
                      {item.blog.blocks[0].data.text}
                    </h3>
                    <button
                      onClick={() =>
                        history.push({ pathname: "/blog", state: item.blog })
                      }
                      className={styles.knowBtn}
                    >
                      know more
                    </button>
                  </div>
                </div>
              );
            })
          ) : (
            <div>No Blogs Found</div>
          )}
        </div>
      </div>
    </>
  );
}

export default BlogsListWeb;
