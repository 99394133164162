import React from "react";

import styles from "./TnC.module.css";

function RefundPolicy() {
  return (
    <>
      <div className={styles.container}>
        <h1 className={styles.header}>Refund Policy</h1>
        <div className={styles.line}></div>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi
          quaerat maxime, fuga praesentium id, impedit cupiditate placeat
          dolorum quis omnis similique sit consequuntur vitae ad tempore ut.
          Officia, eos fugiat?
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi
          quaerat maxime, fuga praesentium id, impedit cupiditate placeat
          dolorum quis omnis similique sit consequuntur vitae ad tempore ut.
          Officia, eos fugiat?
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi
          quaerat maxime, fuga praesentium id, impedit cupiditate placeat
          dolorum quis omnis similique sit consequuntur vitae ad tempore ut.
          Officia, eos fugiat?
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi
          quaerat maxime, fuga praesentium id, impedit cupiditate placeat
          dolorum quis omnis similique sit consequuntur vitae ad tempore ut.
          Officia, eos fugiat?
        </p>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quasi
          quaerat maxime, fuga praesentium id, impedit cupiditate placeat
          dolorum quis omnis similique sit consequuntur vitae ad tempore ut.
          Officia, eos fugiat?
        </p>
      </div>
    </>
  );
}

export default RefundPolicy;
