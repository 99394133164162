import React from "react";
import pic from "../../assets/img/asset-3.svg";
import graphicDoctorImg from "../../assets/img/doctor-1.svg";

import "./MainSection.css";

function MainSection() {
  return (
    <>
      <div className="main-section" id="home">
        <div className="main-heading">
          <h1 className="line1">Doctor? Cliniker!</h1>
          <h4 className="line2">"One stop for all your health concerns"</h4>
        </div>
        <div className="right-ring">
          <img className="main-image" src={pic} alt="Doctors" />
        </div>
        <a className="know-more" href="#about">
          Know More
        </a>

        <div className="about-section">
          <div className="about-heading">
            <h1>Our Mission</h1>
          </div>
          <div className="about-text" id="about">
            <div className="doctor-img">
              <img src={graphicDoctorImg} alt="Doctor" />
            </div>
            <p>
              With hundreds of clinics and doctors to choose from, selecting the
              best healthcare provider becomes difficult to safeguard your
              family's needs, Cliniker aims to help you select the best clinic
              near you which provides{" "}
              <b>
                Doctor consultation, Blood tests, Medicine delivery, Child
                Vaccinations and Other medical needs (dressing, injections etc).
              </b>
            </p>
            <br />
            <p>
              Be it Physician, Gynaecologist, Paediatrician, or any other
              specialist, select only the best from Cliniker assured list of
              Doctors. Not only do we aim to provide you with the best quality
              diagnosis, but we also provide you with a hassle-free experience
              of visiting a clinic by assuring{" "}
              <b>
                Minimum wait time, Preferred slot availability with rescheduling
                facilities, Free Home sample collection facility, Doctor's
                recommended testing centres, Medical prescriptions and test
                reports repository
              </b>
              .
            </p>{" "}
            <br />
            <p>
              So don’t think much, fill in the inquiry form below, and our team
              will get in touch with you to connect you with the best available
              healthcare provider.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default MainSection;
