import React from "react";
import searchIcon from "../../assets/img/searchWeb.png";

import styles from "./SearchBar.module.css";

function SearchBar() {
  return (
    <>
      {/* <div className={styles.searcContainer}> */}
        <div className={styles.searchContainer}>
          <button className={styles.searchBtn}>Search Location</button>
          <input
            className={styles.searchInput}
            placeholder="Search Doctors, Clinics and Lab test"
            type="text"
          />
          <img className={styles.searchIcon} src={searchIcon} alt="" />
        </div>
      {/* </div> */}
    </>
  );
}

export default SearchBar;
