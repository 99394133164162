import React, {useEffect, useState} from "react";
import {
  addToDoctor,
  addToLabTest,
  addToStaff,
  getAllDoctors,
} from "../services/firebaseServices";

import styles from "./DoctorListing.module.css";
import DoctorSearchCard from "./DoctorSearchCard";

function DoctorListing() {
  const [search, setSearch] = useState("");
  const [doctors, setDoctors] = useState("");
  const [loading, setLoading] = useState(true);

  const fetchDoctors = async () => {
    const docs = await getAllDoctors();
    setDoctors(docs);
    setLoading(false);
  };

  useEffect(() => {
    fetchDoctors();
    // addToDoctor();
    // addToLabTest();
    // addToStaff();
  }, []);
  return (
    <>
      <div className={styles.listingContainer}>
        <div className={styles.searchContainer}>
          <input
            onChange={(e) => {
              setSearch(e.target.value);
              //   console.log(search);
            }}
            className={styles.search}
            type="text"
            placeholder="Search doctors, clinics and more..."
            value={search}
          />
        </div>
        {/* <DoctorSearchCard />
        <DoctorSearchCard />
        <DoctorSearchCard /> */}

        {/* {console.log(doctors)} */}
        {!loading &&
          doctors &&
          doctors.map((item) => (
            <DoctorSearchCard key={item.doc_id} doctor={item} />
          ))}
      </div>
    </>
  );
}

export default DoctorListing;
