import React, {useState, useContext, useEffect} from "react";
import {Link} from "react-router-dom";
import profilePic from "../assets/img/docPP.jfif";
import {AuthContext} from "../auth";
import {UserContext} from "../Context/UserContext";
import {
  changeActiveFamily,
  getAllFamilyProfiles,
  getFamilyMembers,
  getUser,
} from "../services/firebaseServices";

import styles from "./FamilyProfilesList.module.css";

function FamilyProfilesList() {
  const {currentUser} = useContext(AuthContext);
  const {user} = useContext(UserContext);

  const [familyMembers, setFamilyMembers] = useState();

  const fetchFamilyProfiles = async () => {
    // const response = await getUser(currentUser.uid);
    // setFamilyMembers(response.family);
    // console.log(response);

    const response = await getAllFamilyProfiles(currentUser.uid);
    setFamilyMembers(response);
    // console.log(response);
  };

  useEffect(() => {
    fetchFamilyProfiles();
  }, []);

  const makeActiveFamily = (item) => {
    changeActiveFamily(item, currentUser.uid).then((res) =>
      window.location.reload()
    );
  };

  return (
    <>
      <div className={styles.listContainer}>
        {familyMembers?.map((item, index) => (
          <div key={index} className={styles.profileCard}>
            <div className={styles.flex}>
              <img className={styles.userImg} src={profilePic} alt="" />
              <div className={styles.userDetails}>
                <h2 className={styles.header}>{item.name}</h2>
                <p className={styles.para}>Age - {item.age}</p>
                <p className={styles.para}>Gender - {item.gender}</p>
              </div>
            </div>
            <div className={styles.btnContainer}>
              <Link
                to={{
                  pathname: "/add-profile",
                  state: item,
                }}
              >
                <button className={styles.editBtn}>EDIT PROFILE</button>
              </Link>
              <button
                disabled={item.doc_id === user?.active_family[0]}
                onClick={() => makeActiveFamily(item)}
                className={styles.setBtn}
                style={{
                  backgroundColor:
                    item.doc_id === user?.active_family.doc_id ? "#444049" : "",
                }}
              >
                {item.doc_id === user?.active_family.doc_id
                  ? "ACTIVE"
                  : "SET AS ACTIVE"}
              </button>
            </div>
          </div>
        ))}

        <Link style={{textDecoration: "none"}} to="/add-profile">
          <button className={styles.addBtn}>ADD FAMILY MEMBER</button>
        </Link>
      </div>
    </>
  );
}

export default FamilyProfilesList;
