import React from "react";
import {Link} from "react-router-dom";
import labImgTemp from "../../assets/img/labImgTemp.png";
import lpDocImg from "../../assets/img/lp-doc.png";

import styles from "./DocConsultSection.module.css";

function DocConsultSection() {
  return (
    <>
      <div className={styles.docConsultContainer}>
        <div className={styles.leftContainer}>
          <h2 className={styles.titleText}>Find best doctors near you</h2>
          <p className={styles.paraText}>
            Connecting to trusted doctors with best qualifications ensures a
            safe and effective consultation experience, and Cliniker promises
            you a satisfying consultation everytime you book.
          </p>
          <Link style={{textDecoration: "none"}} to="/consult">
            <button className={styles.bookBtn}>Consult Now</button>
          </Link>
        </div>

        <img className={styles.docConsultImg} src={lpDocImg} alt="" />
      </div>
    </>
  );
}

export default DocConsultSection;
