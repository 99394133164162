import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import {AuthContext} from "../auth";
import {getUser} from "../services/firebaseServices";

export const UserContext = createContext();

export const UserProvider = ({children}) => {
  const {currentUser} = useContext(AuthContext);
  const [user, setUser] = useState();

  // const mounted = useRef(true);

  const fetchUser = async () => {
    const response = await getUser(currentUser?.uid);
    setUser(response);

    // if (mounted.current) {
    //   setUser(response);
    //   console.log("res", user);
    // }
  };

  useEffect(() => {
    fetchUser();
    // if (mounted.current) fetchUser();
  }, []);

  // useEffect(() => () => (mounted.current = false), []);

  return (
    <UserContext.Provider value={{user, setUser}}>
      {children}
    </UserContext.Provider>
  );
};
