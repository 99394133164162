import React from "react";
import folder from "../assets/img/folder.svg";

import styles from "./ManageRecords.module.css";

function ManageRecords() {
  const record = () => (
    <div className={styles.record}>
      <div className={styles.flex}>
        <img className={styles.folderIcon} src={folder} width={40} alt="" />
        <div className={styles.details}>
          <h2 className={styles.header}>Prescription 1</h2>
          <p className={styles.subHead}>Record Type - Prescription</p>
          <p className={styles.upload}>Upload Date - 12/05/2021</p>
        </div>
      </div>
      <div className={styles.flexBtn}>
        <button className={styles.openBtn}>OPEN</button>
        <button className={styles.editBtn}>EDIT</button>
        <button className={styles.delBtn}>DELETE</button>
      </div>
    </div>
  );
  return (
    <>
      <div className={styles.recordsContainer}>
        <select className={styles.selectMenu}>
          <option className={styles.selectOption} value="">
            User Name
          </option>
          <option className={styles.selectOption} value="">
            User Name
          </option>
          <option className={styles.selectOption} value="">
            User Name
          </option>
        </select>
        {record()}
        {record()}
        {record()}
        <button className={styles.uploadBtn}>UPLOAD DOCUMENT</button>
      </div>
    </>
  );
}

export default ManageRecords;
