import React, { useRef } from "react";
import EditorJs from "react-editor-js";
import { getAllBlogs } from "../../services/firebaseServices";
import { firestore } from "../../firebase";
import { db } from "../../firebase";
// import { firestore } from "../firebase";

import { EDITOR_JS_TOOLS } from "./BlogTools";

function BlogInput() {
  const instanceRef = useRef(null);

  let data = {
    time: 1626168670359,
    blocks: [
      {
        id: "tPd6cn8eQo",
        type: "header",
        data: {
          text: "In-clinic visit v/s video/tele consultation",
          level: 2,
        },
      },
      {
        id: "230x-0P3_0",
        type: "paragraph",
        data: {
          text: "Hey. Meet the new Editor. On this page you can see it in action — try to edit this text.",
        },
      },
      {
        id: "e4s08Hvge6",
        type: "paragraph",
        data: {
          text: "The question then arises - is this here to stay? The boom in tele consultation maybe because of the pandemic, but do we see a complete shift towards online patient care in the future? Wherein you consult a doctor ONLINE, send prescription to pharmacy ONLINE and get medicines delivered touchfree.WE DON’T THINK SO!",
        },
      },
      {
        id: "K-Tjmf9_Hn",
        type: "simpleImage",
        data: {
          url: "https://st2.depositphotos.com/3889193/7657/i/600/depositphotos_76570869-stock-photo-confident-female-doctor-posing-in.jpg",
          caption: "A smiling doctor&nbsp;",
          withBorder: false,
          withBackground: true,
          stretched: false,
        },
      },
      {
        id: "lfHX190FiW",
        type: "paragraph",
        data: {
          text: "Secondly, medicine is one profession/industry which requires care and empathy. Not just the right diagnosis and medicine, but proper care is what makes someone recover and back to health. There is a reason why we call it HealthCARE :) An empathetic doctor who understands the patient’s fears and apprehensions can help him recover much better than even a trained professional who cannot empathize with the patient. After all it is the Doctor whose skill and knowledge people trust and undergo the treatment right?",
        },
      },
      {
        id: "Zr-M2MENt7",
        type: "paragraph",
        data: {
          text: "And one thing which the internet and technology has successfully done, despite all it’s abilities to connect people across the world, is to reduce that empathy towards one another. A warm hug, a generous handshake or even a pat on the shoulder that says “Everything is going to be alright” goes a long way in healing someone. And that can only be provided via a physical visit to the medical practitioner",
        },
      },
      {
        id: "Uo42VS0-sy",
        type: "paragraph",
        data: {
          text: "Moreover, it is easier for the medical practitioner to examine and diagnose in-person. Even for a basic problem like Cough or Cold, a doctor knows that it is important to check all the vitals to be informed of any major problems that may arise in the future. And we don’t have all the equipment at our homes, do we? Even for a simple Blood Pressure Measurement for which various sorts of portable devices are available, not all of us can afford those and therefore we need to visit a clinic for the same.",
        },
      },
      {
        id: "Jt6BpBrr9C",
        type: "paragraph",
        data: {
          text: "So in-clinic visit not only helps in providing better care, but better diagnosis as well - a win-win situation for the patients, right?",
        },
      },
      {
        id: "VPIWRvF-QH",
        type: "paragraph",
        data: {
          text: "We decided to test this hypothesis. At one Cliniker’s partner clinics in Noida, we rolled out a survey to ACTUAL PATIENTS where in we asked them -",
        },
      },
      {
        id: "MIZcgyCvF7",
        type: "paragraph",
        data: {
          text: "What do you prefer? A visual consultation (phone or video) or real consultation?",
        },
      },
      {
        id: "YuiAqZmQoN",
        type: "list",
        data: {
          style: "ordered",
          items: ["Phone or Video Consultation", "&nbsp;Real Consultation<br>"],
        },
      },
      {
        id: "XKYcXoeQd8",
        type: "paragraph",
        data: {
          text: "To our surprise, this was the outcome -",
        },
      },
      {
        id: "zRWdR16exy",
        type: "simpleImage",
        data: {
          url: "https://st2.depositphotos.com/3889193/7657/i/600/depositphotos_76570869-stock-photo-confident-female-doctor-posing-in.jpg",
          caption: "A smiling doctor&nbsp;",
          withBorder: false,
          withBackground: true,
          stretched: false,
        },
      },
      {
        id: "zx3uFl37Aw",
        type: "paragraph",
        data: {
          text: "More than 90% of people preferred that they would rather choose an in-clinic visit to a doctor than a tele consultation. And mind you, this was in Feb 2021 - during Covid-19",
        },
      },
      {
        id: "j4oXi8wuQr",
        type: "paragraph",
        data: {
          text: "When people were asked why they felt in-clinic visit was more preferable them, these were some of the reasons which they stated -",
        },
      },
      {
        id: "n50DtX4Q1o",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "“I feel more comfortable and more confident about the diagnosis when it’s in person”",
            "“Knowing the medical practitioner personally helps build confidence instead of connecting with someone miles away, even though he is a great professional”",
            "“It’s always better to consult someone who knows my medical history and has been treating me for a long time than with a completely new doctor every time”",
            "“If any procedure, medicine, diagnostic test needs to be done, the doctor can suggest me the best place near me and I won’t have to worry about the quality”",
          ],
        },
      },
      {
        id: "EdOv1jWZAU",
        type: "paragraph",
        data: {
          text: "So then what is the way ahead, is it tele-consultation or in-clinic visits?",
        },
      },
      {
        id: "wV1ylVJjie",
        type: "paragraph",
        data: {
          text: "We believe that it’ll be a combination of both. There will always be some people who like the convenience of consulting a doctor through their phones, and some people who prefer an in-person diagnosis.",
        },
      },
      {
        id: "ViDecyv-DM",
        type: "paragraph",
        data: {
          text: "We at Cliniker are doing our part by enhancing the experience of an in-clinic visit. Like we said, technology is an enabler, and we are using it to connect you with the best clinics NEAR YOU where you can go and get an in-clinic doctor consultation done. We also aim to connect you with best quality diagnostic and pathology facilities near you so that you can get your blood tests and regular health checkups done easily and of the utmost quality. We intend to use technology to help reduce wait times, provide correct information on facilities available near you, help you store your medical reports safely and many more things. We at Cliniker hope to assist medical practitioners practice medicine with the utmost ease and convenience and help them in providing the best quality medical CARE to everyone out there.",
        },
      },
      {
        id: "3ZbSpK3Fqc",
        type: "paragraph",
        data: {
          text: "<b>Clinker - We care for you!<b/>",
        },
      },
    ],
    version: "2.22.0",
  };

  async function handleSave() {
    const savedData = await instanceRef.current.save();
    console.log(savedData);
    const blogRef = db.collection("blog");

    blogRef.add({ blog: savedData }).then(() => {
      console.log("blogadded");
    });
    // saveAs
  }
  return (
    <>
      <button onClick={() => handleSave()}>Save</button>
      <EditorJs
        instanceRef={(instance) => (instanceRef.current = instance)}
        tools={EDITOR_JS_TOOLS}
        // data={data}
      />
    </>
  );
}

export default BlogInput;
