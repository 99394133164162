import React from "react";

import healthVaultMain from "../../assets/img/healthVaultMain.png";
import healthVaultS1 from "../../assets/img/healthVaultS1.png";
import healthVaultS2 from "../../assets/img/healthVaultS2.png";
import healthVaultS3 from "../../assets/img/healthVaultS3.png";
import healthVaultS4 from "../../assets/img/healthVaultS4.png";

import circleImg from "../../assets/img/circleWebTemp.png";
import midCircle from "../../assets/img/midCircleVault.png";
import healthVault from "../../assets/img/healthVault.svg";

import styles from "./VaultSection.module.css";

function VaultSection() {
  return (
    <>
      <div id="vault" className={styles.vaultSection}>
        <h1 className={styles.titleText}>Health Vault</h1>
        {/* <img
          style={{ width: "70%", marginTop: "80px" }}
          src={healthVault}
          alt=""
        /> */}
        <div className={styles.grid}>
          <img className={styles.midCircle} src={healthVaultMain} alt="" />
          <div className={styles.topLeft}>
            <p className={styles.paraText}>
              Access all the cliniker reports on the app itself
            </p>
            <img className={styles.circleImg} src={healthVaultS1} alt="" />
          </div>
          <div className={styles.topRight}>
            <p className={styles.paraText}>
              Upload and keep all your medical records safe digitally
            </p>
            <img className={styles.circleImg} src={healthVaultS2} alt="" />
          </div>
          <div className={styles.bottomLeft}>
            <p className={styles.paraText}>
              Easy and Hasslefree access to your reports on the go
            </p>
            <img className={styles.circleImg} src={healthVaultS3} alt="" />
          </div>
          <div className={styles.bottomRight}>
            <p className={styles.paraText}>
              Never loose your records, it' remains safe with us
            </p>
            <img className={styles.circleImg} src={healthVaultS4} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default VaultSection;
