import React, { useState } from "react";
import styles from "./ContactUs.module.css";
import isMobilePhone from "validator/lib/isMobilePhone";
import isEmail from "validator/lib/isEmail";
import app from "../firebase";

function ContactUs() {
	const [formData, setFormData] = useState({
		name: "",
		phone: "",
		email: "",
		message: "",
	});

	const [errors, setErrors] = useState({
		emailError: null,
		phoneError: null,
	});

	const [formSubmit, setFormSubmit] = useState(false);

	const handleChange = (e) => {
		setFormData({
			...formData,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log("Handle");

		const isValidPhone = isMobilePhone(formData.phone, "en-IN");
		const isValidEmail = isEmail(formData.email);

		if (isValidEmail === false) {
			setErrors((prevErrors) => ({
				...prevErrors,
				emailError: "Invalid Email!!",
			}));
		} else {
			setErrors((prevErrors) => ({
				...prevErrors,
				emailError: null,
			}));
		}
		if (isValidPhone === false) {
			setErrors((prevErrors) => ({
				...prevErrors,
				phoneError: "Invalid Phone No.",
			}));
		} else {
			setErrors((prevErrors) => ({
				...prevErrors,
				phoneError: null,
			}));
		}

		console.log("FormData & Errors", formData, errors);

		if (isValidEmail && isValidPhone) {
			setFormSubmit(true);
			app
				.database()
				.ref()
				.child("contact-form")
				.push(formData, (err) => {
					if (err) console.log(err);
				})
				.then((res) => {
					console.log(res);
					setFormSubmit(true);
					setFormData({
						name: "",
						phone: "",
						email: "",
						message: "",
					});
				});
		} else {
			console.log("Errors Exisit", errors);
		}
	};

	return (
		<>
			<div className={styles.contactContainer}>
				<div>
					<h1 className={styles.heading}>Contact Us</h1>
				</div>
				<div>
					<p className={styles.para}>
						At Cliniker, taking care of our customers and providing them best
						experience is our priority. If you feel we have not been able to
						deliver as per your expectations or have a query, please feel free
						to reach out to us. You can reach out to us directly via the details
						mentioned below.
					</p>
				</div>
				<div className={styles.contactOptions}>
					<p className={styles.options}>
						Contact No./Whatsapp -{" "}
						<a href="tel:+918826264333" className={styles.boldText}>
							+918826264333
						</a>
					</p>
					<p className={styles.options}>
						Email ID -
						<a
							href="mailto:medihealthcloud@gmail.com"
							className={styles.boldText}
						>
							medihealthcloud@gmail.com
						</a>
					</p>
					<p className={styles.options}>
						Address -
						<span className={styles.boldText}>
							Shop No. 9 (UGF), Mahagun Mart, Gaur City-2, Sector-16C, Greater
							Noida West: 201305
						</span>
					</p>
				</div>
				<div className={styles.formDiv}>
					<form onSubmit={handleSubmit}>
						<div className={styles.grid}>
							<div className={styles.formGroup}>
								<label className={styles.formLabel} htmlFor="">
									Name
								</label>
								<input
									required={true}
									name="name"
									value={formData.name}
									onChange={handleChange}
									className={styles.formInput}
									type="text"
									placeholder="Name"
								/>
							</div>
							<div className={styles.formGroup}>
								<label className={styles.formLabel} htmlFor="">
									Email
								</label>
								<input
									required
									name="email"
									value={formData.email}
									onChange={handleChange}
									className={styles.formInput}
									type="email"
									placeholder="Email"
								/>

								{errors?.emailError ? (
									<div style={{ color: "red", marginTop: 10 }}>
										{errors.emailError}
									</div>
								) : (
									""
								)}
							</div>
							<div
								className={styles.formGroup}
								style={{ "grid-column": "span 2" }}
							>
								<label className={styles.formLabel} htmlFor="">
									Phone Number
								</label>
								<input
									required
									name="phone"
									value={formData.phone}
									onChange={handleChange}
									className={styles.formInput}
									type="tel"
									placeholder="Phone Number"
								/>
								{errors?.phoneError ? (
									<div style={{ color: "red", marginTop: 10 }}>
										{errors.phoneError}
									</div>
								) : (
									""
								)}
							</div>
							<div
								className={styles.formGroup}
								style={{ "grid-column": "span 2" }}
							>
								<label className={styles.formLabel} htmlFor="">
									Message
								</label>
								<textarea
									required
									name="message"
									value={formData.message}
									onChange={handleChange}
									className={styles.formInput}
									type="text"
									placeholder="Write your message"
									rows={10}
								/>
							</div>
						</div>
						<button className={styles.formBtn} type="submit">
							Submit
						</button>
						{formSubmit ? <span>Form Submitted Succesfully</span> : ""}
					</form>
				</div>
			</div>
		</>
	);
}

export default ContactUs;
