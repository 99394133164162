import React, {useContext} from "react";
import ReactStars from "react-rating-stars-component";
import {useHistory} from "react-router-dom";
import docPic from "../assets/img/docPP.jfif";
import map from "../assets/img/map.png";
import {ScheduleContext} from "../Context/ScheduleContext";

import styles from "./DoctorSearchCard.module.css";

function DoctorSearchCard({doctor}) {
  const {order, setOrder} = useContext(ScheduleContext);

  const history = useHistory();
  return (
    <>
      <div className={styles.docSContainer}>
        <div className={styles.grid}>
          <div className={styles.imageContainer}>
            <img className={styles.profileImage} src={docPic} alt="" />
            <p className={styles.experience}>{doctor.experience} Years</p>
          </div>
          <div className={styles.detailsContainer}>
            <h2 className={styles.name}>{doctor.name}</h2>
            {/* <p className={styles.designation}>Dentist</p> */}
            <p className={styles.designation}>{doctor.specialist[0]}</p>
            <p className={styles.location}>
              <img className={styles.mapIcon} src={map} alt="" /> 3 km away
            </p>
            <h3 className={styles.consultationFee}>
              Consultation Fee -{" "}
              <b style={{color: "#835CB9"}}>₹ {doctor.fees}</b>
            </h3>
          </div>
          <div className={styles.rating}>
            <ReactStars
              classNames={styles.rating}
              count={5}
              value={4}
              edit={false}
              size={15}
              isHalf={true}
              activeColor="#ffd700"
            />
          </div>
        </div>
        <div className={styles.line}></div>
        <div className={styles.flex}>
          <button className={styles.knowBtn}>KNOW MORE</button>
          <button
            onClick={() => {
              setOrder({...doctor, order_type: "consultation"});
              history.push("/schedule");
            }}
            className={styles.bookBtn}
          >
            BOOK APPOINTMENT
          </button>
        </div>
      </div>
    </>
  );
}

export default DoctorSearchCard;
