import React from "react";
import ReactStars from "react-rating-stars-component";
import { Carousel } from "react-responsive-carousel";

import SearchBar from "../components/WebComponents/SearchBar";
import DownloadSection from "../components/LandingPage/DownloadSection";

import packageImg from "../assets/img/packageImgTemp.png";
import packageTotal from "../assets/img/packageTotal2.png";
import packageEssential from "../assets/img/packageEssential.png";
import packageAdvanced from "../assets/img/packageAdvanced2.png";
import packageExclusive from "../assets/img/packageExclusive2.png";
import cardLogo from "../assets/img/cardLogo.png";

import userImg from "../assets/img/userImgTemp2.png";

import uspImg from "../assets/img/book-usp.png";

import bookf1 from "../assets/img/book-f1.png";
import bookf2 from "../assets/img/book-f2.png";
import bookf3 from "../assets/img/book-f3.png";

import book1 from "../assets/img/consult-book1.png";
import book2 from "../assets/img/consult-book2.png";
import book3 from "../assets/img/consult-book3.png";
import book4 from "../assets/img/consult-book4.png";
import book5 from "../assets/img/consult-book5.png";

// import bannerImg from "../assets/img/consultDocBanner.png";

import bannerLeftImg from "../assets/img/labBannerLeft.png";
import bannerRightImg from "../assets/img/labBannerRight.png";

import diabetes from "../assets/img/LT-diabetes.png";
import kidney from "../assets/img/LT-kidney.png";
import heart from "../assets/img/LT-heart.png";
import anemia from "../assets/img/LT-anemia.png";
import liver from "../assets/img/LT-liver.png";
import thyroid from "../assets/img/LT-thyroid.png";
import bone from "../assets/img/LT-bone.png";

import styles from "./BookLabTest.module.css";

function BookLabTest() {
  return (
    <>
      <div className={StyleSheet.bookContainer}>
        <section className={styles.bannerSection}>
          <div className={styles.bannerImgsDiv}>
            <img className={styles.bannerLeftImg} src={bannerLeftImg} alt="" />
            <div className={styles.description}>
              <h2 className={styles.bannerTitle}>
                Book test from comfort of your home
              </h2>
              <p className={styles.bannerText}>
                With the best lab partners and professionally trained staff, we
                assure you an excellent experience for every test done , and a
                muddle-free document repository for future references.
              </p>
            </div>
            <img
              className={styles.bannerRightImg}
              src={bannerRightImg}
              alt=""
            />
          </div>
          <div className={styles.searchComponent}>
            <SearchBar />
          </div>
          {/* <div className={styles.bannerSection}>
            <img className={styles.bannerImg} src={bannerImg} alt="" />
            <div className={styles.searchComponent}>
              <SearchBar />
            </div>
          </div> */}
        </section>
        <section className={styles.tilesSection}>
          <div className={styles.healthIssues}>
            <div className={styles.headingDiv}>
              <h3 className={styles.titleText}>
                Book health checkups and lab tests for
              </h3>
            </div>
            <div className={styles.issuesContainer}>
              <a
                href="https://play.google.com/store/apps/details?id=com.dtscliniker"
                className={styles.paraText}
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.flexCol}>
                  <img
                    className={styles.illustrationImg}
                    src={diabetes}
                    alt=""
                  />
                  <p>Diabetes</p>
                </div>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.dtscliniker"
                className={styles.paraText}
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.flexCol}>
                  <img className={styles.illustrationImg} src={kidney} alt="" />
                  <p>Kidney</p>
                </div>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.dtscliniker"
                className={styles.paraText}
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.flexCol}>
                  <img className={styles.illustrationImg} src={liver} alt="" />
                  <p>Liver</p>
                </div>
              </a>

              <a
                href="https://play.google.com/store/apps/details?id=com.dtscliniker"
                className={styles.paraText}
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.flexCol}>
                  <img className={styles.illustrationImg} src={heart} alt="" />
                  <p>Heart</p>
                </div>
              </a>

              <a
                href="https://play.google.com/store/apps/details?id=com.dtscliniker"
                className={styles.paraText}
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.flexCol}>
                  <img
                    className={styles.illustrationImg}
                    src={thyroid}
                    alt=""
                  />
                  <p>Thyroid</p>
                </div>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.dtscliniker"
                className={styles.paraText}
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.flexCol}>
                  <img className={styles.illustrationImg} src={bone} alt="" />
                  <p>Bone</p>
                </div>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.dtscliniker"
                className={styles.paraText}
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.toothAche}>
                  <img className={styles.illustrationImg} src={anemia} alt="" />
                  <p>Anemia</p>
                </div>
              </a>
            </div>
          </div>

          <div className={styles.specialisationsContainer}>
            {/* <div className={styles.specialisationsContainer}> */}
            <div className={styles.headingDiv}>
              <h3 className={styles.titleText}>Popular Health Test Packages</h3>
            </div>
            <div className={styles.packageContainer}>
              <div className={styles.packageSquare}>
                {/* <img className={styles.cardLogo} src={cardLogo} alt="" /> */}
                <img
                  className={styles.packageImg}
                  src={packageEssential}
                  alt=""
                />
                <div className={styles.packageInfo}>
                  <p className={styles.cardTitle}>Lipid Profile Serum</p>
                  <div className={styles.flex}>
                    <span>5 tests</span>
                    {/* <span>Know more</span> */}
                  </div>
                  <div className={styles.price}>
                    <span>₹820</span>
                    <span className={styles.smallText}>+25 % off</span>
                  </div>
                  {/* <p>Earn cashback</p> */}
                  <a
                    href="https://play.google.com/store/apps/details?id=com.dtscliniker"
                    className={styles.paraText}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className={styles.bookBtn}>BOOK NOW</button>
                  </a>
                </div>
              </div>
              <div className={styles.packageSquare}>
                {/* <img className={styles.cardLogo} src={cardLogo} alt="" /> */}
                <img className={styles.packageImg} src={packageTotal} alt="" />
                <div className={styles.packageInfo}>
                  <p className={styles.cardTitle}>Thyroid Profile</p>
                  <div className={styles.flex}>
                    <span>3 tests</span>
                    {/* <span>Know more</span> */}
                  </div>
                  <div className={styles.price}>
                    <span>₹950</span>
                    <span className={styles.smallText}>+25 % off</span>
                  </div>
                  {/* <p>Earn cashback</p> */}
                  <a
                    href="https://play.google.com/store/apps/details?id=com.dtscliniker"
                    className={styles.paraText}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className={styles.bookBtn}>BOOK NOW</button>
                  </a>
                </div>
              </div>
              <div className={styles.packageSquare}>
                {/* <img className={styles.cardLogo} src={cardLogo} alt="" /> */}
                <img
                  className={styles.packageImg}
                  src={packageAdvanced}
                  alt=""
                />
                <div className={styles.packageInfo}>
                  <p className={styles.cardTitle}>CBNAAT Covid 19 Assay</p>
                  <div className={styles.flex}>
                    <span>1 test</span>
                    {/* <span>Know more</span> */}
                  </div>
                  <div className={styles.price}>
                    <span>₹300</span>
                    <span className={styles.smallText}>+25 % off</span>
                  </div>
                  {/* <p>Earn cashback</p> */}
                  <a
                    href="https://play.google.com/store/apps/details?id=com.dtscliniker"
                    className={styles.paraText}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className={styles.bookBtn}>BOOK NOW</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={styles.uspSection}>
          {/* <div className={styles.uspSection}> */}
          <img className={styles.uspMainImg} src={uspImg} alt="" />
          <div className={styles.uspContainer}>
            <h3 className={styles.titleText}>Best facilities only for you</h3>
            <div className={styles.itemContainer}>
              <img className={styles.stepImg} src={bookf1} alt="" />
              <p className={styles.paraText}>
                Cliniker aassured nearest clinic
              </p>
            </div>
            <div className={styles.itemContainer}>
              <img className={styles.stepImg} src={bookf2} alt="" />
              <p className={styles.paraText}>Minimum wait time</p>
            </div>
            <div className={styles.itemContainer}>
              <img className={styles.stepImg} src={bookf3} alt="" />
              <p className={styles.paraText}>
                Preferred slot availability with rescheduling facilities
              </p>
            </div>
          </div>
          {/* </div> */}
        </section>
        <section className={styles.section4}>
          <div className={styles.bookSection}>
            <h3 className={styles.titleText}>How to book?</h3>
            {/* <img className={styles.listImg} src={listImg} alt="" /> */}
            <div className={styles.listContainer}>
              <div className={styles.line}></div>
              <div className={styles.stepsContainer}>
                <div className={styles.step}>
                  <img className={styles.stepImg} src={book1} alt="" />
                  <p className={styles.paraText}>Download the Cliniker app</p>
                </div>
                <div className={styles.step}>
                  <img className={styles.stepImg} src={book2} alt="" />
                  <p className={styles.paraText}>Search for Lab Test</p>
                </div>
                <div className={styles.step}>
                  <img className={styles.stepImg} src={book3} alt="" />
                  <p className={styles.paraText}>Book for Collection</p>
                </div>
                <div className={styles.step}>
                  <img className={styles.stepImg} src={book4} alt="" />
                  <p className={styles.paraText}>Auto upload your reports</p>
                </div>
                <div className={styles.step}>
                  <img className={styles.stepImg} src={book5} alt="" />
                  <p className={styles.paraText}>
                    Earn Clinicoins for every booking
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.reviewsSection}>
            <h3 className={styles.titleText}>What users say about it</h3>
            <Carousel
              // renderArrowPrev={(onClickHandler, hasPrev) =>
              //   hasPrev && (
              //     <img
              //       style={{
              //         width: 20,
              //         position: "absolute",
              //         top: "50%",
              //       }}
              //       src={carouselArrowPrev}
              //     ></img>
              //   )
              // }
              // renderArrowNext={(onClickHandler, hasNext) =>
              //   hasNext && (
              //     <img
              //       style={{
              //         width: 20,
              //         // position: "absolute",
              //         // top: "50%",
              //       }}
              //       src={carouselArrowNext}
              //     ></img>
              //   )
              // }
              showArrows={true}
              showThumbs={false}
              autoPlay={true}
            >
              <div className={styles.cardsContainer}>
                {/* <div> */}
                <div className={styles.card}>
                  <div className={styles.userInfo}>
                    <img className={styles.userImg} src={userImg} alt="" />
                    <h3 className={styles.titleText}>UserName</h3>
                  </div>
                  <ReactStars
                    classNames={styles.rating}
                    count={5}
                    value={4}
                    edit={false}
                    size={40}
                    isHalf={true}
                    activeColor="#ffd700"
                  />
                  <div className={styles.userComment}>
                    <p className={styles.paraText}>
                      Efficient service. They collect samples from home which is
                      very convenient. Got my reports by evening of the day.
                      Thank you Cliniker for such quick and reliabe service.
                    </p>
                  </div>
                </div>
                <div className={styles.card}>
                  <div className={styles.userInfo}>
                    <img className={styles.userImg} src={userImg} alt="" />
                    <h3 className={styles.titleText}>UserName</h3>
                  </div>
                  <ReactStars
                    classNames={styles.rating}
                    count={5}
                    value={4}
                    edit={false}
                    size={40}
                    isHalf={true}
                    activeColor="#ffd700"
                  />
                  <div className={styles.userComment}>
                    <p className={styles.paraText}>
                      Prompt blood sample collection was done and reports also
                      were given as per the informed timings.I am really happy
                      with the service.
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.cardsContainer}>
                {/* <div> */}
                <div className={styles.card}>
                  <div className={styles.userInfo}>
                    <img className={styles.userImg} src={userImg} alt="" />
                    <h3 className={styles.titleText}>UserName</h3>
                  </div>
                  <ReactStars
                    classNames={styles.rating}
                    count={5}
                    value={4}
                    edit={true}
                    size={40}
                    isHalf={true}
                    activeColor="#ffd700"
                  />
                  <div className={styles.userComment}>
                    <p className={styles.paraText}>
                      Efficient service. They collect samples from home which is
                      very convenient. Got my reports by evening of the day.
                      Thank you Cliniker for such quick and reliabe service.
                    </p>
                  </div>
                </div>
                <div className={styles.card}>
                  <div className={styles.userInfo}>
                    <img className={styles.userImg} src={userImg} alt="" />
                    <h3 className={styles.titleText}>UserName</h3>
                  </div>
                  <ReactStars
                    classNames={styles.rating}
                    count={5}
                    value={4}
                    edit={true}
                    size={40}
                    isHalf={true}
                    activeColor="#ffd700"
                  />
                  <div className={styles.userComment}>
                    <p className={styles.paraText}>
                      Prompt blood sample collection was done and reports also
                      were given as per the informed timings.I am really happy
                      with the service.
                    </p>
                  </div>
                </div>
              </div>
            </Carousel>
            {/* <div className={styles.cardsContainer}>
              <div className={styles.card}>
                <div className={styles.userInfo}>
                  <img className={styles.userImg} src={userImg} alt="" />
                  <h3 className={styles.titleText}>Name</h3>
                </div>
                <div className={styles.userComment}>
                  <p className={styles.paraText}>
                    Efficient service. They collect samples from home which is
                    very convenient. Got my reports by evening of the day. Thank
                    you Cliniker for such quick and reliabe service.
                  </p>
                </div>
              </div>
              <div className={styles.card}>
                <div className={styles.userInfo}>
                  <img className={styles.userImg} src={userImg} alt="" />
                  <h3 className={styles.titleText}>Name</h3>
                </div>
                <div className={styles.userComment}>
                  <p className={styles.paraText}>
                    Prompt blood sample collection was done and reports also
                    were given as per the informed timings.I am really happy
                    with the service.
                  </p>
                </div>
              </div>
            </div> */}
          </div>{" "}
        </section>
        <DownloadSection />
      </div>
    </>
  );
}

export default BookLabTest;
