import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {AuthContext} from "../auth";
import {getAllClinics} from "../services/firebaseServices";

export const ClinicContext = createContext();

export const ClinicProvider = ({children}) => {
  const [clinics, setClinics] = useState();

  const mounted = useRef(true);
  // const {currentUser} = useContext(AuthContext);

  const fetchClinics = async () => {
    const list = await getAllClinics();
    // console.log("Fetched data: ", list);
    if (mounted.current) {
      setClinics(list);
    }
  };

  useEffect(() => () => (mounted.current = false), []);

  // const fetchClinics = async () => {
  //   const list = await getAllClinics();

  //   if (mounted.current) setClinics(list);
  // };

  useEffect(() => {
    fetchClinics();

    // console.log("Clinic Context Loaded, All Clinics: ", clinics);
  }, []);

  return (
    <ClinicContext.Provider value={{clinics}}>
      {children}
    </ClinicContext.Provider>
  );
};
