import React, { useState } from "react";
import LabTestCard from "./LabTestCard";

import styles from "./LabTestListing.module.css";

function LabTestListing() {
  const [search, setSearch] = useState("");
  return (
    <>
      <div className={styles.listingContainer}>
        <div className={styles.searchContainer}>
          <input
            onChange={(e) => {
              setSearch(e.target.value);
              //   console.log(search);
            }}
            className={styles.search}
            type="text"
            placeholder="Search doctors, clinics and more..."
            value={search}
          />
        </div>
        <h2 className={styles.header}>Lab Tests</h2>
        <LabTestCard />
        <LabTestCard />
        <LabTestCard />
      </div>
    </>
  );
}

export default LabTestListing;
