import React from "react";
import Stepper from "react-stepper-js";
import fail from "../assets/img/fail.png";
import styles from "./Cart.module.css";

function CartFailiure() {
  return (
    <>
      <div className={styles.cartContainer}>
        <Stepper
          color="#835cb9"
          fontSize="15px"
          fontColor="#7f8386"
          steps={[
            { label: "Details" },
            { label: "Place Order" },
            { label: "Confirmation" },
          ]}
          currentStep={3}
        />
        <img className={styles.successImg} src={fail} alt="" />
        <h2 style={{ textAlign: "center" }} className={styles.header}>
          Payment Unsuccessful
        </h2>
        <div className={styles.couponSection}>
          <h2 className={styles.header}>
            The system is currently experiencing some technical difficulties.
            Error code: xyzsdfsf
          </h2>
          {/* <p className={styles.para}>
            New Balance - <b className={styles.formHeader}>300</b>
          </p> */}
        </div>
        <button className={styles.returnBtn}>TRY AGAIN</button>
      </div>
    </>
  );
}

export default CartFailiure;
