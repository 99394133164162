import React from "react";
import downloadImg from "../../assets/img/downloadImgTemp.png";
import userImg from "../../assets/img/userImgTemp.png";
import userImg2 from "../../assets/img/userImgTemp2.png";
import { Link } from "react-router-dom";

import styles from "./DownloadSection.module.css";

function DownloadSection() {
  return (
    <>
      <div className={styles.downloadContainer}>
        <div className={styles.flex}>
          <img className={styles.downloadImg} src={downloadImg} alt="" />
          <div className={styles.btnContainer}>
            <a
              href="https://play.google.com/store/apps/details?id=com.dtscliniker"
              className={styles.paraText}
              target="_blank"
              rel="noreferrer"
            >
              <button className={styles.downloadBtn}>PLAY STORE</button>
            </a>

            <Link to="/coming" className={styles.paraText}>
              <button className={styles.downloadBtn}>APP STORE</button>
            </Link>
          </div>
        </div>
        <div className={styles.flex}>
          <h2 className={styles.titleText}>
            Download the <span style={{ color: "#7549B1" }}>Cliniker</span> App
          </h2>
          <p className={styles.paraText}>
            Acces reports on the go, Book appointments, Lab tests and much more.
            One application for all your family's healthcare needs.
          </p>
          <div className={styles.reviewContainer}>
            <h2 className={styles.titleText}>What users say about it</h2>
            <div className={styles.review}>
              <img className={styles.userImg} src={userImg} alt="" />
              <p className={styles.paraText}>
                Excellent staff, Were extremely polite and cordial. Timely
                service
              </p>
            </div>
            <div className={styles.review}>
              <img className={styles.userImg} src={userImg2} alt="" />
              <p className={styles.paraText}>
                Got my appointment timely with no waiting time, efficient
                service
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DownloadSection;
