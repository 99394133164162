import React from "react";
import pharmacyImg from "../../assets/img/pharmacyTemp.png";
import mobileImg from "../../assets/img/labImgTemp.png";
import callIcon from "../../assets/img/callIconMobile.png";
import pharmacy1 from "../../assets/img/pharmacy-1.jpg";
import pharmacy2 from "../../assets/img/pharmacy-2.jpg";
import pharmacy3 from "../../assets/img/pharmacy-3.jpg";

import styles from "./PharmaciesSection.module.css";

function PharmaciesSection() {
  return (
    <>
      <div className={styles.pharmacySectionContainer}>
        <div className={styles.flex}>
          <h1 className={styles.mainTitleText}>
            Get Medicines at your doorstep
          </h1>
          <img className={styles.mobileImg} src={mobileImg} alt="" />

          <p className={styles.paraText}>
            Confused over the ideal pharmacy? Cliniker has the most reliable and
            trusted ones to fulfill all your medical needs with offers and
            timely delivery.
          </p>
        </div>
        <div className={styles.card}>
          <h2 className={styles.titleText}>Pharmacies near you </h2>
          <div className={styles.pharmacyMain}>
            <div className={styles.pharmacyContainer}>
              <div>
                <img className={styles.pharmacyImg} src={pharmacy1} alt="" />
              </div>
              <div className={styles.pharmacyInfo}>
                <h2 className={styles.pharmaName}>Patna Pharmacy</h2>
                <p className={styles.pharmadd}>
                  Gaur arcade, Front of, 16th Ave, Gaur City 2, Greater Noida,
                  Uttar Pradesh 201009
                </p>
                <a
                  href="https://goo.gl/maps/ooHQXQzGERCPa4UC9"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className={styles.bookBtn}>View</button>
                </a>
              </div>
              {/* <div className={styles.callBtnContainer}>
                <img src={callIcon} alt="" />
              </div> */}
            </div>
            <div className={styles.pharmacyContainer}>
              <div>
                <img className={styles.pharmacyImg} src={pharmacy2} alt="" />
              </div>
              <div className={styles.pharmacyInfo}>
                <h2 className={styles.pharmaName}>Tablet Medical Hub</h2>
                <p className={styles.pharmadd}>
                  Shop No 11 , Galleria Market, Gaur City 2, Uttar Pradesh
                  201009
                </p>
                <a
                  href="https://goo.gl/maps/mKzqC21wa5MAJbiT6"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className={styles.bookBtn}>View</button>
                </a>
              </div>
              {/* <div className={styles.callBtnContainer}>
                <img src={callIcon} alt="" />
              </div> */}
            </div>
            <div className={styles.pharmacyContainer}>
              <div>
                <img className={styles.pharmacyImg} src={pharmacy3} alt="" />
              </div>
              <div className={styles.pharmacyInfo}>
                <h2 className={styles.pharmaName}>Drug Mart Pharmacy</h2>
                <p className={styles.pharmadd}>
                  Shop No. UGF-12, Mahagun Mart, Gaur City 2, Greater Noida,
                  Uttar Pradesh 201308
                </p>
                <a
                  href="https://goo.gl/maps/pRTunTHm881rxzYk9"
                  target="_blank"
                  rel="noreferrer"
                >
                  <button className={styles.bookBtn}>View</button>
                </a>
              </div>
              {/* <div className={styles.callBtnContainer}>
                <img src={callIcon} alt="" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PharmaciesSection;
