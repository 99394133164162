import React, {createContext, useState, useEffect} from "react";

import {getDocCart, getLabCart} from "../services/firebaseServices";

export const CartContext = createContext();

export const CartProvider = ({children}) => {
  //   const [cart, setCart] = useState({
  //     docCartProducts: [],
  //     labCartProducts: [],
  //   });

  const [docCartProducts, setDocCartProducts] = useState();
  const [labCartProducts, setLabCartProducts] = useState();
  //   const [loading, setLoading] = useState(true);

  // const fetchDocCart = async () => {
  //   const response = await getDocCart(" jRzPsJlUYRQ9jut2Ls31qrddMNJ2");
  //   setDocCartProducts(response.appointments);
  //   // setCart((prevCart) => {
  //   //   return {
  //   //     ...prevCart,
  //   //     docCartProducts: response.schedule,
  //   //   };
  //   // });
  //   // console.log(docCartProducts);
  // };

  // const fetchLabCart = async () => {
  //   const response = await getLabCart("jRzPsJlUYRQ9jut2Ls31qrddMNJ2");
  //   setLabCartProducts(response.appointments);
  //   // console.log(response);
  //   // setCart((prevCart) => {
  //   //   return {
  //   //     ...prevCart,
  //   //     labCartProducts: response.schedule,
  //   //   };
  //   // });
  //   // console.log(labCartProducts);
  // };

  // useEffect(() => {
  //   fetchDocCart();
  //   fetchLabCart();
  //   // setLoading(false);
  // }, []);

  return (
    <CartContext.Provider
      value={{
        docCartProducts,
        setDocCartProducts,
        labCartProducts,
        setLabCartProducts,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};
