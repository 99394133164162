import React, {useContext, useEffect, useState} from "react";
// import firebase from "../firebase";
import {addToClinic, getAllClinics} from "../services/firebaseServices";
import ClinicCard from "./ClinicCard";
import clinicPic from "../assets/img/clinicPic.jfif";
// import search from "../assets/img/search.svg";

import styles from "./AllClinics.module.css";
import {ClinicContext} from "../Context/ClinicContext";
import {useHistory} from "react-router";

function AllClinics() {
  const {clinics} = useContext(ClinicContext);

  const [clinicsData, setClinicsData] = useState();

  let history = useHistory();

  // const sortWithRating = () => {
  //   clinicsData?.sort((itemA, itemB) => itemA.rating - itemB.rating);
  //   setClinicsData(clinicsData);
  //   console.log("sorted :", clinicsData);
  // };

  const sortWithRating = () => {
    setClinicsData((prevClinics) =>
      prevClinics.slice().sort((a, b) => {
        return b.rating - a.rating;
      })
    );
  };

  const fetchClinics = async () => {
    const list = await getAllClinics();
    setClinicsData(list);
  };

  useEffect(() => {
    if (clinics) {
      setClinicsData(clinics);
    } else {
      fetchClinics();
    }
    // addToClinic();
  }, []);

  // const [clinics, setClinics] = useState([]);
  // const [loading, setLoading] = useState(true);

  return (
    <>
      {/* {console.log(clinicsData, clinics)} */}
      <div className={styles.clinicsContainer}>
        <div className={styles.searchContainer}>
          {/* <img src={search} alt="" /> */}
          <input
            className={styles.search}
            type="text"
            placeholder="Search doctors, clinics and more..."
          />
        </div>
        <div className={styles.sortContainer}>
          <span className={styles.sortLabel}>Sort</span>
          <button
            onClick={() => sortWithRating()}
            className={styles.sortButton}
          >
            Rating
          </button>
          <button className={styles.sortButton}>Distance</button>
        </div>
        <div className={styles.clinicsList}>
          {/* <ClinicCard image={clinicPic} name={"Clinic Name"} />
          <ClinicCard image={clinicPic} name={"Clinic Name"} />
          <ClinicCard image={clinicPic} name={"Clinic Name"} /> */}

          {/* {console.log(clinics)} */}

          {/* {loading
            ? "Loading...."
            : clinics.map((item) => (
                <ClinicCard image={clinicPic} name={"Clinic Name"} />
              ))} */}
          {clinicsData?.map((item) => (
            <div
              onClick={() => history.push(`/clinic/${item.id}`)}
              key={item.id}
            >
              <ClinicCard
                image={clinicPic}
                name={item.name}
                rating={item.rating}
                place={item.location.place}
                facilities={item.facility}
              />
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default AllClinics;
