import React, { useState } from "react";
import app from "../firebase";

import isMobilePhone from "validator/lib/isMobilePhone";
import isEmail from "validator/lib/isEmail";

import uspImg from "../assets/img/labImgTemp.png";
import mobScreen from "../assets/img/enquiryScreen.png";
import mobScreen2 from "../assets/img/enquiryScreen2.png";

import tempImg from "../assets/img/userImgTemp.png";
import phone from "../assets/img/enquiryPB1.png";
import badge from "../assets/img/enquiryPB2.png";
import loudspeaker from "../assets/img/enquiryPB3.png";
import trade from "../assets/img/enquiryPB4.png";
// import circleWebTemp from "../assets/img/circleWebTemp.png";

import styles from "./PartnerEnquiry.module.css";
import { HashLink } from "react-router-hash-link";

function PartnerEnquiry() {
  const [formSubmit, setFormSubmit] = useState();

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    clinicName: "",
    address: "",
  });

  const [errors, setErrors] = useState({
    emailError: null,
    phoneError: null,
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isValidPhone = isMobilePhone(formData.phone, "en-IN");
    const isValidEmail = isEmail(formData.email);

    if (isValidEmail === false) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        emailError: "Invalid Email!!",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        emailError: null,
      }));
    }
    if (isValidPhone === false) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneError: "Invalid Phone No.",
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phoneError: null,
      }));
    }

    console.log("FormData & Errors", formData, errors);

    if (isValidEmail && isValidPhone) {
      app
        .database()
        .ref()
        .child("contact-form")
        .push(formData, (err) => {
          if (err) console.log(err);
        })
        .then((res) => {
          setFormSubmit(res);
          setFormData({
            name: "",
            phone: "",
            email: "",
            clinicName: "",
            address: "",
          });
        });
    } else {
      console.log("Errors Exisit", errors);
    }
  };

  return (
    <>
      <div className={styles.partnerEnquiryContainer}>
        <section className={styles.registerInfoSection}>
          <div className={styles.leftDiv}>
            <h2
              // style={{marginBottom: 30}}
              className={styles.titleText}
            >
              Register Your Clinic Today
            </h2>
            <p className={styles.paraText}>
              The primary aim of Cliniker is to empower local clinics and
              doctors to not only have a digital presence, but also assist them
              in providing the best quality healthcare to everyone. For the
              same, we are looking for Clinics - doctors, collection centers,
              diagnostic centers etc., who believe in providing excellent
              quality medical care to their patients. We intend to support them
              in whatever means possible so that together we can move one step
              closer to our shared dream of healthy india.
            </p>
            <div className={styles.btn}>
              <HashLink to="/enquiry#form" className={styles.linktext}>
                Join Us
              </HashLink>
            </div>
          </div>

          <img className={styles.registerInfoImg} src={mobScreen} alt="" />
        </section>

        <section className={styles.uspSection}>
          <img className={styles.uspMainImg} src={mobScreen2} alt="" />
          <div className={styles.uspContainer}>
            <h3 className={styles.uspTitle}>Partner benefits at cliniker</h3>
            <div className={styles.itemContainer}>
              <img className={styles.stepImg} src={phone} alt="" />
              <p className={styles.paraText}>
                Get a platform to be viewed by users near you – Digital access
                to your services
              </p>
            </div>
            <div className={styles.itemContainer}>
              <img className={styles.stepImg} src={badge} alt="" />
              <p className={styles.paraText}>
                Get quality assurance certificate from Cliniker.com – offline
                reputation also improves
              </p>
            </div>
            <div className={styles.itemContainer}>
              <img className={styles.stepImg} src={loudspeaker} alt="" />
              <p className={styles.paraText}>
                Personalized branding in & out of clinic and publicity (digital
                & print) in vicinity
              </p>
            </div>
            <div className={styles.itemContainer}>
              <img className={styles.stepImg} src={trade} alt="" />
              <p className={styles.paraText}>
                Revenue Sharing business model – GROW TOGETHER!
              </p>
            </div>
          </div>
        </section>

        <section id="form" className={styles.formSection}>
          <h2
            // style={{marginBottom: 30}}
            className={styles.titleText}
          >
            Patients are looking for right Doctors like you
          </h2>
          <p className={styles.paraText}>
            So if you believe your clinic should be a part of the Cliniker
            community, then do get in touch with us at support@cliniker.com or
            by calling us at 8826264333. Let us collaborate and make quality
            healthcare hassle free and accessible to all!
          </p>
          <div className={styles.formDiv}>
            <form onSubmit={handleSubmit}>
              <div className={styles.grid}>
                <div className={styles.formGroup}>
                  <input
                    required={true}
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className={styles.formInput}
                    type="text"
                    placeholder="Name"
                  />
                  <label className={styles.formLabel} htmlFor="">
                    Enter your name
                  </label>
                </div>
                <div className={styles.formGroup}>
                  <input
                    required
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    className={styles.formInput}
                    type="tel"
                    placeholder="Phone Number"
                  />
                  <label className={styles.formLabel} htmlFor="">
                    Enter your phone no.
                  </label>
                  {errors?.phoneError ? (
                    <div style={{ color: "red", marginTop: 10 }}>
                      {errors.phoneError}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className={styles.formGroup}>
                  <input
                    required
                    name="clinicName"
                    value={formData.clinicName}
                    onChange={handleChange}
                    className={styles.formInput}
                    type="text"
                    placeholder="Clinic / Hospital"
                  />
                  <label className={styles.formLabel} htmlFor="">
                    Enter your clinic/hospital name
                  </label>
                </div>
                <div className={styles.formGroup}>
                  <input
                    required
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={styles.formInput}
                    type="email"
                    placeholder="Email"
                  />
                  <label className={styles.formLabel} htmlFor="">
                    Enter your email
                  </label>
                  {errors?.emailError ? (
                    <div style={{ color: "red", marginTop: 10 }}>
                      {errors.emailError}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className={styles.formGroup}>
                  <input
                    required
                    name="address"
                    value={formData.address}
                    onChange={handleChange}
                    className={styles.formInput}
                    type="text"
                    placeholder="Clinic Address"
                  />
                  <label className={styles.formLabel} htmlFor="">
                    Enter your clinic address
                  </label>
                </div>
                {/* <div className={styles.formGroup}>
                  <input
                    required
                    onChange={handleChange}
                    className={styles.formInput}
                    type="text"
                    placeholder="Experience"
                  />
                  <label className={styles.formLabel} htmlFor="">
                    Enter your experience
                  </label>
                </div> */}
              </div>
              <button
                // style={{marginTop: 40, marginLeft: 40}}
                className={styles.formBtn}
                type="submit"
              >
                Submit
              </button>
              {formSubmit ? <span>Form Submitted Succesfully</span> : ""}
            </form>
          </div>
        </section>

        <section className={styles.partnerSection}>
          <h2 style={{ textAlign: "center" }} className={styles.titleText}>
            Our Partners
          </h2>
          <div className={styles.header}>
            <h3 className={styles.headerText}>Popular</h3>
            {/* <button className={styles.viewAllBtn}>View All</button> */}
          </div>
          <div className={styles.cardsContainer}>
            <div className={styles.partnerCard}>
              <img
                className={styles.cardImg}
                src={
                  "https://firebasestorage.googleapis.com/v0/b/cliniker-app.appspot.com/o/profiles%2FDrAnujAgrawal.jpeg?alt=media&token=b1c465b0-7b84-4e44-bf49-dba237b3af78"
                }
                alt=""
              />
              <h3 className={styles.cardTitle}>Dr. Anuj Agrawal</h3>
              <p className={styles.paraText}>
                Consultant OrthopaedicJoint Replacement Surgeon
              </p>
            </div>
            <div className={styles.partnerCard}>
              <img
                className={styles.cardImg}
                src={
                  "https://firebasestorage.googleapis.com/v0/b/cliniker-app.appspot.com/o/profiles%2FDrManishKumarSharma.jpeg?alt=media&token=d60ff5de-48d5-4d4c-8b2f-a4973c58e8aa"
                }
                alt=""
              />
              <h3 className={styles.cardTitle}>Dr. Manish Kumar Sharma</h3>
              <p className={styles.paraText}>
                Chest PhysicianSpecialist PulmonolgyCritical Care Medicine
              </p>
            </div>
            <div className={styles.partnerCard}>
              <img
                className={styles.cardImg}
                src={
                  "https://firebasestorage.googleapis.com/v0/b/cliniker-app.appspot.com/o/profiles%2Fphoto1643475467.jpeg?alt=media&token=14a63b02-ae2a-4c69-8182-96a3b0e1c3b4"
                }
                alt=""
              />
              <h3 className={styles.cardTitle}>PT Anil Sikerwar</h3>
              <p className={styles.paraText}>Physiotherapist</p>
            </div>
            <div className={styles.partnerCardMobile}>
              <img
                className={styles.cardImg}
                src={
                  "https://firebasestorage.googleapis.com/v0/b/cliniker-app.appspot.com/o/profiles%2Fphoto1643475467.jpeg?alt=media&token=14a63b02-ae2a-4c69-8182-96a3b0e1c3b4"
                }
                alt=""
              />
              <h3 className={styles.cardTitle}>Dr. Rashmi (Chourasia) Dey</h3>
              <p className={styles.paraText}>
                Consultant Gynaecologist & Obstetrician
              </p>
            </div>
            <div className={styles.partnerCardMobile}>
              <img
                className={styles.cardImg}
                src={
                  "https://firebasestorage.googleapis.com/v0/b/cliniker-app.appspot.com/o/profiles%2FDrRandhirKhurana.jpeg?alt=media&token=1add2727-5af7-47aa-97e0-1d7fef276afe"
                }
                alt=""
              />
              <h3 className={styles.cardTitle}>Dr. Randhir Khurana</h3>
              <p className={styles.paraText}>
                Consultant Pediatrician & Neonatologist
              </p>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}

export default PartnerEnquiry;
