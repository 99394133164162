import Header from "@editorjs/header";
import Paragraph from "@editorjs/paragraph";
import List from "@editorjs/list";
import SimpleImage from "@editorjs/simple-image";
import Image from "@editorjs/image";
import {ImageTool} from "@editorjs/image";
import {AlignmentBlockTune} from "editorjs-text-alignment-blocktune";

// import Embed from "@editorjs/embed";
// import Table from "@editorjs/table";
// import Warning from "@editorjs/warning";
// import Code from "@editorjs/code";
// import LinkTool from "@editorjs/link";
// import Raw from "@editorjs/raw";
// import Quote from "@editorjs/quote";
// import Marker from "@editorjs/marker";
// import CheckList from "@editorjs/checklist";
// import Delimiter from "@editorjs/delimiter";
// import InlineCode from "@editorjs/inline-code";

export const EDITOR_JS_TOOLS = {
  paragraph: Paragraph,
  list: List,
  simpleImage: SimpleImage,
  header: Header,
  image: Image,
  // AlignmentBlockTune: AlignmentBlockTune,
  // anyTuneName: {
  //   class: AlignmentBlockTune,
  //   config: {
  //     default: "right",
  //     blocks: {
  //       header: "center",
  //       list: "right",
  //     },
  //   },
  // },
  //   embed: Embed,
  //   table: Table,
  //   warning: Warning,
  //   code: Code,
  //   linkTool: LinkTool,
  //   raw: Raw,
  //   quote: Quote,
  //   marker: Marker,
  //   checklist: CheckList,
  //   delimiter: Delimiter,
  //   inlineCode: InlineCode,
};
