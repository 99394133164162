import React from "react";
import SearchBar from "../components/WebComponents/SearchBar";

// import bannerImg from "../assets/img/consultDocBanner.png";
import bannerLeftImg from "../assets/img/pharmacyBannerLeft.png";
import bannerRightImg from "../assets/img/pharmacyBannerRight.png";

import packageImg from "../assets/img/packageImgTemp.png";

import styles from "./Pharmacy.module.css";
import DownloadSection from "../components/LandingPage/DownloadSection";
import pharmacy1 from "../assets/img/pharmacy-1.jpg";
import pharmacy2 from "../assets/img/pharmacy-2.jpg";
import pharmacy3 from "../assets/img/pharmacy-3.jpg";

function Pharmacy() {
  return (
    <>
      <div className={styles.pharmacyContainer}>
        <section className={styles.bannerSection}>
          <div className={styles.bannerImgsDiv}>
            <img className={styles.bannerLeftImg} src={bannerLeftImg} alt="" />
            <div className={styles.description}>
              <h2 className={styles.bannerTitle}>
                Connect with pharmacies near me
              </h2>
              <p className={styles.bannerText}>
                Confused over the ideal pharmacy near you? Reach out to
                cliniker, we help you connect with most reliable and trusted
                ones to fulfill all your medical needs.
              </p>
            </div>
            <img
              className={styles.bannerRightImg}
              src={bannerRightImg}
              alt=""
            />
          </div>
          <div className={styles.searchComponent}>
            <SearchBar />
          </div>
          {/* <div className={styles.bannerSection}>
            <img className={styles.bannerImg} src={bannerImg} alt="" />
            <div className={styles.searchComponent}>
              <SearchBar />
            </div>
          </div> */}
        </section>

        <section className={styles.tilesSection}>
          <div>
            <div className={styles.headingDiv}>
              <h3 className={styles.titleText}>Popular Pharmacies near you</h3>
              {/* <button className={styles.viewAllBtn}>view all</button> */}
            </div>
            <div className={styles.packageContainer}>
              <div className={styles.packageSquare}>
                <img className={styles.packageImg} src={pharmacy1} alt="" />
                <div className={styles.packageInfo}>
                  <h3 className={styles.cardTitle}>Patna Pharmacy</h3>
                  <p className={styles.smallText}>
                    Gaur arcade, Front of, 16th Ave, Gaur City 2, Greater Noida,
                    Uttar Pradesh 201009
                  </p>
                  <p>⭐⭐⭐⭐⭐</p>
                  <a
                    href="https://goo.gl/maps/ooHQXQzGERCPa4UC9"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className={styles.bookBtn}>View</button>
                  </a>
                </div>
              </div>
              <div className={styles.packageSquare}>
                <img className={styles.packageImg} src={pharmacy2} alt="" />
                <div className={styles.packageInfo}>
                  <h3 className={styles.cardTitle}>Tablet Medical Hub</h3>
                  <p className={styles.smallText}>
                    Shop No 11 , Galleria Market, Gaur City 2, Uttar Pradesh
                    201009
                  </p>
                  <p>⭐⭐⭐⭐</p>
                  <a
                    href="https://goo.gl/maps/mKzqC21wa5MAJbiT6"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className={styles.bookBtn}>View</button>
                  </a>
                </div>
              </div>
              <div className={styles.packageSquare}>
                <img className={styles.packageImg} src={pharmacy3} alt="" />
                <div className={styles.packageInfo}>
                  <h3 className={styles.cardTitle}>Drug Mart Pharmacy</h3>
                  <p className={styles.smallText}>
                    Shop No. UGF-12, Mahagun Mart, Gaur City 2, Greater Noida,
                    Uttar Pradesh 201308
                  </p>
                  <p>⭐⭐⭐⭐</p>
                  <a
                    href="https://goo.gl/maps/pRTunTHm881rxzYk9"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <button className={styles.bookBtn}>View</button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <DownloadSection />
      </div>
    </>
  );
}

export default Pharmacy;
