import React from "react";
import styles from "./Card.module.css";

function Card({
  image,
  clinicName,
  text,
  location,
  inTime,
  outTime,
  days,
  width,
  radius,
  button,
}) {
  return (
    <>
      <div className={styles.cardContainer}>
        <div className={styles.imgSection}>
          <img style={{borderRadius: 10, width: "100%"}} src={image} alt="" />
        </div>
        <div className={styles.textSection}>
          <h3 className={styles.titleText}>{clinicName}</h3>
          <p className={styles.subText}>{location}</p>
          <p
            className={styles.subText}
            style={{marginTop: 3, marginBottom: 3, color: "#4d2684"}}
          >
            5 km away
          </p>
          <p className={styles.subText}>
            <span style={{color: "#000", fontWeight: "bold"}}>Timing</span> -{" "}
            {inTime} to {outTime}
          </p>
          <p className={styles.subText}>
            <span style={{color: "#000", fontWeight: "bold"}}>Days</span> -{" "}
            {days
              ? days.map((item) => {
                  return `${item}, `;
                })
              : "Nothing"}
          </p>
        </div>
        {button ? <button className={styles.bookBtn}>{button}</button> : ""}
      </div>
    </>
  );
}

export default Card;
