import React, {useState, useEffect, useContext} from "react";
import profilePic from "../assets/img/docPP.jfif";
import bellIcon from "../assets/img/bell.svg";
import userIcon from "../assets/img/user.svg";
import reportIcon from "../assets/img/report.svg";
import editIcon from "../assets/img/edit.svg";

import styles from "./UserProfile.module.css";
import {UserContext} from "../Context/UserContext";
import {Link} from "react-router-dom";
import {getActiveAddress, getUser} from "../services/firebaseServices";
import {AuthContext} from "../auth";

function UserProfile() {
  // const {user} = useContext(UserContext);
  const {currentUser} = useContext(AuthContext);
  const [user, setUser] = useState();
  const [activeAddress, setActiveAddress] = useState();

  // const {name, age, email, gender, phone_no} = user?.Info;

  // const fetchAddress = () => {
  //   let docId = user?.active_address[0];
  //   getActiveAddress(currentUser.uid, docId).then((res) =>
  //     setActiveAddress(res)
  //   );
  // };

  const fetchUser = () => {
    getUser(currentUser.uid).then((res) => {
      setUser(res);
      return res;
    });
    // .then((res) => {
    //   getActiveAddress(currentUser.uid, res.active_address[0]).then((res) => {
    //     setActiveAddress(res);
    //     // console.log(res);
    //   });
    // });
  };

  useEffect(() => {
    fetchUser();
    // fetchAddress();
  }, []);

  return (
    <>
      {console.log("user", user)}
      <div className={styles.profileContainer}>
        <div className={styles.imgContainer}>
          <img className={styles.userImg} src={profilePic} alt="" />
          <h2 className={styles.userName}>{user?.Info.name}</h2>
          <div className={styles.editIcon}>
            <Link
              to={{
                pathname: "/edit-profile",
                state: {
                  name: user?.Info.name,
                  age: user?.Info.age,
                  email: user?.Info.email,
                  gender: user?.Info.gender,
                  phone_no: user?.Info.phone_no,
                },
              }}
            >
              <img className={styles.editIcon} src={editIcon} alt="" />
            </Link>
          </div>
        </div>

        <div className={styles.personalInforamation}>
          <h2 className={styles.headerPI}>Personal Information</h2>
          <div className={styles.grid}>
            <h2 className={styles.header}>Contact No.</h2>
            <p className={styles.para}>{user?.Info.phone_no}</p>

            <h2 className={styles.header}>Age</h2>
            <p className={styles.para}>{user?.Info.age}</p>

            <h2 className={styles.header}>Gender</h2>
            <p className={styles.para}>{user?.Info.gender}</p>
          </div>
        </div>
        <div className={styles.address}>
          <h2 className={styles.header}>Selected Address - </h2>
          {/* {user?.active_address && Object.keys(user.active_address).length == 0
            ? console.log("undefined")
            : console.log("something")} */}

          {user?.active_address &&
          Object.keys(user.active_address).length !== 0 ? (
            <div>
              <p className={styles.para}>
                {user?.active_address.address_line1}
              </p>
              <p className={styles.para}>
                {user?.active_address.address_line2}
              </p>
              <p className={styles.para}>
                {user?.active_address.address_line3}
              </p>
              <p className={styles.para}>
                Pincode - {user?.active_address.pincode}
              </p>
            </div>
          ) : (
            "No Active Address, Please add one!"
          )}
        </div>
        <div className={styles.menu}>
          <div className={styles.flex}>
            <img className={styles.icon} width={15} src={reportIcon} alt="" />
            <h2 className={styles.header}>Attachment and Reports</h2>
          </div>
          <div className={styles.flex}>
            <img className={styles.icon} width={15} src={bellIcon} alt="" />
            <h2 className={styles.header}>Notifications/Reminders</h2>
          </div>
          <Link style={{textDecoration: "none"}} to={"/family-profiles"}>
            <div className={styles.flex}>
              <img className={styles.icon} width={15} src={userIcon} alt="" />
              <h2 className={styles.header}>Family Profiles</h2>
            </div>
          </Link>
          <Link style={{textDecoration: "none"}} to="/addresses">
            <div className={styles.flex}>
              <img className={styles.icon} width={15} src={editIcon} alt="" />
              <h2 className={styles.header}>My Addresses</h2>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}

export default UserProfile;
