import { firestore } from "../firebase";
import { db } from "../firebase";
// import {admin} from "../firebase-admin";

export async function getAllBlogs() {
  try {
    let blogs = [];

    const blogRef = db.collection("blog");
    const snapshot = await blogRef.get();

    snapshot.forEach((doc) => {
      // console.log(doc.data());

      blogs.push({ blog: doc.data().blog, blogId: doc.id });
    });
    return blogs;
  } catch (err) {
    console.log(err);
  }
}

export async function getABlog(blogId) {
  try {
    const blogRef = db.collection("blog").doc(blogId);
    const response = await blogRef.get();

    // console.log(response.data().blog);
    return response.data().blog;
  } catch (err) {
    console.log(err);
  }
}

export async function getAllPharmacies() {
  try {
    let pharmacies = [];
    const pharmaRef = db.collection("pharmacy");
    const snapshot = await pharmaRef.get();

    snapshot.forEach((doc) => {
      // console.log(doc.data());
      pharmacies.push(doc.data());
    });

    return pharmacies;
  } catch (error) {
    console.log(error);
  }
}

export async function editUserProfile(
  docId,
  name,
  contact,
  age,
  gender,
  email
) {
  const userRef = db.collection("user").doc(docId);
  const response = userRef.update({
    "Info.name": name,
    "Info.phone_no": contact,
    "Info.age": age,
    "Info.gender": gender,
    "Info.email": email,
  });

  // console.log(docId, name, contact, age, gender, email);

  return response;
}

export async function changeActiveAddress(item, userId) {
  // let address = [id];

  const userRef = db.collection("user").doc(userId);
  const response = userRef.update({ active_address: item });
  // console.log(address);

  return response;
}

// export async function getActiveAddress(userId, docId) {
//   const addressRef = db
//     .collection("user")
//     .doc(userId)
//     .collection("address")
//     .doc(docId);
//   const response = await addressRef.get();

//   // console.log(response);

//   return response.data();
// }

export async function getAllAddresses(userId) {
  let addresses = [];

  const addressRef = db.collection("user").doc(userId).collection("address");
  const snapshot = await addressRef.get();

  snapshot.forEach((doc) => {
    // console.log("Addresses fetched", doc.data());
    addresses.push({ ...doc.data(), address_id: doc.id });
  });
  // console.log(addresses);
  return addresses;
}

export async function editAddress(data, userId, docId) {
  try {
    const addressRef = db
      .collection("user")
      .doc(userId)
      .collection("address")
      .doc(docId);
    const response = await addressRef.set(data);

    return response;
  } catch (err) {
    console.log(err);
  }
}

export async function addAddress(data, userId) {
  try {
    const addressRef = db
      .collection("user")
      .doc(userId)
      .collection("address")
      .doc();
    const response = await addressRef.set(data);

    return response;
  } catch (err) {
    console.log(err);
  }
}

export async function getAllFamilyProfiles(userId) {
  try {
    let members = [];
    const familyMembersRef = db
      .collection("user")
      .doc(userId)
      .collection("family");
    const snapshot = await familyMembersRef.get();
    snapshot.forEach((doc) => {
      // console.log(doc.id);
      members.push({ ...doc.data(), doc_id: doc.id });
    });
    return members;
  } catch (err) {
    console.log(err);
  }
}

export async function changeActiveFamily(item, userId) {
  // let familyMember = [id];
  // console.log(item);

  const userRef = db.collection("user").doc(userId);
  const response = userRef.update({ active_family: item });

  return response;
}

export async function editFamilyMember(data, userId, docId) {
  try {
    console.log(docId);
    const memberRef = db
      .collection("user")
      .doc(userId)
      .collection("family")
      .doc(docId);
    const response = await memberRef.set(data);

    return response;
  } catch (err) {
    console.log(err);
  }
}

export async function addFamilyMember(data, userId) {
  try {
    // console.log("from firebase :", userId, data);

    const userRef = db
      .collection("user")
      .doc(userId)
      .collection("family")
      .doc();

    const response = await userRef.set(data, { merge: true });

    // const userRef = db.collection("user").doc(userId);

    // const response = await userRef.update({
    //   family: firestore.FieldValue.arrayUnion(data),
    // });

    // console.log(data);
    return response;
  } catch (err) {
    console.log(err);
  }
}

export async function updateCartInfo(data, userId) {
  try {
    // console.log(data);
    const cartRef = db.collection("doctor_cart").doc(userId);
    const response = await cartRef.update({
      appointments: data,
    });

    return response;
  } catch (err) {
    console.log(err);
  }
}

export async function removeItemFromDocCart(item, userId) {
  try {
    // console.log("item from service", item, userId);
    const docCartRef = db.collection("doctor_cart").doc(userId);
    const response = await docCartRef.update({
      appointments: firestore.FieldValue.arrayRemove(item),
    });

    return response;
  } catch (err) {
    console.log(err);
  }
}

export async function addItemToDocCart(item, userId) {
  try {
    const data = item;

    const docCartRef = db.collection("doctor_cart").doc(userId);
    const response = await docCartRef.update({
      appointments: firestore.FieldValue.arrayUnion(data),
      // appointments: admin.firestore.FieldValue.arrayUnion(data),
    });

    return response;
  } catch (err) {
    console.log(err);
  }
}

export async function addItemToLabCart() {
  try {
    const data = {
      appointment_id: Math.random(),
      address: "C 32 Mayur Vihar, Sector-62 Noida",
      collection_option: {
        clinic: true,
        home: false,
      },
      consultation_fee: "1000",
      lab_id: "1",
      labtest_name: "Some Lab Test 1",
      labtest_type: "Type",
      opted_slot: {
        date: "20",
        day: "Monday",
        time: "03:00pm",
      },
      patient_name: "Patient",
    };

    const labCartRef = db
      .collection("lab_cart")
      .doc("jRzPsJlUYRQ9jut2Ls31qrddMNJ2");
    const response = await labCartRef.update({
      appointments: firestore.FieldValue.arrayUnion(data),
      // appointments: admin.firestore.FieldValue.arrayUnion(data),
    });

    return response;
  } catch (err) {
    console.log(err);
  }
}

export async function createUser(userId, data) {
  try {
    // console.log("create newUser", userId, data);
    const userRef = db.collection("user").doc(userId);
    const snapshot = await userRef.get();

    if (!snapshot.exists) {
      // console.log("not exist");
      const newUser = userRef.set(data);

      const createEmptyCart = await db
        .collection("doctor_cart")
        .doc(userId)
        .set({ appointments: [] });
      const createEmptyOrders = await db
        .collection("appointment_doctor")
        .doc(userId)
        .set({ schedule: [] });

      return newUser;
    } else {
      // console.log("user exist");
    }

    // return newUser;
  } catch (err) {
    console.log(err);
  }
}

export async function getUser(userId) {
  try {
    let user = {};
    const userRef = db.collection("user").doc(userId);
    const response = await userRef.get();

    user = response.data();
    // console.log("User", user);
    return user;
  } catch (err) {
    console.log(err);
  }
}

export async function getAllClinics() {
  try {
    var clinicList = [];
    const clinicsRef = db.collection("clinic");
    const snapshot = await clinicsRef.get();
    snapshot.forEach((doc) => {
      // console.log(doc.id, "=>", doc.data());
      clinicList.push(doc.data());
    });
    return clinicList;
  } catch (err) {
    console.log(err);
  }
}

export async function getClinicDocs(clinicId) {
  try {
    let clinicDocs = [];
    const docsRef = db.collection("doctor");
    const snapshot = await docsRef.get();
    snapshot.forEach((doc) => {
      // console.log(doc.data().clinics);
      doc.data().clinics.map((item) => {
        if (item.id === clinicId) {
          // console.log(item);
          clinicDocs.push(doc.data());
        }
      });
    });
    // console.log(snapshot);
    return clinicDocs;
  } catch (err) {
    console.log(err);
  }
}

export async function getAllDoctors() {
  try {
    var docList = [];
    const doctorsRef = db.collection("doctor");
    const snapshot = await doctorsRef.get();
    snapshot.forEach((doc) => {
      docList.push(doc.data());
    });
    // console.log(docList);
    return docList;
  } catch (err) {
    console.log(err);
  }
}

export async function getClinic(clinicId) {
  try {
    let id = parseInt(clinicId);
    let clinic = {};
    const cityref = db.collection("clinic");
    const response = await cityref.where("id", "==", id).get();
    response.forEach((doc) => {
      clinic = doc.data();
    });
    // console.log(response);
    return clinic;
  } catch (err) {
    console.log(err);
  }
}

export async function getDoctor(doctorId) {
  try {
    let doctor = {};
    const docref = db.collection("doctor");
    const response = await docref.where("doc_id", "==", doctorId).get();
    response.forEach((doc) => {
      doctor = doc.data();
      // console.log(doc.data());
    });
    // console.log(doctorId);
    return doctor;
  } catch (err) {
    console.log(err);
  }
}

//cancel order

export async function cancelOrder(oldOrder, userId) {
  try {
    const orderRef = db.collection("appointment_doctor").doc(userId);
    const response = await orderRef.update({
      schedule: firestore.FieldValue.arrayRemove(oldOrder),
    });

    if (oldOrder.type === "checkup") {
      let doctorId = oldOrder.doctor.doc_id;
      doctorId = doctorId.toString();

      const canceledOrderRef = db.collection("cancelled_order").doc(doctorId);
      const cancelRes = await canceledOrderRef.update({
        cancelled_orders: firestore.FieldValue.arrayUnion(oldOrder),
      });
    }
    if (oldOrder.type === "labtest") {
      let labId = oldOrder.clinic.id;
      labId = labId.toString();

      const canceledOrderRef = db.collection("cancelled_order").doc(labId);
      const cancelRes = await canceledOrderRef.update({
        cancelled_orders: firestore.FieldValue.arrayUnion(oldOrder),
      });
    }

    // console.log(oldOrder);

    // const cancellRes = await canceledOrderRef.get();
    // console.log("can-res", cancellRes);

    // return cancelRes;
  } catch (err) {
    console.log(err);
  }
}

// export async function cancelLabOrder(oldOrder, userId) {
//   try {
//     let labId = oldOrder.clinic.id;
//     labId = labId.toString();

//     const orderRef = db.collection("appointment_lab").doc(userId);
//     const response = await orderRef.update({
//       schedule: firestore.FieldValue.arrayRemove(oldOrder),
//     });

//     const canceledOrderRef = db.collection("cancelled_order").doc(labId);
//     const cancelRes = await canceledOrderRef.update({
//       cancelled_orders: firestore.FieldValue.arrayUnion(oldOrder),
//     });

//     // console.log("cancle lab", oldOrder, userId);

//     // return cancelRes;
//   } catch (err) {
//     console.log(err);
//   }
// }

// reschedule cart
export async function rescheduleCart(newCart, userId) {
  // console.log("newCart", newCart, userId);
  const cartRef = db.collection("doctor_cart").doc(userId);
  const response = await cartRef.set({
    appointments: newCart,
  });

  return response;
}

//reschedule order
export async function rescheduleLabOrder(tempOrder, oldOrder, userId) {
  try {
    // console.log("temp", tempOrder, "old", oldOrder, "userId", userId);

    let newOrder = tempOrder;
    newOrder.reschedule_times = tempOrder.reschedule_times + 1;

    // console.log("temp", tempOrder, "old", oldOrder);
    const orderRef = db.collection("appointment_lab").doc(userId);

    const responseRemove = await orderRef.update({
      schedule: firestore.FieldValue.arrayRemove(oldOrder),
    });

    const responseUnion = await orderRef.update({
      schedule: firestore.FieldValue.arrayUnion(newOrder),
    });
  } catch (err) {
    console.log(err);
  }
}

export async function rescheduleDocOrder(tempOrder, oldOrder, userId) {
  try {
    // console.log("temp", tempOrder, "old", oldOrder, "userId", userId);

    let newOrder = tempOrder;
    newOrder.reschedule_times = tempOrder.reschedule_times + 1;

    // console.log("temp", tempOrder, "old", oldOrder);
    const orderRef = db.collection("appointment_doctor").doc(userId);

    const responseRemove = await orderRef.update({
      schedule: firestore.FieldValue.arrayRemove(oldOrder),
    });

    const responseUnion = await orderRef.update({
      schedule: firestore.FieldValue.arrayUnion(newOrder),
    });

    // .then((res) => {
    //   orderRef.update({
    //     schedule: firestore.FieldValue.arrayUnion(newOrder),
    //   });
    // })
    // .then((res) => {
    //   orderRef.update({
    //     reschedule_times: firestore.FieldValue.increment(1),
    //   });
    // });

    // const responseUnion = await orderRef.update({
    //   schedule: firestore.FieldValue.arrayUnion(newOrder),
    // });

    // console.log(responseRemove, responseUnion);
    // return response;
  } catch (err) {
    console.log(err);
  }
}

export async function getMyConsultOrders(userId) {
  try {
    const docref = db.collection("appointment_doctor").doc(userId);
    const response = await docref.get();

    // console.log("app", response.data().schedule);

    // let consultOrders = {};
    // let consultOrders = [];
    // const docref = db.collection("appointment_doctor").doc(userId).collection("schedule");
    // const snapshot = await docref.get();
    // snapshot.forEach((doc) => {
    //   // if (doc.id === userId) {
    //   //   consultOrders = doc.data();
    //   //   // console.log(doc.data());
    //   // }

    //   // console.log(doc.data(), doc.id);
    //   consultOrders.push({ ...doc.data(), order_id: doc.id})
    // });
    // console.log(consultOrders);
    return response.data()?.schedule;
  } catch (err) {
    console.log(err);
  }
}

export async function getMyLabOrders(userId) {
  try {
    let labOrders = {};
    const labref = db.collection("appointment_lab");
    const snapshot = await labref.get();
    snapshot.forEach((doc) => {
      if (doc.id === userId) {
        labOrders = doc.data();
        // console.log(doc.data());
      }
      // console.log(doc.id);
    });
    return labOrders;
  } catch (err) {
    console.log(err);
  }
}

// jRzPsJlUYRQ9jut2Ls31qrddMNJ2
// jRzPsJlUYRQ9jut2Ls31qrddMNJ2

export async function getDocCart(userId) {
  try {
    let docCart = {};
    const cartref = db.collection("doctor_cart");
    const snapshot = await cartref.get();
    snapshot.forEach((doc) => {
      if (doc.id === userId) {
        docCart = doc.data();
      }
    });
    return docCart;
  } catch (err) {
    console.log(err);
  }
}

// export async function getLabCart(userId) {
//   try {
//     let labCart = {};
//     const cartref = db.collection("lab_cart");
//     const snapshot = await cartref.get();
//     snapshot.forEach((doc) => {
//       if (doc.id === userId) {
//         labCart = doc.data();
//       }
//     });
//     return labCart;
//   } catch (err) {
//     console.log(err);
//   }
// }

// Temp functions to add data to backend

//temp func to add blog
export async function addToBlog() {
  let blog = {
    time: 1626168670359,
    blocks: [
      {
        id: "tPd6cn8eQo",
        type: "header",
        data: {
          text: "In-clinic visit v/s video/tele consultation",
          level: 2,
        },
      },
      {
        id: "230x-0P3_0",
        type: "paragraph",
        data: {
          text: "Hey. Meet the new Editor. On this page you can see it in action — try to edit this text.",
        },
      },
      {
        id: "e4s08Hvge6",
        type: "paragraph",
        data: {
          text: "The question then arises - is this here to stay? The boom in tele consultation maybe because of the pandemic, but do we see a complete shift towards online patient care in the future? Wherein you consult a doctor ONLINE, send prescription to pharmacy ONLINE and get medicines delivered touchfree.WE DON’T THINK SO!",
        },
      },
      {
        id: "K-Tjmf9_Hn",
        type: "simpleImage",
        data: {
          url: "https://st2.depositphotos.com/3889193/7657/i/600/depositphotos_76570869-stock-photo-confident-female-doctor-posing-in.jpg",
          caption: "A smiling doctor&nbsp;",
          withBorder: false,
          withBackground: true,
          stretched: false,
        },
      },
      {
        id: "lfHX190FiW",
        type: "paragraph",
        data: {
          text: "Secondly, medicine is one profession/industry which requires care and empathy. Not just the right diagnosis and medicine, but proper care is what makes someone recover and back to health. There is a reason why we call it HealthCARE :) An empathetic doctor who understands the patient’s fears and apprehensions can help him recover much better than even a trained professional who cannot empathize with the patient. After all it is the Doctor whose skill and knowledge people trust and undergo the treatment right?",
        },
      },
      {
        id: "Zr-M2MENt7",
        type: "paragraph",
        data: {
          text: "And one thing which the internet and technology has successfully done, despite all it’s abilities to connect people across the world, is to reduce that empathy towards one another. A warm hug, a generous handshake or even a pat on the shoulder that says “Everything is going to be alright” goes a long way in healing someone. And that can only be provided via a physical visit to the medical practitioner",
        },
      },
      {
        id: "Uo42VS0-sy",
        type: "paragraph",
        data: {
          text: "Moreover, it is easier for the medical practitioner to examine and diagnose in-person. Even for a basic problem like Cough or Cold, a doctor knows that it is important to check all the vitals to be informed of any major problems that may arise in the future. And we don’t have all the equipment at our homes, do we? Even for a simple Blood Pressure Measurement for which various sorts of portable devices are available, not all of us can afford those and therefore we need to visit a clinic for the same.",
        },
      },
      {
        id: "Jt6BpBrr9C",
        type: "paragraph",
        data: {
          text: "So in-clinic visit not only helps in providing better care, but better diagnosis as well - a win-win situation for the patients, right?",
        },
      },
      {
        id: "VPIWRvF-QH",
        type: "paragraph",
        data: {
          text: "We decided to test this hypothesis. At one Cliniker’s partner clinics in Noida, we rolled out a survey to ACTUAL PATIENTS where in we asked them -",
        },
      },
      {
        id: "MIZcgyCvF7",
        type: "paragraph",
        data: {
          text: "What do you prefer? A visual consultation (phone or video) or real consultation?",
        },
      },
      {
        id: "YuiAqZmQoN",
        type: "list",
        data: {
          style: "ordered",
          items: ["Phone or Video Consultation", "&nbsp;Real Consultation<br>"],
        },
      },
      {
        id: "XKYcXoeQd8",
        type: "paragraph",
        data: {
          text: "To our surprise, this was the outcome -",
        },
      },
      {
        id: "zRWdR16exy",
        type: "simpleImage",
        data: {
          url: "https://st2.depositphotos.com/3889193/7657/i/600/depositphotos_76570869-stock-photo-confident-female-doctor-posing-in.jpg",
          caption: "A smiling doctor&nbsp;",
          withBorder: false,
          withBackground: true,
          stretched: false,
        },
      },
      {
        id: "zx3uFl37Aw",
        type: "paragraph",
        data: {
          text: "More than 90% of people preferred that they would rather choose an in-clinic visit to a doctor than a tele consultation. And mind you, this was in Feb 2021 - during Covid-19",
        },
      },
      {
        id: "j4oXi8wuQr",
        type: "paragraph",
        data: {
          text: "When people were asked why they felt in-clinic visit was more preferable them, these were some of the reasons which they stated -",
        },
      },
      {
        id: "n50DtX4Q1o",
        type: "list",
        data: {
          style: "ordered",
          items: [
            "“I feel more comfortable and more confident about the diagnosis when it’s in person”",
            "“Knowing the medical practitioner personally helps build confidence instead of connecting with someone miles away, even though he is a great professional”",
            "“It’s always better to consult someone who knows my medical history and has been treating me for a long time than with a completely new doctor every time”",
            "“If any procedure, medicine, diagnostic test needs to be done, the doctor can suggest me the best place near me and I won’t have to worry about the quality”",
          ],
        },
      },
      {
        id: "EdOv1jWZAU",
        type: "paragraph",
        data: {
          text: "So then what is the way ahead, is it tele-consultation or in-clinic visits?",
        },
      },
      {
        id: "wV1ylVJjie",
        type: "paragraph",
        data: {
          text: "We believe that it’ll be a combination of both. There will always be some people who like the convenience of consulting a doctor through their phones, and some people who prefer an in-person diagnosis.",
        },
      },
      {
        id: "ViDecyv-DM",
        type: "paragraph",
        data: {
          text: "We at Cliniker are doing our part by enhancing the experience of an in-clinic visit. Like we said, technology is an enabler, and we are using it to connect you with the best clinics NEAR YOU where you can go and get an in-clinic doctor consultation done. We also aim to connect you with best quality diagnostic and pathology facilities near you so that you can get your blood tests and regular health checkups done easily and of the utmost quality. We intend to use technology to help reduce wait times, provide correct information on facilities available near you, help you store your medical reports safely and many more things. We at Cliniker hope to assist medical practitioners practice medicine with the utmost ease and convenience and help them in providing the best quality medical CARE to everyone out there.",
        },
      },
      {
        id: "3ZbSpK3Fqc",
        type: "paragraph",
        data: {
          text: "Clinker - We care for you!",
        },
      },
    ],
    version: "2.22.0",
  };

  const addBlog = db.collection("blog").add({ blog });
}
//temp func to add
export async function addlabtoschedule() {
  try {
    let obj = {
      // address: "C42 Random Vihar",
      clinic_id: "1",
      clinic_name: "Clinic 1",
      date: {
        dateString: "2021-5-28",
        day: 25,
        month: 5,
        year: 2021,
        timestamp: 1624579200008,
      },
      // day: "fri",
      doctor: {
        clinics: [
          {
            days: ["mon", "wed", "fri"],
            id: 1,
            timing: {
              intime: 7,
              outime: 9,
            },
          },

          {
            days: ["mon", "wed", "thu"],
            id: 2,
            timing: {
              intime: 6,
              outime: 9,
            },
          },
        ],
        doc_id: 2,
        experience: 15,
        expertise: [
          "Child/Infant care",
          "New born child growth",
          "Child care treatment",
        ],
        fees: 400,
        image: "base64code",
        name: "Sunil Kumar",
        pastaff: [
          "10 years experience in Apollo Hospital",
          "10 years experience in Apollo Hospital",
        ],
        phone_no: "8290660905",
        rating: 4,
        specialist: ["Nephrologist"],
      },
      patient_name: "Ankit kushwaha",
      rating: "0",
      reschedule_times: 0,
      review: "",
      slot: 7,
      status: {
        booked: { confirmed: true },
        consultation: { confirmed: false },
        prescription: { uploaded: false },
      },
      subslot: 1,
      time: "8:20am-8:30am",
      type: "checkup",
    };
    // console.log(obj);
    const scheduleRef = db.collection("appointment_lab").doc("8290660905");
    const response = await scheduleRef.update({
      schedule: firestore.FieldValue.arrayUnion(obj),
    });
  } catch (err) {
    console.log(err);
  }
}

export async function addtoschedule() {
  try {
    let obj = {
      // address: "C42 Random Vihar",
      clinic_id: "1",
      clinic_name: "Clinic 1",
      date: {
        dateString: "2021-5-28",
        day: 25,
        month: 5,
        year: 2021,
        timestamp: 1624579200008,
      },
      // day: "fri",
      doctor: {
        clinics: [
          {
            days: ["mon", "wed", "fri"],
            id: 1,
            timing: {
              intime: 7,
              outime: 9,
            },
          },

          {
            days: ["mon", "wed", "thu"],
            id: 2,
            timing: {
              intime: 6,
              outime: 9,
            },
          },
        ],
        doc_id: 2,
        experience: 15,
        expertise: [
          "Child/Infant care",
          "New born child growth",
          "Child care treatment",
        ],
        fees: 400,
        image: "base64code",
        name: "Sunil Kumar",
        pastaff: [
          "10 years experience in Apollo Hospital",
          "10 years experience in Apollo Hospital",
        ],
        phone_no: "8290660905",
        rating: 4,
        specialist: ["Nephrologist"],
      },
      patient_name: "Ankit kushwaha",
      rating: "0",
      reschedule_times: 0,
      review: "",
      slot: 7,
      status: {
        booked: { confirmed: true },
        consultation: { confirmed: false },
        prescription: { uploaded: false },
      },
      subslot: 1,
      time: "8:20am-8:30am",
      type: "checkup",
    };
    // console.log(obj);
    const scheduleRef = db
      .collection("appointment_doctor")
      .doc("5g6qJMQIk3PAOvNNrFA94aJLFuH2");
    const response = await scheduleRef.update({
      schedule: firestore.FieldValue.arrayUnion(obj),
    });
  } catch (err) {
    console.log(err);
  }
}

// temp func to add clinic

export async function addToClinic() {
  let clinics = [
    {
      id: 1,
      name: "MAXcare Multispeciality Clinic Gaur City",
      address:
        "Shop No. 9, UGF, Mahagun Mart, Gaur City-2, Sector-16C, Greater Noida West - 201305",
      facility: "Consultation and Lab tests",
      lab_services: "Same as Max lab test list pdf",
      labs_available: "Max Lab",
      staff_list: [
        "Vaseem Ahmed",
        "Sahil Khan",
        "Jyoti",
        "Gunjan",
        "Gullu",
        "Sanjeev Kumar",
        "Sachin Pandey",
        "Anuj Sharma",
      ],
      distance_servicable_km: 20,
      distance_charge: 0,
      time: {
        intime: 7,
        outime: 21,
      },
    },
    {
      id: 2,
      name: "MAXcare Mother and Child Clinic",
      address:
        "Shop No. 10, LGF, Mahagun Mart, Gaur City-2, Sector-16C, Greater Noida West - 201305",
      facility: "Consultation and Lab tests",
      lab_services: "Same as Max lab test list pdf",
      labs_available: "Max Lab",
      staff_list: [
        "Vaseem Ahmed",
        "Sahil Khan",
        "Jyoti",
        "Gunjan",
        "Gullu",
        "Sanjeev Kumar",
        "Sachin Pandey",
        "Anuj Sharma",
      ],
      distance_servicable_km: 20,
      distance_charge: 0,
      time: {
        intime: 7,
        outime: 21,
      },
    },
    {
      id: 3,
      name: "MAXcare Multispeciality Clinic Ace City",
      address:
        "FF-107, First floor, City Square, Ace City, Near Yatharth Hospital, Greater Noida West - 201301",
      facility: "Consultation and Lab tests",
      lab_services: "Same as Max lab test list pdf",
      labs_available: "Max Lab",
      staff_list: [
        "Vaseem Ahmed",
        "Sahil Khan",
        "Jyoti",
        "Gunjan",
        "Gullu",
        "Sanjeev Kumar",
        "Sachin Pandey",
        "Anuj Sharma",
      ],
      distance_servicable_km: 20,
      distance_charge: 0,
      time: {
        intime: 7,
        outime: 21,
      },
    },
    {
      id: 4,
      name: "MAXcare Multispeciality Clinic Sector 137",
      address:
        "Shop No. 11, Commercial-2, Paras Tierra, Sector-137, Noida - 201306",
      facility: "Consultation and Lab tests",
      lab_services: "Same as Max lab test list pdf",
      labs_available: "Max Lab",
      staff_list: [
        "Vaseem Ahmed",
        "Sahil Khan",
        "Jyoti",
        "Gunjan",
        "Gullu",
        "Sanjeev Kumar",
        "Sachin Pandey",
        "Anuj Sharma",
      ],
      distance_servicable_km: 20,
      distance_charge: 0,
      time: {
        intime: 7,
        outime: 21,
      },
    },
  ];
  // const clinicRef = db.collection("clinic");
  // const response = await clinicRef.add(clinic);
}

// temp func to add doctor data
export async function addToDoctor() {
  let doctors = [
    {
      doc_id: 1,
      name: "Dr. Randhir Khurana",
      phone_no: 9993126272,
      speciality: "Consultant Pediatrician & Neonatologist",
      fees: 500,
      expertise:
        "Child/Infant Care\r\nNew Born, Child Growth\r\nAll Child Care Treatment",
      qualification: "MBBS, MD (Pediatrics)\r\nFellowship in Neonatology (NNF)",
      pastaff:
        "1. Fortis Hospital, Sec-62, Noida\r\n2. Nayati Multispeciality Hospital, Mathura",
      gender: "Male",
    },
    {
      doc_id: 2,
      name: "Dr. Manish Kumar Sharma",
      phone_no: 8334094208,
      speciality:
        "Chest Physician\r\nSpecialist Pulmonolgy\r\nCritical Care Medicine",
      fees: 500,
      expertise:
        "All Lung Disease, Breathing Disorders, Critical Care, Medicine Requiring, ICU Care, Asthma, COPD, ILPD, TB, Sleep Disorder - Sleep Apnea, Allergic Disorder",
      qualification: "DNB (Res. Med) DTCD,\r\nEDARM (Europe) FCCS (USA) MBBS",
      gender: "Male",
    },
    {
      doc_id: 3,
      name: "Dr. Rashmi (Chourasia) Dey",
      speciality: "Consultant Gynaecologist & Obstetrician",
      fees: 500,
      expertise:
        "Pregnancy Care & Birth\r\nPainless Delivery, Infertility, White Discharge, Heavy Periods, Prevention of Cervical Cancer",
      qualification: "MBBS, DGO (10+ Years of Experience)",
      pastaff: "Delhi Govt. Hospital",
      gender: "Female",
    },
    {
      doc_id: 4,
      name: "Dr. Anuj Agrawal",
      speciality: "Consultant Orthopaedic\r\nJoint Replacement Surgeon",
      fees: 600,
      expertise: "All Bone Diseases\r\nKnee, Joint pain, Arthritis, Fracture",
      qualification: "MBBS (AIIMS), MS (PGIMER), MCH (USAIM), MRCS (UK), FIAS",
      pastaff: "Senior Consultant Max Hopsital",
      gender: "Male",
    },
    {
      doc_id: 5,
      name: "Dr. Sumit I. Karn",
      phone_no: 9975057475,
      speciality: "Consultant Pediatrician & Neonatologist",
      fees: 500,
      expertise:
        "Child/Infant Care\r\nNew Born, Child Growth\r\nAll Child Care Treatment",
      qualification: "MBBS, DNB (Paediatrics)",
      pastaff:
        "1. Fortis Hospital, Sec-62, Noida\r\n2. Apollo Hospital, Sec-26, Noida",
      gender: "Male",
    },
    {
      doc_id: 6,
      name: "Dr. Anil Sikerwar PT",
      phone_no: 8368909925,
      speciality: "Physiotherapist",
      fees: 500,
      expertise: "Neuro Rehab Physiotherapy & Health Care Clinic",
      qualification: "MPT (Neurology)",
      gender: "Male",
    },
    {
      doc_id: 7,
      name: "Dr. Ravindra Srivastava",
      speciality: "Neuro Headache & Spine Care",
      fees: 600,
      expertise:
        "Consultation for Brain Tumor, Headache, Brain Hemorrhage, Sciatica, Cervical Pain, Head Injury, Paralysis, Epilepsy, Slip Disc, Vertigo, Spinal Tumor",
      qualification:
        "MBBS (Gold Medalist), MS (Gen. Surgery), DNB, MNAMS, Mch (Neuro), AIIMS New Delhi",
      gender: "Male",
    },
    {
      doc_id: 8,
      name: "Dr. Vidushi Lakhanpal",
      speciality: "Gynaecologist & Obstetrician",
      fees: 500,
      expertise: "Pregnancy Care & Birth\r\nNormal/Painless Delivery",
      qualification: "MBBS, MRCOG (UK), DRCOG (UK), DGO",
      pastaff: "Total 20 years experience\r\n10 years experience in London",
      gender: "Female",
    },
    {
      doc_id: 9,
      name: "Dr. Neeraj Kumar Goyal",
      speciality: "Senior Orthopaedic Consultant",
      fees: 600,
      expertise: "Knee, Joint Pain, Arthritis, Fracture",
      qualification:
        "MBBS, MS (Ortho)\r\nJoint Replacement & Arthroscopy Specialist",
      gender: "Male",
    },
    {
      doc_id: 10,
      name: "Dr. Venkatesh Kumar",
      phone_no: 9439438400,
      speciality: "Urologist & Andrologist",
      fees: 600,
      expertise:
        "Kidney Problems, Urinary Tract Infection, Laser Surgery of Stone & Prostate, Laproscopic, Erectile Dysfunction, Male Infertility",
      qualification: "MD Physician, MS Surgery, DNB Urology",
      pastaff:
        "1. Consultant Neo Hospital\r\n2. Apollo Hospital\r\n3. Shri Ram Singh Hospital\r\n4. Motherland Hospital",
      gender: "Male",
    },
  ];

  const docRef = db.collection("doctors");
  // const response = await docRef.add(doctor);

  doctors.map((item) => {
    const response = docRef.add(item);
  });
}

//temp func to add labtest data

export async function addToLabTest() {
  let tests = [
    {
      max_lab_code: 26490,
      package_name: "Antenatal Antibody Screening Test",
      lab_name: "Max Lab",
      fees: 620,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 36129,
      package_name: "Anti A /B Titers",
      lab_name: "Max Lab",
      fees: 820,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37069,
      package_name: "Anti A/B Titration",
      lab_name: "Max Lab",
      fees: 2160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45206,
      package_name: "Antibodies Screening",
      lab_name: "Max Lab",
      fees: 550,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26492,
      package_name: "Antibody Identification -  11Cell Panel",
      lab_name: "Max Lab",
      fees: 5000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26493,
      package_name: "Antibody Identification with Prophylaxis-3 Cell",
      lab_name: "Max Lab",
      fees: 1870,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37389,
      package_name: "Antibody Titration.",
      lab_name: "Max Lab",
      fees: 2580,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5943,
      package_name: "Blood Grouping and RH Factor",
      lab_name: "Max Lab",
      fees: 180,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25544,
      package_name: "Blood Grouping for Patients (Infants) Less Than 4 Months",
      lab_name: "Max Lab",
      fees: 530,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28670,
      package_name: "Cold Agglutination Test Serum",
      lab_name: "Max Lab",
      fees: 990,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5961,
      package_name: "Coombs Test Direct",
      lab_name: "Max Lab",
      fees: 520,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5962,
      package_name: "Coombs Test Indirect",
      lab_name: "Max Lab",
      fees: 520,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25782,
      package_name: "Mother Blood Grouping",
      lab_name: "Max Lab",
      fees: 470,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 20799,
      package_name: "Platelet Concentrate Processing Charges",
      lab_name: "Max Lab",
      fees: 1100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45990,
      package_name: "5-Amino levulinic AcidQualitative Urine(L)",
      lab_name: "Max Lab",
      fees: 4330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6255,
      package_name: "ACE (Angiotensing Converting Enzyme)",
      lab_name: "Max Lab",
      fees: 1100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25147,
      package_name: "ADA Serum",
      lab_name: "Max Lab",
      fees: 760,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6238,
      package_name: "Albumin Serum",
      lab_name: "Max Lab",
      fees: 150,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5844,
      package_name: "Alkaline Phosphatase",
      lab_name: "Max Lab",
      fees: 220,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5819,
      package_name: "Ammonia EDTA Sample",
      lab_name: "Max Lab",
      fees: 1170,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19554,
      package_name: "Amphetamine and Metabolite Screen Urine",
      lab_name: "Max Lab",
      fees: 810,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5820,
      package_name: "Amylase Serum",
      lab_name: "Max Lab",
      fees: 460,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25633,
      package_name: "Amylase Spot Urine",
      lab_name: "Max Lab",
      fees: 550,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19564,
      package_name: "Apolipoproteins A1 & BSerum",
      lab_name: "Max Lab",
      fees: 850,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45363,
      package_name: "Aryl Sulphatase A (M)",
      lab_name: "Max Lab",
      fees: 4870,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26248,
      package_name: "Ascitic Fluid - ADA",
      lab_name: "Max Lab",
      fees: 740,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5811,
      package_name: "Ascitic Fluid - Glucose & Protein",
      lab_name: "Max Lab",
      fees: 460,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5816,
      package_name: "Ascitic Fluid - LDH (Lactate Dehydrogenase)",
      lab_name: "Max Lab",
      fees: 430,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6050,
      package_name: "ASO (Anti Streptolysin O) Serum",
      lab_name: "Max Lab",
      fees: 700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5838,
      package_name: "B.U.N (Blood Urea Nitrogen) Serum",
      lab_name: "Max Lab",
      fees: 140,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19566,
      package_name: "Barbiturate Screen Urine",
      lab_name: "Max Lab",
      fees: 810,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19567,
      package_name: "Benzodiazepine Screen Urine",
      lab_name: "Max Lab",
      fees: 850,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6512,
      package_name: "Beta-2-Microglobulin Urine",
      lab_name: "Max Lab",
      fees: 1300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25566,
      package_name: "Beta-2-MicroglobulinSerum",
      lab_name: "Max Lab",
      fees: 1330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5827,
      package_name: "Bicarbonate Serum",
      lab_name: "Max Lab",
      fees: 240,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 31495,
      package_name: "Bile Acids Total Serum",
      lab_name: "Max Lab",
      fees: 1580,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5822,
      package_name: "Bilirubin Total and Direct",
      lab_name: "Max Lab",
      fees: 290,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37799,
      package_name: "Bladder Tumor Antigen Test (BTA Test)",
      lab_name: "Max Lab",
      fees: 2930,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5854,
      package_name: "Blood Sugar (Random) Fluoride Plasma",
      lab_name: "Max Lab",
      fees: 80,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5853,
      package_name: "Blood Sugar 2 Hr. PPFluoride Plasma",
      lab_name: "Max Lab",
      fees: 90,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5855,
      package_name: "Blood Sugar FastingFluoride Plasma",
      lab_name: "Max Lab",
      fees: 80,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28990,
      package_name: "Blood Sugar PP (After Lunch)",
      lab_name: "Max Lab",
      fees: 150,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19570,
      package_name: "C3 & C4 Complement PanelSerum",
      lab_name: "Max Lab",
      fees: 1510,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6321,
      package_name: "C3 ComplementSerum",
      lab_name: "Max Lab",
      fees: 820,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6326,
      package_name: "C4 ComplementSerum",
      lab_name: "Max Lab",
      fees: 820,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5823,
      package_name: "Calcium Serum",
      lab_name: "Max Lab",
      fees: 180,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5875,
      package_name: "Calcium24hrs Urine",
      lab_name: "Max Lab",
      fees: 270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6305,
      package_name: "CalciumSpot Urine",
      lab_name: "Max Lab",
      fees: 300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37795,
      package_name: "Calcium/Creatinine Ratio 24 hrs Urine",
      lab_name: "Max Lab",
      fees: 630,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26249,
      package_name: "Calcium/Creatinine Ratio Urine",
      lab_name: "Max Lab",
      fees: 500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19575,
      package_name: "Cannabinoids Random Urine (THC) Marijuana",
      lab_name: "Max Lab",
      fees: 770,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5920,
      package_name: "Carbamazepine (Tegretol)Serum",
      lab_name: "Max Lab",
      fees: 970,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 31494,
      package_name: "CeruloplasminSerum",
      lab_name: "Max Lab",
      fees: 1190,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5876,
      package_name: "Chloride 24 Hrs Urine",
      lab_name: "Max Lab",
      fees: 280,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5824,
      package_name: "Chloride Serum",
      lab_name: "Max Lab",
      fees: 150,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24068,
      package_name: "Chloride Spot Urine",
      lab_name: "Max Lab",
      fees: 280,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5825,
      package_name: "Cholesterol",
      lab_name: "Max Lab",
      fees: 150,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26450,
      package_name: "Cholinesterase",
      lab_name: "Max Lab",
      fees: 540,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19583,
      package_name: "Cocaine ( Benzoylecgonine ) Screen Urine",
      lab_name: "Max Lab",
      fees: 810,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 30216,
      package_name: "Contrast Profile",
      lab_name: "Max Lab",
      fees: 510,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34742,
      package_name: "Copper Serum",
      lab_name: "Max Lab",
      fees: 1380,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5849,
      package_name: "Creatine Kinase (CPK)Serum",
      lab_name: "Max Lab",
      fees: 280,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5848,
      package_name: "Creatinine Clearance Test24Hrs Urine",
      lab_name: "Max Lab",
      fees: 620,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5877,
      package_name: "Creatinine 24 Hrs Urine",
      lab_name: "Max Lab",
      fees: 520,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5847,
      package_name: "Creatinine Serum",
      lab_name: "Max Lab",
      fees: 140,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6306,
      package_name: "Creatinine Spot Urine",
      lab_name: "Max Lab",
      fees: 460,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6033,
      package_name: "CRP (C-Reactive Protein) High Sensitive",
      lab_name: "Max Lab",
      fees: 750,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 40253,
      package_name: "CRP- C-REACTIVE PROTEIN",
      lab_name: "Max Lab",
      fees: 430,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26250,
      package_name: "CSF - Glucose & Protein",
      lab_name: "Max Lab",
      fees: 470,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25148,
      package_name: "CSF- ADA Analysis",
      lab_name: "Max Lab",
      fees: 760,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21595,
      package_name: "CyclosporinePeak level (C2)Whole Blood EDTA",
      lab_name: "Max Lab",
      fees: 2580,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21596,
      package_name: "CyclosporineTrough level (C0)Whole Blood EDTA",
      lab_name: "Max Lab",
      fees: 2320,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 36165,
      package_name: "Dialysis Water for Bicarbonate",
      lab_name: "Max Lab",
      fees: 260,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24167,
      package_name: "Dialysis Water for Calcium",
      lab_name: "Max Lab",
      fees: 260,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24168,
      package_name: "Dialysis Water for Inorganic phosphorus",
      lab_name: "Max Lab",
      fees: 250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 36164,
      package_name: "Dialysis Water for Magnesium",
      lab_name: "Max Lab",
      fees: 260,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24170,
      package_name: "Dialysis Water for Potassium",
      lab_name: "Max Lab",
      fees: 260,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24169,
      package_name: "Dialysis Water for Sodium",
      lab_name: "Max Lab",
      fees: 260,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19636,
      package_name: "FDP (Fibrinogen Degradation Products)",
      lab_name: "Max Lab",
      fees: 1020,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6445,
      package_name: "FructosamineSerum",
      lab_name: "Max Lab",
      fees: 630,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5947,
      package_name: "G - 6 PD Deficiency TestQuantitativeEDTA",
      lab_name: "Max Lab",
      fees: 880,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50100,
      package_name: "Gestational Diabetes Screening(75g Glucose2hrs.)",
      lab_name: "Max Lab",
      fees: 180,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29992,
      package_name: "Gestational GTT (3 specimens) after 75gm Glucose",
      lab_name: "Max Lab",
      fees: 350,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6394,
      package_name: "Gestational GTT (4 Specimen) after 100gm Glucose",
      lab_name: "Max Lab",
      fees: 370,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 40446,
      package_name: "GFR Estimated (eGFR) Serum",
      lab_name: "Max Lab",
      fees: 380,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5829,
      package_name: "GGTP (Gamma GT) Serum",
      lab_name: "Max Lab",
      fees: 290,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37688,
      package_name: "Glucose Challenge Test (GCT) 75gFluoride Plasma",
      lab_name: "Max Lab",
      fees: 180,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6345,
      package_name: "Glucose Challenge Test (GCT)Fluoride Plasma",
      lab_name: "Max Lab",
      fees: 100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5857,
      package_name: "Glucose Tolerance Test - (GTT 2 specimens) after 75gm",
      lab_name: "Max Lab",
      fees: 260,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6273,
      package_name: "Glucose Tolerance Test - (GTT 4 specimen)Fluoride Plasma",
      lab_name: "Max Lab",
      fees: 560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6302,
      package_name: "Glucose Tolerance Test - (GTT 6 specimen)Fluoride Plasma",
      lab_name: "Max Lab",
      fees: 820,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39472,
      package_name: "Glucose Tolerance Test-(GTT 5 Specimen)Fluoride Plasma",
      lab_name: "Max Lab",
      fees: 710,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5879,
      package_name: "Glucose24Hrs Urine",
      lab_name: "Max Lab",
      fees: 320,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5830,
      package_name: "Glycosylated Haemoglobin (HbA1C)EDTA Routine",
      lab_name: "Max Lab",
      fees: 450,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51383,
      package_name: "GTIR(Glucose Tolerance Insulin Response)",
      lab_name: "Max Lab",
      fees: 4840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6348,
      package_name: "GTT-(GTT 3 Specimen) after 75 gm Glucose Fluoride Plasma",
      lab_name: "Max Lab",
      fees: 340,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6598,
      package_name: "HaptoglobinSerum",
      lab_name: "Max Lab",
      fees: 2040,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 52919,
      package_name: "HbA1c Boronate Affinity HPLC",
      lab_name: "Max Lab",
      fees: 1145,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5831,
      package_name: "HDL Cholesterol",
      lab_name: "Max Lab",
      fees: 300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19651,
      package_name: "Homocysteine Quantitative",
      lab_name: "Max Lab",
      fees: 950,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5860,
      package_name: "IgA (Immunoglobulin-A)",
      lab_name: "Max Lab",
      fees: 500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5861,
      package_name: "IgG (Immunoglobulin-G)",
      lab_name: "Max Lab",
      fees: 500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5862,
      package_name: "IgM (Immunoglobulin-M)",
      lab_name: "Max Lab",
      fees: 500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6271,
      package_name: "Immunoglobulin Profile (IgG + IgA + IgM) Serum",
      lab_name: "Max Lab",
      fees: 1380,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5880,
      package_name: "Inorganic Phosphorus 24 Hrs Urine",
      lab_name: "Max Lab",
      fees: 350,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5866,
      package_name: "Inorganic Phosphorus Serum",
      lab_name: "Max Lab",
      fees: 200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25634,
      package_name: "Inorganic Phosphorus Spot Urine",
      lab_name: "Max Lab",
      fees: 340,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19957,
      package_name: "Ionized Calcium",
      lab_name: "Max Lab",
      fees: 600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5863,
      package_name: "Iron and Total Iron Binding Capacity Serum",
      lab_name: "Max Lab",
      fees: 550,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19654,
      package_name: "Kappa Lambda Light Chains-freeserum",
      lab_name: "Max Lab",
      fees: 6960,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 30276,
      package_name: "KetoneBlood",
      lab_name: "Max Lab",
      fees: 410,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45229,
      package_name: "KFT Profile with CalciumUric Acid",
      lab_name: "Max Lab",
      fees: 780,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24193,
      package_name: "Lactate Plasma",
      lab_name: "Max Lab",
      fees: 940,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24194,
      package_name: "LactateCSF",
      lab_name: "Max Lab",
      fees: 1040,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5832,
      package_name: "LDH (Lactate Dehydrogenase) Total  Serum",
      lab_name: "Max Lab",
      fees: 400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5833,
      package_name: "LDL Cholesterol Serum",
      lab_name: "Max Lab",
      fees: 320,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5834,
      package_name: "Lipase",
      lab_name: "Max Lab",
      fees: 630,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 9121,
      package_name: "Lipid ProfileSerum",
      lab_name: "Max Lab",
      fees: 820,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6297,
      package_name: "Lipoprotein(a)  Lp (a)",
      lab_name: "Max Lab",
      fees: 1080,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 9125,
      package_name: "Liver Function Test ProfileSerum",
      lab_name: "Max Lab",
      fees: 780,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45124,
      package_name: "Liver Transplant Profile",
      lab_name: "Max Lab",
      fees: 1590,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6270,
      package_name: "Magnesium Serum",
      lab_name: "Max Lab",
      fees: 600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24072,
      package_name: "Magnesium 24hrs Urine",
      lab_name: "Max Lab",
      fees: 690,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25645,
      package_name: "Magnesium Random Urine",
      lab_name: "Max Lab",
      fees: 610,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6427,
      package_name: "MAU (Microalbumin) Random Urine",
      lab_name: "Max Lab",
      fees: 590,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26018,
      package_name: "MAU (Microalbumin)24Hrs Urine",
      lab_name: "Max Lab",
      fees: 710,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26112,
      package_name: "MAU / Creatinine Ratio Urine",
      lab_name: "Max Lab",
      fees: 690,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48544,
      package_name: "MDMA ScreenUrine",
      lab_name: "Max Lab",
      fees: 1010,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48543,
      package_name: "Methadone Screen Urine",
      lab_name: "Max Lab",
      fees: 470,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48542,
      package_name: "Methamphetamine Screen Urine",
      lab_name: "Max Lab",
      fees: 1010,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 31581,
      package_name: "MethotrexateSerum",
      lab_name: "Max Lab",
      fees: 2020,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19664,
      package_name: "Morphine (MOP) Screen Urine",
      lab_name: "Max Lab",
      fees: 770,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35336,
      package_name: "Nephro Panel - Basic",
      lab_name: "Max Lab",
      fees: 670,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 453000,
      package_name: "New Sepsis Profile",
      lab_name: "Max Lab",
      fees: 7270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50294,
      package_name: "Opiate Screen Urine",
      lab_name: "Max Lab",
      fees: 1010,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37781,
      package_name: "Osmolal Gap Serum",
      lab_name: "Max Lab",
      fees: 1510,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6218,
      package_name: "Osmolality Random Urine",
      lab_name: "Max Lab",
      fees: 760,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6596,
      package_name: "Osmolality Serum",
      lab_name: "Max Lab",
      fees: 840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 20102,
      package_name: "Other Fluid - ADA",
      lab_name: "Max Lab",
      fees: 760,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6017,
      package_name: "Other Fluid - Glucose & Protein",
      lab_name: "Max Lab",
      fees: 470,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6021,
      package_name: "Other Fluid - LDH (Lactate Dehydrogenase)",
      lab_name: "Max Lab",
      fees: 430,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28325,
      package_name: "Other Fluid- Creatinine",
      lab_name: "Max Lab",
      fees: 320,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26414,
      package_name: "Other Fluid- Rheumatoid Factor(Quantitative)",
      lab_name: "Max Lab",
      fees: 670,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28324,
      package_name: "Other Fluid- Urea",
      lab_name: "Max Lab",
      fees: 320,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26409,
      package_name: "Other Fluid- Uric Acid",
      lab_name: "Max Lab",
      fees: 320,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6016,
      package_name: "Other Fluid-Albumin",
      lab_name: "Max Lab",
      fees: 160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6018,
      package_name: "Other Fluid-Amylase",
      lab_name: "Max Lab",
      fees: 580,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 42519,
      package_name: "Other Fluid-Chloride",
      lab_name: "Max Lab",
      fees: 290,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28756,
      package_name: "Other Fluid-Cholesterol",
      lab_name: "Max Lab",
      fees: 260,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26256,
      package_name: "Other Fluid-Lipase",
      lab_name: "Max Lab",
      fees: 750,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 42518,
      package_name: "Other Fluid-Potassium",
      lab_name: "Max Lab",
      fees: 300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 42517,
      package_name: "Other Fluid-Sodium",
      lab_name: "Max Lab",
      fees: 300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28757,
      package_name: "Other Fluid-Triglycerides",
      lab_name: "Max Lab",
      fees: 390,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46950,
      package_name: "PAPP-A",
      lab_name: "Max Lab",
      fees: 990,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26344,
      package_name: "Pericardial Fluid  - LDH (Lactate Dehydrogenase)",
      lab_name: "Max Lab",
      fees: 430,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26251,
      package_name: "Pericardial Fluid - ADA",
      lab_name: "Max Lab",
      fees: 780,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26252,
      package_name: "Pericardial Fluid - Glucose & Protein",
      lab_name: "Max Lab",
      fees: 470,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26253,
      package_name: "Peritoneal Fluid Fluid  - LDH",
      lab_name: "Max Lab",
      fees: 430,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26254,
      package_name: "Peritoneal Fluid Fluid - ADA",
      lab_name: "Max Lab",
      fees: 760,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6023,
      package_name: "Peritoneal Fluid Fluid - Glucose & Protein",
      lab_name: "Max Lab",
      fees: 470,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 17145,
      package_name: "Phencyclidine(pcp) Screen Urine",
      lab_name: "Max Lab",
      fees: 810,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5921,
      package_name: "Phenobarbitone Serum",
      lab_name: "Max Lab",
      fees: 1080,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5922,
      package_name: "Phenytoin",
      lab_name: "Max Lab",
      fees: 1060,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33115,
      package_name: "Placental Growth Factor (PGLF)",
      lab_name: "Max Lab",
      fees: 2500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25149,
      package_name: "Pleural Fluid - ADA",
      lab_name: "Max Lab",
      fees: 760,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6022,
      package_name: "Pleural Fluid - Glucose & Protein",
      lab_name: "Max Lab",
      fees: 470,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6029,
      package_name: "Pleural Fluid - LDH (lactate Dehydrogenase)",
      lab_name: "Max Lab",
      fees: 570,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5881,
      package_name: "Potassium 24 Hrs Urine",
      lab_name: "Max Lab",
      fees: 350,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5835,
      package_name: "Potassium Serum",
      lab_name: "Max Lab",
      fees: 190,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24067,
      package_name: "Potassium Spot Urine",
      lab_name: "Max Lab",
      fees: 270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27800,
      package_name: "Protein /Creatinine Ratio Random Urine",
      lab_name: "Max Lab",
      fees: 650,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5882,
      package_name: "Protein24hrs Urine",
      lab_name: "Max Lab",
      fees: 270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 22188,
      package_name: "ProteinSpot Urine",
      lab_name: "Max Lab",
      fees: 250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37794,
      package_name: "Protein/Creatinine Ratio 24 hrs Urine",
      lab_name: "Max Lab",
      fees: 830,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 36760,
      package_name: "Renin Activity Plasma",
      lab_name: "Max Lab",
      fees: 5200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6048,
      package_name: "Rheumatoid Factor(Quantitative) Serum",
      lab_name: "Max Lab",
      fees: 440,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28247,
      package_name: "SAAG (Serum-Ascites Albumin Gradient)",
      lab_name: "Max Lab",
      fees: 350,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5821,
      package_name: "SGOT - Aspartate Amino Transferase Serum",
      lab_name: "Max Lab",
      fees: 170,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5845,
      package_name: "SGPT - Alanine Amino Transferase Serum",
      lab_name: "Max Lab",
      fees: 170,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5883,
      package_name: "Sodium 24 Hrs Urine",
      lab_name: "Max Lab",
      fees: 340,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5837,
      package_name: "Sodium Serum",
      lab_name: "Max Lab",
      fees: 170,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6388,
      package_name: "Sodium Spot Urine",
      lab_name: "Max Lab",
      fees: 270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50316,
      package_name: "ST 2 (Soluble Suppression of Tumorigenesis 2)",
      lab_name: "Max Lab",
      fees: 3790,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26345,
      package_name: "Synovial Fluid - Glucose & Protein",
      lab_name: "Max Lab",
      fees: 470,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26255,
      package_name: "Synovial Fluid - LDH (Lactate Dehydrogenase)",
      lab_name: "Max Lab",
      fees: 430,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26340,
      package_name: "Tacrolimus EDTA",
      lab_name: "Max Lab",
      fees: 2300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6392,
      package_name: "Theophylline Serum",
      lab_name: "Max Lab",
      fees: 1030,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6071,
      package_name: "Total Protein Serum",
      lab_name: "Max Lab",
      fees: 200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5839,
      package_name: "Total Proteins with AlbuminGlobulinA/GSerum",
      lab_name: "Max Lab",
      fees: 250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6431,
      package_name: "Transferrin Serum",
      lab_name: "Max Lab",
      fees: 1180,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50295,
      package_name: "Tricyclic Antidepressants (TCA)",
      lab_name: "Max Lab",
      fees: 1010,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5840,
      package_name: "Triglycerides Serum",
      lab_name: "Max Lab",
      fees: 310,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6316,
      package_name: "Troponin-T",
      lab_name: "Max Lab",
      fees: 1720,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26111,
      package_name: "Urea 24 Hrs Urine",
      lab_name: "Max Lab",
      fees: 350,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49951,
      package_name: "Urea Random Urine",
      lab_name: "Max Lab",
      fees: 380,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5841,
      package_name: "Urea Serum",
      lab_name: "Max Lab",
      fees: 190,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28387,
      package_name: "Uric Acid Random Urine",
      lab_name: "Max Lab",
      fees: 320,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5885,
      package_name: "Uric Acid 24 Hrs Urine",
      lab_name: "Max Lab",
      fees: 340,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5842,
      package_name: "Uric Acid Serum",
      lab_name: "Max Lab",
      fees: 160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 53067,
      package_name: "Urine Cotinine Qualitative",
      lab_name: "Max Lab",
      fees: 2000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19655,
      package_name: "Urine Kappa Lambda Light Chainsfree",
      lab_name: "Max Lab",
      fees: 7030,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35467,
      package_name: "Urine Protein/Creatinine Ratio (Nephro)",
      lab_name: "Max Lab",
      fees: 630,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25644,
      package_name: "Urine- PCP (Phencylidine)",
      lab_name: "Max Lab",
      fees: 690,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21409,
      package_name: "Valporic Acid",
      lab_name: "Max Lab",
      fees: 910,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6589,
      package_name: "Vancomycin",
      lab_name: "Max Lab",
      fees: 5140,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5843,
      package_name: "VLDL Cholesterol",
      lab_name: "Max Lab",
      fees: 360,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51856,
      package_name: "Voriconazole Therapeutic Monitoring",
      lab_name: "Max Lab",
      fees: 4330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33377,
      package_name: "Water Deprivation Test 4 Specimens",
      lab_name: "Max Lab",
      fees: 5620,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33376,
      package_name: "WATER DEPRIVATION TEST6 SPECIMENS",
      lab_name: "Max Lab",
      fees: 8430,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28389,
      package_name: "Biotinidase Newborn Screen",
      lab_name: "Max Lab",
      fees: 750,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 31487,
      package_name: "CSF Protein ElectrophoresisOligoclonal Bands",
      lab_name: "Max Lab",
      fees: 4680,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28455,
      package_name: "Galactosemia Newborn Screen",
      lab_name: "Max Lab",
      fees: 280,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 31486,
      package_name: "Immunofixation Electrophoresis24 hrs Urine",
      lab_name: "Max Lab",
      fees: 10380,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6593,
      package_name: "Immunofixation ElectrophoresisSerum",
      lab_name: "Max Lab",
      fees: 6810,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45175,
      package_name: "Newborn Screen TSH",
      lab_name: "Max Lab",
      fees: 250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6167,
      package_name: "Newborn Screen 7 test (CH CAH G6PD CF PKU GLA & BIO)",
      lab_name: "Max Lab",
      fees: 1640,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5836,
      package_name: "Protein Electrophoresis Serum",
      lab_name: "Max Lab",
      fees: 680,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 31485,
      package_name: "Protein Electrophoresis-24Hrs Urine",
      lab_name: "Max Lab",
      fees: 4940,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5874,
      package_name: "Bence Jones Proteins-Urine",
      lab_name: "Max Lab",
      fees: 270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 52917,
      package_name: "Fecal Immunochemical Test - (FIT)",
      lab_name: "Max Lab",
      fees: 1950,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28679,
      package_name: "Fecal Occult Blood",
      lab_name: "Max Lab",
      fees: 180,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26146,
      package_name: "Ryle Tube Aspiration For Occult Blood",
      lab_name: "Max Lab",
      fees: 260,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6599,
      package_name: "Sputum for Occult Blood",
      lab_name: "Max Lab",
      fees: 180,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6496,
      package_name: "Stool for Eosinophills",
      lab_name: "Max Lab",
      fees: 250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26257,
      package_name: "Stool for Fat Globules",
      lab_name: "Max Lab",
      fees: 180,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6277,
      package_name: "Stool for Occult Blood",
      lab_name: "Max Lab",
      fees: 180,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6276,
      package_name: "Stool for Reducing Substance",
      lab_name: "Max Lab",
      fees: 210,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6444,
      package_name: "Stool pH",
      lab_name: "Max Lab",
      fees: 180,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5889,
      package_name: "Stool Routine and Microscopy",
      lab_name: "Max Lab",
      fees: 120,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28920,
      package_name: "Urine Dipstix Test",
      lab_name: "Max Lab",
      fees: 210,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44614,
      package_name: "Urine for Albumin",
      lab_name: "Max Lab",
      fees: 130,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6266,
      package_name: "Urine for Bile Pigment",
      lab_name: "Max Lab",
      fees: 160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6265,
      package_name: "Urine for Bile Salt",
      lab_name: "Max Lab",
      fees: 160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6505,
      package_name: "Urine for Blood/Haematuria",
      lab_name: "Max Lab",
      fees: 160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25637,
      package_name: "Urine for Crystals",
      lab_name: "Max Lab",
      fees: 160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24070,
      package_name: "Urine for Fat Globules",
      lab_name: "Max Lab",
      fees: 160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25638,
      package_name: "Urine for pH",
      lab_name: "Max Lab",
      fees: 160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25639,
      package_name: "Urine for Protein (Qualitative)",
      lab_name: "Max Lab",
      fees: 160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25640,
      package_name: "Urine for Red Blood Cells",
      lab_name: "Max Lab",
      fees: 130,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24071,
      package_name: "Urine for Reducing Substances",
      lab_name: "Max Lab",
      fees: 160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25641,
      package_name: "Urine for Specific Gravity",
      lab_name: "Max Lab",
      fees: 160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25642,
      package_name: "Urine for Sugar(Qualitative)",
      lab_name: "Max Lab",
      fees: 160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6419,
      package_name: "Urine Ketone",
      lab_name: "Max Lab",
      fees: 160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6073,
      package_name: "Urine Pregnancy Test",
      lab_name: "Max Lab",
      fees: 190,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5888,
      package_name: "Urine Routine And Microscopy",
      lab_name: "Max Lab",
      fees: 110,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6289,
      package_name: "Urine Striptest",
      lab_name: "Max Lab",
      fees: 160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6470,
      package_name: "Urine Sugar Fasting",
      lab_name: "Max Lab",
      fees: 160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6471,
      package_name: "Urine Sugar PP",
      lab_name: "Max Lab",
      fees: 160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5897,
      package_name: "Urine Sugar Random",
      lab_name: "Max Lab",
      fees: 160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 36190,
      package_name: "1P19Q - FISH",
      lab_name: "Max Lab",
      fees: 12980,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35213,
      package_name: "ALK Fusion - FISH",
      lab_name: "Max Lab",
      fees: 12980,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46389,
      package_name: "CLL Panel Fish",
      lab_name: "Max Lab",
      fees: 15440,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 32755,
      package_name: "Cytogenetics Karyotyping-Haemat Malignancy",
      lab_name: "Max Lab",
      fees: 3830,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 43778,
      package_name: "Ewings Sarcoma",
      lab_name: "Max Lab",
      fees: 8650,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34573,
      package_name: "FISH-BMT Sex Mismatch XY Probe",
      lab_name: "Max Lab",
      fees: 4660,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49096,
      package_name: "FISH-Deletion 11q22.3 (ATM)",
      lab_name: "Max Lab",
      fees: 4330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49098,
      package_name: "FISH-Deletion 13q14.3",
      lab_name: "Max Lab",
      fees: 4330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49099,
      package_name: "FISH-Deletion 13q34",
      lab_name: "Max Lab",
      fees: 4330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49097,
      package_name: "FISH-Deletion 17p13.1 (TP53)",
      lab_name: "Max Lab",
      fees: 4330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49095,
      package_name: "FISH-Deletion 20q12",
      lab_name: "Max Lab",
      fees: 4330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49092,
      package_name: "FISH-Deletion 5q31",
      lab_name: "Max Lab",
      fees: 4330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49102,
      package_name: "FISH-Deletion 6q23 (MYB",
      lab_name: "Max Lab",
      fees: 4330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49093,
      package_name: "FISH-Deletion 7q31",
      lab_name: "Max Lab",
      fees: 4330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49101,
      package_name: "FISH-IGH gene rearrangement (14q32)",
      lab_name: "Max Lab",
      fees: 4330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49103,
      package_name: "FISH-T(11;14)(CCND1/IGH)",
      lab_name: "Max Lab",
      fees: 4330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49105,
      package_name: "FISH-T(14 16)(IGH/MAF)",
      lab_name: "Max Lab",
      fees: 4330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49104,
      package_name: "FISH-T(4 14)(FGFR3/IGH)",
      lab_name: "Max Lab",
      fees: 4330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49100,
      package_name: "FISH-Trisomy 12 (CEP 12)",
      lab_name: "Max Lab",
      fees: 4330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49094,
      package_name: "FISH-Trisomy 8 (CEP 8)",
      lab_name: "Max Lab",
      fees: 4330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35205,
      package_name: "HER-2/NEU - FISH",
      lab_name: "Max Lab",
      fees: 13000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26322,
      package_name: "Karyotyping-Peripheral Blood Cells",
      lab_name: "Max Lab",
      fees: 2810,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46388,
      package_name: "MDS Panel Fish",
      lab_name: "Max Lab",
      fees: 10280,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 32762,
      package_name: "Multiple Myeloma Markers (Cytogenetics) FISH",
      lab_name: "Max Lab",
      fees: 12980,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35214,
      package_name: "Ros1 - Fish",
      lab_name: "Max Lab",
      fees: 16870,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5814,
      package_name: "Ascitic Fluid For Malignant Cells",
      lab_name: "Max Lab",
      fees: 840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6507,
      package_name: "BAL (Broncho Alveolar Lavage) For Malignant Cells",
      lab_name: "Max Lab",
      fees: 840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29338,
      package_name: "BAL - Silver Methanamine Staining",
      lab_name: "Max Lab",
      fees: 490,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 36269,
      package_name: "BAL–Perl Iron Stain",
      lab_name: "Max Lab",
      fees: 490,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5977,
      package_name: "Bone Marrow Examination",
      lab_name: "Max Lab",
      fees: 1210,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19568,
      package_name: "Bone Marrow Iron Stain",
      lab_name: "Max Lab",
      fees: 690,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46390,
      package_name: "Brush Biopsy",
      lab_name: "Max Lab",
      fees: 990,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5910,
      package_name: "Buccal Smear",
      lab_name: "Max Lab",
      fees: 420,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26259,
      package_name: "CSF for Malignant Cells",
      lab_name: "Max Lab",
      fees: 850,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6457,
      package_name: "Cytology for Nipple Discharge",
      lab_name: "Max Lab",
      fees: 760,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6555,
      package_name: "Effusion Fluid for Malignant Cells",
      lab_name: "Max Lab",
      fees: 850,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21722,
      package_name: "Fine Needle Aspiration - Slide Review",
      lab_name: "Max Lab",
      fees: 1310,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6076,
      package_name: "FNAC with Procedure",
      lab_name: "Max Lab",
      fees: 2200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28578,
      package_name: "Liquid Based Cytology(LBC)",
      lab_name: "Max Lab",
      fees: 1150,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6020,
      package_name: "Other Fluid for Malignant Cells",
      lab_name: "Max Lab",
      fees: 840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6077,
      package_name: "Pap Smear",
      lab_name: "Max Lab",
      fees: 640,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26260,
      package_name: "Pericardial Fluid for Malignant Cells",
      lab_name: "Max Lab",
      fees: 850,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26278,
      package_name: "Peritoneal Fluid  - Malignant Cells",
      lab_name: "Max Lab",
      fees: 850,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6027,
      package_name: "Pleural Fluid for Malignant Cells",
      lab_name: "Max Lab",
      fees: 850,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35128,
      package_name: "Rapid & Conventional Cytology (<10 Smears)",
      lab_name: "Max Lab",
      fees: 2710,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35129,
      package_name: "Rapid & Conventional Cytology (>10 Smears)",
      lab_name: "Max Lab",
      fees: 3330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27503,
      package_name: "Silver Methanamine for PCP Staining",
      lab_name: "Max Lab",
      fees: 490,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5912,
      package_name: "Slide Review - Cytopathology",
      lab_name: "Max Lab",
      fees: 800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5915,
      package_name: "Smear for Cytology",
      lab_name: "Max Lab",
      fees: 800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25626,
      package_name: "Smear for Malignant Cells",
      lab_name: "Max Lab",
      fees: 810,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29357,
      package_name: "Sputum - Silver Methanamine for PCP Staining",
      lab_name: "Max Lab",
      fees: 490,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5914,
      package_name: "Sputum for Cytology",
      lab_name: "Max Lab",
      fees: 1410,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26261,
      package_name: "Synovial Fluid for Malignant Cells",
      lab_name: "Max Lab",
      fees: 850,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27028,
      package_name: "Testicular FNAC",
      lab_name: "Max Lab",
      fees: 3270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6308,
      package_name: "Tzank Smear",
      lab_name: "Max Lab",
      fees: 350,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: "FEF",
      package_name: "FAMILY EXCLUSIVE FEMALE",
      lab_name: "Max Lab",
      fees: 6300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: "FEFA",
      package_name: "FAMILY EXCLUSIVE FEMALE+ ADVANCED",
      lab_name: "Max Lab",
      fees: 5250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: "FEFE",
      package_name: "FAMILY EXCLUSIVE FEMALE+ ESSENTIAL",
      lab_name: "Max Lab",
      fees: 4725,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: "FEFT",
      package_name: "FAMILY EXCLUSIVE FEMALE+ TOTAL",
      lab_name: "Max Lab",
      fees: 5775,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: "FEM",
      package_name: "FAMILY EXCLUSIVE MALE",
      lab_name: "Max Lab",
      fees: 6300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: "FEMA",
      package_name: "FAMILY EXCLUSIVE MALE+ ADVANCED",
      lab_name: "Max Lab",
      fees: 5250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: "FEME",
      package_name: "FAMILY EXCLUSIVE MALE+ ESSENTIAL",
      lab_name: "Max Lab",
      fees: 4725,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: "FEMF",
      package_name: "FAMILY EXCLUSIVE MALE+ FEMALE",
      lab_name: "Max Lab",
      fees: 6300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: "FEMT",
      package_name: "FAMILY EXCLUSIVE MALE+ TOTAL",
      lab_name: "Max Lab",
      fees: 6100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: "FWB",
      package_name: "FAMILY WELLNESS ADVANCED",
      lab_name: "Max Lab",
      fees: 3200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: "FBG",
      package_name: "FAMILY WELLNESS ADVANCED+ESSENTIAL",
      lab_name: "Max Lab",
      fees: 2500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: "FBT",
      package_name: "FAMILY WELLNESS ADVANCED+TOTAL",
      lab_name: "Max Lab",
      fees: 4200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: "FWG",
      package_name: "FAMILY WELLNESS ESSENTIAL",
      lab_name: "Max Lab",
      fees: 1600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: "FGT",
      package_name: "FAMILY WELLNESS ESSENTIAL+TOTAL",
      lab_name: "Max Lab",
      fees: 3600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: "FWT",
      package_name: "FAMILY WELLNESS TOTAL",
      lab_name: "Max Lab",
      fees: 5000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44836,
      package_name: "Absolute Atypical Lymphocytes",
      lab_name: "Max Lab",
      fees: 290,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44835,
      package_name: "Absolute Band Count",
      lab_name: "Max Lab",
      fees: 290,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6148,
      package_name: "Absolute Basophil Count",
      lab_name: "Max Lab",
      fees: 260,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5942,
      package_name: "Absolute Eosinophil Count",
      lab_name: "Max Lab",
      fees: 200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6215,
      package_name: "Absolute Lymphocyte Count",
      lab_name: "Max Lab",
      fees: 260,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6130,
      package_name: "Absolute Monocyte Count",
      lab_name: "Max Lab",
      fees: 260,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6131,
      package_name: "Absolute Neutrophil Count",
      lab_name: "Max Lab",
      fees: 250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24274,
      package_name: "APT Test",
      lab_name: "Max Lab",
      fees: 180,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5957,
      package_name: "APTT (Partial Thromboplastin Time)Citrate Plasma",
      lab_name: "Max Lab",
      fees: 550,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26262,
      package_name: "Ascitic Fluid - Cell Count & Type",
      lab_name: "Max Lab",
      fees: 450,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5810,
      package_name: "Ascitic Fluid-Cell Count & Type/Glucose & Prot./Alb",
      lab_name: "Max Lab",
      fees: 840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28575,
      package_name: "C.B.C  (ONCOLOGY)EDTA",
      lab_name: "Max Lab",
      fees: 450,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6085,
      package_name: "CBC (Complete Blood Count)",
      lab_name: "Max Lab",
      fees: 350,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6086,
      package_name: "Complete Haemogram Peripheral Smear and ESREDTA",
      lab_name: "Max Lab",
      fees: 420,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45046,
      package_name: "Cord Blood Haemoglobin",
      lab_name: "Max Lab",
      fees: 180,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24269,
      package_name: "Cryoglobulin Screening",
      lab_name: "Max Lab",
      fees: 1080,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6290,
      package_name: "CSF-Cell Type& Count Glucose Protein",
      lab_name: "Max Lab",
      fees: 840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19624,
      package_name: "D-Dimer (Quantitative)Citrate Plasma",
      lab_name: "Max Lab",
      fees: 1450,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5971,
      package_name: "DLC",
      lab_name: "Max Lab",
      fees: 160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6075,
      package_name: "Effusion Fluid-Cell Count & Type/Clucose & Protein",
      lab_name: "Max Lab",
      fees: 850,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6216,
      package_name: "ESR (Westergren)EDTA",
      lab_name: "Max Lab",
      fees: 150,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6451,
      package_name: "Factor VIII StudiesCitrate Plasma",
      lab_name: "Max Lab",
      fees: 1660,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5828,
      package_name: "Fibrinogen",
      lab_name: "Max Lab",
      fees: 820,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6257,
      package_name: "Filarial Antigen",
      lab_name: "Max Lab",
      fees: 1110,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21816,
      package_name: "Flow Cytometry- Cd5",
      lab_name: "Max Lab",
      fees: 1800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25126,
      package_name: "Gastric Aspiration for Polymorphs",
      lab_name: "Max Lab",
      fees: 250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5964,
      package_name: "Haemoglobin EstimationEDTA",
      lab_name: "Max Lab",
      fees: 130,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5948,
      package_name: "LE Cells",
      lab_name: "Max Lab",
      fees: 430,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6149,
      package_name: "Malaria AntigenEDTA",
      lab_name: "Max Lab",
      fees: 650,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6404,
      package_name: "MCH",
      lab_name: "Max Lab",
      fees: 160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6405,
      package_name: "MCHC",
      lab_name: "Max Lab",
      fees: 160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6406,
      package_name: "MCV",
      lab_name: "Max Lab",
      fees: 160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 30278,
      package_name: "Mixing Studies - PT & APTT",
      lab_name: "Max Lab",
      fees: 2980,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 30279,
      package_name: "Mixing Studies- APTT",
      lab_name: "Max Lab",
      fees: 2010,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 30280,
      package_name: "Mixing Studies- PT",
      lab_name: "Max Lab",
      fees: 1500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5913,
      package_name: "Nasal Smear for Eosinophils",
      lab_name: "Max Lab",
      fees: 250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 22450,
      package_name: "Neonatal Haemogram",
      lab_name: "Max Lab",
      fees: 450,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26264,
      package_name: "Other Fluid - Cell Count & Type",
      lab_name: "Max Lab",
      fees: 450,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6015,
      package_name: "Other Fluid - Cell Count &Type/Gluco. & Prot.",
      lab_name: "Max Lab",
      fees: 850,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6248,
      package_name: "Packed Cell Volume (PCV)",
      lab_name: "Max Lab",
      fees: 50,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 23099,
      package_name: "Parasitic Index",
      lab_name: "Max Lab",
      fees: 160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26265,
      package_name: "Pericardial Fluid - Cell Count & Type",
      lab_name: "Max Lab",
      fees: 460,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25620,
      package_name: "Pericardial Fluid - Cell Count & Type/Gluc & Prot.",
      lab_name: "Max Lab",
      fees: 850,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5976,
      package_name: "Peripheral Smear Examination",
      lab_name: "Max Lab",
      fees: 140,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25621,
      package_name: "Peripheral Smear Examination for Abnormal Cells",
      lab_name: "Max Lab",
      fees: 300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25622,
      package_name: "Peripheral Smear Examination for Band Cells",
      lab_name: "Max Lab",
      fees: 310,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25623,
      package_name: "Peripheral Smear Examination for Haemolysis",
      lab_name: "Max Lab",
      fees: 300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6337,
      package_name: "Peripheral Smear Examination for Micro Filaria",
      lab_name: "Max Lab",
      fees: 310,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25624,
      package_name: "Peripheral Smear Examination for Type of Anaemia",
      lab_name: "Max Lab",
      fees: 300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6395,
      package_name: "Peripheral Smear for Malarial ParasiteEDTA",
      lab_name: "Max Lab",
      fees: 160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25120,
      package_name: "Peripheral Smear Toxic Granules",
      lab_name: "Max Lab",
      fees: 310,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26266,
      package_name: "Peritoneal Fluid - Cell Count & Type/gluc & Prot.",
      lab_name: "Max Lab",
      fees: 850,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26279,
      package_name: "Peritoneal Fluid Fluid - Cell Count & Type",
      lab_name: "Max Lab",
      fees: 460,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5950,
      package_name: "Platelet Count",
      lab_name: "Max Lab",
      fees: 50,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26346,
      package_name: "Pleural Fluid - Cell Count & Type",
      lab_name: "Max Lab",
      fees: 460,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26267,
      package_name: "Pleural Fluid - Cell Type& Count Glucose Protein",
      lab_name: "Max Lab",
      fees: 840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5958,
      package_name: "Prothrombin Time (with INR)Citrate Plasma",
      lab_name: "Max Lab",
      fees: 370,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5972,
      package_name: "RBC",
      lab_name: "Max Lab",
      fees: 160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5951,
      package_name: "Reticulocyte CountEDTA",
      lab_name: "Max Lab",
      fees: 450,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26404,
      package_name: "Ryle Tube for RBCs",
      lab_name: "Max Lab",
      fees: 250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5887,
      package_name: "Semen Analysis",
      lab_name: "Max Lab",
      fees: 650,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29221,
      package_name: "Semen for Pus Cells",
      lab_name: "Max Lab",
      fees: 110,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27508,
      package_name: "Semen Fructose",
      lab_name: "Max Lab",
      fees: 380,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 17437,
      package_name: "Semen Sample Preparation For Iu Insemination",
      lab_name: "Max Lab",
      fees: 1600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26269,
      package_name: "Smear For Eosinophils",
      lab_name: "Max Lab",
      fees: 310,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26268,
      package_name: "Synovial Fluid - Cell Count/Type & Gluc./prot.",
      lab_name: "Max Lab",
      fees: 790,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 23074,
      package_name: "Synovial Fluid Analysis - Cell Count & Type",
      lab_name: "Max Lab",
      fees: 450,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5970,
      package_name: "TLC",
      lab_name: "Max Lab",
      fees: 160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 23100,
      package_name: "Urine for Eosinophils",
      lab_name: "Max Lab",
      fees: 250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26312,
      package_name: "Acute Leukemia Comprehensive PanelHeparin+EDTA",
      lab_name: "Max Lab",
      fees: 16270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26313,
      package_name: "Acute Lymphoblastic Leukemia; ALL-BHeparin+EDTA",
      lab_name: "Max Lab",
      fees: 8830,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26314,
      package_name: "Acute Lymphoblastic Leukemia; ALL-THeparin+EDTA",
      lab_name: "Max Lab",
      fees: 8830,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26315,
      package_name: "Acute Myeloid Leukemia AMLHeparin+EDTA",
      lab_name: "Max Lab",
      fees: 10000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 20103,
      package_name: "ANA By Immunofluorescence",
      lab_name: "Max Lab",
      fees: 1500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25563,
      package_name: "ANCA -Immunofluorescence",
      lab_name: "Max Lab",
      fees: 1770,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51196,
      package_name: "Anti Mitochondrial Antibody by IF",
      lab_name: "Max Lab",
      fees: 2180,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49034,
      package_name: "Anti MOG + NMO Panel",
      lab_name: "Max Lab",
      fees: 6960,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6450,
      package_name: "Anti Thrombin - III - FunctionalCitrate Plasma",
      lab_name: "Max Lab",
      fees: 6270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26399,
      package_name: "Anti-ds-DNA by IF",
      lab_name: "Max Lab",
      fees: 1860,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44525,
      package_name: "ASMA Smooth Muscle Antibody IF",
      lab_name: "Max Lab",
      fees: 2040,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44887,
      package_name: "Auto Immune Encephalitis  Panel",
      lab_name: "Max Lab",
      fees: 18720,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6103,
      package_name: "CD4 - Flow Cytometry",
      lab_name: "Max Lab",
      fees: 1900,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26317,
      package_name: "CD4+CD8 Count Flow CytometryEDTA",
      lab_name: "Max Lab",
      fees: 2710,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26316,
      package_name: "CLPD / Lymphoma Comprehensive Panel",
      lab_name: "Max Lab",
      fees: 14680,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39547,
      package_name: "DRVVT-Lupus Anticoagulant",
      lab_name: "Max Lab",
      fees: 1820,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19632,
      package_name: "Factor V by APCR Citrate Plasma",
      lab_name: "Max Lab",
      fees: 5610,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 30846,
      package_name: "Flow Cytometry - Kappa+Lambda",
      lab_name: "Max Lab",
      fees: 4240,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29362,
      package_name: "Flow Cytometry - Multiple Myeloma Panel",
      lab_name: "Max Lab",
      fees: 7060,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 30845,
      package_name: "Flow Cytometry- cCD3",
      lab_name: "Max Lab",
      fees: 2100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21817,
      package_name: "Flow Cytometry- CD10",
      lab_name: "Max Lab",
      fees: 1800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26623,
      package_name: "Flow Cytometry- CD103 V",
      lab_name: "Max Lab",
      fees: 1800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26624,
      package_name: "Flow Cytometry- CD117",
      lab_name: "Max Lab",
      fees: 1800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26606,
      package_name: "Flow Cytometry- CD11c",
      lab_name: "Max Lab",
      fees: 1800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26607,
      package_name: "Flow Cytometry- CD13",
      lab_name: "Max Lab",
      fees: 1800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26608,
      package_name: "Flow Cytometry- CD14",
      lab_name: "Max Lab",
      fees: 1800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26609,
      package_name: "Flow Cytometry- CD15",
      lab_name: "Max Lab",
      fees: 1800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26610,
      package_name: "Flow Cytometry- CD16",
      lab_name: "Max Lab",
      fees: 1800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6226,
      package_name: "Flow Cytometry- CD19",
      lab_name: "Max Lab",
      fees: 3180,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6099,
      package_name: "Flow Cytometry- CD2",
      lab_name: "Max Lab",
      fees: 3180,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6100,
      package_name: "Flow Cytometry- CD20",
      lab_name: "Max Lab",
      fees: 3180,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26611,
      package_name: "Flow Cytometry- CD22",
      lab_name: "Max Lab",
      fees: 1800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21820,
      package_name: "Flow Cytometry- CD23",
      lab_name: "Max Lab",
      fees: 1800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26612,
      package_name: "Flow Cytometry- CD25",
      lab_name: "Max Lab",
      fees: 1800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21815,
      package_name: "Flow Cytometry- CD3",
      lab_name: "Max Lab",
      fees: 3180,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6102,
      package_name: "Flow Cytometry- CD33",
      lab_name: "Max Lab",
      fees: 3180,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26613,
      package_name: "Flow Cytometry- CD34",
      lab_name: "Max Lab",
      fees: 1800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6601,
      package_name: "Flow Cytometry- CD38",
      lab_name: "Max Lab",
      fees: 3180,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 30840,
      package_name: "Flow Cytometry- CD41",
      lab_name: "Max Lab",
      fees: 1800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26614,
      package_name: "Flow Cytometry- CD45",
      lab_name: "Max Lab",
      fees: 1800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26615,
      package_name: "Flow Cytometry- CD56",
      lab_name: "Max Lab",
      fees: 1800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26620,
      package_name: "Flow Cytometry- CD64",
      lab_name: "Max Lab",
      fees: 1800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26605,
      package_name: "Flow Cytometry- CD7",
      lab_name: "Max Lab",
      fees: 1800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26622,
      package_name: "Flow Cytometry- CD79a",
      lab_name: "Max Lab",
      fees: 2100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 30844,
      package_name: "Flow Cytometry- cMPO",
      lab_name: "Max Lab",
      fees: 2100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 30843,
      package_name: "Flow Cytometry- Gly A",
      lab_name: "Max Lab",
      fees: 1800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 30842,
      package_name: "Flow Cytometry- HLA DR",
      lab_name: "Max Lab",
      fees: 1800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 30841,
      package_name: "Flow Cytometry- Tdt",
      lab_name: "Max Lab",
      fees: 2100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34181,
      package_name: "Flow Cytometry-Cytoplasmic Panel(cMPOcCD79acCD3",
      lab_name: "Max Lab",
      fees: 4280,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51391,
      package_name: "Flow Cytometry-Double Negative T Cell",
      lab_name: "Max Lab",
      fees: 6700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34180,
      package_name: "Flow Cytometry-Immunodeficiency Panel",
      lab_name: "Max Lab",
      fees: 7930,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45044,
      package_name: "Flowcytometry HLA T & B Cell Cross Match",
      lab_name: "Max Lab",
      fees: 10280,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46109,
      package_name: "Flowcytometry HLA T & B Cell Cross Match (Pronase)",
      lab_name: "Max Lab",
      fees: 10820,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19668,
      package_name: "Free Protein SCitrate Plasma",
      lab_name: "Max Lab",
      fees: 3640,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6087,
      package_name: "Haemoglobin HPLCEDTA",
      lab_name: "Max Lab",
      fees: 950,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6158,
      package_name: "HLA B-27 Flow CytometryEDTA",
      lab_name: "Max Lab",
      fees: 2450,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51195,
      package_name: "LKM Antibody by IF",
      lab_name: "Max Lab",
      fees: 2020,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29910,
      package_name: "Lupus Anticoagulant",
      lab_name: "Max Lab",
      fees: 2090,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 36223,
      package_name: "Mononuclear Cell Viability- Flow Cytometry",
      lab_name: "Max Lab",
      fees: 1800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51389,
      package_name: "MRD-ALL Panel",
      lab_name: "Max Lab",
      fees: 12360,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51390,
      package_name: "MRD-CLL Panel",
      lab_name: "Max Lab",
      fees: 12360,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37923,
      package_name: "Natural Killer Cells Flow Cytometry",
      lab_name: "Max Lab",
      fees: 4080,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34571,
      package_name: "NMDA Receptor Antibody",
      lab_name: "Max Lab",
      fees: 6740,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51388,
      package_name: "PNH Panel",
      lab_name: "Max Lab",
      fees: 6700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19666,
      package_name: "Protein C Functional",
      lab_name: "Max Lab",
      fees: 3610,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 32101,
      package_name: "Sezary Syndrome Flow Cytometry",
      lab_name: "Max Lab",
      fees: 9160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 32099,
      package_name: "Stem Cell Count (CD-34 enumeration)",
      lab_name: "Max Lab",
      fees: 4890,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34572,
      package_name: "VGKC Serum",
      lab_name: "Max Lab",
      fees: 8680,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27505,
      package_name: "Cell Block Preparation",
      lab_name: "Max Lab",
      fees: 1530,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46847,
      package_name: "Cryostat coated slides",
      lab_name: "Max Lab",
      fees: 520,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29901,
      package_name: "High Orchidectomy with Lymph Node Dissection",
      lab_name: "Max Lab",
      fees: 6080,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6088,
      package_name: "Histo Biopsy - Endometrial",
      lab_name: "Max Lab",
      fees: 1520,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6091,
      package_name: "Histo Biopsy - Hemi/Total/Sigmoid Colectomy",
      lab_name: "Max Lab",
      fees: 3080,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26115,
      package_name: "Histo Biopsy Aorta",
      lab_name: "Max Lab",
      fees: 1560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16227,
      package_name: "Histo Biopsy Appendix",
      lab_name: "Max Lab",
      fees: 1520,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21778,
      package_name: "Histo Biopsy Bone",
      lab_name: "Max Lab",
      fees: 1860,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16228,
      package_name: "Histo Biopsy Bone Marrow",
      lab_name: "Max Lab",
      fees: 1840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26273,
      package_name: "Histo Biopsy Breast - Lump",
      lab_name: "Max Lab",
      fees: 1520,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26113,
      package_name: "Histo Biopsy Breast - True cut Biopsy",
      lab_name: "Max Lab",
      fees: 1560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16229,
      package_name: "Histo Biopsy Bronchial Biopsy/TBLB",
      lab_name: "Max Lab",
      fees: 1660,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21782,
      package_name: "Histo Biopsy Cardiac Valve",
      lab_name: "Max Lab",
      fees: 1560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16230,
      package_name: "Histo Biopsy Cervix Biopsy",
      lab_name: "Max Lab",
      fees: 1520,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16231,
      package_name: "Histo Biopsy Cervix Conisation Specimen",
      lab_name: "Max Lab",
      fees: 2810,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21780,
      package_name: "Histo Biopsy Conjuctival",
      lab_name: "Max Lab",
      fees: 1560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16276,
      package_name: "Histo Biopsy Cyst",
      lab_name: "Max Lab",
      fees: 1520,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34992,
      package_name: "Histo Biopsy Ear",
      lab_name: "Max Lab",
      fees: 1660,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16278,
      package_name: "Histo Biopsy Endoscopic of Bladder Mucosa",
      lab_name: "Max Lab",
      fees: 1560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48000,
      package_name: "Histo Biopsy Endoscopic of Colon",
      lab_name: "Max Lab",
      fees: 1980,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16238,
      package_name: "Histo Biopsy Endoscopic of Duodenum",
      lab_name: "Max Lab",
      fees: 1650,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16236,
      package_name: "Histo Biopsy Endoscopic of Esophageal",
      lab_name: "Max Lab",
      fees: 1560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16277,
      package_name: "Histo Biopsy Endoscopic of Rectum",
      lab_name: "Max Lab",
      fees: 1560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16237,
      package_name: "Histo Biopsy Endoscopic of Stomach",
      lab_name: "Max Lab",
      fees: 1560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16241,
      package_name: "Histo Biopsy Endoscopic of Tracheal",
      lab_name: "Max Lab",
      fees: 1560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16242,
      package_name: "Histo Biopsy Eye Enucleation",
      lab_name: "Max Lab",
      fees: 2160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21781,
      package_name: "Histo Biopsy Fallopian Tube",
      lab_name: "Max Lab",
      fees: 1560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16243,
      package_name: "Histo Biopsy Fistula",
      lab_name: "Max Lab",
      fees: 2140,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21802,
      package_name: "Histo Biopsy Frozen Section",
      lab_name: "Max Lab",
      fees: 2140,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6338,
      package_name: "Histo Biopsy Gall Bladder",
      lab_name: "Max Lab",
      fees: 1650,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35010,
      package_name: "Histo Biopsy Ganglion",
      lab_name: "Max Lab",
      fees: 1660,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6565,
      package_name: "Histo Biopsy Hysterectomy",
      lab_name: "Max Lab",
      fees: 2450,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16244,
      package_name: "Histo Biopsy Hysterectomy with Salpingo Oopherectomy",
      lab_name: "Max Lab",
      fees: 3050,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34991,
      package_name: "Histo Biopsy Ileum/ Jejunum",
      lab_name: "Max Lab",
      fees: 1660,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16245,
      package_name: "Histo Biopsy Immunoflorescence",
      lab_name: "Max Lab",
      fees: 3040,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6570,
      package_name: "Histo Biopsy Kidney / Nephrectomy",
      lab_name: "Max Lab",
      fees: 2810,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50515,
      package_name: "Histo Biopsy Kidney with Immunoflorescence",
      lab_name: "Max Lab",
      fees: 6660,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26274,
      package_name: "Histo Biopsy Large (15 or More Blocks)",
      lab_name: "Max Lab",
      fees: 4020,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5983,
      package_name: "Histo Biopsy Large (<15 Blocks)",
      lab_name: "Max Lab",
      fees: 3230,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16253,
      package_name: "Histo Biopsy Liver Needle Biopsy",
      lab_name: "Max Lab",
      fees: 2470,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34997,
      package_name: "Histo Biopsy Lump (Soft Tissue)",
      lab_name: "Max Lab",
      fees: 1660,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21783,
      package_name: "Histo Biopsy Lung",
      lab_name: "Max Lab",
      fees: 1790,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16254,
      package_name: "Histo Biopsy Lymph Node",
      lab_name: "Max Lab",
      fees: 2160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16256,
      package_name: "Histo Biopsy Medium Specimens",
      lab_name: "Max Lab",
      fees: 2020,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6560,
      package_name: "Histo Biopsy Muscle",
      lab_name: "Max Lab",
      fees: 2070,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35007,
      package_name: "Histo Biopsy Myoma",
      lab_name: "Max Lab",
      fees: 2070,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34998,
      package_name: "Histo Biopsy Nail",
      lab_name: "Max Lab",
      fees: 1660,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16257,
      package_name: "Histo Biopsy Nasopharynx/Nasal polyp/Nasal cavity",
      lab_name: "Max Lab",
      fees: 1560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16258,
      package_name: "Histo Biopsy Nerve",
      lab_name: "Max Lab",
      fees: 2470,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16251,
      package_name: "Histo Biopsy of Epiglottis",
      lab_name: "Max Lab",
      fees: 1560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16250,
      package_name: "Histo Biopsy of Larynx",
      lab_name: "Max Lab",
      fees: 1560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16252,
      package_name: "Histo Biopsy of Vocal Cord Vocal Nodule",
      lab_name: "Max Lab",
      fees: 1560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21784,
      package_name: "Histo Biopsy Orbital",
      lab_name: "Max Lab",
      fees: 1560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16260,
      package_name: "Histo Biopsy Orchiectomy",
      lab_name: "Max Lab",
      fees: 2160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16261,
      package_name: "Histo Biopsy Oropharynx(gum/palate/pharynx/tonsils)",
      lab_name: "Max Lab",
      fees: 1660,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16262,
      package_name: "Histo Biopsy Ovary / Cyst",
      lab_name: "Max Lab",
      fees: 1570,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21785,
      package_name: "Histo Biopsy Pancreatic",
      lab_name: "Max Lab",
      fees: 1560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21786,
      package_name: "Histo Biopsy Parathyroid",
      lab_name: "Max Lab",
      fees: 1560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34994,
      package_name: "Histo Biopsy Pelvis/Puj/Ureter",
      lab_name: "Max Lab",
      fees: 1660,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34856,
      package_name: "Histo Biopsy Penectomy",
      lab_name: "Max Lab",
      fees: 2270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34857,
      package_name: "Histo Biopsy Penectomy with Inguinal Block Dissect",
      lab_name: "Max Lab",
      fees: 3790,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34993,
      package_name: "Histo Biopsy Penile/Prepuce",
      lab_name: "Max Lab",
      fees: 1660,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21801,
      package_name: "Histo Biopsy Pitutory Gland",
      lab_name: "Max Lab",
      fees: 1560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21789,
      package_name: "Histo Biopsy Placenta",
      lab_name: "Max Lab",
      fees: 2160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16263,
      package_name: "Histo Biopsy Pneumonectomy/Lobectomy (Non Tumour)",
      lab_name: "Max Lab",
      fees: 2810,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16264,
      package_name: "Histo Biopsy Products of Conception",
      lab_name: "Max Lab",
      fees: 1520,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34990,
      package_name: "Histo Biopsy Prostate",
      lab_name: "Max Lab",
      fees: 1650,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34995,
      package_name: "Histo Biopsy Rectum for Hirschprungs Disease with IHC",
      lab_name: "Max Lab",
      fees: 4090,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34989,
      package_name: "Histo Biopsy Resection Small Intestine",
      lab_name: "Max Lab",
      fees: 3080,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35009,
      package_name: "Histo Biopsy Review of Blocks & Slides",
      lab_name: "Max Lab",
      fees: 3080,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35008,
      package_name: "Histo Biopsy RND",
      lab_name: "Max Lab",
      fees: 3080,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16267,
      package_name: "Histo Biopsy Salivary Gland",
      lab_name: "Max Lab",
      fees: 1560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34999,
      package_name: "Histo Biopsy Scalp",
      lab_name: "Max Lab",
      fees: 1660,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16268,
      package_name: "Histo Biopsy Sinus/PilonidalSinus/Fissure/H'rhoids",
      lab_name: "Max Lab",
      fees: 2160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16269,
      package_name: "Histo Biopsy Skin",
      lab_name: "Max Lab",
      fees: 1770,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34988,
      package_name: "Histo Biopsy Sleeve Gastrectomy",
      lab_name: "Max Lab",
      fees: 2580,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35687,
      package_name: "Histo Biopsy slide & block review (upto 5 slides &",
      lab_name: "Max Lab",
      fees: 1770,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35686,
      package_name: "Histo Biopsy slide review (upto 5 slides)",
      lab_name: "Max Lab",
      fees: 1400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5986,
      package_name: "Histo Biopsy Small",
      lab_name: "Max Lab",
      fees: 1460,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21792,
      package_name: "Histo Biopsy Splenectomy",
      lab_name: "Max Lab",
      fees: 2160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34987,
      package_name: "Histo Biopsy Stomach/Gastric/Antrum",
      lab_name: "Max Lab",
      fees: 1660,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16272,
      package_name: "Histo Biopsy Swelling / Lump / Mass",
      lab_name: "Max Lab",
      fees: 1660,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16273,
      package_name: "Histo Biopsy Synovium",
      lab_name: "Max Lab",
      fees: 1560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21793,
      package_name: "Histo Biopsy Thymectomy",
      lab_name: "Max Lab",
      fees: 2160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16274,
      package_name: "Histo Biopsy Tongue",
      lab_name: "Max Lab",
      fees: 1560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34996,
      package_name: "Histo Biopsy Trucut",
      lab_name: "Max Lab",
      fees: 1970,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27524,
      package_name: "Histo Biopsy Trus Guided Biopsy Prostate",
      lab_name: "Max Lab",
      fees: 2470,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 16275,
      package_name: "HISTO BIOPSY TURP",
      lab_name: "Max Lab",
      fees: 3080,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21799,
      package_name: "Histo Biopsy Vessels",
      lab_name: "Max Lab",
      fees: 1560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21794,
      package_name: "Histo Biopsy Vulva",
      lab_name: "Max Lab",
      fees: 1560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29926,
      package_name: "Histo Onco Adrenalectomy",
      lab_name: "Max Lab",
      fees: 3920,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29931,
      package_name: "Histo Onco Adrenalectomy with Lymphnode Dissection",
      lab_name: "Max Lab",
      fees: 5140,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29892,
      package_name: "Histo Onco Amputation of Digits",
      lab_name: "Max Lab",
      fees: 2700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29893,
      package_name: "Histo Onco Amputation of Limbs",
      lab_name: "Max Lab",
      fees: 5140,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29885,
      package_name: "Histo Onco APR/AR",
      lab_name: "Max Lab",
      fees: 6380,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49037,
      package_name: "Histo Onco BCS with Extended IHC",
      lab_name: "Max Lab",
      fees: 18270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49038,
      package_name: "Histo Onco BCS with IHC",
      lab_name: "Max Lab",
      fees: 15240,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49039,
      package_name: "Histo Onco BCS without IHC",
      lab_name: "Max Lab",
      fees: 8610,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29902,
      package_name: "Histo Onco Block Dissection Specimen",
      lab_name: "Max Lab",
      fees: 5140,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35004,
      package_name: "Histo Onco Buccal Mucosa with RND",
      lab_name: "Max Lab",
      fees: 6460,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35003,
      package_name: "Histo Onco Buccal Mucosa/Bite Resection",
      lab_name: "Max Lab",
      fees: 4600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29903,
      package_name: "Histo Onco Curettage Of Bone Tumour",
      lab_name: "Max Lab",
      fees: 2700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29888,
      package_name: "Histo Onco Esophagectomy",
      lab_name: "Max Lab",
      fees: 6380,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29887,
      package_name: "Histo Onco Gastrectomy (Total/Partial)",
      lab_name: "Max Lab",
      fees: 6380,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35001,
      package_name: "Histo Onco Glossectomy",
      lab_name: "Max Lab",
      fees: 4600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35006,
      package_name: "Histo Onco Glossectomy with RND",
      lab_name: "Max Lab",
      fees: 6400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29886,
      package_name: "Histo Onco Hemicolectomy (Tumour)",
      lab_name: "Max Lab",
      fees: 5140,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29900,
      package_name: "Histo Onco High Orchidectomy",
      lab_name: "Max Lab",
      fees: 5140,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35000,
      package_name: "Histo Onco Laryngectomy",
      lab_name: "Max Lab",
      fees: 6460,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29890,
      package_name: "Histo Onco Lung Resection (Tumour)",
      lab_name: "Max Lab",
      fees: 6380,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35002,
      package_name: "Histo Onco Mandibulectomy/commando",
      lab_name: "Max Lab",
      fees: 6460,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29927,
      package_name: "Histo Onco Maxillectomy With Lymphnode Dissection",
      lab_name: "Max Lab",
      fees: 5140,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49040,
      package_name: "Histo Onco MRM with Extended IHC",
      lab_name: "Max Lab",
      fees: 18270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49041,
      package_name: "Histo Onco MRM with IHC",
      lab_name: "Max Lab",
      fees: 15240,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49042,
      package_name: "Histo Onco MRM without IHC",
      lab_name: "Max Lab",
      fees: 8530,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29898,
      package_name: "Histo Onco Pancreaticoduodencetomy(Whipple's proc)",
      lab_name: "Max Lab",
      fees: 6310,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29924,
      package_name: "Histo Onco Parotidectomy",
      lab_name: "Max Lab",
      fees: 3920,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29929,
      package_name: "Histo Onco Parotidectomy With Lymphnode Dissection",
      lab_name: "Max Lab",
      fees: 5140,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29899,
      package_name: "Histo Onco Radical Cholecystectomy",
      lab_name: "Max Lab",
      fees: 5140,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29896,
      package_name: "Histo Onco Radical Cystectomy",
      lab_name: "Max Lab",
      fees: 5140,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29884,
      package_name: "Histo Onco Radical Hysterectomy For Ca Cervix",
      lab_name: "Max Lab",
      fees: 6380,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29883,
      package_name: "Histo Onco Radical Hysterectomy For Ca Endometrium",
      lab_name: "Max Lab",
      fees: 5140,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29882,
      package_name: "Histo Onco Radical Hysterectomy For Ovarian Mass",
      lab_name: "Max Lab",
      fees: 7610,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35005,
      package_name: "Histo Onco Radical Neck Dissection (RND)",
      lab_name: "Max Lab",
      fees: 3570,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29894,
      package_name: "Histo Onco Radical Nephrectomy",
      lab_name: "Max Lab",
      fees: 5140,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29897,
      package_name: "Histo Onco Radical Prostatectomy",
      lab_name: "Max Lab",
      fees: 5140,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29895,
      package_name: "Histo Onco Skin Tumor Resection",
      lab_name: "Max Lab",
      fees: 5140,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29891,
      package_name: "Histo Onco Soft Tissue Sarcoma Resection",
      lab_name: "Max Lab",
      fees: 6380,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29928,
      package_name: "Histo Onco Thyroidectomy with Lymphnode Dissection",
      lab_name: "Max Lab",
      fees: 5140,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29923,
      package_name: "Histo Onco Thyroidectomyy",
      lab_name: "Max Lab",
      fees: 3920,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29922,
      package_name: "Histo Onco-Maxillectomy",
      lab_name: "Max Lab",
      fees: 3920,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24867,
      package_name: "Issue of Slide (2-5)",
      lab_name: "Max Lab",
      fees: 290,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24868,
      package_name: "Issue of Slide (5-10)",
      lab_name: "Max Lab",
      fees: 410,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24870,
      package_name: "Issue of Slide (more Than 10)",
      lab_name: "Max Lab",
      fees: 650,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24869,
      package_name: "Issue of Slide. (less Than 2)",
      lab_name: "Max Lab",
      fees: 150,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29889,
      package_name: "Resection (Oral Cavity/Larynx)",
      lab_name: "Max Lab",
      fees: 6170,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28736,
      package_name: "Slide Review & IHC (Piramal)",
      lab_name: "Max Lab",
      fees: 4040,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29906,
      package_name: "Small Biopsy  (3-5 Container)",
      lab_name: "Max Lab",
      fees: 3740,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29907,
      package_name: "Small Biopsy  (More than 5 Container)",
      lab_name: "Max Lab",
      fees: 8390,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29905,
      package_name: "Small Biopsy  (Two Container)",
      lab_name: "Max Lab",
      fees: 2580,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29904,
      package_name: "Small Biopsy (Single Container)",
      lab_name: "Max Lab",
      fees: 1520,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29930,
      package_name: "Submandbular Gland Resection with Lymphnode Dissec",
      lab_name: "Max Lab",
      fees: 4900,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29925,
      package_name: "Submandbular Gland Resection without Lymphnode Dis",
      lab_name: "Max Lab",
      fees: 3740,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 52794,
      package_name: "1st Trimester Quad with Pre-Eclampsia Screening",
      lab_name: "Max Lab",
      fees: 3500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 55200,
      package_name: "1st Trimester Quad without Pre-Eclampsia",
      lab_name: "Max Lab",
      fees: 2200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6268,
      package_name: "25 Hydroxy Vitamin D LevelSerum",
      lab_name: "Max Lab",
      fees: 1600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25555,
      package_name: "AFP (Alpha Feto Protein)Serum",
      lab_name: "Max Lab",
      fees: 970,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25556,
      package_name: "AFP (Alpha Feto Protein)-Amniotic Fluid",
      lab_name: "Max Lab",
      fees: 1010,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28248,
      package_name: "Anti Mullerian Hormone Serum",
      lab_name: "Max Lab",
      fees: 2000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6119,
      package_name: "CA - 125 (Ovarian Cancer Marker)Serum",
      lab_name: "Max Lab",
      fees: 1290,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6430,
      package_name: "CA 15.3 (Breast Cancer Marker)Serum",
      lab_name: "Max Lab",
      fees: 1480,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6391,
      package_name: "CA 19.9  Serum",
      lab_name: "Max Lab",
      fees: 1390,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19577,
      package_name: "CEA (Carcino Embryonic Antigen)Serum",
      lab_name: "Max Lab",
      fees: 850,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28450,
      package_name: "CK- MB (Mass)Serum",
      lab_name: "Max Lab",
      fees: 700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24182,
      package_name: "Cord Blood  Free -T4Serum",
      lab_name: "Max Lab",
      fees: 520,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24183,
      package_name: "Cord Blood -FT3Serum",
      lab_name: "Max Lab",
      fees: 520,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24181,
      package_name: "Cord Blood TSHSerum",
      lab_name: "Max Lab",
      fees: 420,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6123,
      package_name: "Cortisol (Evening Sample)Serum",
      lab_name: "Max Lab",
      fees: 620,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6122,
      package_name: "Cortisol (Morning Sample)Serum",
      lab_name: "Max Lab",
      fees: 620,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26275,
      package_name: "Cortisol (Random Sample)Serum",
      lab_name: "Max Lab",
      fees: 690,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45334,
      package_name: "Cortisol Serum (Overnight Dexamethasone1mg",
      lab_name: "Max Lab",
      fees: 800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27500,
      package_name: "Cortisol Stimulation Test (2 Specimen)",
      lab_name: "Max Lab",
      fees: 1340,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27501,
      package_name: "Cortisol Stimulation Test (3 Specimen)",
      lab_name: "Max Lab",
      fees: 1930,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27502,
      package_name: "Cortisol Stimulation Test (4 Specimen)",
      lab_name: "Max Lab",
      fees: 2620,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6330,
      package_name: "Creatine Kinase Mbserum",
      lab_name: "Max Lab",
      fees: 460,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 17368,
      package_name: "DHEA-S (Dehydroepiandrosterone Sulphate)",
      lab_name: "Max Lab",
      fees: 1210,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6442,
      package_name: "Digoxin Serum",
      lab_name: "Max Lab",
      fees: 1060,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26546,
      package_name: "Double Marker",
      lab_name: "Max Lab",
      fees: 2200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6351,
      package_name: "Erythropoietin Levels",
      lab_name: "Max Lab",
      fees: 2040,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5929,
      package_name: "Estradiol (E2)Serum",
      lab_name: "Max Lab",
      fees: 670,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6125,
      package_name: "FerritinSerum",
      lab_name: "Max Lab",
      fees: 740,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6200,
      package_name: "Folate  Serum",
      lab_name: "Max Lab",
      fees: 1350,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37407,
      package_name: "Free PSA % ( Free PSA Total PSA )Serum",
      lab_name: "Max Lab",
      fees: 1850,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6082,
      package_name: "Free PSA (Prostate Specific Antigen)Serum",
      lab_name: "Max Lab",
      fees: 970,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 52955,
      package_name: "Free ß HCG Serum",
      lab_name: "Max Lab",
      fees: 690,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6081,
      package_name: "Free T3Serum",
      lab_name: "Max Lab",
      fees: 420,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5930,
      package_name: "Free T4 Serum",
      lab_name: "Max Lab",
      fees: 420,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5925,
      package_name: "FSH - Follicle Stimulating HormoneSerum",
      lab_name: "Max Lab",
      fees: 550,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6465,
      package_name: "Growth Hormone Stimulation Test-Clonidone",
      lab_name: "Max Lab",
      fees: 3640,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25312,
      package_name: "Growth Hormone Stimulation Test-Insulin",
      lab_name: "Max Lab",
      fees: 8280,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 40510,
      package_name: "Growth Hormone Suppression Test",
      lab_name: "Max Lab",
      fees: 4440,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19640,
      package_name: "Growth HormoneSerum",
      lab_name: "Max Lab",
      fees: 830,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5931,
      package_name: "HCG - Beta SpecificSerum",
      lab_name: "Max Lab",
      fees: 650,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28723,
      package_name: "Inhibin A Reproductive Marker",
      lab_name: "Max Lab",
      fees: 830,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 17230,
      package_name: "Insulin Level (Fasting)",
      lab_name: "Max Lab",
      fees: 870,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 17231,
      package_name: "Insulin Level (PP)",
      lab_name: "Max Lab",
      fees: 880,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 17232,
      package_name: "Insulin Level (Random)",
      lab_name: "Max Lab",
      fees: 880,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5927,
      package_name: "LH-Luteinizing Hormone  Serum",
      lab_name: "Max Lab",
      fees: 570,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5932,
      package_name: "ProgesteroneSerum",
      lab_name: "Max Lab",
      fees: 620,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5933,
      package_name: "Prolactin Serum",
      lab_name: "Max Lab",
      fees: 580,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6041,
      package_name: "Prostate Specific Antigen (P.S.A.) - Total",
      lab_name: "Max Lab",
      fees: 800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6145,
      package_name: "PTH (Parathyroid Hormone)- IntactEDTA",
      lab_name: "Max Lab",
      fees: 1740,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27656,
      package_name: "Quad Screen Test",
      lab_name: "Max Lab",
      fees: 3500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6396,
      package_name: "Serum Thyroglobulin",
      lab_name: "Max Lab",
      fees: 1790,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6232,
      package_name: "Testosterone TotalSerum",
      lab_name: "Max Lab",
      fees: 740,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24195,
      package_name: "Thyroid Profile(cord Blood)",
      lab_name: "Max Lab",
      fees: 1270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 9245,
      package_name: "Thyroid Profile*Serum",
      lab_name: "Max Lab",
      fees: 950,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46422,
      package_name: "Total - Thyroid Profile",
      lab_name: "Max Lab",
      fees: 540,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46524,
      package_name: "Total T3 Serum",
      lab_name: "Max Lab",
      fees: 210,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46523,
      package_name: "Total T4 Serum",
      lab_name: "Max Lab",
      fees: 210,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6261,
      package_name: "Triple Test",
      lab_name: "Max Lab",
      fees: 2650,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 20328,
      package_name: "Trop ISerum (High Sensitive)",
      lab_name: "Max Lab",
      fees: 2060,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5928,
      package_name: "TSHSerum",
      lab_name: "Max Lab",
      fees: 350,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28155,
      package_name: "Unconjugated Estriol Serum",
      lab_name: "Max Lab",
      fees: 1410,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6202,
      package_name: "Vitamin B12 & Folate Serum",
      lab_name: "Max Lab",
      fees: 1240,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6128,
      package_name: "Vitamin B12 Serum",
      lab_name: "Max Lab",
      fees: 1100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21827,
      package_name: "C-erbb2/her-2-neu - Immunohistochemistry",
      lab_name: "Max Lab",
      fees: 1680,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 40470,
      package_name: "Histo Biopsy Brain Tumor with 2 IHC",
      lab_name: "Max Lab",
      fees: 4510,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 40471,
      package_name: "Histo Biopsy Brain Tumor with 4 IHC",
      lab_name: "Max Lab",
      fees: 6970,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35223,
      package_name: "IHC-MMR",
      lab_name: "Max Lab",
      fees: 6430,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44676,
      package_name: "Immunohistochemistry Androgen Receptor",
      lab_name: "Max Lab",
      fees: 2160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27492,
      package_name: "Immunohistochemistry Dystrophin 1 and 2",
      lab_name: "Max Lab",
      fees: 4170,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21831,
      package_name: "ImmunoHistoChemistry EG FR",
      lab_name: "Max Lab",
      fees: 2060,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21833,
      package_name: "ImmunoHistoChemistry ER (Estrogen Receptor)",
      lab_name: "Max Lab",
      fees: 1580,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24224,
      package_name: "Immunohistochemistry Individual Marker",
      lab_name: "Max Lab",
      fees: 1840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21837,
      package_name: "Immunohistochemistry KI-67 - Tissue",
      lab_name: "Max Lab",
      fees: 1820,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 40453,
      package_name: "Immunohistochemistry Panel 1",
      lab_name: "Max Lab",
      fees: 3820,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24225,
      package_name: "Immunohistochemistry Panel 2",
      lab_name: "Max Lab",
      fees: 5890,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24226,
      package_name: "Immunohistochemistry Panel 3",
      lab_name: "Max Lab",
      fees: 7030,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24227,
      package_name: "Immunohistochemistry Panel 4",
      lab_name: "Max Lab",
      fees: 9200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21843,
      package_name: "ImmunoHistoChemistry PR (Progesterone Receptor)",
      lab_name: "Max Lab",
      fees: 2010,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21835,
      package_name: "Immunohistochemistry-Growth Hormone",
      lab_name: "Max Lab",
      fees: 2330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21844,
      package_name: "Immunohistochemistry-Prolactin",
      lab_name: "Max Lab",
      fees: 2010,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 43777,
      package_name: "PD-L1 (SP263) Ventana-IHC",
      lab_name: "Max Lab",
      fees: 4920,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5817,
      package_name: "AFB Stain",
      lab_name: "Max Lab",
      fees: 300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25553,
      package_name: "Albert Stain",
      lab_name: "Max Lab",
      fees: 320,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19442,
      package_name: "Arterial Line Tip For Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5812,
      package_name: "Ascitic Fluid - Gram's Stain",
      lab_name: "Max Lab",
      fees: 490,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5813,
      package_name: "Ascitic Fluid -Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5818,
      package_name: "Ascitic Fluid-AFB Staining",
      lab_name: "Max Lab",
      fees: 330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26960,
      package_name: "Ascitic Fluid-Fungus Examination",
      lab_name: "Max Lab",
      fees: 320,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19565,
      package_name: "Bacterial Meningitis PanelCSF",
      lab_name: "Max Lab",
      fees: 2860,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27017,
      package_name: "BAL (Broncho Alveolar Lavage)  - Gram's Stain",
      lab_name: "Max Lab",
      fees: 490,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29334,
      package_name: "BAL (Broncho Alveolar Lavage) - AFB Stain",
      lab_name: "Max Lab",
      fees: 340,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19448,
      package_name: "BAL (Broncho Alveolar Lavage) - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 760,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26964,
      package_name: "BAL (Broncho Alveolar Lavage) -Fungus Examination",
      lab_name: "Max Lab",
      fees: 320,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29339,
      package_name: "BAL - Modified AFB Stain",
      lab_name: "Max Lab",
      fees: 330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47028,
      package_name: "Bile Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 960,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21732,
      package_name: "Blood - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 1180,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21728,
      package_name: "Body Fluid (Rapid) - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 1550,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47030,
      package_name: "Bone Marrow Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 960,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6304,
      package_name: "Catheter Tip - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19440,
      package_name: "Central Line Tip For Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6193,
      package_name: "Clostridium Difficile Toxin A & B Stool",
      lab_name: "Max Lab",
      fees: 2520,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6390,
      package_name: "Conjuctival Swab-Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45111,
      package_name: "CRE Screening",
      lab_name: "Max Lab",
      fees: 780,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6182,
      package_name: "Cryptococus  Antigen CSF",
      lab_name: "Max Lab",
      fees: 2370,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6183,
      package_name: "Cryptococus  Antigen Serum",
      lab_name: "Max Lab",
      fees: 2160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6185,
      package_name: "Cryptosporidium/Isospora Identification",
      lab_name: "Max Lab",
      fees: 340,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21731,
      package_name: "CSF (Rapid) - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 1550,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5995,
      package_name: "CSF- Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 820,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26276,
      package_name: "CSF- Gram's Stain",
      lab_name: "Max Lab",
      fees: 490,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26277,
      package_name: "CSF-AFB Stain",
      lab_name: "Max Lab",
      fees: 340,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26968,
      package_name: "CSF-Indian Ink",
      lab_name: "Max Lab",
      fees: 330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19611,
      package_name: "Culture & Sensitivity - Bone Marrow (Rapid)",
      lab_name: "Max Lab",
      fees: 1550,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25585,
      package_name: "Culture & Sensitivity -Valve",
      lab_name: "Max Lab",
      fees: 800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25575,
      package_name: "Culture & Sensitivity- Drain Fluid",
      lab_name: "Max Lab",
      fees: 800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19612,
      package_name: "Culture - Cholera",
      lab_name: "Max Lab",
      fees: 800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5993,
      package_name: "Culture And Sensitivity (Other Specimen)",
      lab_name: "Max Lab",
      fees: 760,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19618,
      package_name: "Culture Nocardia",
      lab_name: "Max Lab",
      fees: 1110,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19621,
      package_name: "Culture- Water Presumptive Coliform Count",
      lab_name: "Max Lab",
      fees: 1170,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19439,
      package_name: "CVP Line Tip For Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 730,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24171,
      package_name: "Dialysis Water - Culture",
      lab_name: "Max Lab",
      fees: 800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25592,
      package_name: "Direct Smear Examination",
      lab_name: "Max Lab",
      fees: 180,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5996,
      package_name: "EAR Discharge - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 780,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6295,
      package_name: "EAR Swab - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 780,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47014,
      package_name: "Endometrial - AFB Stain",
      lab_name: "Max Lab",
      fees: 380,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46996,
      package_name: "ET Secretion-Grams Stain",
      lab_name: "Max Lab",
      fees: 110,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47005,
      package_name: "ET Secretion-Modified AFB",
      lab_name: "Max Lab",
      fees: 400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19444,
      package_name: "ET Secretions - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 780,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27008,
      package_name: "ET Secretions-AFB Stain",
      lab_name: "Max Lab",
      fees: 340,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27009,
      package_name: "ET Secretions-Fungus Examination",
      lab_name: "Max Lab",
      fees: 330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19447,
      package_name: "ET TIP - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5991,
      package_name: "Eye Swab - Culture And Sensitivity",
      lab_name: "Max Lab",
      fees: 800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19445,
      package_name: "Femoral Line Tip - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25588,
      package_name: "Fungal Smear Examination",
      lab_name: "Max Lab",
      fees: 330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47012,
      package_name: "Gastric Aspiration - AFB Stain",
      lab_name: "Max Lab",
      fees: 380,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47003,
      package_name: "Gastric Aspiration - Grams Stain",
      lab_name: "Max Lab",
      fees: 550,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47031,
      package_name: "Gastric Aspiration Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 960,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47022,
      package_name: "Gastric Aspiration-Fungus Examination",
      lab_name: "Max Lab",
      fees: 400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19670,
      package_name: "Gonococcus Smear Examination",
      lab_name: "Max Lab",
      fees: 240,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6004,
      package_name: "Gram's Staining",
      lab_name: "Max Lab",
      fees: 470,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6421,
      package_name: "Groin Swab - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39622,
      package_name: "H Pylori Stool",
      lab_name: "Max Lab",
      fees: 1600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47026,
      package_name: "Hair - Fungus Examination",
      lab_name: "Max Lab",
      fees: 400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47017,
      package_name: "High Vaginal Swab - AFB Stain",
      lab_name: "Max Lab",
      fees: 380,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6331,
      package_name: "High Vaginal Swab Culture And Sensitivity",
      lab_name: "Max Lab",
      fees: 780,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47023,
      package_name: "High Vaginal Swab-Fungus Examination",
      lab_name: "Max Lab",
      fees: 400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47001,
      package_name: "High Vaginal Swab-Grams Stain",
      lab_name: "Max Lab",
      fees: 550,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26656,
      package_name: "HVS Culture For Group B Streptococcus",
      lab_name: "Max Lab",
      fees: 780,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24974,
      package_name: "India Ink Preparation",
      lab_name: "Max Lab",
      fees: 320,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25198,
      package_name: "Infection Control Culture",
      lab_name: "Max Lab",
      fees: 300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37330,
      package_name: "Legionella Pneumophila Antigen Urine",
      lab_name: "Max Lab",
      fees: 4850,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47029,
      package_name: "Liver Abscess Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 960,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47021,
      package_name: "Liver Abscess Fungus Examination",
      lab_name: "Max Lab",
      fees: 400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47000,
      package_name: "Liver Abscess Grams Stain",
      lab_name: "Max Lab",
      fees: 550,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47016,
      package_name: "Lymph nodes - AFB Stain",
      lab_name: "Max Lab",
      fees: 320,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47015,
      package_name: "Menstrual Blood - AFB Stain",
      lab_name: "Max Lab",
      fees: 380,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27504,
      package_name: "Modified AFB Stain",
      lab_name: "Max Lab",
      fees: 330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47024,
      package_name: "Nail - Fungus Examination",
      lab_name: "Max Lab",
      fees: 400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19449,
      package_name: "Nasal Swab - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 780,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26282,
      package_name: "Other Fluid - AFB Stain",
      lab_name: "Max Lab",
      fees: 340,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6019,
      package_name: "Other Fluid - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 780,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26283,
      package_name: "Other Fluid - Gram Stain",
      lab_name: "Max Lab",
      fees: 490,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26972,
      package_name: "Other Fluid -Fungus Examination",
      lab_name: "Max Lab",
      fees: 330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47006,
      package_name: "Other Fluid-Modified AFB",
      lab_name: "Max Lab",
      fees: 400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 30275,
      package_name: "Paired Blood Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 1170,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26284,
      package_name: "Pericardial Fluid  - AFB Stain",
      lab_name: "Max Lab",
      fees: 340,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25576,
      package_name: "Pericardial Fluid  - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26285,
      package_name: "Pericardial Fluid  - Gram Stain",
      lab_name: "Max Lab",
      fees: 500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26976,
      package_name: "Pericardial Fluid-Fungus Examination",
      lab_name: "Max Lab",
      fees: 330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26287,
      package_name: "Peritoneal Fluid   - AFB Stain",
      lab_name: "Max Lab",
      fees: 340,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26286,
      package_name: "Peritoneal Fluid   - Gram Stain",
      lab_name: "Max Lab",
      fees: 500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25577,
      package_name: "Peritoneal Fluid  - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26980,
      package_name: "Peritoneal Fluid-Fungus Examination",
      lab_name: "Max Lab",
      fees: 330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6025,
      package_name: "Pleural Fluid - AFB Stain",
      lab_name: "Max Lab",
      fees: 330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6030,
      package_name: "Pleural Fluid - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 780,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6024,
      package_name: "Pleural Fluid - Grams Stain",
      lab_name: "Max Lab",
      fees: 500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26984,
      package_name: "Pleural Fluid-Fungus Examination",
      lab_name: "Max Lab",
      fees: 330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5892,
      package_name: "Post Coital High Vaginal Swab",
      lab_name: "Max Lab",
      fees: 560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29350,
      package_name: "Pus - AFB Stain",
      lab_name: "Max Lab",
      fees: 300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6001,
      package_name: "Pus - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 760,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27018,
      package_name: "Pus - Grams Stain",
      lab_name: "Max Lab",
      fees: 490,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29353,
      package_name: "Pus - Modified AFB Stain",
      lab_name: "Max Lab",
      fees: 330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26987,
      package_name: "Pus -Fungus Examination",
      lab_name: "Max Lab",
      fees: 330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27493,
      package_name: "Rotavirus Antigen Test",
      lab_name: "Max Lab",
      fees: 480,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5989,
      package_name: "Scraping For Fungus",
      lab_name: "Max Lab",
      fees: 350,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47009,
      package_name: "Semen - AFB Statin",
      lab_name: "Max Lab",
      fees: 400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6003,
      package_name: "Semen - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 780,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46997,
      package_name: "Semen-Grams Stain",
      lab_name: "Max Lab",
      fees: 550,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26281,
      package_name: "Skin - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47025,
      package_name: "Skin - Fungus Examination",
      lab_name: "Max Lab",
      fees: 400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28669,
      package_name: "Smear for Amoebic Trophozoite",
      lab_name: "Max Lab",
      fees: 300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5999,
      package_name: "Smear For Fungus",
      lab_name: "Max Lab",
      fees: 330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6006,
      package_name: "Sputum - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 750,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29358,
      package_name: "Sputum - Modified AFB Stain",
      lab_name: "Max Lab",
      fees: 320,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27014,
      package_name: "Sputum-AFB Stain",
      lab_name: "Max Lab",
      fees: 300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27015,
      package_name: "Sputum-Fungus Examination",
      lab_name: "Max Lab",
      fees: 320,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27013,
      package_name: "Sputum-Gram's Stain",
      lab_name: "Max Lab",
      fees: 480,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 20086,
      package_name: "Stool - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 720,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6313,
      package_name: "Stool For Hanging Drop",
      lab_name: "Max Lab",
      fees: 180,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39630,
      package_name: "Surgical Wound Swab Culture",
      lab_name: "Max Lab",
      fees: 780,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 43110,
      package_name: "Surveillance Air Culture",
      lab_name: "Max Lab",
      fees: 650,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 43112,
      package_name: "Surveillance Food Culture",
      lab_name: "Max Lab",
      fees: 650,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 43113,
      package_name: "Surveillance Miscellaneous Culture",
      lab_name: "Max Lab",
      fees: 650,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 43109,
      package_name: "Surveillance Swab Culture",
      lab_name: "Max Lab",
      fees: 640,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 43111,
      package_name: "Surveillance Water Culture",
      lab_name: "Max Lab",
      fees: 650,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 20284,
      package_name: "Swab For MRSA",
      lab_name: "Max Lab",
      fees: 690,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26288,
      package_name: "Synovial Fluid - AFB Stain",
      lab_name: "Max Lab",
      fees: 340,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25578,
      package_name: "Synovial Fluid - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 780,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26289,
      package_name: "Synovial Fluid - Gram Stain",
      lab_name: "Max Lab",
      fees: 500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26992,
      package_name: "Synovial Fluid -Fungus Examination",
      lab_name: "Max Lab",
      fees: 330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47013,
      package_name: "TBNA - AFB Stain",
      lab_name: "Max Lab",
      fees: 380,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47010,
      package_name: "Throat Swab - AFB Stain",
      lab_name: "Max Lab",
      fees: 380,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47019,
      package_name: "Throat Swab - Albert Stain",
      lab_name: "Max Lab",
      fees: 380,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6010,
      package_name: "Throat Swab - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 760,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46998,
      package_name: "Throat Swab-Grams Stain",
      lab_name: "Max Lab",
      fees: 540,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6325,
      package_name: "Tip - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47018,
      package_name: "Tissue - AFB Stain",
      lab_name: "Max Lab",
      fees: 380,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19443,
      package_name: "Tissue - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 750,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47027,
      package_name: "Tissue - Fungus Examination",
      lab_name: "Max Lab",
      fees: 400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47008,
      package_name: "Tissue - Modified AFB",
      lab_name: "Max Lab",
      fees: 400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47004,
      package_name: "Tissue-Grams Stain",
      lab_name: "Max Lab",
      fees: 550,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47007,
      package_name: "Tracheal Secretion-Modified AFB",
      lab_name: "Max Lab",
      fees: 400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19446,
      package_name: "Tracheal Secretions - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 780,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27001,
      package_name: "Tracheal Secretions - Gram's Stain",
      lab_name: "Max Lab",
      fees: 500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27002,
      package_name: "Tracheal Secretions -AFB Stain",
      lab_name: "Max Lab",
      fees: 340,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19922,
      package_name: "Tracheostomy Tip For Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47011,
      package_name: "Urine - AFB Stain",
      lab_name: "Max Lab",
      fees: 320,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47020,
      package_name: "Urine - Fungus Examination",
      lab_name: "Max Lab",
      fees: 310,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6011,
      package_name: "Urine Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46999,
      package_name: "Urine-Grams Stain",
      lab_name: "Max Lab",
      fees: 550,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6350,
      package_name: "Vaginal Discharge (Direct Microscopy)",
      lab_name: "Max Lab",
      fees: 210,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19924,
      package_name: "Venous Line Tip For Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6293,
      package_name: "Wound Swab - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 760,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26996,
      package_name: "Wound Swab- Gram's Stain",
      lab_name: "Max Lab",
      fees: 420,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26997,
      package_name: "Wound Swab-Fungus Examination",
      lab_name: "Max Lab",
      fees: 330,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6009,
      package_name: "AFB Culture",
      lab_name: "Max Lab",
      fees: 1170,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 30372,
      package_name: "AFB Sensitivity 1st Line {5 Drugs}",
      lab_name: "Max Lab",
      fees: 5010,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 30373,
      package_name: "AFB Sensitivity 2nd Line {7 Drugs}",
      lab_name: "Max Lab",
      fees: 5010,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47517,
      package_name: "AFB Stain & Culture–3",
      lab_name: "Max Lab",
      fees: 5750,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49548,
      package_name: "AFB Stain Second Sample",
      lab_name: "Max Lab",
      fees: 370,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49549,
      package_name: "AFB Stain Third Sample",
      lab_name: "Max Lab",
      fees: 370,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19588,
      package_name: "Anaerobic (Miscellaneous) - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 1270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25573,
      package_name: "Anaerobic Blood - Culture & Sensitivity",
      lab_name: "Max Lab",
      fees: 1100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29274,
      package_name: "Ascitic Fluid- AFB Culture",
      lab_name: "Max Lab",
      fees: 1250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29275,
      package_name: "Ascitic Fluid- Fungus Culture",
      lab_name: "Max Lab",
      fees: 1250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49731,
      package_name: "Ascitic fluid-GeneXpert MTB/RIF Ultra",
      lab_name: "Max Lab",
      fees: 3590,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29335,
      package_name: "BAL (Broncho Alveolar Lavage) - AFB Culture",
      lab_name: "Max Lab",
      fees: 1230,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29336,
      package_name: "BAL (Broncho Alveolar Lavage) - Fungus Culture",
      lab_name: "Max Lab",
      fees: 1250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49722,
      package_name: "BAL-GeneXpert MTB/RIF Ultra",
      lab_name: "Max Lab",
      fees: 3590,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48741,
      package_name: "Bone Marrow AFB Culture",
      lab_name: "Max Lab",
      fees: 1130,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48810,
      package_name: "Bone Marrow Fungus Culture",
      lab_name: "Max Lab",
      fees: 1120,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29340,
      package_name: "CSF - AFB Culture",
      lab_name: "Max Lab",
      fees: 1250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29341,
      package_name: "CSF - Fungus Culture",
      lab_name: "Max Lab",
      fees: 1270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49723,
      package_name: "CSF-GeneXpert MTB/RIF Ultra",
      lab_name: "Max Lab",
      fees: 3590,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49725,
      package_name: "Drain fluid- GeneXpert MTB/RIF Ultra",
      lab_name: "Max Lab",
      fees: 3590,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48783,
      package_name: "Endometrial AFB Culture",
      lab_name: "Max Lab",
      fees: 1250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49727,
      package_name: "Endometrial tissue- GeneXpert MTB/RIF Ultra",
      lab_name: "Max Lab",
      fees: 2140,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48770,
      package_name: "ET Secretion AFB Culture",
      lab_name: "Max Lab",
      fees: 1130,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48811,
      package_name: "ET Secretion Fungus Culture",
      lab_name: "Max Lab",
      fees: 1210,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49728,
      package_name: "ET- GeneXpert MTB/RIF Ultra",
      lab_name: "Max Lab",
      fees: 3590,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49729,
      package_name: "FNAC aspirated fluid-GeneXpert MTB/RIF Ultra",
      lab_name: "Max Lab",
      fees: 3590,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6000,
      package_name: "Fungal Culture",
      lab_name: "Max Lab",
      fees: 1100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26459,
      package_name: "Fungal Susceptibility",
      lab_name: "Max Lab",
      fees: 1750,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48827,
      package_name: "Fungus Blood Culture",
      lab_name: "Max Lab",
      fees: 1210,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48809,
      package_name: "Gastric Aspiration AFB Culture",
      lab_name: "Max Lab",
      fees: 1130,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49737,
      package_name: "Gastric Aspiration-GeneXpert MTB/RIF Ultra",
      lab_name: "Max Lab",
      fees: 3590,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34578,
      package_name: "GeneXpert MTB/RIF",
      lab_name: "Max Lab",
      fees: 2200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 30722,
      package_name: "Hains 1st Line",
      lab_name: "Max Lab",
      fees: 2790,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 30723,
      package_name: "Hains 2nd Line",
      lab_name: "Max Lab",
      fees: 5060,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 30724,
      package_name: "Hains Mott Species Identification",
      lab_name: "Max Lab",
      fees: 4310,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48812,
      package_name: "High Vaginal Swab Fungus Culture",
      lab_name: "Max Lab",
      fees: 1270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48808,
      package_name: "Liver Abscess AFB Culture",
      lab_name: "Max Lab",
      fees: 1210,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49739,
      package_name: "Liver Abscess-GeneXpert MTB/RIF Ultra",
      lab_name: "Max Lab",
      fees: 3590,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49740,
      package_name: "Lymph Nodes-GeneXpert MTB/RIF",
      lab_name: "Max Lab",
      fees: 3590,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48807,
      package_name: "Menstrual Blood AFB Culture",
      lab_name: "Max Lab",
      fees: 1170,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49734,
      package_name: "Menstrual Blood- GeneXpert MTB/RIF",
      lab_name: "Max Lab",
      fees: 3590,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48828,
      package_name: "Nail Fungus Culture",
      lab_name: "Max Lab",
      fees: 1270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29342,
      package_name: "Other Fluid - AFB Culture",
      lab_name: "Max Lab",
      fees: 1250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29343,
      package_name: "Other Fluid - Fungus Culture",
      lab_name: "Max Lab",
      fees: 1270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49736,
      package_name: "Other fluid-GeneXpert MTB/RIF",
      lab_name: "Max Lab",
      fees: 3780,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29344,
      package_name: "Pericardial Fluid - AFB Culture",
      lab_name: "Max Lab",
      fees: 1250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29345,
      package_name: "Pericardial Fluid - Fungal Culture",
      lab_name: "Max Lab",
      fees: 1270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29346,
      package_name: "Peritoneal Fluid - AFB Culture",
      lab_name: "Max Lab",
      fees: 1250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29347,
      package_name: "Peritoneal Fluid - Fungus Culture",
      lab_name: "Max Lab",
      fees: 1270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49741,
      package_name: "Pleural Biopsy-GeneXpert MTB/RIF",
      lab_name: "Max Lab",
      fees: 3590,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29348,
      package_name: "Pleural Fluid - AFB Culture",
      lab_name: "Max Lab",
      fees: 1250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29349,
      package_name: "Pleural Fluid - Fungus Culture",
      lab_name: "Max Lab",
      fees: 1270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49733,
      package_name: "Pleural fluid-GeneXpert MTB/RIF",
      lab_name: "Max Lab",
      fees: 3590,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29351,
      package_name: "Pus - AFB Culture",
      lab_name: "Max Lab",
      fees: 1190,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29352,
      package_name: "Pus - Fungus Culture",
      lab_name: "Max Lab",
      fees: 1270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49730,
      package_name: "Pus-GeneXpert MTB/RIF",
      lab_name: "Max Lab",
      fees: 3590,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48775,
      package_name: "Semen AFB Culture",
      lab_name: "Max Lab",
      fees: 1190,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49742,
      package_name: "Semen-GeneXpert MTB/RIF",
      lab_name: "Max Lab",
      fees: 3590,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29354,
      package_name: "Sputum - AFB Culture",
      lab_name: "Max Lab",
      fees: 960,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29355,
      package_name: "Sputum - Fungus Culture",
      lab_name: "Max Lab",
      fees: 1100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49735,
      package_name: "Sputum-GeneXpert MTB/RIF",
      lab_name: "Max Lab",
      fees: 3740,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48831,
      package_name: "Stool Fungus Culture",
      lab_name: "Max Lab",
      fees: 1290,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29359,
      package_name: "Synovial Fluid - AFB Culture",
      lab_name: "Max Lab",
      fees: 1250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29360,
      package_name: "Synovial Fluid - Fungus Culture",
      lab_name: "Max Lab",
      fees: 1160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49732,
      package_name: "Synovial fluid-GeneXpert MTB/RIF",
      lab_name: "Max Lab",
      fees: 3590,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48776,
      package_name: "TBNA AFB Culture",
      lab_name: "Max Lab",
      fees: 1250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49743,
      package_name: "TBNA-GeneXpert MTB/RIF",
      lab_name: "Max Lab",
      fees: 3590,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49744,
      package_name: "Throat swab-GeneXpert MTB/RIF",
      lab_name: "Max Lab",
      fees: 3590,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48771,
      package_name: "Tissue AFB Culture",
      lab_name: "Max Lab",
      fees: 1190,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49726,
      package_name: "TISSUE-GeneXpert MTB/RIF",
      lab_name: "Max Lab",
      fees: 3780,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48772,
      package_name: "Tracheal AFB Culture",
      lab_name: "Max Lab",
      fees: 1130,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48817,
      package_name: "Tracheal Fungus Culture",
      lab_name: "Max Lab",
      fees: 1210,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49745,
      package_name: "Tracheal-GeneXpert MTB/RIF",
      lab_name: "Max Lab",
      fees: 3590,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48773,
      package_name: "Urine AFB Culture",
      lab_name: "Max Lab",
      fees: 1190,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48819,
      package_name: "Urine Fungus Culture",
      lab_name: "Max Lab",
      fees: 1270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49724,
      package_name: "URINE-GeneXpert MTB/RIF",
      lab_name: "Max Lab",
      fees: 3590,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6327,
      package_name: "Blood Gas Analysis",
      lab_name: "Max Lab",
      fees: 1100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44356,
      package_name: "Blood Gas Analysis with Electrolytes",
      lab_name: "Max Lab",
      fees: 1280,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24135,
      package_name: "Blood sugar-Glucometer*",
      lab_name: "Max Lab",
      fees: 150,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: "PPE1",
      package_name: "Covid PPE  Home Visit Charge",
      lab_name: "Max Lab",
      fees: 450,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: "PHEBO",
      package_name: "Phlebotomy service at home by partner.",
      lab_name: "Max Lab",
      fees: 150,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: "PPE",
      package_name: "PPE Services",
      lab_name: "Max Lab",
      fees: 1000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: "RCCN",
      package_name: "Report Courier Charge (Normal)",
      lab_name: "Max Lab",
      fees: 50,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: "RCCP",
      package_name: "Report Courier Charges (Priority)",
      lab_name: "Max Lab",
      fees: 100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5906,
      package_name: "Sample Collection Charge (Within 8 kms)",
      lab_name: "Max Lab",
      fees: 350,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33056,
      package_name: "Specimen Collection for DNA",
      lab_name: "Max Lab",
      fees: 1980,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49959,
      package_name: "BAL H1N1/Swine Flu RT PCR",
      lab_name: "Max Lab",
      fees: 4220,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26327,
      package_name: "BCR-ABL Qualitative Real Time PCR",
      lab_name: "Max Lab",
      fees: 4870,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26328,
      package_name: "BCR-ABL Quantitative Real Time PCR",
      lab_name: "Max Lab",
      fees: 6320,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51392,
      package_name: "BK Virus Qualitative RT PCR",
      lab_name: "Max Lab",
      fees: 4380,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34179,
      package_name: "BK Virus Quantitive PCR",
      lab_name: "Max Lab",
      fees: 5690,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51870,
      package_name: "Carba-Resistant gene detection RT PCR (Xpert)",
      lab_name: "Max Lab",
      fees: 4940,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26336,
      package_name: "Chikungunya PCR",
      lab_name: "Max Lab",
      fees: 1000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49359,
      package_name: "Chlamydia Trichomatis Urine/Swab",
      lab_name: "Max Lab",
      fees: 3790,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45108,
      package_name: "Clostridium Toxin A&B PCR",
      lab_name: "Max Lab",
      fees: 4030,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 32078,
      package_name: "CMV Qualitative PCR",
      lab_name: "Max Lab",
      fees: 5710,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 31488,
      package_name: "CMV Quantitative RT PCR",
      lab_name: "Max Lab",
      fees: 9220,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: "52963..",
      package_name: "COVID-19 (SARS CoV-2)Throat/Nasal Swab",
      lab_name: "Max Lab",
      fees: 800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27121,
      package_name: "Dengue Virus RNA PCR",
      lab_name: "Max Lab",
      fees: 1550,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26347,
      package_name: "EBV (Epstein Barr Virus) PCR",
      lab_name: "Max Lab",
      fees: 4150,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37689,
      package_name: "Encephalitis/Meningitis Panel ( FilmArray PCR)",
      lab_name: "Max Lab",
      fees: 14490,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49752,
      package_name: "Endometrial tissue-PCR for Tuberculosis",
      lab_name: "Max Lab",
      fees: 2360,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49960,
      package_name: "ET/Tracheal H1N1/Swine Flu RT PCR",
      lab_name: "Max Lab",
      fees: 4220,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45787,
      package_name: "Gastrointestinal Panel (FilmArray PCR)",
      lab_name: "Max Lab",
      fees: 14610,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33053,
      package_name: "Genotyping for HCV EDTA",
      lab_name: "Max Lab",
      fees: 4500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28520,
      package_name: "H1N1/ Swine Flu Real Time PCR-Routine",
      lab_name: "Max Lab",
      fees: 4250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6462,
      package_name: "HBV DNA Qualitative Real Time PCR",
      lab_name: "Max Lab",
      fees: 5760,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6278,
      package_name: "HBV DNA Quantitative Real Time PCR",
      lab_name: "Max Lab",
      fees: 7200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 529632,
      package_name: "HC Delhi- COVID-19 (SARS CoV-2)Throat/Nasal Swab",
      lab_name: "Max Lab",
      fees: 1200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6385,
      package_name: "HCV RNA Qualitative Real Time PCR",
      lab_name: "Max Lab",
      fees: 4500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6413,
      package_name: "HCV RNA Quantitative Real Time PCR",
      lab_name: "Max Lab",
      fees: 4500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37838,
      package_name: "HIV - 1 Qualitative Real Time PCR",
      lab_name: "Max Lab",
      fees: 4890,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 31476,
      package_name: "HIV-1 RNA QuantitativeReal Time PCR",
      lab_name: "Max Lab",
      fees: 5950,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26567,
      package_name: "HLA B5 (B*51/52)",
      lab_name: "Max Lab",
      fees: 4300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44357,
      package_name: "HLA B7",
      lab_name: "Max Lab",
      fees: 4300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 36287,
      package_name: "HLA-T & B Cell Lymphocyte Cross Match",
      lab_name: "Max Lab",
      fees: 1750,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 529631,
      package_name:
        "Home Collection HR /UP -COVID-19 (SARS COV-2)THROAT/NASAL SWAB",
      lab_name: "Max Lab",
      fees: 900,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28649,
      package_name: "HPV DNA High Risk Hybrid Capture",
      lab_name: "Max Lab",
      fees: 2260,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45778,
      package_name: "HSV 1 & 2 DNA Real Time PCR Blood",
      lab_name: "Max Lab",
      fees: 3650,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 31477,
      package_name: "HSV 1 & 2 REAL TIME PCR  C.S.F",
      lab_name: "Max Lab",
      fees: 6570,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33042,
      package_name: "Jak2 Exon Mutation Analysis(v617f) Real Time PCR",
      lab_name: "Max Lab",
      fees: 7400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48825,
      package_name: "Liver Abscess Fungus Culture",
      lab_name: "Max Lab",
      fees: 1390,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49753,
      package_name: "Liver Abscess-PCR for Tuberculosis",
      lab_name: "Max Lab",
      fees: 2430,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49769,
      package_name: "Lower Respiratory Panel (FilmArray) - BAL",
      lab_name: "Max Lab",
      fees: 10600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49770,
      package_name: "Lower Respiratory Panel (FilmArray) - ET/Tracheal",
      lab_name: "Max Lab",
      fees: 10600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49738,
      package_name: "Lower Respiratory Panel (FilmArray) - Sputum",
      lab_name: "Max Lab",
      fees: 10090,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49754,
      package_name: "Lymph Nodes-PCR for Tuberculosis",
      lab_name: "Max Lab",
      fees: 2430,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49759,
      package_name: "Menstrual Blood-PCR for Tuberculosis",
      lab_name: "Max Lab",
      fees: 1990,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29748,
      package_name: "Neisseria gonorrhoeaePCR",
      lab_name: "Max Lab",
      fees: 3280,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49761,
      package_name: "Other fluid-PCR for Tuberculosis",
      lab_name: "Max Lab",
      fees: 2010,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 36757,
      package_name: "Parvovirus B 19 DNA PCR",
      lab_name: "Max Lab",
      fees: 2500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 30520,
      package_name: "PCR FOR HLA B27EDTA",
      lab_name: "Max Lab",
      fees: 3200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 20104,
      package_name: "PCR for Tuberculosis",
      lab_name: "Max Lab",
      fees: 1950,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49763,
      package_name: "Pericardial fluid-PCR for Tuberculosis",
      lab_name: "Max Lab",
      fees: 2430,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49762,
      package_name: "Peritoneal fluid-PCR for Tuberculosis",
      lab_name: "Max Lab",
      fees: 2010,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49721,
      package_name: "Plasma-BK Virus QuantitativePCR",
      lab_name: "Max Lab",
      fees: 2430,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49758,
      package_name: "Pleural fluid-PCR for Tuberculosis",
      lab_name: "Max Lab",
      fees: 2550,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51857,
      package_name: "Pneumocystis RT PCR Respiratory",
      lab_name: "Max Lab",
      fees: 4640,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49755,
      package_name: "Pus-PCR for Tuberculosis",
      lab_name: "Max Lab",
      fees: 2530,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45159,
      package_name: "Single Antigen Bead DSA - Class I & II",
      lab_name: "Max Lab",
      fees: 40000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46865,
      package_name: "Single Antigen Bead DSA-Class I",
      lab_name: "Max Lab",
      fees: 22000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46866,
      package_name: "Single Antigen Bead DSA-Class II",
      lab_name: "Max Lab",
      fees: 20000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49961,
      package_name: "Sputum H1N1/Swine Flu RT PCR",
      lab_name: "Max Lab",
      fees: 4220,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49760,
      package_name: "Sputum-PCR for Tuberculosis",
      lab_name: "Max Lab",
      fees: 2430,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49757,
      package_name: "Synovial fluid-PCR for Tuberculosis",
      lab_name: "Max Lab",
      fees: 2430,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 40402,
      package_name: "T(5 12) or PDGFRB/ETV6 RTPCR  (O)",
      lab_name: "Max Lab",
      fees: 4600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49765,
      package_name: "TBNA-PCR for Tuberculosis",
      lab_name: "Max Lab",
      fees: 2430,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49962,
      package_name: "Throat & Nasal swab H1N1/Swine Flu RT PCR",
      lab_name: "Max Lab",
      fees: 4220,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 52963,
      package_name: "Throat/Nasal Swab COVID-19 (SARS CoV-2)",
      lab_name: "Max Lab",
      fees: 800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 52963,
      package_name: "Throat/Nasal Swab COVID-19 (SARS CoV-2) - UP",
      lab_name: "Max Lab",
      fees: 700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49292,
      package_name: "Upper Respiratory Panel (FilmArray)- Throat/Nasal Swab",
      lab_name: "Max Lab",
      fees: 14610,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49720,
      package_name: "Urine-BK Virus QuantitativePCR",
      lab_name: "Max Lab",
      fees: 2430,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49748,
      package_name: "Urine-PCR for Tuberculosis",
      lab_name: "Max Lab",
      fees: 2550,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 57311,
      package_name: "CBNAAT Covid 19 Assay",
      lab_name: "Max Lab",
      fees: 3000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19573,
      package_name: "(CA) Cyfra 21-1; Lung Cancer Marker (L)",
      lab_name: "Max Lab",
      fees: 2700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21744,
      package_name: "17-H-Corticosteriods & 17-Ketosteriods Urine-(L)",
      lab_name: "Max Lab",
      fees: 12000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5937,
      package_name: "17-Ketosteroids 24-Hour Urine (L)",
      lab_name: "Max Lab",
      fees: 5700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24267,
      package_name: "5 Alpha DHT (5 Alphadihydrotestosterone) (L)",
      lab_name: "Max Lab",
      fees: 2700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46846,
      package_name: "5 Nucleotidase (SR)",
      lab_name: "Max Lab",
      fees: 13980,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21739,
      package_name: "5-HIAA24Hrs Urine (L)",
      lab_name: "Max Lab",
      fees: 3500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5903,
      package_name: "5-HIAARandom Urine (L)",
      lab_name: "Max Lab",
      fees: 3500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27356,
      package_name: "ACHR (Acetylcholine Receptor) Autoantibodies (L)",
      lab_name: "Max Lab",
      fees: 2380,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 52795,
      package_name: "Acid Phosphatase Total (O)",
      lab_name: "Max Lab",
      fees: 350,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 52796,
      package_name: "Acid Phosphatase with Prostatic (O)",
      lab_name: "Max Lab",
      fees: 430,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47140,
      package_name: "Actim Pancreatitis",
      lab_name: "Max Lab",
      fees: 1735,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6186,
      package_name: "Actinomyces Culture (L)",
      lab_name: "Max Lab",
      fees: 1160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 40401,
      package_name: "Active B12 (Holotranscobalamin) Serum (L)",
      lab_name: "Max Lab",
      fees: 1600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49293,
      package_name: "Activity Natural Killer Cells (DH)",
      lab_name: "Max Lab",
      fees: 7100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 32768,
      package_name: "Acute Leukemia Cytogenetics-(C)",
      lab_name: "Max Lab",
      fees: 7660,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50407,
      package_name: "Adenovirus IgM (M)",
      lab_name: "Max Lab",
      fees: 1560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50296,
      package_name: "Adiponectin Serum (M)",
      lab_name: "Max Lab",
      fees: 2730,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6159,
      package_name: "Alcohol (Ethanol)-Quantitative (L)",
      lab_name: "Max Lab",
      fees: 680,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47632,
      package_name: "AlcoholUrine (M)",
      lab_name: "Max Lab",
      fees: 1320,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5869,
      package_name: "Aldolase (L)",
      lab_name: "Max Lab",
      fees: 690,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6254,
      package_name: "Aldosterone24 Hr Urine (L)",
      lab_name: "Max Lab",
      fees: 1600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37483,
      package_name: "Aldosterone/Plasma Renin Activity Ratio (L)",
      lab_name: "Max Lab",
      fees: 6700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 40681,
      package_name: "ALK 1 Immunohistochemistry (L)",
      lab_name: "Max Lab",
      fees: 8000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6501,
      package_name: "Alkaline Phosphatase Bone Specific (L)",
      lab_name: "Max Lab",
      fees: 4180,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6446,
      package_name: "Alkaline Phosphatase Isoenzyme (L)",
      lab_name: "Max Lab",
      fees: 5200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 53004,
      package_name: "Alkaptonuria Urine Qualitative (L)",
      lab_name: "Max Lab",
      fees: 280,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 53005,
      package_name: "Alkaptonuria Urine Quantitative (L)",
      lab_name: "Max Lab",
      fees: 3200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49379,
      package_name: "Allergen Individual-Drug Diclofenac PK (M)",
      lab_name: "Max Lab",
      fees: 1530,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49377,
      package_name: "Allergen Individual-Drug Disprin (Aspirin) PK (M)",
      lab_name: "Max Lab",
      fees: 1530,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49376,
      package_name: "Allergen Individual-Drug Paracetamol AntiPyretic (M)",
      lab_name: "Max Lab",
      fees: 1530,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49378,
      package_name: "AllergenIndividual-Drug Ibuprofen PK(M)",
      lab_name: "Max Lab",
      fees: 1530,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50389,
      package_name: "Allergy 12 Panel Drug Panel (M)",
      lab_name: "Max Lab",
      fees: 9480,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39469,
      package_name: "Allergy Individual Marker-gluten (L)",
      lab_name: "Max Lab",
      fees: 1970,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47655,
      package_name: "Alpha & Beta Thalassemia(HBA1 & HBA2HBB)Gene(MG)",
      lab_name: "Max Lab",
      fees: 28600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5871,
      package_name: "Alpha HBDH - Hydroxybutyrate Dehydrogenase (L)",
      lab_name: "Max Lab",
      fees: 250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50331,
      package_name: "Alpha Iduronidase Hurler (M)",
      lab_name: "Max Lab",
      fees: 4680,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5846,
      package_name: "Alpha Sub Unit (L)",
      lab_name: "Max Lab",
      fees: 7920,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47653,
      package_name: "Alpha Thalassemia Gene (HBA1 & HBA2) (MG)",
      lab_name: "Max Lab",
      fees: 16380,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25558,
      package_name: "Alpha-1-Antitrypsin Quantitation (L)",
      lab_name: "Max Lab",
      fees: 2000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 57179,
      package_name: "ALPHA-2-Macroglobulin (L)",
      lab_name: "Max Lab",
      fees: 2740,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50298,
      package_name: "Altum 315K (Low Resolution Microarray) (Lilac)",
      lab_name: "Max Lab",
      fees: 19660,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50299,
      package_name: "Altum 750K (High Resolution Microarray) (Lilac)",
      lab_name: "Max Lab",
      fees: 26220,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39532,
      package_name: "Aluminum Serum/Plasma (L)",
      lab_name: "Max Lab",
      fees: 2000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50487,
      package_name: "Amino Acid Quantitative CSF (L)",
      lab_name: "Max Lab",
      fees: 5200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26292,
      package_name: "Aminoacids Qualitative Csf (L)",
      lab_name: "Max Lab",
      fees: 1200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6441,
      package_name: "Aminoacids Qualitative Plasma (L)",
      lab_name: "Max Lab",
      fees: 1320,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21727,
      package_name: "Aminoacids Qualitative Urine (1 D Tlc) (L)",
      lab_name: "Max Lab",
      fees: 1320,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25559,
      package_name: "Aminoacids Qualitative Urine (2 D TLC) (L)",
      lab_name: "Max Lab",
      fees: 1350,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37399,
      package_name: "Aminoacids Quantitative Blood 10 (L)",
      lab_name: "Max Lab",
      fees: 4260,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37906,
      package_name: "Aml/eto Quantitative Rt Pcr (C)",
      lab_name: "Max Lab",
      fees: 6000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49294,
      package_name: "AML/MDS Genetic Profile-NGS (MG)",
      lab_name: "Max Lab",
      fees: 19760,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33058,
      package_name: "Amniotic Fluid -1318 21 & Sex Chro A Fish (DH)",
      lab_name: "Max Lab",
      fees: 9840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51588,
      package_name: "Amniotic fluid FISH+Karyotyping (MG)",
      lab_name: "Max Lab",
      fees: 11960,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33060,
      package_name: "Amniotic Fluid For Karyotyping - (DH)",
      lab_name: "Max Lab",
      fees: 8960,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33057,
      package_name: "Amniotic Fluid13 21 Chromosomes Fish-(DH)",
      lab_name: "Max Lab",
      fees: 8200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39613,
      package_name: "Amyloid A (M)",
      lab_name: "Max Lab",
      fees: 3990,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49528,
      package_name: "Androgen Index (L)",
      lab_name: "Max Lab",
      fees: 2750,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6342,
      package_name: "Androstenedione (L)",
      lab_name: "Max Lab",
      fees: 1820,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50300,
      package_name: "Anti Diuretic Hormone (ADH) (M)",
      lab_name: "Max Lab",
      fees: 4480,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34958,
      package_name: "Anti DNase B Serum (L)",
      lab_name: "Max Lab",
      fees: 1200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6283,
      package_name: "Anti Endomysial AntibodyIgA IFA (L)",
      lab_name: "Max Lab",
      fees: 1700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26461,
      package_name: "Anti Insulin Antibodies (L)",
      lab_name: "Max Lab",
      fees: 1990,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26309,
      package_name: "Anti Parietal Cell Antibody. IFA (L)",
      lab_name: "Max Lab",
      fees: 1480,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47308,
      package_name: "Anti Phospholipase A2 receptorR (PLA2R) IgG (SR)",
      lab_name: "Max Lab",
      fees: 5460,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26306,
      package_name: "Anti Reticulin Antibody Iga (L)",
      lab_name: "Max Lab",
      fees: 5840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46436,
      package_name: "Anti Soluble Liver Antigen Sla (L)",
      lab_name: "Max Lab",
      fees: 1300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6165,
      package_name: "Anti Sperm Antibody (M)",
      lab_name: "Max Lab",
      fees: 1210,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6031,
      package_name: "Anti-SS-DNA Antibody (Single Stranded)Serum (L)",
      lab_name: "Max Lab",
      fees: 2680,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50686,
      package_name: "Antibody Ganglioside Profile IgG (L)",
      lab_name: "Max Lab",
      fees: 4000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50687,
      package_name: "Antibody Ganglioside Profile IgM (L)",
      lab_name: "Max Lab",
      fees: 4000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50330,
      package_name: "Antibody Receptor TSH (L)",
      lab_name: "Max Lab",
      fees: 3750,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25565,
      package_name: "APC-R (Activated Protein C Resistance) (L)",
      lab_name: "Max Lab",
      fees: 3900,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26293,
      package_name: "Arsenic 24 Hrs Urine (L)",
      lab_name: "Max Lab",
      fees: 2750,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26295,
      package_name: "Arsenic Blood (L)",
      lab_name: "Max Lab",
      fees: 2750,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26294,
      package_name: "Arsenic Random Urine (L)",
      lab_name: "Max Lab",
      fees: 2750,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45364,
      package_name: "Aryl Sulphatase B (M)",
      lab_name: "Max Lab",
      fees: 7100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25561,
      package_name: "ASKA(anti Skeletal/striated Muscle Antidody) (L)",
      lab_name: "Max Lab",
      fees: 2000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50457,
      package_name: "Ataxia Telangiectasia  del/dup byMLPA(MG)",
      lab_name: "Max Lab",
      fees: 11440,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 32096,
      package_name: "B cell gene rearrangement PCR (SR)",
      lab_name: "Max Lab",
      fees: 16000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45003,
      package_name: "Baby Shield 11 Tests(Sickle Cell DiseaseHB Varian",
      lab_name: "Max Lab",
      fees: 2620,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45005,
      package_name: "Baby Shield TMS 62 Test (LC)",
      lab_name: "Max Lab",
      fees: 5200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33410,
      package_name: "Babyshield 111 Urine-(LC)",
      lab_name: "Max Lab",
      fees: 3830,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33409,
      package_name: "Babyshield 119 Urine & Blood-(LC)",
      lab_name: "Max Lab",
      fees: 5680,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 40445,
      package_name: "Babyshield Tms (47+5) And Gcms (urine) (LC)",
      lab_name: "Max Lab",
      fees: 6700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37427,
      package_name: "Babyshield Tms (above 6 Months) (LC)",
      lab_name: "Max Lab",
      fees: 2880,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37426,
      package_name: "Babyshield TMS (Below 6 Months)CHCAHG6P(LC)",
      lab_name: "Max Lab",
      fees: 4240,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50297,
      package_name: "Basal Ganglion Antibody (M)",
      lab_name: "Max Lab",
      fees: 4370,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50380,
      package_name: "BCL2 (18q21.3) Fish (C)",
      lab_name: "Max Lab",
      fees: 10000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 52928,
      package_name: "BCL6 (3q27) translocation FISH (C)",
      lab_name: "Max Lab",
      fees: 10000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37901,
      package_name: "Bcr-abl Kinase Domain Mutations (C)",
      lab_name: "Max Lab",
      fees: 9000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26387,
      package_name: "BCR/ABL Gene FISH- (C)",
      lab_name: "Max Lab",
      fees: 4000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48156,
      package_name: "Beta 2 Transferrin carbohydrate R (M)",
      lab_name: "Max Lab",
      fees: 6020,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51085,
      package_name: "Beta Crosslaps (L)",
      lab_name: "Max Lab",
      fees: 1670,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50324,
      package_name: "Beta Hexosaminidase A (M)",
      lab_name: "Max Lab",
      fees: 6240,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47078,
      package_name: "Beta Thalassemia 16 Mutation (Lilac)",
      lab_name: "Max Lab",
      fees: 7660,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47654,
      package_name: "Beta Thalassemia HBB Gene (MG)",
      lab_name: "Max Lab",
      fees: 2230,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45754,
      package_name: "Beta Thalassemia Mutation (L)",
      lab_name: "Max Lab",
      fees: 5500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51127,
      package_name: "BGI C+D Panel (SY)",
      lab_name: "Max Lab",
      fees: 192400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28388,
      package_name: "Biotinidase Deficiency (Quantitative) Heparin (L)",
      lab_name: "Max Lab",
      fees: 3800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50498,
      package_name: "Bone Marrow Failure Panel (MG)",
      lab_name: "Max Lab",
      fees: 24960,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51746,
      package_name: "Bordetella Pertussis IgG (M)",
      lab_name: "Max Lab",
      fees: 2370,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49083,
      package_name: "BRACA (1 & 2) (C)",
      lab_name: "Max Lab",
      fees: 25000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50591,
      package_name: "BRACA myriad genetics  (PB)",
      lab_name: "Max Lab",
      fees: 260000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35221,
      package_name: "BRAF – Mutation PCR (L)",
      lab_name: "Max Lab",
      fees: 5700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39718,
      package_name: "BRCA 1 & 2 (SR)",
      lab_name: "Max Lab",
      fees: 20600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 52213,
      package_name: "BRCA Comprehensive MLPA (C)",
      lab_name: "Max Lab",
      fees: 41200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50561,
      package_name: "BRCA1 & BRCA2 Mutation Advance Panel (L)",
      lab_name: "Max Lab",
      fees: 22880,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 41393,
      package_name: "BRCA1/BRCA2 With MLPA (MG)",
      lab_name: "Max Lab",
      fees: 43680,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50455,
      package_name: "Bullous Pemphigoid 180  (M)",
      lab_name: "Max Lab",
      fees: 4060,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50456,
      package_name: "Bullous Pemphigoid 230  (M)",
      lab_name: "Max Lab",
      fees: 4060,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37516,
      package_name: "C-kit Mutation Analysis Gist (C)",
      lab_name: "Max Lab",
      fees: 7000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35048,
      package_name: "C-myc Gene Amplificationpcr-(O)",
      lab_name: "Max Lab",
      fees: 10920,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45267,
      package_name: "C1 Esterase Inhibitor Antibody (M)",
      lab_name: "Max Lab",
      fees: 2730,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26386,
      package_name: "C1 Esterase Inhibitor Protein Quantitation (L)",
      lab_name: "Max Lab",
      fees: 2200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39623,
      package_name: "C1q Complement (L)",
      lab_name: "Max Lab",
      fees: 3500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25567,
      package_name: "C6 Complement (L)",
      lab_name: "Max Lab",
      fees: 1500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25568,
      package_name: "C7 Complement (L)",
      lab_name: "Max Lab",
      fees: 5750,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25569,
      package_name: "C8 Complement (L)",
      lab_name: "Max Lab",
      fees: 5750,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25570,
      package_name: "C9 Complement (L)",
      lab_name: "Max Lab",
      fees: 5750,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19571,
      package_name: "Ca 27.29 : Breast Cancer Marker (L)",
      lab_name: "Max Lab",
      fees: 5810,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6152,
      package_name: "CA 72.4 - Gastric Cancer Marker (L)",
      lab_name: "Max Lab",
      fees: 1450,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 32083,
      package_name: "Cadmium (L)",
      lab_name: "Max Lab",
      fees: 3200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51546,
      package_name: "Cadmium Urine (L)",
      lab_name: "Max Lab",
      fees: 3200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19574,
      package_name: "Calcitonin Serum (L)",
      lab_name: "Max Lab",
      fees: 2150,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37905,
      package_name: "Calr Mutation Analysis (C)",
      lab_name: "Max Lab",
      fees: 6000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28320,
      package_name: "Campylobacter Species Culture (SR)",
      lab_name: "Max Lab",
      fees: 1850,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44616,
      package_name: "Canassist - Breast (L)",
      lab_name: "Max Lab",
      fees: 65000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45911,
      package_name: "Cancer 50 Gene Panel (L)",
      lab_name: "Max Lab",
      fees: 32760,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46485,
      package_name: "Cancer Targeted Gene (CNSTumor) (L)",
      lab_name: "Max Lab",
      fees: 20000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46486,
      package_name: "Cancer Targeted Gene (Colon/Colorectal) (L)",
      lab_name: "Max Lab",
      fees: 15000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49931,
      package_name: "Cancer Targeted Gene Panel Lung (L)",
      lab_name: "Max Lab",
      fees: 20000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 52954,
      package_name: "Candida Albicans Antibodies  (M)",
      lab_name: "Max Lab",
      fees: 3700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50821,
      package_name: "Cardio Channelopathy Gene Panel (MG)",
      lab_name: "Max Lab",
      fees: 2030,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37596,
      package_name: "Cardio Genomic Test (ST)",
      lab_name: "Max Lab",
      fees: 32760,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45760,
      package_name: "Cardiopathy Gene Panel (MG)",
      lab_name: "Max Lab",
      fees: 23220,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48645,
      package_name: "Caris Molecular Intelligence (PB)",
      lab_name: "Max Lab",
      fees: 520000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49891,
      package_name: "Carrier Screening 100 Genes Couple (MG)",
      lab_name: "Max Lab",
      fees: 36400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6456,
      package_name: "Catecholamines & VMA 24 Hrs Urine (L)",
      lab_name: "Max Lab",
      fees: 7800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19576,
      package_name: "Catecholamines 24hrs Urine- (L)",
      lab_name: "Max Lab",
      fees: 4500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28672,
      package_name: "Catecholamines EDTA- (M)",
      lab_name: "Max Lab",
      fees: 7440,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 36762,
      package_name: "Cebpa ( Prognostic Marker Of Aml ) (SR)",
      lab_name: "Max Lab",
      fees: 7500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37909,
      package_name: "Cebpa Mutation Analysis (C)",
      lab_name: "Max Lab",
      fees: 7000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50344,
      package_name: "CFTR gene dele/dup (MG)",
      lab_name: "Max Lab",
      fees: 10400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28671,
      package_name: "Ch-50 Serum-(M)",
      lab_name: "Max Lab",
      fees: 3830,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44031,
      package_name: "Chikungunya PCR CSF (L)",
      lab_name: "Max Lab",
      fees: 1000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37335,
      package_name: "Chimerism Post - Engraftment (L)",
      lab_name: "Max Lab",
      fees: 5700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37334,
      package_name: "Chimerism Pre - Engraftment (L)",
      lab_name: "Max Lab",
      fees: 5700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37333,
      package_name: "Chimerism Pre - Engraftment Donor & Recipient (l",
      lab_name: "Max Lab",
      fees: 10000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19578,
      package_name: "Chlamydia IgG Antibody Panel -(L)",
      lab_name: "Max Lab",
      fees: 1400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34071,
      package_name: "Chlamydia Trachomatis IgA Antibody (M)",
      lab_name: "Max Lab",
      fees: 1040,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19582,
      package_name: "Chlamydia Trachomatis IgG Antibody (M)",
      lab_name: "Max Lab",
      fees: 1040,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6178,
      package_name: "Chlamydia Trachomatis IgM Antibody (M)",
      lab_name: "Max Lab",
      fees: 1040,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33059,
      package_name: "Chorionic Villi Cells For 1318 & 21  Fish- (DH)",
      lab_name: "Max Lab",
      fees: 10380,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33061,
      package_name: "Chorionic Villi Cells For Karyotyping - (DH)",
      lab_name: "Max Lab",
      fees: 11480,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34177,
      package_name: "Chromium (L)",
      lab_name: "Max Lab",
      fees: 3200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51547,
      package_name: "Chromium Urine (L)",
      lab_name: "Max Lab",
      fees: 3200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 52901,
      package_name: "Chromogranin  A (M)",
      lab_name: "Max Lab",
      fees: 5800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28680,
      package_name: "Chromogranin A (L)",
      lab_name: "Max Lab",
      fees: 6900,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33044,
      package_name: "Chromosomal Break Studies for Fanconi's Anaemia (O)",
      lab_name: "Max Lab",
      fees: 8640,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 40777,
      package_name: "Chromosome Interphase Profiling POC(L)",
      lab_name: "Max Lab",
      fees: 12000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 40778,
      package_name: "Chromosome Interphase Profiling Amniotic Fluid (l",
      lab_name: "Max Lab",
      fees: 14200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44888,
      package_name: "Chromosome-SNP Microarray 750K (L)",
      lab_name: "Max Lab",
      fees: 23000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29361,
      package_name: "Chyle Examination-(L)",
      lab_name: "Max Lab",
      fees: 550,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50266,
      package_name: "Chylomicron Qualitative (Specimen-Fluid) (C)",
      lab_name: "Max Lab",
      fees: 600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25762,
      package_name: "Citrate 24 Hours Urine (L)",
      lab_name: "Max Lab",
      fees: 820,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45310,
      package_name: "Claria NIPT(MG)",
      lab_name: "Max Lab",
      fees: 15000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 41375,
      package_name: "Clinical Exon Sequencing (MG)",
      lab_name: "Max Lab",
      fees: 28680,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48022,
      package_name: "Clopidogren Genotyping (M)",
      lab_name: "Max Lab",
      fees: 7220,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51788,
      package_name: "CMPD gene panel (MG)",
      lab_name: "Max Lab",
      fees: 17200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34178,
      package_name: "Cobalt (L)",
      lab_name: "Max Lab",
      fees: 3200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51548,
      package_name: "Cobalt Urine(L)",
      lab_name: "Max Lab",
      fees: 3200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26308,
      package_name: "Collagen Diseases Antibody Panel (L)",
      lab_name: "Max Lab",
      fees: 9000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48998,
      package_name: "Color Hereditary Panel (SY)",
      lab_name: "Max Lab",
      fees: 39320,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50454,
      package_name: "Comboquest AML V  (O)",
      lab_name: "Max Lab",
      fees: 8800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50104,
      package_name: "Comprehensive Tumor Panel (73 genes) (C)",
      lab_name: "Max Lab",
      fees: 65000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46363,
      package_name: "Confirmation Malaria Parasite (L)",
      lab_name: "Max Lab",
      fees: 120,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51090,
      package_name: "Congenital Neutropenia Gene Panel (MG)",
      lab_name: "Max Lab",
      fees: 24960,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19584,
      package_name: "Copper (Atomic Absorption)-24Hrs Urine (L)",
      lab_name: "Max Lab",
      fees: 2030,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47558,
      package_name: "Copper Random Urine (L)",
      lab_name: "Max Lab",
      fees: 1850,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45047,
      package_name: "Cord Blood Karyotyping (L)",
      lab_name: "Max Lab",
      fees: 3500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50112,
      package_name: "Core Prime EGFR (C)",
      lab_name: "Max Lab",
      fees: 15000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28321,
      package_name: "Coxsackie Antibodies Panel IgG& IgM (L)",
      lab_name: "Max Lab",
      fees: 3080,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19585,
      package_name: "CPK Isoenzyme Electrophoresis (L)",
      lab_name: "Max Lab",
      fees: 8000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33863,
      package_name: "Cri-du-chat Syndrome Blood/AF/CVS -(Lilac)",
      lab_name: "Max Lab",
      fees: 6240,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24270,
      package_name: "Cryoglobulin Confirmatory Panel (L)",
      lab_name: "Max Lab",
      fees: 5750,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6181,
      package_name: "Cryptococus AntibodySerum (L)",
      lab_name: "Max Lab",
      fees: 4360,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50781,
      package_name: "CSF Cysticercus (Taenia Solium) IgG (M)",
      lab_name: "Max Lab",
      fees: 1670,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51785,
      package_name: "CSF HSV (1&2) IgG (M)",
      lab_name: "Max Lab",
      fees: 2580,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49276,
      package_name: "CSF JEV PCR (L)",
      lab_name: "Max Lab",
      fees: 3000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47035,
      package_name: "CSF Measles (Rubeola) IgG Antibody (M)",
      lab_name: "Max Lab",
      fees: 1750,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51077,
      package_name: "CYP3A5 Tacrolimus (MG)",
      lab_name: "Max Lab",
      fees: 7280,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27986,
      package_name: "Cystatin C (L)",
      lab_name: "Max Lab",
      fees: 1200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51198,
      package_name: "DCP PIVKA-II (M)",
      lab_name: "Max Lab",
      fees: 3020,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50499,
      package_name: "Decarboxy Prothrombin (DCP) (L)",
      lab_name: "Max Lab",
      fees: 3500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45177,
      package_name: "Deletion Duplication Analysis(MLPA) DMD (MG)",
      lab_name: "Max Lab",
      fees: 8880,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44244,
      package_name: "Desmoglein (DSG) 1 And 3 Antibody (L)",
      lab_name: "Max Lab",
      fees: 4500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6230,
      package_name: "DHEA (Dehydroepiandrosterone) (L)",
      lab_name: "Max Lab",
      fees: 2450,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 52214,
      package_name: "Di Hydro Testosterone (M)",
      lab_name: "Max Lab",
      fees: 2370,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 41377,
      package_name: "Diabeties Type -1 Auto Immune Profile (L)",
      lab_name: "Max Lab",
      fees: 10500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19626,
      package_name: "Diazepam (calmposevaliumpaxumcalmod) (L)",
      lab_name: "Max Lab",
      fees: 10900,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33861,
      package_name: "DiGeorge/VCFS region probe Blood/AF/CVS -(Lilac)",
      lab_name: "Max Lab",
      fees: 6240,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47665,
      package_name: "Diphtheria IgG (M)",
      lab_name: "Max Lab",
      fees: 2630,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19614,
      package_name: "Diptheria - Culture (L)",
      lab_name: "Max Lab",
      fees: 1210,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44523,
      package_name: "DNA Analysis For Kinship Testing (L)",
      lab_name: "Max Lab",
      fees: 10000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44524,
      package_name: "DNA Analysis For Paternity Testing (L)",
      lab_name: "Max Lab",
      fees: 10000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45268,
      package_name: "DNA For Paternaly Chromosome (L)",
      lab_name: "Max Lab",
      fees: 10400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26300,
      package_name: "Dna Ploidy & S Phase-(O)",
      lab_name: "Max Lab",
      fees: 2630,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26301,
      package_name: "DNPH Urine (L)",
      lab_name: "Max Lab",
      fees: 120,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50473,
      package_name: "Double /Triple HIT Lymphoma (C)",
      lab_name: "Max Lab",
      fees: 15500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50590,
      package_name: "DPD Gene Mutations (5-FU) (M)",
      lab_name: "Max Lab",
      fees: 13520,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49026,
      package_name: "Dr Shubhada Kane Review of slide & block (SR)",
      lab_name: "Max Lab",
      fees: 10200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44353,
      package_name: "DSG 1&3 Antibody (L)",
      lab_name: "Max Lab",
      fees: 4500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 52315,
      package_name: "DST (MOTT) (L)",
      lab_name: "Max Lab",
      fees: 9200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28352,
      package_name: "Duchene Muscular Dystrophy (DMD) PCR Gene Mutation (L)",
      lab_name: "Max Lab",
      fees: 7500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47685,
      package_name: "Dystonia Gene Panel (MG)",
      lab_name: "Max Lab",
      fees: 23220,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51054,
      package_name: "EBV (NA) IgG (SR)",
      lab_name: "Max Lab",
      fees: 2080,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51053,
      package_name: "EBV(EA) IgG (SR)",
      lab_name: "Max Lab",
      fees: 2080,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19628,
      package_name: "Echinococus Detection For Scolices Fluid - (L)",
      lab_name: "Max Lab",
      fees: 500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 36191,
      package_name: "EGFR Amplification - Fish  (C)",
      lab_name: "Max Lab",
      fees: 12000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49283,
      package_name: "EGFR Real time PCR Assay (C)",
      lab_name: "Max Lab",
      fees: 8000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 36192,
      package_name: "EGFR VIII Variant PCR (C)",
      lab_name: "Max Lab",
      fees: 24040,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 43049,
      package_name: "Electron Microscopy (kidney Muscle Nerve) (L)",
      lab_name: "Max Lab",
      fees: 7000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 43294,
      package_name: "Enhanced Liver Fibrosis (ELF) Panel (L)",
      lab_name: "Max Lab",
      fees: 4500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46192,
      package_name: "Epidermal(pemphigus) IF(L)",
      lab_name: "Max Lab",
      fees: 2000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47469,
      package_name: "Epstein Barr Virus Early RNA (EBER-ISH) (C)",
      lab_name: "Max Lab",
      fees: 4500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19630,
      package_name: "Estrogen Total (L)",
      lab_name: "Max Lab",
      fees: 7300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37801,
      package_name: "Everolimus (L)",
      lab_name: "Max Lab",
      fees: 4600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37595,
      package_name: "Eye Genomic Test (ST)",
      lab_name: "Max Lab",
      fees: 32760,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19631,
      package_name: "Factor II Functional (L)",
      lab_name: "Max Lab",
      fees: 7200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6452,
      package_name: "Factor IX Functional (L)",
      lab_name: "Max Lab",
      fees: 1750,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6475,
      package_name: "Factor V Functional (L)",
      lab_name: "Max Lab",
      fees: 3200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19633,
      package_name: "Factor VII Functional (L)",
      lab_name: "Max Lab",
      fees: 2900,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19634,
      package_name: "Factor X Functional (L)",
      lab_name: "Max Lab",
      fees: 4700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6472,
      package_name: "Factor XI FunctionalSodium Citrate (L)",
      lab_name: "Max Lab",
      fees: 3100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6477,
      package_name: "Factor XII Functional (L)",
      lab_name: "Max Lab",
      fees: 3200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19635,
      package_name: "FactorXIIIClot Solubility-(L)",
      lab_name: "Max Lab",
      fees: 350,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45362,
      package_name: "Fatty Acids With Very Long Chains C22 To C26 Plasm",
      lab_name: "Max Lab",
      fees: 16720,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45075,
      package_name: "Fecal Elastase (L)",
      lab_name: "Max Lab",
      fees: 5000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34955,
      package_name: "Ferric Chloride Test Urine-(L)",
      lab_name: "Max Lab",
      fees: 120,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 52957,
      package_name: "FETAL SCOPE (LC)",
      lab_name: "Max Lab",
      fees: 10500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50820,
      package_name: "FGFR 2 Mutation (Lilac)",
      lab_name: "Max Lab",
      fees: 23220,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39969,
      package_name: "Fibrometer Nafld (SR)",
      lab_name: "Max Lab",
      fees: 4000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37974,
      package_name: "Filaria Antibody (M)",
      lab_name: "Max Lab",
      fees: 1100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 32770,
      package_name: "FIP1 L1 PDGFRa Aassay-(O)",
      lab_name: "Max Lab",
      fees: 5960,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51124,
      package_name: "FISH Eosinophilic Leukemia Panel (O)",
      lab_name: "Max Lab",
      fees: 15600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33857,
      package_name: "FISH for Aneuploidy Screen CVS -(Lilac)",
      lab_name: "Max Lab",
      fees: 6760,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 31497,
      package_name: "Fish For Chromosome Y- (DH)",
      lab_name: "Max Lab",
      fees: 9840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37779,
      package_name: "Fish For Subtelomeric Deletions (DH)",
      lab_name: "Max Lab",
      fees: 21840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51827,
      package_name: "FISH Prenatal Panel (131821X&Y) (L)",
      lab_name: "Max Lab",
      fees: 10610,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 52723,
      package_name: "FISH-Prader Willi Syndrome (L)",
      lab_name: "Max Lab",
      fees: 6400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34747,
      package_name: "Flow Cytometry Surface Igm-(O)",
      lab_name: "Max Lab",
      fees: 2030,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26604,
      package_name: "Flow Cytometry- Cd1a (O)",
      lab_name: "Max Lab",
      fees: 2030,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26617,
      package_name: "Flow Cytometry- Cd59 Bone Marrow (O)",
      lab_name: "Max Lab",
      fees: 2130,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26618,
      package_name: "Flow Cytometry- Cd61(O)",
      lab_name: "Max Lab",
      fees: 2030,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26619,
      package_name: "Flow Cytometry- Cd62 (O)",
      lab_name: "Max Lab",
      fees: 2030,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26621,
      package_name: "Flow Cytometry- Cd71 (O)",
      lab_name: "Max Lab",
      fees: 2030,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34743,
      package_name: "Flt 3 Gene Mutation-(C)",
      lab_name: "Max Lab",
      fees: 5460,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46391,
      package_name: "Food Intolerance Test (L)",
      lab_name: "Max Lab",
      fees: 13000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45074,
      package_name: "Foundation One (O)",
      lab_name: "Max Lab",
      fees: 270400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44641,
      package_name: "Foundation One Heme (O)",
      lab_name: "Max Lab",
      fees: 409500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50681,
      package_name: "Fractional Excretion of Sodium (L)",
      lab_name: "Max Lab",
      fees: 750,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47098,
      package_name: "Fragile X (FMR1) Mutation Screen (L)",
      lab_name: "Max Lab",
      fees: 8280,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35416,
      package_name: "Fragile X Chromosome Analysis–(DH)",
      lab_name: "Max Lab",
      fees: 12240,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51126,
      package_name: "Free Plasma Metanephrine (L)",
      lab_name: "Max Lab",
      fees: 6000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47246,
      package_name: "Friedreichs Ataxia GAA (L)",
      lab_name: "Max Lab",
      fees: 390,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49381,
      package_name: "FTA - ABS (L)",
      lab_name: "Max Lab",
      fees: 2000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25593,
      package_name: "GAD65 IgG (L)",
      lab_name: "Max Lab",
      fees: 6200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25591,
      package_name: "GalactosePlasma (L)",
      lab_name: "Max Lab",
      fees: 1600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25590,
      package_name: "Galactose-1-PhosphateQuantitativeblood (L)",
      lab_name: "Max Lab",
      fees: 1700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28453,
      package_name: "Galactosemia Panel 1 (L)",
      lab_name: "Max Lab",
      fees: 2450,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 36224,
      package_name: "Galactosemia Panel 2 (L)",
      lab_name: "Max Lab",
      fees: 3350,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28454,
      package_name: "Galactosemia Screening (L)",
      lab_name: "Max Lab",
      fees: 700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19639,
      package_name: "Gall Stone Analysis (L)",
      lab_name: "Max Lab",
      fees: 1050,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 40356,
      package_name: "Ganglioside IgG Antibody (M)",
      lab_name: "Max Lab",
      fees: 3610,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 40355,
      package_name: "Ganglioside IgM Antibody (M)",
      lab_name: "Max Lab",
      fees: 3610,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6443,
      package_name: "Gastrin (L)",
      lab_name: "Max Lab",
      fees: 1200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46883,
      package_name: "Gene Core Hot Spot (C)",
      lab_name: "Max Lab",
      fees: 32760,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44488,
      package_name: "Gene Paradigm (NSG+Tumor Specific IHC)(C)",
      lab_name: "Max Lab",
      fees: 283920,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47717,
      package_name: "Gene Predict (C)",
      lab_name: "Max Lab",
      fees: 21840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 52559,
      package_name: "Genecore Oncomine 52 Gene Panel (C)",
      lab_name: "Max Lab",
      fees: 41200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50474,
      package_name: "Genotyping TPMT (O)",
      lab_name: "Max Lab",
      fees: 6880,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 38115,
      package_name: "Germline -3gene Breast-ovarian Cancer Test (ST)",
      lab_name: "Max Lab",
      fees: 18580,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37594,
      package_name: "Germline Cancer Test (ST)",
      lab_name: "Max Lab",
      fees: 24580,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46516,
      package_name: "GHRH(Growth Hormone Releasing) (L)",
      lab_name: "Max Lab",
      fees: 22000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50125,
      package_name: "Glioma Panel(1p/19Q MGMT) (SR)",
      lab_name: "Max Lab",
      fees: 20000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49375,
      package_name: "Glycine Quantitative 24 Hrs. Urine (M)",
      lab_name: "Max Lab",
      fees: 8200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49373,
      package_name: "Glycine Quantitative CSF (M)",
      lab_name: "Max Lab",
      fees: 8200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49374,
      package_name: "Glycine Quantitative EDTA (M)",
      lab_name: "Max Lab",
      fees: 8200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34745,
      package_name: "GM1 Gangliosidosis Quantative-(L)",
      lab_name: "Max Lab",
      fees: 1700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34746,
      package_name: "GM2Quantitative-(L)",
      lab_name: "Max Lab",
      fees: 2400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19619,
      package_name: "Gonoccocus - Culture (L)",
      lab_name: "Max Lab",
      fees: 1050,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44352,
      package_name: "GQ1B Antibody IgG (L)",
      lab_name: "Max Lab",
      fees: 10590,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45755,
      package_name: "H. Pylori IgA  (M)",
      lab_name: "Max Lab",
      fees: 2030,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45756,
      package_name: "H.pylori IgG  (M)",
      lab_name: "Max Lab",
      fees: 2030,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45757,
      package_name: "H.pylori  IgM (M)",
      lab_name: "Max Lab",
      fees: 2030,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50379,
      package_name: "Haemochromatosis 2 Mutation HFE (M)",
      lab_name: "Max Lab",
      fees: 6760,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 40680,
      package_name: "Haemoglobin Free Citrate Plasma (M)",
      lab_name: "Max Lab",
      fees: 1100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26464,
      package_name: "Haemoglobin Free Urine (L)",
      lab_name: "Max Lab",
      fees: 210,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26302,
      package_name: "Haemosiderin-Urine (L)",
      lab_name: "Max Lab",
      fees: 390,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25594,
      package_name: "Ham/pnh Screeningtest (L)",
      lab_name: "Max Lab",
      fees: 1320,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44375,
      package_name: "Hanta Virus (SR)",
      lab_name: "Max Lab",
      fees: 2200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6592,
      package_name: "HDV Antibody (Hepatitis D Antibody) (L)",
      lab_name: "Max Lab",
      fees: 2550,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37328,
      package_name: "HE4 (Epithelial Ovarian Cancer Marker) (M)",
      lab_name: "Max Lab",
      fees: 2730,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51744,
      package_name: "Heavy Metal Profile-1 (M)",
      lab_name: "Max Lab",
      fees: 10400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 53057,
      package_name: "Heparin Induce Thrombocytopenia/Anti PF4 IgG (L)",
      lab_name: "Max Lab",
      fees: 11900,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49278,
      package_name: "Hepatitis A PCR (HAV) (M)",
      lab_name: "Max Lab",
      fees: 5140,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39261,
      package_name: "Hepatitis B Genotyping (L)",
      lab_name: "Max Lab",
      fees: 10920,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46438,
      package_name: "Hepatitis E PCR (L)",
      lab_name: "Max Lab",
      fees: 4920,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50516,
      package_name: "Hepcidin (M)",
      lab_name: "Max Lab",
      fees: 1560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 52743,
      package_name: "Hereditary Cancer Panel 32 Genes (SR)",
      lab_name: "Max Lab",
      fees: 35000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 41394,
      package_name: "Heridatory Cancer Gene (MG)",
      lab_name: "Max Lab",
      fees: 21840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 36822,
      package_name: "High Resolution-HLA A B C DR DQ Typing (C)",
      lab_name: "Max Lab",
      fees: 15000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 36821,
      package_name: "High Resolution-HLA A B DR Typing (C)",
      lab_name: "Max Lab",
      fees: 15840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6329,
      package_name: "Histone Antibodies (L)",
      lab_name: "Max Lab",
      fees: 1350,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44017,
      package_name: "Histopathology Kidney Panel -3 (L)",
      lab_name: "Max Lab",
      fees: 10080,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44607,
      package_name: "Histoplasma Antibody Immunodiffusion (L)",
      lab_name: "Max Lab",
      fees: 15840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44617,
      package_name: "Hiv 1 Genotype & Drug Resistance (L)",
      lab_name: "Max Lab",
      fees: 21000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47247,
      package_name: "HIV P24 Antigen (Screening) (M)",
      lab_name: "Max Lab",
      fees: 990,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 43559,
      package_name: "HLA -DR4 (DRB1*04) (L)",
      lab_name: "Max Lab",
      fees: 4300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46421,
      package_name: "HLA ABC Typing (L)",
      lab_name: "Max Lab",
      fees: 9200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44115,
      package_name: "HLA B5701 (M)",
      lab_name: "Max Lab",
      fees: 3280,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44573,
      package_name: "HLA Celiac disease (DQB1*021*03) DQA1*051*03 (L)",
      lab_name: "Max Lab",
      fees: 5700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46340,
      package_name: "HLA Class 1 IgG Quantitative (L)",
      lab_name: "Max Lab",
      fees: 10920,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46341,
      package_name: "HLA Class 2 IgG Quantitative (L)",
      lab_name: "Max Lab",
      fees: 10920,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 32088,
      package_name: "HLA DNA Typing (ABCDR & DQ) Low Resolution (L)",
      lab_name: "Max Lab",
      fees: 22710,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48223,
      package_name: "HLA DNA Typing High Resolution NGS(L)",
      lab_name: "Max Lab",
      fees: 14200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45875,
      package_name: "HLA DQ DNA Typing (L)",
      lab_name: "Max Lab",
      fees: 5410,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47656,
      package_name: "HLA Typing High Res (HLA ABCDRB1DQB1)(C)",
      lab_name: "Max Lab",
      fees: 16000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39571,
      package_name: "HLA-AB  (Class I) Typing (L)",
      lab_name: "Max Lab",
      fees: 7400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39474,
      package_name: "HLA-DR & DQB1 (Class II) Typing (L)",
      lab_name: "Max Lab",
      fees: 8850,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39473,
      package_name: "HLA-DR (Class II) Typing (L)",
      lab_name: "Max Lab",
      fees: 7400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 36731,
      package_name: "HLA-DSA(Donor Specific IgG Antibod)Class I&II(L)",
      lab_name: "Max Lab",
      fees: 10920,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50366,
      package_name: "HLH Gene Panel (MG)",
      lab_name: "Max Lab",
      fees: 24960,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45230,
      package_name: "Holo Vitamin B12 (L)",
      lab_name: "Max Lab",
      fees: 1750,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46862,
      package_name: "Homa-IR Insulin Resistance Index (L)",
      lab_name: "Max Lab",
      fees: 920,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28322,
      package_name: "Homovanillic Acid  24Hours Urine (L)",
      lab_name: "Max Lab",
      fees: 4000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28323,
      package_name: "Homovanillic Acid  Random Urine (L)",
      lab_name: "Max Lab",
      fees: 4000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47693,
      package_name: "HSV Type 1 IgG & IgM Panel (L)",
      lab_name: "Max Lab",
      fees: 1300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47692,
      package_name: "HSV Type 1 IgG (L)",
      lab_name: "Max Lab",
      fees: 700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47691,
      package_name: "HSV Type 1 IgM (L)",
      lab_name: "Max Lab",
      fees: 700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47664,
      package_name: "HSV Type 2 IgG & IgM Panel (L)",
      lab_name: "Max Lab",
      fees: 1300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47663,
      package_name: "HSV Type 2 IgG (L)",
      lab_name: "Max Lab",
      fees: 700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47455,
      package_name: "HSV Type 2 IGM (L)",
      lab_name: "Max Lab",
      fees: 700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47549,
      package_name: "HSV Type 2 Qualitative PCR (L)",
      lab_name: "Max Lab",
      fees: 3700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6402,
      package_name: "HTLV - I/II Antibody (SR)",
      lab_name: "Max Lab",
      fees: 5200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49277,
      package_name: "Huntington Mutation PCR (L)",
      lab_name: "Max Lab",
      fees: 3000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34957,
      package_name: "IA-2 Antigen Serum (L)",
      lab_name: "Max Lab",
      fees: 5000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 36189,
      package_name: "IDH - Mutation Analysis (L)",
      lab_name: "Max Lab",
      fees: 7660,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50332,
      package_name: "Iduronate 2 Sulphate Sulphatase (M)",
      lab_name: "Max Lab",
      fees: 7280,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28326,
      package_name: "IgF Binding Protein-2 IGFBP-2 (L)",
      lab_name: "Max Lab",
      fees: 8800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28327,
      package_name: "IgF Binding Protein-3 IGFBP-3 (L)",
      lab_name: "Max Lab",
      fees: 4050,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45160,
      package_name: "IgG Synthesis Index (L)",
      lab_name: "Max Lab",
      fees: 2450,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 42737,
      package_name: "IgG Tissue Transglutaminase (L)",
      lab_name: "Max Lab",
      fees: 1000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37486,
      package_name: "IgG4 (immunoglobin GSubclass 4) (M)",
      lab_name: "Max Lab",
      fees: 5040,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19647,
      package_name: "IgM-Anti HCV (M)",
      lab_name: "Max Lab",
      fees: 1590,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45199,
      package_name: "IGVH Gene Mutation (C)",
      lab_name: "Max Lab",
      fees: 10450,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49892,
      package_name: "IgVH Mutation Detection (L)",
      lab_name: "Max Lab",
      fees: 7500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 36187,
      package_name: "IHC-Neuro DDX-(C )",
      lab_name: "Max Lab",
      fees: 11140,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27141,
      package_name: "Imd Panel - (L)",
      lab_name: "Max Lab",
      fees: 10000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47739,
      package_name: "Immature Platelet Fraction (L)",
      lab_name: "Max Lab",
      fees: 600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5859,
      package_name: "Immunoglobulin (IgGIgMIgA)CSF (L)",
      lab_name: "Max Lab",
      fees: 13160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28316,
      package_name: "Influenza Virus A (igg)elisa (SR)",
      lab_name: "Max Lab",
      fees: 2100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28317,
      package_name: "Influenza Virus A (igm)elisa (SR)",
      lab_name: "Max Lab",
      fees: 2100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28318,
      package_name: "Influenza Virus B (igg)elisa (SR)",
      lab_name: "Max Lab",
      fees: 2100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28319,
      package_name: "Influenza Virus B (IgM)elisa (SR)",
      lab_name: "Max Lab",
      fees: 2100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27357,
      package_name: "Inhibin-B (L)",
      lab_name: "Max Lab",
      fees: 1900,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47992,
      package_name: "Insigh T (NIPT-10) (Lilac)",
      lab_name: "Max Lab",
      fees: 26000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26407,
      package_name: "Interlukin-2 Receptor (L)",
      lab_name: "Max Lab",
      fees: 22700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35418,
      package_name: "Intrinsic Factor Antibody -(M)",
      lab_name: "Max Lab",
      fees: 2520,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37907,
      package_name: "Inv 16 Quantitative RT PCR (C)",
      lab_name: "Max Lab",
      fees: 5460,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34966,
      package_name: "Inv 16-(L)",
      lab_name: "Max Lab",
      fees: 4590,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 43597,
      package_name: "Iodine Serum (SR)",
      lab_name: "Max Lab",
      fees: 9800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 32769,
      package_name: "Irma  Imatinib Resistance Mutation Analysis-(O)",
      lab_name: "Max Lab",
      fees: 9940,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49923,
      package_name: "IRMA PCR (L)",
      lab_name: "Max Lab",
      fees: 8520,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51787,
      package_name: "Iron-refractory-IDA gene panel (MG)",
      lab_name: "Max Lab",
      fees: 24620,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 20446,
      package_name: "ISLET - Cells (IF) Indilution (L)",
      lab_name: "Max Lab",
      fees: 3610,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 20445,
      package_name: "Islet Cells Antibody (M)",
      lab_name: "Max Lab",
      fees: 2300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37902,
      package_name: "Jak 2 Exon 12 Mutation Analysis (C)",
      lab_name: "Max Lab",
      fees: 5000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37903,
      package_name: "Jak 2 Exon 12-15 Mutation Analysis (C)",
      lab_name: "Max Lab",
      fees: 7500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48207,
      package_name: "JAK 2  EXON 14 to 12 (Reflex Testing) (C)",
      lab_name: "Max Lab",
      fees: 6500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26329,
      package_name: "Japnese Encephalitis Virus PCR (M)",
      lab_name: "Max Lab",
      fees: 4320,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47079,
      package_name: "JC virus DNA detection CSF (M)",
      lab_name: "Max Lab",
      fees: 4370,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49555,
      package_name: "JC virus DNA detection Plasma (M)",
      lab_name: "Max Lab",
      fees: 4160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49556,
      package_name: "JC virus DNA detection Urine (M)",
      lab_name: "Max Lab",
      fees: 3610,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49439,
      package_name: "Karyotyping & FISH POC (M)",
      lab_name: "Max Lab",
      fees: 9300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6520,
      package_name: "Karyotyping - Products Of Conception (L)",
      lab_name: "Max Lab",
      fees: 6680,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33853,
      package_name: "Karyotyping Amniotic Fluid -(Lilac)",
      lab_name: "Max Lab",
      fees: 7100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33854,
      package_name: "Karyotyping CVS -(Lilac)",
      lab_name: "Max Lab",
      fees: 7100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6095,
      package_name: "Kidney Stone Analysis(L)",
      lab_name: "Max Lab",
      fees: 1210,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50895,
      package_name: "Kinship Analysis(SR)",
      lab_name: "Max Lab",
      fees: 26000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49291,
      package_name: "KRAS Mutation Analysis (L)",
      lab_name: "Max Lab",
      fees: 8020,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 53058,
      package_name: "KT+QFPCR  (LC)",
      lab_name: "Max Lab",
      fees: 7000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 52781,
      package_name: "Lamotrigine Level C3 (M)",
      lab_name: "Max Lab",
      fees: 4100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 20402,
      package_name: "Lap Score (M)",
      lab_name: "Max Lab",
      fees: 1210,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46437,
      package_name: "LC 1 Antibody Liver Cytosolic Antigen (M)",
      lab_name: "Max Lab",
      fees: 3060,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6549,
      package_name: "Lead Level Blood (L)",
      lab_name: "Max Lab",
      fees: 1800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6550,
      package_name: "Lead Level Random Urine (L)",
      lab_name: "Max Lab",
      fees: 1800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37331,
      package_name: "Legionella Pneumophila IgG Antibodies Serum (M)",
      lab_name: "Max Lab",
      fees: 2730,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37332,
      package_name: "Legionella Pneumophila IgM Antibodies Serum (M)",
      lab_name: "Max Lab",
      fees: 2730,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39616,
      package_name: "Leiden Mutation Factor V (L)",
      lab_name: "Max Lab",
      fees: 5500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25610,
      package_name: "Leishmania Antibody IgGKala Azar Antibody (L)",
      lab_name: "Max Lab",
      fees: 1600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46972,
      package_name: "Leptin (L)",
      lab_name: "Max Lab",
      fees: 3900,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26417,
      package_name: "Levetiracetam Serum (M)",
      lab_name: "Max Lab",
      fees: 5460,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47718,
      package_name: "Liqui core detect (C)",
      lab_name: "Max Lab",
      fees: 136500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50113,
      package_name: "Liqui Core G360 (C)",
      lab_name: "Max Lab",
      fees: 327600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48157,
      package_name: "Liquid Biopsy for 56 genes (MG)",
      lab_name: "Max Lab",
      fees: 34960,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46462,
      package_name: "Liquid Bx Cell Free EGFR (O)",
      lab_name: "Max Lab",
      fees: 13760,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44837,
      package_name: "Liquiegfr (Cell Free DNA) (C)",
      lab_name: "Max Lab",
      fees: 17480,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5924,
      package_name: "Lithium (L)",
      lab_name: "Max Lab",
      fees: 300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46831,
      package_name: "Liver biopsy with IHC (SR)",
      lab_name: "Max Lab",
      fees: 4920,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51590,
      package_name: "Lp-PLA2 Activity (L)",
      lab_name: "Max Lab",
      fees: 2180,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24172,
      package_name: "Lyme Disease Antibodies (L)",
      lab_name: "Max Lab",
      fees: 3300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 41768,
      package_name: "Lyme Disease Immunoblot (L)",
      lab_name: "Max Lab",
      fees: 13000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50109,
      package_name: "Lynch Syndrome/NHPCC Gene Panel (MG)",
      lab_name: "Max Lab",
      fees: 7220,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51784,
      package_name: "MAG Antibodies (M)",
      lab_name: "Max Lab",
      fees: 4120,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37514,
      package_name: "Mamma Print (C)",
      lab_name: "Max Lab",
      fees: 190000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51549,
      package_name: "Manganese Urine (L)",
      lab_name: "Max Lab",
      fees: 1900,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 43593,
      package_name: "Manganese Blood (L)",
      lab_name: "Max Lab",
      fees: 1900,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49827,
      package_name: "MBFD With Microdeletions (L)",
      lab_name: "Max Lab",
      fees: 28000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48518,
      package_name: "MELAS Mutation Detection (L)",
      lab_name: "Max Lab",
      fees: 990,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51125,
      package_name: "Mercury (M)",
      lab_name: "Max Lab",
      fees: 3120,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26412,
      package_name: "Mercury 24 Hrs Urine (L)",
      lab_name: "Max Lab",
      fees: 3240,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26413,
      package_name: "Mercury Urine Spot (L)",
      lab_name: "Max Lab",
      fees: 3240,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35212,
      package_name: "Met - Fish  (C)",
      lab_name: "Max Lab",
      fees: 17040,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 23130,
      package_name: "Metachromatic Leucodystrophy (Quantitative) (L)",
      lab_name: "Max Lab",
      fees: 1900,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 32081,
      package_name: "Metanephrine Plasma (M)",
      lab_name: "Max Lab",
      fees: 4370,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6349,
      package_name: "Metanephrine24hrs Urine (L)",
      lab_name: "Max Lab",
      fees: 6000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 43407,
      package_name: "Metanephrines Random Urine (L)",
      lab_name: "Max Lab",
      fees: 6000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50574,
      package_name: "Methaqualone Screen Urine (L)",
      lab_name: "Max Lab",
      fees: 700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28156,
      package_name: "Methhaemoglobin Estimation (M)",
      lab_name: "Max Lab",
      fees: 1040,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27355,
      package_name: "Methyletra Hydro Folate Reductase Enzyme-(SR)",
      lab_name: "Max Lab",
      fees: 10060,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50753,
      package_name: "Methylmalonic Acid Quantitative (L)",
      lab_name: "Max Lab",
      fees: 2000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 36188,
      package_name: "MGMT - PCR (C)",
      lab_name: "Max Lab",
      fees: 7000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49651,
      package_name: "Microarray 315K (LC)",
      lab_name: "Max Lab",
      fees: 5840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50680,
      package_name: "Microarray 750K (LC)",
      lab_name: "Max Lab",
      fees: 21840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33866,
      package_name: "Miller-Dieker Syndrome(Lilac)",
      lab_name: "Max Lab",
      fees: 6240,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51511,
      package_name: "Mitochondrial Mutation Comprehensive Panel (L)",
      lab_name: "Max Lab",
      fees: 24000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50678,
      package_name: "Mitochondrial Mutation Panel-1 (M)",
      lab_name: "Max Lab",
      fees: 20800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50679,
      package_name: "Mitochondrial Mutation Panel-2 (M)",
      lab_name: "Max Lab",
      fees: 20800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 41376,
      package_name: "Mitrochondrial Genome (MG)",
      lab_name: "Max Lab",
      fees: 19120,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 32758,
      package_name: "Mll Gene  Fish-(C)",
      lab_name: "Max Lab",
      fees: 5460,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37904,
      package_name: "Mpl W 515 Mutation Analysis (C)",
      lab_name: "Max Lab",
      fees: 7660,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44354,
      package_name: "MPN Reflex Panel (SR)",
      lab_name: "Max Lab",
      fees: 15700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34570,
      package_name: "MPS (Mucoploysaccharidosis-(L)",
      lab_name: "Max Lab",
      fees: 390,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33043,
      package_name: "MRD Panel AML (O)",
      lab_name: "Max Lab",
      fees: 13540,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47770,
      package_name: "MSI PCR (L)",
      lab_name: "Max Lab",
      fees: 10000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47434,
      package_name: "MSI PCR (SR)",
      lab_name: "Max Lab",
      fees: 6460,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 42516,
      package_name: "MTHFR Gene Mutation PCR (L)",
      lab_name: "Max Lab",
      fees: 3200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37329,
      package_name: "Mucopoly Saccharidosis (MPS) Type -1(L)",
      lab_name: "Max Lab",
      fees: 4700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 32077,
      package_name: "Musk Antibody Serum (L)",
      lab_name: "Max Lab",
      fees: 5500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35220,
      package_name: "Mycc - Fish  (C)",
      lab_name: "Max Lab",
      fees: 9840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26396,
      package_name: "Mycoplasma Culture (SR)",
      lab_name: "Max Lab",
      fees: 1370,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26433,
      package_name: "Mycoplasma Pneumoniea Antibody Panel(IgG+IgM) (L)",
      lab_name: "Max Lab",
      fees: 3600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26338,
      package_name: "Mycoplasma Pneumoniea IgG Antibody (L)",
      lab_name: "Max Lab",
      fees: 2000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26339,
      package_name: "Mycoplasma Pneumoniea IgM Antibody (L)",
      lab_name: "Max Lab",
      fees: 2000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21691,
      package_name: "Myelin Basic Protein CSF (L)",
      lab_name: "Max Lab",
      fees: 4550,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 52797,
      package_name: "Myeloid NGS-DNA Studies (Lilac)",
      lab_name: "Max Lab",
      fees: 15000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49529,
      package_name: "Myeloproliferative Neoplasia Profile(MPN) (L)",
      lab_name: "Max Lab",
      fees: 12000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6497,
      package_name: "Myoglobin-(M)",
      lab_name: "Max Lab",
      fees: 2300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50782,
      package_name: "Myotonic Dystrophy (1&2) Profile (L)",
      lab_name: "Max Lab",
      fees: 8000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37336,
      package_name: "N Telopeptide Urine (L)",
      lab_name: "Max Lab",
      fees: 8200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35049,
      package_name: "N-myc Gene Amplificationpcr-(O)",
      lab_name: "Max Lab",
      fees: 10920,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 40776,
      package_name: "Natural Killer Cells-poc (DH)",
      lab_name: "Max Lab",
      fees: 4920,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 36758,
      package_name: "Neisseria Meningitis Abcy & W 135 Antigen Detec",
      lab_name: "Max Lab",
      fees: 3160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27143,
      package_name: "New Born Extended Screening (L)",
      lab_name: "Max Lab",
      fees: 5000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46463,
      package_name: "NGS Cancer Hot Spot (O)",
      lab_name: "Max Lab",
      fees: 36600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46464,
      package_name: "NGS Lung Plus Panel (O)",
      lab_name: "Max Lab",
      fees: 43680,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50353,
      package_name: "Nickel  Blood (M)",
      lab_name: "Max Lab",
      fees: 2600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39535,
      package_name: "Nickel Random Urine (L)",
      lab_name: "Max Lab",
      fees: 3100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37439,
      package_name: "Nicotine Metabolite Quantitative Blood (M)",
      lab_name: "Max Lab",
      fees: 1420,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37440,
      package_name: "Nicotine Metabolite Quantitative Urine (M)",
      lab_name: "Max Lab",
      fees: 1420,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 52953,
      package_name: "NIPT (Ion Torrent)(LC)",
      lab_name: "Max Lab",
      fees: 15000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37369,
      package_name: "NIPT (Maternal Blood For Fetal DNA–MBFD)(L)",
      lab_name: "Max Lab",
      fees: 15000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50290,
      package_name: "Non Invasive Pre-natal (NIPT) (LC)",
      lab_name: "Max Lab",
      fees: 26220,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 38086,
      package_name: "Nor–Metanephrine Urine-(M)",
      lab_name: "Max Lab",
      fees: 2190,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35417,
      package_name: "Nor–metanephrine Free Plasma- (M)",
      lab_name: "Max Lab",
      fees: 4370,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47707,
      package_name: "Notch-3 Gene Sequencing Cadasil 5 Blood (M)",
      lab_name: "Max Lab",
      fees: 24040,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34744,
      package_name: "Npm 1 Gene Mutation-(C)",
      lab_name: "Max Lab",
      fees: 5000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37515,
      package_name: "NRAS Mutation Analysis (SR)",
      lab_name: "Max Lab",
      fees: 6760,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6112,
      package_name: "NSE (Neurone Specific Enolase)(L)",
      lab_name: "Max Lab",
      fees: 3200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49466,
      package_name: "Nx Clinical Exome - Thermofisher (L)",
      lab_name: "Max Lab",
      fees: 21840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50560,
      package_name: "Nx Gen Clinical Exome Reflex Confrm & Parents (L)",
      lab_name: "Max Lab",
      fees: 31200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49950,
      package_name: "Nx GEN Whole Exome Sequencing - Thermofisher (L)",
      lab_name: "Max Lab",
      fees: 30000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50559,
      package_name: "Oncopro Focus Fusion Panel (L)",
      lab_name: "Max Lab",
      fees: 35000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48069,
      package_name: "Oncopro Hereditary Cancer Risk (98 gene) (L)",
      lab_name: "Max Lab",
      fees: 27300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50370,
      package_name: "OncoPro Pan Cancer (L)",
      lab_name: "Max Lab",
      fees: 25000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45799,
      package_name: "Oncotype Dx Brest Cancer (ML)",
      lab_name: "Max Lab",
      fees: 339620,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45801,
      package_name: "Oncotype Dx Colon Cancer(ML)",
      lab_name: "Max Lab",
      fees: 267540,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45800,
      package_name: "Oncotype Dx DCIS  (ML)",
      lab_name: "Max Lab",
      fees: 267540,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45802,
      package_name: "Oncotype Dx Prostate Cancer(ML)",
      lab_name: "Max Lab",
      fees: 267540,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 32080,
      package_name: "Organic Acid Urine (SR)",
      lab_name: "Max Lab",
      fees: 6600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28391,
      package_name: "Orotic Acid (L)",
      lab_name: "Max Lab",
      fees: 3500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 31492,
      package_name: "Osmotic Fragility  Heparin (L)",
      lab_name: "Max Lab",
      fees: 490,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19665,
      package_name: "OsteocalcinSerum (Bone GLA ProteinBGP) (L)",
      lab_name: "Max Lab",
      fees: 2730,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44899,
      package_name: "Osteogenesis Imperfecta (MG)",
      lab_name: "Max Lab",
      fees: 23220,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29752,
      package_name: "Osteomon Serum(SR)",
      lab_name: "Max Lab",
      fees: 3500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47775,
      package_name: "Oxalate Random Urine (L)",
      lab_name: "Max Lab",
      fees: 1700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21490,
      package_name: "Oxalate24hrs Urine (L)",
      lab_name: "Max Lab",
      fees: 1700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35207,
      package_name: "P13k Pathway - Fish (C)",
      lab_name: "Max Lab",
      fees: 7660,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51086,
      package_name: "P1NP Total (L)",
      lab_name: "Max Lab",
      fees: 1570,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50476,
      package_name: "Pan Neurotropic Virus Panel (SR)",
      lab_name: "Max Lab",
      fees: 7500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46515,
      package_name: "Pancreatic Polypeptide (L)",
      lab_name: "Max Lab",
      fees: 10000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45326,
      package_name: "Panfungal DNA PCR (M)",
      lab_name: "Max Lab",
      fees: 3830,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 36761,
      package_name: "Panfungus DNA Detection PCR CSF ( SR)",
      lab_name: "Max Lab",
      fees: 3830,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26341,
      package_name: "Paracetamol (SR)",
      lab_name: "Max Lab",
      fees: 1200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 43776,
      package_name: "Paradigm (C)",
      lab_name: "Max Lab",
      fees: 163800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26445,
      package_name: "Parvovirus B-19 Antibodies Panel (L)",
      lab_name: "Max Lab",
      fees: 4550,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26446,
      package_name: "Parvovirus B-19IgG (L)",
      lab_name: "Max Lab",
      fees: 2450,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26447,
      package_name: "Parvovirus B-19IgM (L)",
      lab_name: "Max Lab",
      fees: 2450,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37941,
      package_name: "PCP By Immunoflourescence (M)",
      lab_name: "Max Lab",
      fees: 720,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 53043,
      package_name: "PD-L1 (SP142) Ventana for TNBC (L)",
      lab_name: "Max Lab",
      fees: 5000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45120,
      package_name: "PDGFRA Mutation Paraffin Block (C)",
      lab_name: "Max Lab",
      fees: 7660,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51545,
      package_name: "PDL-1 Dako (SR)",
      lab_name: "Max Lab",
      fees: 9460,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49467,
      package_name: "Penta Marker (LC)",
      lab_name: "Max Lab",
      fees: 4370,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28456,
      package_name: "Pheochromocytoma 24Hrs Urine (L)",
      lab_name: "Max Lab",
      fees: 10000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 52668,
      package_name: "PIIINP (Procollagen Type III) (L)",
      lab_name: "Max Lab",
      fees: 4000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 41341,
      package_name: "PLA2 Receptor Antibody (M)",
      lab_name: "Max Lab",
      fees: 4920,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50826,
      package_name: "Plasma Oxalate(O)",
      lab_name: "Max Lab",
      fees: 12740,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50289,
      package_name: "Plasma SOMATOSTATIN (L)",
      lab_name: "Max Lab",
      fees: 12100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48583,
      package_name: "Platelet Antibodies Qualitative Test (M)",
      lab_name: "Max Lab",
      fees: 6560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 32756,
      package_name: "PML/ RaRa (t15:17) FISH-(C)",
      lab_name: "Max Lab",
      fees: 5000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 32764,
      package_name: "PML/ RaRa (t15:17) Qualitative PCR-(C)",
      lab_name: "Max Lab",
      fees: 6500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 32765,
      package_name: "PML/ RaRa t (15:17) Quantitative  PCR-(C)",
      lab_name: "Max Lab",
      fees: 6500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47681,
      package_name: "POC Karyotyping (DH)",
      lab_name: "Max Lab",
      fees: 6340,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49367,
      package_name: "Polarizing Microscopy Body Fluids (L)",
      lab_name: "Max Lab",
      fees: 300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5900,
      package_name: "Porphobilinogen Qualitative-Urine (L)",
      lab_name: "Max Lab",
      fees: 3490,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25636,
      package_name: "Porphyrins-24hrs Urine (L)",
      lab_name: "Max Lab",
      fees: 4400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26487,
      package_name: "Porphyrins-Random Urine (L)",
      lab_name: "Max Lab",
      fees: 4400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 52230,
      package_name: "Positive Select Ultimate (PB)",
      lab_name: "Max Lab",
      fees: 150000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 36742,
      package_name: "PRA Screen (HLA Panel IgG Antibodies) (L)",
      lab_name: "Max Lab",
      fees: 6340,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 53078,
      package_name: "Prader-Willi  by MLPA  (MG)",
      lab_name: "Max Lab",
      fees: 16000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33860,
      package_name: "Prader-Willi Syndrome(Lilac)",
      lab_name: "Max Lab",
      fees: 6240,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39859,
      package_name: "PRA–CLASS Quantitative-1 (L)",
      lab_name: "Max Lab",
      fees: 10500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39860,
      package_name: "PRA–CLASS Quantitative-2 (L)",
      lab_name: "Max Lab",
      fees: 10500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46557,
      package_name: "Pre Albumin (M)",
      lab_name: "Max Lab",
      fees: 3280,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45174,
      package_name: "PRE-Eclampsia Panel (L)",
      lab_name: "Max Lab",
      fees: 3000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51509,
      package_name: "Pre-Implantation Genetic Screening (MG)",
      lab_name: "Max Lab",
      fees: 15600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50819,
      package_name: "Prenatal B-Thalassemia Parents & Fetus (Lilac)",
      lab_name: "Max Lab",
      fees: 22880,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46387,
      package_name: "Primary Immunodeficiency Gene Panel (MG)",
      lab_name: "Max Lab",
      fees: 26220,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33063,
      package_name: "Products of Conception FISH (DH)",
      lab_name: "Max Lab",
      fees: 9840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 40780,
      package_name: "Proinsulin (L) SERUM",
      lab_name: "Max Lab",
      fees: 10160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45925,
      package_name: "Propoxyphene Qualitative(M)",
      lab_name: "Max Lab",
      fees: 720,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39001,
      package_name: "Prosigna (C)",
      lab_name: "Max Lab",
      fees: 212940,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51087,
      package_name: "Prosigna (SY)",
      lab_name: "Max Lab",
      fees: 124800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44018,
      package_name: "Prothrombin Gene(Factor II) Gene Mutation (L)",
      lab_name: "Max Lab",
      fees: 3300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49532,
      package_name: "PTHrP (L)",
      lab_name: "Max Lab",
      fees: 10450,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51088,
      package_name: "Pulmo 5 Gene Panel (C)",
      lab_name: "Max Lab",
      fees: 23000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47719,
      package_name: "Pulmo comprehensive Panel (C)",
      lab_name: "Max Lab",
      fees: 80000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47721,
      package_name: "Pulmo comprehensive without PDL1 Blood (C)",
      lab_name: "Max Lab",
      fees: 65000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47720,
      package_name: "Pulmo comprehensive without PDL1 Tissue (C)",
      lab_name: "Max Lab",
      fees: 50000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35208,
      package_name: "Pulmo Ddx - Ihc (C)",
      lab_name: "Max Lab",
      fees: 6000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51089,
      package_name: "Pulmo Extended Panel PDL-1 (C)",
      lab_name: "Max Lab",
      fees: 25000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28653,
      package_name: "Pyruvate; Pyruvic Acid (L)",
      lab_name: "Max Lab",
      fees: 3250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50886,
      package_name: "Q Fever Ab IgG & IgM (L)",
      lab_name: "Max Lab",
      fees: 5600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48607,
      package_name: "Quantitative PLA2 Receptor (L)",
      lab_name: "Max Lab",
      fees: 4600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37597,
      package_name: "Rare Diseases Test (ST)",
      lab_name: "Max Lab",
      fees: 32760,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 36756,
      package_name: "RBC Folate (L)",
      lab_name: "Max Lab",
      fees: 2100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 41361,
      package_name: "Receptor Antibody TSH (M)",
      lab_name: "Max Lab",
      fees: 4480,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45006,
      package_name: "Ret Gene Mutation (M)",
      lab_name: "Max Lab",
      fees: 4120,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47557,
      package_name: "Retinal Degeneration Gene Panel (MG)",
      lab_name: "Max Lab",
      fees: 23220,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47245,
      package_name: "Rett Syndrome DNA Sequencing(MECP2) (SR)",
      lab_name: "Max Lab",
      fees: 6020,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51197,
      package_name: "ROMA (L)",
      lab_name: "Max Lab",
      fees: 3000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26330,
      package_name: "Rubella PCR (M)",
      lab_name: "Max Lab",
      fees: 3280,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47080,
      package_name: "Sarcoma Mutation Analysis Panel PCR (C)",
      lab_name: "Max Lab",
      fees: 20000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45753,
      package_name: "SCA -12(Spino Cerebellar Ataxia) PCR (L)",
      lab_name: "Max Lab",
      fees: 2500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45550,
      package_name: "SCA 12 Spinocerebellar Ataxia Type 12 CAG Repeats(M)",
      lab_name: "Max Lab",
      fees: 3170,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44285,
      package_name: "SCA comprehensive profile (L)",
      lab_name: "Max Lab",
      fees: 11000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44026,
      package_name: "SCA-1 (Spino Cerebellar Ataxia) PCR  (L)",
      lab_name: "Max Lab",
      fees: 2500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44027,
      package_name: "SCA-2 (Spino Cerebellar Ataxia) PCR (L)",
      lab_name: "Max Lab",
      fees: 2500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44028,
      package_name: "SCA-3 (Spino Cerebellar Ataxia)  PCR (L)",
      lab_name: "Max Lab",
      fees: 5500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44029,
      package_name: "SCA-6 (Spino Cerebellar Ataxia) PCR (L)",
      lab_name: "Max Lab",
      fees: 2500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44030,
      package_name: "SCA-7 (Spino Cerebellar Ataxia) PCR (L)",
      lab_name: "Max Lab",
      fees: 2500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51552,
      package_name: "Selenium Urine (L)",
      lab_name: "Max Lab",
      fees: 3100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 43594,
      package_name: "Selenium Serum (L)",
      lab_name: "Max Lab",
      fees: 3100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50354,
      package_name: "Sequencing Clinical Exome (LC)",
      lab_name: "Max Lab",
      fees: 26000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37850,
      package_name: "Serotonin Serum (M)",
      lab_name: "Max Lab",
      fees: 4590,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37851,
      package_name: "Serotonin Urine (M)",
      lab_name: "Max Lab",
      fees: 4590,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6594,
      package_name: "Sex Hormone Binding Globulin (SHBG) (L)",
      lab_name: "Max Lab",
      fees: 2350,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49675,
      package_name: "sFlt-PIGF ratio Pre - Eclampsia (L)",
      lab_name: "Max Lab",
      fees: 3000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51747,
      package_name: "Single Locus HLA Typing Intermediate reso (SR)",
      lab_name: "Max Lab",
      fees: 6180,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37599,
      package_name: "Single Mutation Confirmation Test (ST)",
      lab_name: "Max Lab",
      fees: 5460,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 52787,
      package_name: "Sirolimus (L)",
      lab_name: "Max Lab",
      fees: 4600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 31496,
      package_name: "Sirolimus Edta Blood (M)",
      lab_name: "Max Lab",
      fees: 5460,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33867,
      package_name: "Smith-Magenis Syndrome(Lilac)",
      lab_name: "Max Lab",
      fees: 6240,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48792,
      package_name: "SMN(1 & 2) Gene by MLPA (MG)",
      lab_name: "Max Lab",
      fees: 10400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 54839,
      package_name: "Somatic Genomic Testing 352 (O)",
      lab_name: "Max Lab",
      fees: 35000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50365,
      package_name: "Sphingomyelinase (M)",
      lab_name: "Max Lab",
      fees: 5720,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50315,
      package_name: "Spinal Muscular Atrophy Del/Dup (MG)",
      lab_name: "Max Lab",
      fees: 10400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47769,
      package_name: "Spinal Muscular Atropy Mutation (L)",
      lab_name: "Max Lab",
      fees: 3280,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49533,
      package_name: "STEROID PANEL 313 STEROIDS (L)",
      lab_name: "Max Lab",
      fees: 4370,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49963,
      package_name: "Strand Advantage 152 Gene Test (ST)",
      lab_name: "Max Lab",
      fees: 81900,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47904,
      package_name: "Strand Advantage 56 Gene Test (ST)",
      lab_name: "Max Lab",
      fees: 43680,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47906,
      package_name: "Strand LB-EGFR-Resist test (ST)",
      lab_name: "Max Lab",
      fees: 21840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47905,
      package_name: "Strand LB-EGFR-Sense test (ST)",
      lab_name: "Max Lab",
      fees: 21840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50275,
      package_name: "Strand MSI By PCR (ST)",
      lab_name: "Max Lab",
      fees: 10920,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39549,
      package_name: "Strandadvantage Breast Carcinoma Panel (ST)",
      lab_name: "Max Lab",
      fees: 18460,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39550,
      package_name: "Strandadvantage Colorectal Carcinoma Panel (ST)",
      lab_name: "Max Lab",
      fees: 20640,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39548,
      package_name: "StrandAdvantage Non-Small Cell Lung Carcinoma (ST)",
      lab_name: "Max Lab",
      fees: 23980,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49082,
      package_name: "Streptococcus Group B Antigen Detection (L)",
      lab_name: "Max Lab",
      fees: 880,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46362,
      package_name: "Succinylacetone (L)",
      lab_name: "Max Lab",
      fees: 3500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 32094,
      package_name: "Sudan IV (L)",
      lab_name: "Max Lab",
      fees: 550,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 43780,
      package_name: "Synovial Sarcoma T(x18) (C)",
      lab_name: "Max Lab",
      fees: 10000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49530,
      package_name: "T (12;22)(q13;q12) EWSR-ATF1 (C)",
      lab_name: "Max Lab",
      fees: 8000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34962,
      package_name: "T(12:21) PCR- (L)",
      lab_name: "Max Lab",
      fees: 3500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34965,
      package_name: "T(1:19) PCR- (L)",
      lab_name: "Max Lab",
      fees: 3500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34960,
      package_name: "T(4:11) PCR- (L)",
      lab_name: "Max Lab",
      fees: 3500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45333,
      package_name: "T(8:14) IGH/MYC Paraffin Block (C)",
      lab_name: "Max Lab",
      fees: 6000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45332,
      package_name: "T(8:14) IGH/MYC Sodium Heparin (C)",
      lab_name: "Max Lab",
      fees: 5000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34961,
      package_name: "T(9:11) PCR- (L)",
      lab_name: "Max Lab",
      fees: 3500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37559,
      package_name: "T-Cell RearrangementPCR (SR)",
      lab_name: "Max Lab",
      fees: 16000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50894,
      package_name: "TB LAMP Qualitative (L)",
      lab_name: "Max Lab",
      fees: 1500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50825,
      package_name: "TB PlatinumInterferon Gamma Release Assay(L)",
      lab_name: "Max Lab",
      fees: 2000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39617,
      package_name: "TB RRNA (L)",
      lab_name: "Max Lab",
      fees: 2500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33865,
      package_name: "TBX1(22q11.2) Blood/AF/CVS  -(Lilac)",
      lab_name: "Max Lab",
      fees: 6240,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47925,
      package_name: "Tert Carrier Gene (C)",
      lab_name: "Max Lab",
      fees: 21500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48067,
      package_name: "TERT full gene Sequencing (C)",
      lab_name: "Max Lab",
      fees: 84000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47666,
      package_name: "Tetanus Toxoid IgG (M)",
      lab_name: "Max Lab",
      fees: 4370,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26435,
      package_name: "Thallium Blood (L)",
      lab_name: "Max Lab",
      fees: 3100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51550,
      package_name: "Thallium Urine (L)",
      lab_name: "Max Lab",
      fees: 3100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6551,
      package_name: "Thrombin Time (TT) (M)",
      lab_name: "Max Lab",
      fees: 990,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19672,
      package_name: "Thyroxine Binding Globulin ; TBG (M)",
      lab_name: "Max Lab",
      fees: 5460,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50123,
      package_name: "Tissue CMV Qualitative PCR(L)",
      lab_name: "Max Lab",
      fees: 4260,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45314,
      package_name: "Tissue Of Origin Test (SY)",
      lab_name: "Max Lab",
      fees: 105040,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39614,
      package_name: "TNF Alpha (M)",
      lab_name: "Max Lab",
      fees: 4260,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 52956,
      package_name: "TORCH PCR (LC)",
      lab_name: "Max Lab",
      fees: 3500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28392,
      package_name: "Toxoplasma Avidity IgG (L)",
      lab_name: "Max Lab",
      fees: 580,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49035,
      package_name: "Toxoplasma DNA PCR (M)",
      lab_name: "Max Lab",
      fees: 5580,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33041,
      package_name: "TPMT Thiopurine Drug Toxicity EDTA - (C)",
      lab_name: "Max Lab",
      fees: 6000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 52824,
      package_name: "Trypanosoma Cruzi IgG (M)",
      lab_name: "Max Lab",
      fees: 2100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44355,
      package_name: "Tryptase (L)",
      lab_name: "Max Lab",
      fees: 6700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44606,
      package_name: "TSI (THYROID STIMULATING IMMUNOGLOBULIN)(L)",
      lab_name: "Max Lab",
      fees: 14720,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25632,
      package_name: "Tumor Necrosis Factor (TNF)Alpha (L)",
      lab_name: "Max Lab",
      fees: 3900,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46002,
      package_name: "UGT1A1 Gene Poly (L)",
      lab_name: "Max Lab",
      fees: 5500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50121,
      package_name: "UGTA1 Gene Mutation (SR)",
      lab_name: "Max Lab",
      fees: 13000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44493,
      package_name: "Unicore (C)",
      lab_name: "Max Lab",
      fees: 390000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48646,
      package_name: "Urea Cycle Disorder Panel (L)",
      lab_name: "Max Lab",
      fees: 7250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6288,
      package_name: "Urinary Free Cortisol 24 Hrs (L)",
      lab_name: "Max Lab",
      fees: 800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26258,
      package_name: "Urine for Dysmorphic RBC's (L)",
      lab_name: "Max Lab",
      fees: 320,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46990,
      package_name: "Urine Metaneprine 24 hrs (SR)",
      lab_name: "Max Lab",
      fees: 2500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 42736,
      package_name: "Urine Triglycerides (L)",
      lab_name: "Max Lab",
      fees: 320,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5886,
      package_name: "Urobilinogen (Qualitative)-24Hrs Urine",
      lab_name: "Max Lab",
      fees: 200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34074,
      package_name: "Varicella Zoster Virus PCR –(M)",
      lab_name: "Max Lab",
      fees: 3280,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50754,
      package_name: "Vitamin A (M)",
      lab_name: "Max Lab",
      fees: 4160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39260,
      package_name: "Vitamin B1 (M)",
      lab_name: "Max Lab",
      fees: 3610,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 52558,
      package_name: "Vitamin B2 (M)",
      lab_name: "Max Lab",
      fees: 3090,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50685,
      package_name: "Vitamin B6 (M)",
      lab_name: "Max Lab",
      fees: 3120,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48234,
      package_name: "Vitamin C (M)",
      lab_name: "Max Lab",
      fees: 3280,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50758,
      package_name: "Vitamin E (M)",
      lab_name: "Max Lab",
      fees: 4370,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49545,
      package_name: "Vitamin K1 Serum (M)",
      lab_name: "Max Lab",
      fees: 4370,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19673,
      package_name: "VMA 24hrs Urine (L)",
      lab_name: "Max Lab",
      fees: 3500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5870,
      package_name: "VMA Random Urine (L)",
      lab_name: "Max Lab",
      fees: 3500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50752,
      package_name: "Vw-Von Willebrand Antigen (M)",
      lab_name: "Max Lab",
      fees: 8840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47891,
      package_name: "VZV IgG CSF & Serum (M)",
      lab_name: "Max Lab",
      fees: 3280,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37340,
      package_name: "West Nile Virus Nat Serum (SR)",
      lab_name: "Max Lab",
      fees: 3500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6506,
      package_name: "Western Blot -HIV 1&2 Antibodies (L)",
      lab_name: "Max Lab",
      fees: 3350,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 38561,
      package_name: "Whole Exome Sequencing (MG)",
      lab_name: "Max Lab",
      fees: 88840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37598,
      package_name: "Whole Exome Sequencing Test At 70x Avera Covge (st",
      lab_name: "Max Lab",
      fees: 109200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 42505,
      package_name: "Whole Exome Sequencing-50 MB (80-100X)EDTA (MG)",
      lab_name: "Max Lab",
      fees: 42320,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33864,
      package_name: "Williams Syndrome Blood/AF/CVS -(Lilac)",
      lab_name: "Max Lab",
      fees: 6240,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33862,
      package_name: "Wolf-Hirschhorn syndrome Blood/AF/CVS -(Lilac)",
      lab_name: "Max Lab",
      fees: 6240,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 41360,
      package_name: "Y Chromosome Microdeletion (only Males) Blood (M)",
      lab_name: "Max Lab",
      fees: 7440,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28198,
      package_name: "Yersinia Culture (SR)",
      lab_name: "Max Lab",
      fees: 2150,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50757,
      package_name: "Yersinia Culture Stool (M)",
      lab_name: "Max Lab",
      fees: 1560,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 40284,
      package_name: "Zap-70 (M)",
      lab_name: "Max Lab",
      fees: 6020,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49893,
      package_name: "ZAP-70 Flow Cytometry (L)",
      lab_name: "Max Lab",
      fees: 2050,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19677,
      package_name: "Zinc (L)",
      lab_name: "Max Lab",
      fees: 1800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51551,
      package_name: "Zinc Urine (L)",
      lab_name: "Max Lab",
      fees: 1800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19676,
      package_name: "Zinc 24 Hour Urine (L)",
      lab_name: "Max Lab",
      fees: 1800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50837,
      package_name: "ABPA Panel",
      lab_name: "Max Lab",
      fees: 2950,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47498,
      package_name: "AMH PLUS Profile",
      lab_name: "Max Lab",
      fees: 5050,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35163,
      package_name: "ANCA Panel (ELISA + IF)",
      lab_name: "Max Lab",
      fees: 5770,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 30469,
      package_name: "Arthiritis Profile",
      lab_name: "Max Lab",
      fees: 3460,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51591,
      package_name: "Autoimmune Hepatitis Panel by IF",
      lab_name: "Max Lab",
      fees: 3610,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47614,
      package_name: "CHC Drug Profile",
      lab_name: "Max Lab",
      fees: 3860,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 9246,
      package_name: "Coagulation Profile",
      lab_name: "Max Lab",
      fees: 1450,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 32025,
      package_name: "Combined Test for FNAC and Biopsy",
      lab_name: "Max Lab",
      fees: 2010,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33269,
      package_name: "CSF Profile",
      lab_name: "Max Lab",
      fees: 1590,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47984,
      package_name: "Customised Synergy Cardio Total-1",
      lab_name: "Max Lab",
      fees: 3570,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47980,
      package_name: "Customised Synergy CARDIO-1",
      lab_name: "Max Lab",
      fees: 1575,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47981,
      package_name: "Customised Synergy CARDIO-2",
      lab_name: "Max Lab",
      fees: 2048,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47982,
      package_name: "Customised Synergy CARDIO-3",
      lab_name: "Max Lab",
      fees: 2520,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: "ABPA",
      package_name: "Customize ABPA Panel Total",
      lab_name: "Max Lab",
      fees: 3200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: "ABPA1",
      package_name: "Customize ABPA-1 Panel",
      lab_name: "Max Lab",
      fees: 2300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: "Vikas2",
      package_name: "Customized Panel- ILD Comprehensive",
      lab_name: "Max Lab",
      fees: 11500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: "ILD1",
      package_name: "Customized Panel- ILD Screening",
      lab_name: "Max Lab",
      fees: 6600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: "RDM",
      package_name: "Customized wellness D R Maternity Centre",
      lab_name: "Max Lab",
      fees: 1000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35464,
      package_name: "Dengue Panel",
      lab_name: "Max Lab",
      fees: 1300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44863,
      package_name: "Diabetes Screening Profile",
      lab_name: "Max Lab",
      fees: 480,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44865,
      package_name: "Diabetes Screening Profile 2",
      lab_name: "Max Lab",
      fees: 410,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28755,
      package_name: "Dialysis Serology Panel",
      lab_name: "Max Lab",
      fees: 2540,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47758,
      package_name: "DSM Health Profile",
      lab_name: "Max Lab",
      fees: 1400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 11594,
      package_name: "Electrolyte(Na K CI) Serum",
      lab_name: "Max Lab",
      fees: 490,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26104,
      package_name: "Electrolytes (NaKCI) 24Hrs Urine",
      lab_name: "Max Lab",
      fees: 620,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 40252,
      package_name: "Extended Renal Profile Test",
      lab_name: "Max Lab",
      fees: 1640,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37500,
      package_name: "Fever With Upper Respiratory Infection Profile",
      lab_name: "Max Lab",
      fees: 2760,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5856,
      package_name: "Glucose Fasting and 2 hrs.  P.P.Fluoride Plasma",
      lab_name: "Max Lab",
      fees: 120,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39651,
      package_name: "Hepatitis B Profile HBsAg HBsAb HBeAg HBeAb HB",
      lab_name: "Max Lab",
      fees: 5620,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50475,
      package_name: "HIV-1 RNA Quantitative RT PCR With CD3 CD4",
      lab_name: "Max Lab",
      fees: 7690,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 30250,
      package_name: "Kidney Metabolic Panel",
      lab_name: "Max Lab",
      fees: 950,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35402,
      package_name: "KTP Advance",
      lab_name: "Max Lab",
      fees: 2680,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35401,
      package_name: "KTP Basic P",
      lab_name: "Max Lab",
      fees: 960,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35400,
      package_name: "KTP Basic S",
      lab_name: "Max Lab",
      fees: 960,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47520,
      package_name: "LFT with LDH Profile",
      lab_name: "Max Lab",
      fees: 750,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44916,
      package_name: "Max Allergy Profile-Comprehensive",
      lab_name: "Max Lab",
      fees: 8500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44915,
      package_name: "Max Allergy Screening Profile",
      lab_name: "Max Lab",
      fees: 1440,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44913,
      package_name: "Max Anemia Profile",
      lab_name: "Max Lab",
      fees: 2580,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 43239,
      package_name: "Max Antenatal Panel",
      lab_name: "Max Lab",
      fees: 1790,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 48509,
      package_name: "Max APLA Syndrome Panel",
      lab_name: "Max Lab",
      fees: 4970,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44906,
      package_name: "Max Arthiritis Panel (Female)-Basic",
      lab_name: "Max Lab",
      fees: 2320,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44905,
      package_name: "Max Arthiritis Panel (Male)-Basic",
      lab_name: "Max Lab",
      fees: 4280,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44908,
      package_name: "Max Arthiritis Panel(Female)-Comprehensive",
      lab_name: "Max Lab",
      fees: 5150,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44907,
      package_name: "Max Arthiritis Panel(Male)-Comprehensive",
      lab_name: "Max Lab",
      fees: 6270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44860,
      package_name: "Max Bone Profile",
      lab_name: "Max Lab",
      fees: 2090,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44861,
      package_name: "Max Cardiac Profile",
      lab_name: "Max Lab",
      fees: 1870,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44923,
      package_name: "Max Cervical Screening",
      lab_name: "Max Lab",
      fees: 1950,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46596,
      package_name: "Max Chemo DayCare Screening Profile",
      lab_name: "Max Lab",
      fees: 1950,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46320,
      package_name: "Max Chikungunya Panel",
      lab_name: "Max Lab",
      fees: 1510,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46319,
      package_name: "Max Dengue Panel",
      lab_name: "Max Lab",
      fees: 890,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46318,
      package_name: "Max Dengue/Chikungunya Panel",
      lab_name: "Max Lab",
      fees: 1940,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44864,
      package_name: "Max Diabetes Extended Profile",
      lab_name: "Max Lab",
      fees: 2740,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44862,
      package_name: "Max Diabetes Profile",
      lab_name: "Max Lab",
      fees: 2090,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44909,
      package_name: "Max Electrolyte Panel",
      lab_name: "Max Lab",
      fees: 410,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45809,
      package_name: "Max Fertility panel-Male (extended)",
      lab_name: "Max Lab",
      fees: 4800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46322,
      package_name: "Max Fever of Unknown Origin/PUO Panel",
      lab_name: "Max Lab",
      fees: 4740,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46316,
      package_name: "Max Fever Panel (Basic)",
      lab_name: "Max Lab",
      fees: 720,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46317,
      package_name: "Max Fever Panel (Comprehensive)",
      lab_name: "Max Lab",
      fees: 1820,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46321,
      package_name: "Max Fever Panel (Fever more than 1 Week)",
      lab_name: "Max Lab",
      fees: 2470,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44912,
      package_name: "Max Fever Profile Comprehensive",
      lab_name: "Max Lab",
      fees: 2360,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45815,
      package_name: "Max Gynae Pre Operative Profile",
      lab_name: "Max Lab",
      fees: 2090,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45704,
      package_name: "Max Gynae Profile - 1",
      lab_name: "Max Lab",
      fees: 2950,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44917,
      package_name: "Max Gynaecological Hormone Profile",
      lab_name: "Max Lab",
      fees: 1550,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44921,
      package_name: "Max Infertility Panel",
      lab_name: "Max Lab",
      fees: 4110,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: "MIP",
      package_name: "Max Insulin Profile",
      lab_name: "Max Lab",
      fees: 1440,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45811,
      package_name: "Max IVF Couple-Routine Profile",
      lab_name: "Max Lab",
      fees: 2090,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45812,
      package_name: "Max IVF Female-Routine Blood",
      lab_name: "Max Lab",
      fees: 5370,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45813,
      package_name: "Max IVF Hormone-Profile",
      lab_name: "Max Lab",
      fees: 2970,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45814,
      package_name: "Max IVF Stimulation Test",
      lab_name: "Max Lab",
      fees: 1230,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44910,
      package_name: "Max Kidney Health Profile-Comprehensive",
      lab_name: "Max Lab",
      fees: 1010,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44904,
      package_name: "Max Nutrition Profile",
      lab_name: "Max Lab",
      fees: 1580,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 43238,
      package_name: "Max PCOD Panel",
      lab_name: "Max Lab",
      fees: 3040,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44922,
      package_name: "Max STD Panel",
      lab_name: "Max Lab",
      fees: 2100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: "MTP",
      package_name: "Max Testosterone Profile",
      lab_name: "Max Lab",
      fees: 2160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44914,
      package_name: "Max Thalassemia Profile",
      lab_name: "Max Lab",
      fees: 930,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44918,
      package_name: "Max Tuberculosis Profile I",
      lab_name: "Max Lab",
      fees: 1940,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44919,
      package_name: "Max Tuberculosis Profile II",
      lab_name: "Max Lab",
      fees: 3010,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44920,
      package_name: "Max Tuberculosis Profile III",
      lab_name: "Max Lab",
      fees: 2600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44859,
      package_name: "Max Wellness Adolescent Profile",
      lab_name: "Max Lab",
      fees: 2550,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45790,
      package_name: "Max-Antenatal Profile (Basic 1)",
      lab_name: "Max Lab",
      fees: 3200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45791,
      package_name: "Max-Antenatal Profile (Basic 2)",
      lab_name: "Max Lab",
      fees: 3850,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45789,
      package_name: "Max-Antenatal Profile (Extended)",
      lab_name: "Max Lab",
      fees: 5070,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45807,
      package_name: "Max-Fertility hormonal followup profile",
      lab_name: "Max Lab",
      fees: 1160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45806,
      package_name: "Max-Fertility hormonal profile",
      lab_name: "Max Lab",
      fees: 2970,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45810,
      package_name: "Max-Fertility panel - Female",
      lab_name: "Max Lab",
      fees: 4990,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45808,
      package_name: "Max-Fertility panel - Male (Basic)",
      lab_name: "Max Lab",
      fees: 3850,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45792,
      package_name: "Max-Pre-operative Panel",
      lab_name: "Max Lab",
      fees: 3400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45804,
      package_name: "Max-Thrombophilia Profile (Basic)",
      lab_name: "Max Lab",
      fees: 13950,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45805,
      package_name: "Max-Thrombophilia Profile (Extended)",
      lab_name: "Max Lab",
      fees: 18600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45793,
      package_name: "Max-Vitamin Panel (Basic)",
      lab_name: "Max Lab",
      fees: 1850,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 43402,
      package_name: "Metabolic syndrome/ PCOS",
      lab_name: "Max Lab",
      fees: 8770,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49122,
      package_name: "Monsoon Fever Profile-Advanced",
      lab_name: "Max Lab",
      fees: 4820,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49121,
      package_name: "Monsoon Fever Profile-Basic",
      lab_name: "Max Lab",
      fees: 3700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49270,
      package_name: "Monsoon Profile-Advanced",
      lab_name: "Max Lab",
      fees: 5360,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49269,
      package_name: "Monsoon Profile-Basic",
      lab_name: "Max Lab",
      fees: 2100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28623,
      package_name: "Myeloma Profile-New",
      lab_name: "Max Lab",
      fees: 8500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45325,
      package_name: "New Sepsis Profile",
      lab_name: "Max Lab",
      fees: 7490,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 30474,
      package_name: "Osteoporosis Profile",
      lab_name: "Max Lab",
      fees: 3270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49001,
      package_name: "PAC - Lab Profile-For Paediatric cardiac surgery",
      lab_name: "Max Lab",
      fees: 7210,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47923,
      package_name: "PAC-7 Lab Profile (SHB)",
      lab_name: "Max Lab",
      fees: 6930,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 30472,
      package_name: "PCOD Panel",
      lab_name: "Max Lab",
      fees: 5060,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 36467,
      package_name: "Renal Profile (Without Urine)Serum",
      lab_name: "Max Lab",
      fees: 860,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39662,
      package_name: "Renal Profile with (Uric Acid)",
      lab_name: "Max Lab",
      fees: 1740,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 9120,
      package_name: "Renal ProfileSerum + Urine",
      lab_name: "Max Lab",
      fees: 840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 40611,
      package_name: "Semen Analysis with Semen Fructose",
      lab_name: "Max Lab",
      fees: 1080,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46868,
      package_name: "Senior Wellness Profile",
      lab_name: "Max Lab",
      fees: 4200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44276,
      package_name: "Sepsis Panel (GGN)",
      lab_name: "Max Lab",
      fees: 6915,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46869,
      package_name: "Soulmates Wellness Profile Part 1",
      lab_name: "Max Lab",
      fees: 3100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46870,
      package_name: "Soulmates Wellness Profile Part 2",
      lab_name: "Max Lab",
      fees: 3100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 9249,
      package_name: "Thalassemia Profile.",
      lab_name: "Max Lab",
      fees: 2190,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47359,
      package_name: "Thrombocheck Total Panel",
      lab_name: "Max Lab",
      fees: 18390,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 30519,
      package_name: "Thrombolysis PT & APTT Profile",
      lab_name: "Max Lab",
      fees: 840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28647,
      package_name: "Thrombophilia Profile",
      lab_name: "Max Lab",
      fees: 15000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 30470,
      package_name: "Typhoid Panel",
      lab_name: "Max Lab",
      fees: 2080,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26383,
      package_name: "Urine Drug Assay Panel",
      lab_name: "Max Lab",
      fees: 4860,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39763,
      package_name: "Viral Markers (Rapid)",
      lab_name: "Max Lab",
      fees: 980,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45803,
      package_name: "Wellwise Advanced Profile",
      lab_name: "Max Lab",
      fees: 2200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46399,
      package_name: "Wellwise Essential Profile",
      lab_name: "Max Lab",
      fees: 999,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49673,
      package_name: "WellWise Exclusive Profile- Male",
      lab_name: "Max Lab",
      fees: 4200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49674,
      package_name: "WellWise Exclusive Profile-Female",
      lab_name: "Max Lab",
      fees: 4200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45036,
      package_name: "Wellwise Total Profile",
      lab_name: "Max Lab",
      fees: 3200,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 44615,
      package_name: "Phlebotomy Service at Home",
      lab_name: "Max Lab",
      fees: 150,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6253,
      package_name: "Aldosterone Serum",
      lab_name: "Max Lab",
      fees: 1720,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 32072,
      package_name: "Anti Hepatitis A Virus-IgGSerum",
      lab_name: "Max Lab",
      fees: 1300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50800,
      package_name: "Anti HEV IgG",
      lab_name: "Max Lab",
      fees: 1650,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21594,
      package_name: "Anti-CCP (Anti Cyclic Citrullinated Peptide)Serum",
      lab_name: "Max Lab",
      fees: 1820,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19643,
      package_name: "Antibodies to HBs Serum",
      lab_name: "Max Lab",
      fees: 740,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46991,
      package_name: "Bal Galactomannan",
      lab_name: "Max Lab",
      fees: 2810,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6195,
      package_name: "Brucella agglutination test",
      lab_name: "Max Lab",
      fees: 760,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26462,
      package_name: "Brucella IgG+IgM Antibodies",
      lab_name: "Max Lab",
      fees: 1080,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47876,
      package_name: "C-Peptide Fasting",
      lab_name: "Max Lab",
      fees: 1100,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47877,
      package_name: "C-Peptide PP",
      lab_name: "Max Lab",
      fees: 1110,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6141,
      package_name: "C-PeptideSerum",
      lab_name: "Max Lab",
      fees: 1090,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 23097,
      package_name: "Chikungunya IgM Test",
      lab_name: "Max Lab",
      fees: 620,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 53108,
      package_name: "Covid 19 Antigen Test (Nasal Swab)",
      lab_name: "Max Lab",
      fees: 1450,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6311,
      package_name: "Cytomegalo Virus (CMV) - IgGSerum",
      lab_name: "Max Lab",
      fees: 680,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21724,
      package_name: "Cytomegalo Virus (CMV) - IgM + IgG CombinedSerum",
      lab_name: "Max Lab",
      fees: 1280,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6312,
      package_name: "Cytomegalo Virus (CMV) - IgMSerum",
      lab_name: "Max Lab",
      fees: 670,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26205,
      package_name: "Dengue NS 1 Antigen Test (Elisa)",
      lab_name: "Max Lab",
      fees: 600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 12330,
      package_name: "Dengue Serology (Ig M & Ig G)Serum/EDTA",
      lab_name: "Max Lab",
      fees: 600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 43553,
      package_name: "Elisa Dengue IgG Antibody Serum",
      lab_name: "Max Lab",
      fees: 600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 43554,
      package_name: "Elisa Dengue IgM Antibody Serum",
      lab_name: "Max Lab",
      fees: 600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 46484,
      package_name: "Febrile Antigen Panel",
      lab_name: "Max Lab",
      fees: 1360,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6231,
      package_name: "Free TestosteroneSerum",
      lab_name: "Max Lab",
      fees: 1850,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6234,
      package_name: "HAV-IgM (Hepatitis A IgM)Serum",
      lab_name: "Max Lab",
      fees: 1270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6515,
      package_name: "HBeAb (Hepatitis B envelope Antibody)Serum",
      lab_name: "Max Lab",
      fees: 850,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19645,
      package_name: "HBeAg (Hepatitis B envelope Antigen)",
      lab_name: "Max Lab",
      fees: 850,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6236,
      package_name: "HCV IgGSerum",
      lab_name: "Max Lab",
      fees: 1550,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26384,
      package_name: "Hep B Core Antibody-IgMSerum",
      lab_name: "Max Lab",
      fees: 1140,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6315,
      package_name: "Hep B Core Antibody-TotalSerum",
      lab_name: "Max Lab",
      fees: 1180,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21723,
      package_name: "Hepatitis B Confirmatory TestSerum",
      lab_name: "Max Lab",
      fees: 720,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6045,
      package_name: "Hepatitis B Surface AntigenSerum",
      lab_name: "Max Lab",
      fees: 650,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19646,
      package_name: "Hepatitis Be Panel (HBe Ab & HBe Ag)Serum",
      lab_name: "Max Lab",
      fees: 1630,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6040,
      package_name: "HIV Test ( I and II )Serum",
      lab_name: "Max Lab",
      fees: 500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39615,
      package_name: "Interleukin -6 (IL-6)",
      lab_name: "Max Lab",
      fees: 3230,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25205,
      package_name: "Kala Azar Screening",
      lab_name: "Max Lab",
      fees: 1490,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6035,
      package_name: "Mantoux / PPD/ TuberculinTest",
      lab_name: "Max Lab",
      fees: 210,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25615,
      package_name: "Mono Spot Test",
      lab_name: "Max Lab",
      fees: 1060,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 52798,
      package_name: "Myositis LIA 16 Antigen",
      lab_name: "Max Lab",
      fees: 9000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34956,
      package_name: "Neuronal (Paraneoplastic) Antibodies Profile",
      lab_name: "Max Lab",
      fees: 13390,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24192,
      package_name: "NT-proBNP",
      lab_name: "Max Lab",
      fees: 3400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21291,
      package_name: "Procalcitonin* Serum",
      lab_name: "Max Lab",
      fees: 3600,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6300,
      package_name: "Progesterone OH-17Serum",
      lab_name: "Max Lab",
      fees: 1390,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34207,
      package_name: "Rapid Card Test - HCV",
      lab_name: "Max Lab",
      fees: 500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28458,
      package_name: "Rapid Card Test - Hepatitis B Surface Antigen",
      lab_name: "Max Lab",
      fees: 220,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28457,
      package_name: "Rapid Card Test - HIV I & II",
      lab_name: "Max Lab",
      fees: 410,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33458,
      package_name: "Rapid Card Test-Dengue NS1 Antigen",
      lab_name: "Max Lab",
      fees: 620,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21726,
      package_name: "Rubella IgG + Rubella IgMSerum",
      lab_name: "Max Lab",
      fees: 1180,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6153,
      package_name: "Rubella IgGSerum",
      lab_name: "Max Lab",
      fees: 680,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6154,
      package_name: "Rubella IgMSerum",
      lab_name: "Max Lab",
      fees: 700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39337,
      package_name: "Scrub Typhus IgM Elisa",
      lab_name: "Max Lab",
      fees: 2000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47862,
      package_name: "Syphilis Test",
      lab_name: "Max Lab",
      fees: 380,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 45798,
      package_name: "Systemic Sclerosis LIA",
      lab_name: "Max Lab",
      fees: 7520,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6038,
      package_name: "Toxoplasma Antibody (IgG)",
      lab_name: "Max Lab",
      fees: 700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6039,
      package_name: "Toxoplasma Antibody (IgM)",
      lab_name: "Max Lab",
      fees: 700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25631,
      package_name: "Toxoplasma Antibody Combined  (IgG+IgM)",
      lab_name: "Max Lab",
      fees: 1220,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6252,
      package_name: "TPHA",
      lab_name: "Max Lab",
      fees: 530,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26541,
      package_name: "Typhidot",
      lab_name: "Max Lab",
      fees: 490,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 24429,
      package_name: "VDRL CSF",
      lab_name: "Max Lab",
      fees: 300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6037,
      package_name: "VDRL/RPR",
      lab_name: "Max Lab",
      fees: 230,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 38764,
      package_name: "Weil Felix Test",
      lab_name: "Max Lab",
      fees: 870,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6051,
      package_name: "Widal Test (Slide)",
      lab_name: "Max Lab",
      fees: 240,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6036,
      package_name: "Widal Test (Tube Method)",
      lab_name: "Max Lab",
      fees: 320,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51855,
      package_name: "13 BETA D GLUCAN Serum",
      lab_name: "Max Lab",
      fees: 4400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6386,
      package_name: "ACTH EDTA",
      lab_name: "Max Lab",
      fees: 1480,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33046,
      package_name: "Allergy Asthma/Rhinitis Profile (Adult)",
      lab_name: "Max Lab",
      fees: 5820,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19552,
      package_name: "Allergy Comprehensive ProfileSerum",
      lab_name: "Max Lab",
      fees: 9800,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37960,
      package_name: "Allergy Eczema ProfileSerum",
      lab_name: "Max Lab",
      fees: 6050,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29749,
      package_name: "Allergy Individual MarkerSerum",
      lab_name: "Max Lab",
      fees: 1120,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 39536,
      package_name: "Allergy Individual Marker-Potato",
      lab_name: "Max Lab",
      fees: 1220,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33049,
      package_name: "Allergy Panel - FoodSerum",
      lab_name: "Max Lab",
      fees: 5360,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27657,
      package_name: "Allergy Screen-PhadiaTop/Inf",
      lab_name: "Max Lab",
      fees: 1050,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 37958,
      package_name: "Allergy Wheeze/Rhinitis Profile (Child)",
      lab_name: "Max Lab",
      fees: 5820,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26749,
      package_name: "ANA & Vasculitis Panel-LIASerum",
      lab_name: "Max Lab",
      fees: 6390,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25200,
      package_name: "ANA (Anti Nuclear Antibody) - FEIA",
      lab_name: "Max Lab",
      fees: 700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26747,
      package_name: "ANA - LIASerum",
      lab_name: "Max Lab",
      fees: 4350,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6529,
      package_name: "ANCA - CSerum",
      lab_name: "Max Lab",
      fees: 1700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6530,
      package_name: "ANCA - PSerum",
      lab_name: "Max Lab",
      fees: 1700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6296,
      package_name: "Anti Cardiolipin Ab Panel (IgGIgAIgM)Serum",
      lab_name: "Max Lab",
      fees: 2710,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21628,
      package_name: "Anti Cardiolipin AbIgASerum",
      lab_name: "Max Lab",
      fees: 1010,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21630,
      package_name: "Anti Cardiolipin AbIgGSerum",
      lab_name: "Max Lab",
      fees: 990,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21629,
      package_name: "Anti Cardiolipin AbIgMSerum",
      lab_name: "Max Lab",
      fees: 990,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51396,
      package_name: "Anti GBM (Glomerular Basement Membrance) Antibody",
      lab_name: "Max Lab",
      fees: 1960,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26310,
      package_name: "Anti JO-1Antibodies",
      lab_name: "Max Lab",
      fees: 1460,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6347,
      package_name: "Anti Scl -70 - Scleroderma Antibody",
      lab_name: "Max Lab",
      fees: 1250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25562,
      package_name: "Anti Smith Antibody-IgG",
      lab_name: "Max Lab",
      fees: 1030,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6138,
      package_name: "Anti Thyroglobulin Antibody (Anti TGO)Serum",
      lab_name: "Max Lab",
      fees: 1340,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6267,
      package_name: "Anti Thyroid Anti Body PanelSerum",
      lab_name: "Max Lab",
      fees: 2470,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6223,
      package_name: "Anti Thyroid Peroxidase AntiBody (Anti TPO)Serum",
      lab_name: "Max Lab",
      fees: 1300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6220,
      package_name: "Anti-ds-DNA Antibody (Double Stranded)",
      lab_name: "Max Lab",
      fees: 1270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6397,
      package_name: "Antibodies to SS-A/Ro",
      lab_name: "Max Lab",
      fees: 1580,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19556,
      package_name: "Antibodies to SS-B/La",
      lab_name: "Max Lab",
      fees: 1580,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29993,
      package_name: "ASCA- IgA Serum",
      lab_name: "Max Lab",
      fees: 1490,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 29994,
      package_name: "ASCA- IgG Serum",
      lab_name: "Max Lab",
      fees: 1510,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 35414,
      package_name: "Aspergillus IgE SpecificSerum",
      lab_name: "Max Lab",
      fees: 1110,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 32074,
      package_name: "Beta-2 Glycoprotein 1 IgA",
      lab_name: "Max Lab",
      fees: 1000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 32075,
      package_name: "Beta-2 Glycoprotein 1 IgG",
      lab_name: "Max Lab",
      fees: 1020,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 32076,
      package_name: "Beta-2 Glycoprotein 1 IgM",
      lab_name: "Max Lab",
      fees: 1020,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 33411,
      package_name: "Beta-2-Glycoprotein 1panel-IgAIgG& IgM",
      lab_name: "Max Lab",
      fees: 2810,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49500,
      package_name: "Calprotectin Fecal",
      lab_name: "Max Lab",
      fees: 3400,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 47652,
      package_name: "Centromere Antibody",
      lab_name: "Max Lab",
      fees: 1390,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 53104,
      package_name: "Covid 19 IgG Antibody  (Quantitative)",
      lab_name: "Max Lab",
      fees: 1250,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 57496,
      package_name: "Covid 19 IgG Antibody (Qualitative)",
      lab_name: "Max Lab",
      fees: 850,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49546,
      package_name: "CTD Profile",
      lab_name: "Max Lab",
      fees: 4720,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6504,
      package_name: "EBV (Epstein-Barr Virus ) to VCA Panel",
      lab_name: "Max Lab",
      fees: 3170,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6487,
      package_name: "EBV (Epstein-Barr Virus ) to VCA IgG",
      lab_name: "Max Lab",
      fees: 1670,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6434,
      package_name: "EBV (Epstein-Barr Virus ) to VCA IgM",
      lab_name: "Max Lab",
      fees: 1660,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 43558,
      package_name: "Extractable Nuclear Antigen (ENA)Quantitative",
      lab_name: "Max Lab",
      fees: 7000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50756,
      package_name: "Gastro Lia",
      lab_name: "Max Lab",
      fees: 4120,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51393,
      package_name: "Gliadin Antibodies Panel (IgA & IgG)",
      lab_name: "Max Lab",
      fees: 3090,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51394,
      package_name: "Gliadin Antibody IgA",
      lab_name: "Max Lab",
      fees: 1750,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 51395,
      package_name: "Gliadin Antibody IgG",
      lab_name: "Max Lab",
      fees: 1750,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6241,
      package_name: "Herpes Simplex Virus IgG (1+2)Serum",
      lab_name: "Max Lab",
      fees: 750,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21725,
      package_name: "Herpes Simplex Virus IgG + IgM CombinedSerum",
      lab_name: "Max Lab",
      fees: 1490,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6240,
      package_name: "Herpes Simplex Virus IgM (1+2)Serum",
      lab_name: "Max Lab",
      fees: 750,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 5858,
      package_name: "IgE (Immunoglobulin-E)",
      lab_name: "Max Lab",
      fees: 880,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6209,
      package_name: "IGF - I (Somatomedin-C)",
      lab_name: "Max Lab",
      fees: 4270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19652,
      package_name: "IGF-I & Growth Hormone Panel",
      lab_name: "Max Lab",
      fees: 4870,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 49275,
      package_name: "Interferon gamma release assay (IGRA)",
      lab_name: "Max Lab",
      fees: 5620,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 50755,
      package_name: "Liver Lia",
      lab_name: "Max Lab",
      fees: 4120,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6428,
      package_name: "Measels/Rubeola -IgG",
      lab_name: "Max Lab",
      fees: 1730,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21737,
      package_name: "Measels/Rubeola -IgG+ IgM",
      lab_name: "Max Lab",
      fees: 3310,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6429,
      package_name: "Measels/Rubeola -IgM",
      lab_name: "Max Lab",
      fees: 1730,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 19661,
      package_name: "Mumps Virus Antibody Panel (IgG+IgM)",
      lab_name: "Max Lab",
      fees: 3270,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25616,
      package_name: "Mumps Virus AntibodyIgG",
      lab_name: "Max Lab",
      fees: 1770,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25617,
      package_name: "Mumps Virus AntibodyIgM",
      lab_name: "Max Lab",
      fees: 1770,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 54838,
      package_name: "NAC-Allergy Comprehensive Panel",
      lab_name: "Max Lab",
      fees: 11000,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 25630,
      package_name: "Quantiferon TB-Gold",
      lab_name: "Max Lab",
      fees: 2500,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 23098,
      package_name: "Tissue TransglutaminaseIgA",
      lab_name: "Max Lab",
      fees: 1040,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6249,
      package_name: "TORCH (IgG IgM)Serum",
      lab_name: "Max Lab",
      fees: 2550,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6250,
      package_name: "TORCH - IgGSerum",
      lab_name: "Max Lab",
      fees: 1340,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6251,
      package_name: "TORCH - IgMSerum",
      lab_name: "Max Lab",
      fees: 1340,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 40779,
      package_name: "U1 RNP Antibodies",
      lab_name: "Max Lab",
      fees: 1840,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 21736,
      package_name: "Varicella Zoster / Chicken pox (IgG+ IgM)",
      lab_name: "Max Lab",
      fees: 2410,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6408,
      package_name: "Varicella Zoster IgG/Chicken Pox IgG",
      lab_name: "Max Lab",
      fees: 1280,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6409,
      package_name: "Varicella Zoster IgM/Chicken Pox IgM",
      lab_name: "Max Lab",
      fees: 1300,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 26748,
      package_name: "Vasculitis - LIA",
      lab_name: "Max Lab",
      fees: 3160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6286,
      package_name: "Vitamin D 125 Dihydroxy Level",
      lab_name: "Max Lab",
      fees: 3430,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6163,
      package_name: "Amoebic Serology IgG",
      lab_name: "Max Lab",
      fees: 1640,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 27993,
      package_name: "Anti Phospho Lipid Antibody IgGSerum",
      lab_name: "Max Lab",
      fees: 850,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6534,
      package_name: "Anti Phospho Lipid Antibody IgMSerum",
      lab_name: "Max Lab",
      fees: 850,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6222,
      package_name: "Anti Taenia /Cysticercosis Ab-IgGSerum",
      lab_name: "Max Lab",
      fees: 1410,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6532,
      package_name: "Antiphospholipid Antibody IgG + IgMSerum",
      lab_name: "Max Lab",
      fees: 1490,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6169,
      package_name: "Aspergillus Antibody IgG+IgMSerum",
      lab_name: "Max Lab",
      fees: 3210,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 34748,
      package_name: "Aspergillus Antibody IgGSerum",
      lab_name: "Max Lab",
      fees: 1700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6168,
      package_name: "Aspergillus Antibody IgMSerum",
      lab_name: "Max Lab",
      fees: 1700,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28390,
      package_name: "Cytomegalovirus Avidity IgGSerum",
      lab_name: "Max Lab",
      fees: 540,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 30838,
      package_name: "Galactomannan",
      lab_name: "Max Lab",
      fees: 2740,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6235,
      package_name: "HEV - IgMSerum",
      lab_name: "Max Lab",
      fees: 1740,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6132,
      package_name: "Hydatid Serology/Anti EchinococcusIgG",
      lab_name: "Max Lab",
      fees: 1160,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 23064,
      package_name: "Leptospira Antibody Panel",
      lab_name: "Max Lab",
      fees: 2630,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 23063,
      package_name: "Leptospira Antibody IgG",
      lab_name: "Max Lab",
      fees: 1510,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 23062,
      package_name: "Leptospira Antibody IgM",
      lab_name: "Max Lab",
      fees: 850,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 6410,
      package_name: "LKM AntibodySerum",
      lab_name: "Max Lab",
      fees: 1660,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 28393,
      package_name: "Rubella (German measles) Avidity IgGSerum",
      lab_name: "Max Lab",
      fees: 550,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
    {
      max_lab_code: 40511,
      package_name: "TTG-DGP Tissue Transglutaminase Screen",
      lab_name: "Max Lab",
      fees: 2040,
      sample_collection_facility: ["Home Collection", "Clinic Collection"],
    },
  ];

  const labtestRef = db.collection("labtest");

  tests.map((item) => {
    const response = labtestRef.add(item);
  });
}

//temp func to add staff data
export async function addToStaff() {
  let staff = [
    {
      name: "Vaseem Ahmed",
      clinic_name: "All MAXcare Clinics",
      gender: "Male",
    },
    {
      name: "Sahil Khan",
      clinic_name: "All MAXcare Clinics",
      gender: "Male",
    },
    {
      name: "Anuj Sharma",
      clinic_name: "All MAXcare Clinics",
      gender: "Male",
    },
    {
      name: "Jyoti",
      clinic_name: "All MAXcare Clinics",
      gender: "Female",
    },
    {
      name: "Gunjan",
      clinic_name: "All MAXcare Clinics",
      gender: "Female",
    },
    {
      name: "Gullu",
      clinic_name: "All MAXcare Clinics",
      gender: "Female",
    },
    {
      name: "Sachin Pandey",
      clinic_name: "All MAXcare Clinics",
      gender: "Male",
    },
    {
      name: "Sanjeev Kumar",
      clinic_name: "All MAXcare Clinics",
      gender: "Male",
    },
  ];

  const staffRef = db.collection("staffs");

  staff.map((item) => {
    const response = staffRef.add(item);
  });
  //called in DoctorListing
}
