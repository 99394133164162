import React, {useContext, useEffect, useState} from "react";
import ReactStars from "react-rating-stars-component";

import Modal from "react-modal";
import hospital from "../assets/img/hospital.svg";
import emptyOrders from "../assets/img/island.svg";
import confirm from "../assets/img/confirmState.svg";
import pending from "../assets/img/pending.svg";
import whiteCircle from "../assets/img/whiteCircle.svg";

import Stepper from "react-stepper-js";
import "react-stepper-js/dist/index.css";

import styles from "./MyOrders.module.css";
import {
  addtoschedule,
  cancelLabOrder,
  cancelOrder,
  getAllFamilyProfiles,
  getDoctor,
  getMyConsultOrders,
  getMyLabOrders,
} from "../services/firebaseServices";
import {ScheduleContext} from "../Context/ScheduleContext";
import {useHistory} from "react-router-dom";
import {AuthContext} from "../auth";

function MyOrders() {
  const [consultOrders, setConsultOrders] = useState();
  const [labOrders, setLabOrders] = useState();
  const [loading, setLoading] = useState(true);
  const [toggle, setToggle] = useState(1);
  const [openRescheduleModal, setOpenRescheduleModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [familyNames, setFamilyNames] = useState();
  const [selectedName, setSelectedName] = useState();
  const [filteredDocArray, setFilteredDocArray] = useState();
  const [filteredLabArray, setFilteredLabArray] = useState();

  const [activeItem, setActiveItem] = useState();

  const history = useHistory();

  const {currentUser} = useContext(AuthContext);
  const {order, setOrder} = useContext(ScheduleContext);

  const fetchConsultOrders = async () => {
    const response = await getMyConsultOrders(currentUser.uid);
    // console.log(response);
    setConsultOrders(response);
    setLoading(false);
    // console.log(consultOrders);
  };

  // const fetchLabOrders = async () => {
  //   const response = await getMyLabOrders("8290660905");
  //   // console.log(response);
  //   setLabOrders(response);
  //   setLoading(false);
  // };

  const fetchFamilyMembers = async () => {
    let names = [];
    const response = await getAllFamilyProfiles(currentUser.uid);
    response.map((item) => {
      names.push(item.name);
    });
    setFamilyNames(names);
    // console.log("names: ", names);
  };

  const filterOrdersByName = (name) => {
    // console.log("filterfunc", name);
    let tempDocArr = [];
    consultOrders?.map((item) => {
      if (Object.values(item).includes(name)) {
        // console.log(`${name} is in ${item} object`);
        tempDocArr.push(item);
      }
    });
    // console.log(tempDocArr);
    setFilteredDocArray(tempDocArr);
  };

  // const fetchTheDoctor = async (docId) => {
  //   const response = await getDoctor(docId);
  //   // console.log("fetch the doc: ", response);
  //   return response;
  // };

  useEffect(() => {
    // addtoschedule();
    fetchConsultOrders();
    // fetchLabOrders();
    fetchFamilyMembers();
  }, []);

  const openRescheduleModalWithItem = (item) => {
    setActiveItem(item);
    setOpenRescheduleModal(true);
    console.log(item);
  };
  const openCancelModalWithItem = (item) => {
    setActiveItem(item);
    setOpenCancelModal(true);
    // console.log(item);
  };

  const DoctorConsultation = ({item}) => (
    <div className={styles.orderDiv}>
      <div className={styles.patientName}>
        <h2 className={styles.header}>{item.patient_name}</h2>
      </div>
      <div className={styles.flex}>
        <div className={styles.detailsDiv}>
          <h2 className={styles.header}>Dr. {item.doctor.name}</h2>
          <h2 className={styles.header}>{item.clinic_name}</h2>
          <p className={styles.location}>{item.address}</p>
        </div>
        <div className={styles.timingDiv}>
          <p>
            <span className={styles.boldHead}>Timing - </span>
            {item.time}
            {/* {item.timing.intime}-{item.timing.outime} */}
          </p>
          <p>
            <span className={styles.boldHead}>Day - </span>
            {item.date.dateString}
          </p>
        </div>
      </div>
      <div className={styles.status}>
        <h2 className={styles.header}>Status - </h2>
        <ul className={styles.statusList}>
          <li>
            <img
              className={styles.listIcon}
              src={item.status.booked.confirmed ? confirm : pending}
              width={16}
              alt=""
            />
            <span>Booked</span>
          </li>
          <li>
            <img
              className={styles.listIcon}
              src={item.status.consultation.confirmed ? confirm : pending}
              width={16}
              alt=""
            />
            <span>Consultation completed</span>
          </li>
          <li>
            <img
              className={styles.listIcon}
              src={item.status.prescription.uploaded ? confirm : pending}
              width={16}
              alt=""
            />
            <span>Prescription Uploaded</span>
          </li>
        </ul>
        {item.status.prescription.uploaded ? (
          <div className={styles.userFeedback}>
            <p className={styles.header}>Rate Your Experience</p>
            <ReactStars
              classNames={styles.rating}
              count={5}
              value={4}
              edit={true}
              size={40}
              isHalf={true}
              activeColor="#ffd700"
            />
            <button className={styles.rescheduleBtn}>ADD A REVIEW</button>
          </div>
        ) : (
          ""
        )}
      </div>
      {item.status.consultation.confirmed || item.reschedule_times >= 2 ? (
        ""
      ) : (
        <div className={styles.btns}>
          <button
            onClick={() => openRescheduleModalWithItem(item)}
            className={styles.rescheduleBtn}
          >
            RESCHEDULE
          </button>
          {item.reschedule_times > 0 ? (
            ""
          ) : (
            <button
              onClick={() => openCancelModalWithItem(item)}
              className={styles.cancelBtn}
            >
              CANCEL
            </button>
          )}
        </div>
      )}
      {item.reschedule_times >= 2 ? (
        <p className={styles.paraText}>
          *cant be rescheduled more than 2 times!
        </p>
      ) : (
        ""
      )}
    </div>
  );

  const LabTest = ({item}) => (
    <div className={styles.orderDiv}>
      {/* {console.log("item", JSON.stringify(item))} */}
      <div className={styles.patientName}>
        <h2 className={styles.header}>{item.patient_name}</h2>
      </div>
      <div className={styles.flex}>
        <div className={styles.detailsDiv}>
          <h2 className={styles.header}>{item.labtest_name}</h2>
          <h2 className={styles.header}>{item.lab_name}</h2>
          {/* <p className={styles.location}>Location of Clinic, City</p> */}
        </div>
        <div className={styles.timingDiv}>
          <p>
            <span className={styles.boldHead}>Report In - </span>5 Days
          </p>
          <p>
            <span className={styles.boldHead}>Date - </span>
            {item.date.dateString}
          </p>
          <p>
            <span className={styles.boldHead}>Time - </span>
            {item.time}
          </p>
          {/* <p>
                <span className={styles.boldHead}>Day - </span>Monday
              </p> */}
        </div>
      </div>
      <div className={styles.addressSection}>
        <h2 className={styles.header}>Slected collection option</h2>
        <p className={styles.address}>
          {item.collection_option.clinic ? "Clinic" : "Home"}
        </p>
        <h2 className={styles.header}>Address</h2>
        <p className={styles.address}>{item.address}</p>
      </div>
      <div className={styles.status}>
        <h2 className={styles.header}>Status - </h2>
        <ul className={styles.statusList}>
          <li>
            <img
              className={styles.listIcon}
              src={item.status.order_placed.confirmed ? confirm : pending}
              width={16}
              alt=""
            />
            <span>Order Placed</span>
          </li>
          <li>
            <img
              className={styles.listIcon}
              src={
                item.status.professional_assigned.confirmed ? confirm : pending
              }
              width={16}
              alt=""
            />
            <span>Professional Assigned</span>
            {item.status.professional_assigned.confirmed === true &&
            item.status.sample_collected.confirmed === false ? (
              <button className={styles.callCollectorBtn}>
                Call Collector
              </button>
            ) : (
              ""
            )}
          </li>
          <li>
            <img
              className={styles.listIcon}
              src={item.status.sample_collected.confirmed ? confirm : pending}
              width={16}
              alt=""
            />
            <span>Sample Collected</span>
          </li>
          <li>
            <img
              className={styles.listIcon}
              src={item.status.report_uploaded.confirmed ? confirm : pending}
              width={16}
              alt=""
            />
            <span>Report Uploaded</span>
          </li>
        </ul>
        {item.status.report_uploaded.confirmed ? (
          <div className={styles.userFeedback}>
            <p className={styles.header}>Rate Your Experience</p>
            <ReactStars
              classNames={styles.rating}
              count={5}
              value={4}
              edit={true}
              size={40}
              isHalf={true}
              activeColor="#ffd700"
            />
            <button className={styles.rescheduleBtn}>ADD A REVIEW</button>
          </div>
        ) : (
          ""
        )}
      </div>
      {item.status.sample_collected.confirmed || item.reschedule_times >= 2 ? (
        ""
      ) : (
        <div className={styles.btns}>
          <button
            onClick={() => openRescheduleModalWithItem(item)}
            className={styles.rescheduleBtn}
          >
            RESCHEDULE
          </button>
          {item.reschedule_times > 0 ? (
            ""
          ) : (
            <button
              onClick={() => openCancelModalWithItem(item)}
              className={styles.cancelBtn}
            >
              CANCEL
            </button>
          )}
        </div>
      )}
      {item.reschedule_times >= 2 ? (
        <p className={styles.paraText}>
          *cant be rescheduled more than 2 times!
        </p>
      ) : (
        ""
      )}
    </div>
  );

  return (
    <>
      {/* {console.log(selectedName)} */}
      <div className={styles.myOrderContainer}>
        <div className={styles.upperSection}>
          <h1 className={styles.orderHeader}>MY ORDERS</h1>
          <ul className={styles.tabList}>
            <li onClick={() => setToggle(1)}>
              <span className={toggle === 1 ? styles.active : styles.tab}>
                DOCTOR CONSULTATION
              </span>
            </li>
            <li onClick={() => setToggle(2)}>
              <span className={toggle === 2 ? styles.active : styles.tab}>
                LAB TESTS
              </span>
            </li>
          </ul>
        </div>
        <select
          onChange={(e) => {
            filterOrdersByName(e.target.value);
          }}
          className={styles.selectMenu}
        >
          <option key={"default"} className={styles.selectOption} value={""}>
            All Orders
          </option>
          {familyNames?.map((item, index) => (
            <option key={index} className={styles.selectOption} value={item}>
              {item}
            </option>
          ))}
        </select>

        {!consultOrders?.length ? (
          <div
            style={{
              textAlign: "center",
              padding: 20,
            }}
          >
            <img src={emptyOrders} alt="" />
            <h2 className={styles.header}>No orders to show</h2>
          </div>
        ) : (
          ""
        )}

        {!loading &&
          !filteredDocArray &&
          toggle == 1 &&
          consultOrders?.map((item, index) => {
            if (item.type === "checkup") {
              return <DoctorConsultation key={index} item={item} />;
            }
          })}

        {!loading &&
          filteredDocArray &&
          toggle == 1 &&
          filteredDocArray?.map((item, index) => {
            if (item.type === "checkup") {
              return (
                <DoctorConsultation key={index} item={item} index={index} />
              );
            }
          })}

        {!loading &&
          !filteredDocArray &&
          toggle == 2 &&
          consultOrders?.map((item, index) => {
            if (item.type === "labtest") {
              return <LabTest key={index} item={item} />;
            }
          })}

        {!loading &&
          filteredDocArray &&
          toggle == 2 &&
          filteredDocArray?.map((item, index) => {
            if (item.type === "labtest") {
              return <LabTest key={index} item={item} />;
            }
          })}

        <Modal
          isOpen={openRescheduleModal}
          ariaHideApp={false}
          //   onAfterOpen={afterOpenModal}
          onRequestClose={() => setOpenRescheduleModal(false)}
          //   style={customStyles}
          className={styles.modal}
          contentLabel="Reschedule Modal"
        >
          <p className={styles.modalText}>
            You can only reschedule two times and once you reschedule you cannot
            cancel the appointment.
          </p>
          <button
            onClick={async () => {
              // console.log(activeItem);
              if (activeItem.type === "labtest") {
                console.log("lab item reschedule", activeItem);
                await setOrder({
                  active_order: activeItem,
                  order_type: "reschedule-labtest",
                });
              } else {
                console.log("doc item reschedule", activeItem);
                await setOrder({
                  active_order: activeItem,
                  // doctor_details: res,
                  order_type: "reschedule-consultation",
                });
              }

              history.push("/schedule");
            }}
            className={styles.modalBtn}
          >
            RESCHEDULE
          </button>
        </Modal>
        <Modal
          isOpen={openCancelModal}
          ariaHideApp={false}
          //   onAfterOpen={afterOpenModal}
          onRequestClose={() => setOpenCancelModal(false)}
          //   style={customStyles}
          className={styles.modal}
          contentLabel="Cancel Modal"
        >
          <p className={styles.modalText}>Are you sure you want to cancel?</p>
          <button
            onClick={() => {
              // console.log(activeItem);
              if (activeItem.type === "checkup") {
                console.log("checkup cancel");
                cancelOrder(activeItem, currentUser.uid).then((res) =>
                  window.location.reload()
                );
              }
              if (activeItem.type === "labtest") {
                console.log("test cancel");
                cancelOrder(activeItem, currentUser.uid).then(
                  (res) => window.location.reload()
                  // console.log(res)
                );
              }
            }}
            className={styles.modalBtn}
          >
            CANCEL
          </button>
        </Modal>
      </div>
    </>
  );
}

export default MyOrders;
