import React from "react";
import labImgTemp from "../../assets/img/labImgTemp.png";
import userImgTemp from "../../assets/img/userImgTemp.png";
// import labTest from "../../assets/img/lp-test.png";
import labTest from "../../assets/img/lp-test1.png";

import styles from "./LabTestSection.module.css";
import {Link} from "react-router-dom";

function LabTestSection() {
  return (
    <>
      <div className={styles.LabTestContainer}>
        <img className={styles.labTestImg} src={labTest} alt="" />
        <div className={styles.rightContainer}>
          <h2 className={styles.titleText}>Book lab test with ease</h2>
          <p className={styles.paraText}>
            With the best lab partners and professionally trained staff, we
            assure you an excellent experience for every test done , and a
            muddle-free document repository for future references.
          </p>
          <Link style={{textDecoration: "none"}} to="/book">
            <button className={styles.bookBtn}>Book Test</button>
          </Link>
          {/* <div className={styles.flex}>
            <img className={styles.testImg} src={userImgTemp} alt="" />
            <img className={styles.testImg} src={userImgTemp} alt="" />
            <img className={styles.testImg} src={userImgTemp} alt="" />
            <img className={styles.testImg} src={userImgTemp} alt="" />
          </div> */}
        </div>
      </div>
    </>
  );
}

export default LabTestSection;
