import React, {createContext, useState} from "react";

export const ScheduleContext = createContext();

export const ScheduleProvider = ({children}) => {
  const [order, setOrder] = useState();

  return (
    <ScheduleContext.Provider value={{order, setOrder}}>
      {children}
    </ScheduleContext.Provider>
  );
};
