import React from "react";
import comingsoon from "../assets/img/coming-soon.svg";
import styles from "./coming.module.css";

function Coming() {
  return (
    <div>
      <img className={styles.comingImg} src={comingsoon} alt="coming-soon" />
    </div>
  );
}

export default Coming;
