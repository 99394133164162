import React, {useState} from "react";
import ReactStars from "react-rating-stars-component";
import arrow from "../assets/img/arrow.svg";
import docPic from "../assets/img/docPP.jfif";
import map from "../assets/img/map.png";

import styles from "./LabTestCard.module.css";

function LabTestCard() {
  // const [openDC, setOpenDC] = useState(false);
  // const [openLT, setOpenLT] = useState(false);
  const [openIDD, setOpenIDD] = useState(false);
  return (
    <>
      <div className={styles.labCardContainer}>
        <div className={styles.docSContainer}>
          <div className={styles.grid}>
            <div className={styles.imageContainer}>
              <img className={styles.profileImage} src={docPic} alt="" />
              <p className={styles.experience}>
                {/* <img className={styles.mapIcon} src={map} alt="" /> */}5 km
              </p>
            </div>
            <div className={styles.detailsContainer}>
              <h2 className={styles.name}>Name of Clinic</h2>
              <p className={styles.designation}>Dr. Lal Path Lab</p>
              <p className={styles.location}>25 Tests in Total</p>
            </div>
            <div>
              <p className={styles.reportIn}>
                <span className={styles.reportInBold}>Report In - </span> 5hr
              </p>
            </div>
          </div>
          <div className={styles.consult}>
            <div style={{marginLeft: 0}}>
              <h3 className={styles.consultationFee}>
                Consultation Fee - <b style={{color: "#835CB9"}}>₹ 300</b>
              </h3>
            </div>

            <button className={styles.cartBtn}>ADD TO CART</button>
          </div>
        </div>
        {/* <div className={styles.docCard}>
          <div className={styles.name}>
            <h3 className={styles.cardHeader}>Liver Panel</h3>
            <p className={styles.cardPara}>Package Name</p>
          </div>
          <ReactStars
            classNames={styles.rating}
            count={5}
            value={5}
            edit={false}
            size={24}
            isHalf={true}
            activeColor="#ffd700"
          />
          <div className={styles.expertise}>
            <h3 className={styles.cardHeader}>Facilities</h3>
            <p className={styles.cardPara}>Doctor Consultation, Lab Test</p>
          </div>
          <div className={styles.timing}>
            <div style={{ display: "inline-flex" }}>
              <h3 style={{ color: "#373737" }} className={styles.cardPara}>
                Report in -
              </h3>
              <h3 className={styles.cardPara}>5 Hours</h3>
            </div>
          </div>
          <div className={styles.fee}>
            <p className={styles.cardHeader}>
              Test Fee - <b>₹ 300</b>
            </p>
          </div>
          <div className={styles.btn}>
            <button>Add TO Cart</button>
          </div>
        </div> */}
        <div className={styles.ddWrapper}>
          <div
            onClick={() => setOpenIDD(!openIDD)}
            className={styles.innerDropDown}
          >
            <p className={styles.knowMore}>
              {openIDD ? (
                "Close"
              ) : (
                <>
                  Know More{" "}
                  <img
                    style={{marginLeft: "5px"}}
                    src={arrow}
                    width={10}
                    alt=""
                  />
                </>
              )}
            </p>
          </div>
          {openIDD && (
            <>
              <div style={{marginTop: "20px"}}>
                <h2 className={styles.cardHeader}>Sample collection</h2>
                <p className={styles.cardPara}>
                  Home collection and Lab collection
                </p>
                <h2 className={styles.cardHeader}>Requirements</h2>
                <p className={styles.cardPara}>Fasting of 12 hours is a must</p>
                <div className={styles.testDiv}>
                  <h2 className={styles.cardHeader}>Tests Included (x)</h2>
                  <ol
                    style={{
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      marginLeft: 5,
                    }}
                  >
                    <li className={styles.innerListItem}>Lipid Profile</li>
                    <li className={styles.innerListItem}>Lipid Profile</li>
                    <li className={styles.innerListItem}>Lipid Profile</li>
                    <li className={styles.innerListItem}>Lipid Profile</li>
                  </ol>
                </div>
                <h2 className={styles.cardHeader}>Process</h2>
                <ul style={{marginLeft: "15px"}}>
                  <li className={styles.cardPara}>Sample Collection</li>
                  <li className={styles.cardPara}>Dummy Text</li>
                  <li className={styles.cardPara}>Dummy Text</li>
                </ul>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default LabTestCard;
