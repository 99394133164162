import React from "react";

import styles from "./HomeCard.module.css";

function HomeCard({image, title, text, button, width, radius}) {
  return (
    <>
      <div className={styles.cardContainer}>
        <div className={styles.imgSection}>
          <img
            style={{borderRadius: radius, width: width}}
            src={image}
            alt=""
          />
        </div>
        <div className={styles.textSection}>
          <h3 className={styles.titleText}>{title}</h3>
          <p className={styles.subText}>{text}</p>
        </div>
        {button ? <button className={styles.bookBtn}>{button}</button> : ""}
      </div>
    </>
  );
}

export default HomeCard;
