import React from "react";

import styles from "./AddRecord.module.css";

function AddRecord() {
  return (
    <>
      <div className={styles.addRecordContainer}>
        {/* <select className={styles.selectMenu}>
          <option className={styles.selectOption} value="">
            User Name
          </option>
          <option className={styles.selectOption} value="">
            User Name
          </option>
          <option className={styles.selectOption} value="">
            User Name
          </option>
        </select> */}
        <div className={styles.formContainer}>
          <h3 className={styles.formHeader}>Add Record</h3>
          <form className={styles.form}>
            <label className={styles.label} htmlFor="">
              Patient Name
            </label>
            <input
              className={styles.input}
              placeholder="Enter Patient Name"
              type="text"
            />
            <label className={styles.label} htmlFor="">
              Add Title
            </label>
            <input
              className={styles.input}
              placeholder="Enter Title"
              type="text"
            />
            <label className={styles.label} htmlFor="">
              Select Type
            </label>
            <select className={styles.input}>
              <option value="">Prescription</option>
              <option value="">Report</option>
            </select>
            <label className={styles.label} htmlFor="">
              Upload
            </label>
            <input className={styles.input} type="file" name="" id="" />

            {/* <div className={styles.btnWrap}>
              <label className={styles.cfBtn} htmlFor="">
                Choose File
              </label>
              <input className={styles.fileInput} type="file" name="" id="" />
            </div> */}
            <button className={styles.uploadBtn} type="submit">
              UPLOAD DOCUMENT
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddRecord;
