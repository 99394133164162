import React, {createContext, useContext, useEffect, useState} from "react";
import {AuthContext} from "../auth";
import {getMyConsultOrders, getMyLabOrders} from "../services/firebaseServices";

export const OrdersContext = createContext();

export const OrdersProvider = ({children}) => {
  const [docOrders, setDocOrders] = useState(null);
  const [labOrders, setLabOrders] = useState(null);

  const {currentUser} = useContext(AuthContext);

  const fetchConsultOrders = async () => {
    await getMyConsultOrders(currentUser.uid).then((res) => {
      setDocOrders(res);
      // console.log("res", res);
    });

    // const response = await getMyConsultOrders("jRzPsJlUYRQ9jut2Ls31qrddMNJ2");
    // // console.log(response.schedule);
    // setDocOrders(response);
    // console.log("First Block", response);
  };

  // const fetchLabOrders = async () => {
  //   await getMyLabOrders("8290660905").then((res) => {
  //     setLabOrders(res);
  //   });

  //   // const response = await getMyLabOrders("8290660905");
  //   // setLabOrders(response);
  //   // console.log("Second Block", upcomingLabOrders);
  // };

  useEffect(() => {
    console.log("UO-Context");
    // fetchLabOrders();
    if (currentUser?.uid) {
      fetchConsultOrders();
    }
  }, []);

  return (
    <OrdersContext.Provider value={{docOrders, labOrders}}>
      {children}
    </OrdersContext.Provider>
  );
};
