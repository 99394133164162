import React from "react";
import ReactStars from "react-rating-stars-component";
import { Carousel } from "react-responsive-carousel";

import carouselArrowPrev from "../assets/img/carouselArrowPrev.png";
import carouselArrowNext from "../assets/img/carouselArrowNext.png";

// import bookImg from "../assets/img/loyaltyPoints.svg";
// import circleWebTemp from "../assets/img/circleWebTemp.png";
// import searchIcon from "../assets/img/searchWeb.png";
// import listImg from "../assets/img/loyaltyPoints.svg";
// import bannerImg from "../assets/img/consultDocBanner.png";

import bannerLeftImg from "../assets/img/consultBannerLeft.png";
import bannerRightImg from "../assets/img/consultBannerRight.png";

import userImg from "../assets/img/userImgTemp2.png";

import uspImg from "../assets/img/consult-usp.png";
import uspImg1 from "../assets/img/consult-usp-1.png";
import uspImg2 from "../assets/img/consult-usp-2.png";
import uspImg3 from "../assets/img/consult-usp-3.png";

import book1 from "../assets/img/consult-book1.png";
import book2 from "../assets/img/consult-book2.png";
import book3 from "../assets/img/consult-book3.png";
import book4 from "../assets/img/consult-book4.png";
import book5 from "../assets/img/consult-book5.png";

import diabetes from "../assets/img/diabetes.png";
import mensuration from "../assets/img/mensuration.png";
import fever from "../assets/img/fever.png";
import cold from "../assets/img/cold.png";
import dental from "../assets/img/dental.png";
import acidity from "../assets/img/acidity.png";
import pregnancy from "../assets/img/pregnancy.png";

import physician from "../assets/img/spl-physician.png";
import pediatrician from "../assets/img/spl-pediatrician.png";
import gynaecologist from "../assets/img/spl-gynacologist.png";
import physiotherapist from "../assets/img/spl-physiotherapist.png";
import orthopedic from "../assets/img/spl-orthopedic.png";

import styles from "./ConsultDoctorWeb.module.css";
import SearchBar from "../components/WebComponents/SearchBar";
import DownloadSection from "../components/LandingPage/DownloadSection";

function ConsultDoctorWeb() {
  return (
    <>
      <div className={styles.consultDocContainer}>
        <section className={styles.bannerSection}>
          <div className={styles.bannerImgsDiv}>
            <img className={styles.bannerLeftImg} src={bannerLeftImg} alt="" />
            <div className={styles.description}>
              <h2 className={styles.bannerTitle}>
                Book In-Clinic Consultation
              </h2>
              <p className={styles.bannerText}>
                Cliniker connects you to the nearest doctors with best
                qualifications ensuring a safe and effective consultation
                experience, and we promises you a satisfying consultation
                everytime you book.
              </p>
            </div>
            <img
              className={styles.bannerRightImg}
              src={bannerRightImg}
              alt=""
            />
          </div>
          <div className={styles.searchComponent}>
            <SearchBar />
          </div>
          {/* <div className={styles.bannerSection}>
            <img className={styles.bannerrImg} src={bannerImg} alt="" />
            <div className={styles.searchComponent}>
              <SearchBar />
            </div>
          </div> */}
        </section>
        <section className={styles.tilesSection}>
          <div className={styles.healthIssues}>
            <div className={styles.headingDiv}>
              <h3 className={styles.titleText}>Common health issues</h3>
            </div>
            <div className={styles.issuesContainer}>
              <a
                href="https://play.google.com/store/apps/details?id=com.dtscliniker"
                className={styles.paraText}
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.flexCol}>
                  <img className={styles.illustrationImg} src={fever} alt="" />
                  <p>Fever</p>
                </div>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.dtscliniker"
                className={styles.paraText}
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.flexCol}>
                  <img className={styles.illustrationImg} src={cold} alt="" />
                  <p>Cold</p>
                </div>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.dtscliniker"
                className={styles.paraText}
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.flexCol}>
                  <img
                    className={styles.illustrationImg}
                    src={acidity}
                    alt=""
                  />
                  <p>Acidity</p>
                </div>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.dtscliniker"
                className={styles.paraText}
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.flexCol}>
                  <img
                    className={styles.illustrationImg}
                    src={diabetes}
                    alt=""
                  />
                  <p>Diabetes</p>
                </div>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.dtscliniker"
                className={styles.paraText}
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.flexCol}>
                  <img
                    className={styles.illustrationImg}
                    src={mensuration}
                    alt=""
                  />
                  <p>Periods</p>
                </div>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.dtscliniker"
                className={styles.paraText}
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.flexCol}>
                  <img
                    className={styles.illustrationImg}
                    src={pregnancy}
                    alt=""
                  />
                  <p>Pregnancy</p>
                </div>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.dtscliniker"
                className={styles.paraText}
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.toothAche}>
                  <img className={styles.illustrationImg} src={dental} alt="" />
                  <p>Tooth Ache</p>
                </div>
              </a>
            </div>
          </div>

          <div>
            {/* <div className={styles.specialisationsContainer}> */}
            <div className={styles.headingDiv}>
              <h3 className={styles.titleText}>Popular Specialisation</h3>
            </div>
            <div className={styles.specialisationContainer}>
              <a
                href="https://play.google.com/store/apps/details?id=com.dtscliniker"
                className={styles.paraText}
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.issueSquare}>
                  <img
                    className={styles.illustrationImg}
                    src={physician}
                    alt=""
                  />
                  <p>Physician</p>
                </div>{" "}
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.dtscliniker"
                className={styles.paraText}
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.issueSquare}>
                  <img
                    className={styles.illustrationImg}
                    src={pediatrician}
                    alt=""
                  />
                  <p>Pediatrician</p>
                </div>{" "}
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.dtscliniker"
                className={styles.paraText}
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.issueSquare}>
                  <img
                    className={styles.illustrationImg}
                    src={gynaecologist}
                    alt=""
                  />
                  <p>Gynaecologist</p>
                </div>{" "}
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.dtscliniker"
                className={styles.paraText}
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.issueSquare}>
                  <img
                    className={styles.illustrationImg}
                    src={physiotherapist}
                    alt=""
                  />
                  <p>Physiotherapist</p>
                </div>{" "}
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.dtscliniker"
                className={styles.paraText}
                target="_blank"
                rel="noreferrer"
              >
                <div className={styles.issueSquareDentist}>
                  <img
                    className={styles.illustrationImg}
                    src={orthopedic}
                    alt=""
                  />
                  <p>Orthopedic</p>
                </div>{" "}
              </a>
            </div>
          </div>
        </section>
        <section id="uspsec" className={styles.uspSection}>
          {/* <div className={styles.uspSection}> */}
          <img className={styles.uspMainImg} src={uspImg} alt="" />
          <div className={styles.uspContainer}>
            <h3 className={styles.titleText}>Best facilities only for you</h3>
            <div className={styles.itemContainer}>
              <img className={styles.stepImg} src={uspImg1} alt="" />
              <p className={styles.paraText}>
                Cliniker aassured nearest clinic
              </p>
            </div>
            <div className={styles.itemContainer}>
              <img className={styles.stepImg} src={uspImg2} alt="" />
              <p className={styles.paraText}>Minimum wait time</p>
            </div>
            <div className={styles.itemContainer}>
              <img
                style={{ marginLeft: 15 }}
                className={styles.stepImg}
                src={uspImg3}
                alt=""
              />
              <p className={styles.paraText}>
                Preferred slot availability with rescheduling facilities
              </p>
            </div>
          </div>
          {/* </div> */}
        </section>
        <section className={styles.section4}>
          <div className={styles.bookSection}>
            <h3 className={styles.titleText}>How to book?</h3>
            {/* <img className={styles.listImg} src={listImg} alt="" /> */}
            <div className={styles.listContainer}>
              <div className={styles.line}></div>
              <div className={styles.stepsContainer}>
                <div className={styles.step}>
                  <img className={styles.stepImg} src={book1} alt="" />
                  <p className={styles.paraText}>Download the Cliniker app</p>
                </div>
                <div className={styles.step}>
                  <img className={styles.stepImg} src={book2} alt="" />
                  <p className={styles.paraText}>Search for Doctors</p>
                </div>
                <div className={styles.step}>
                  <img className={styles.stepImg} src={book3} alt="" />
                  <p className={styles.paraText}>Book Appointment</p>
                </div>
                <div className={styles.step}>
                  <img className={styles.stepImg} src={book4} alt="" />
                  <p className={styles.paraText}>Upload Prescription</p>
                </div>
                <div className={styles.step}>
                  <img className={styles.stepImg} src={book5} alt="" />
                  <p className={styles.paraText}>Earn Clinicoins</p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.reviewsSection}>
            <h3 className={styles.titleText}>What users say about it</h3>
            <div className={styles.cardsContainer}>
              <Carousel
                // renderArrowPrev={(onClickHandler, hasPrev) =>
                //   hasPrev && (
                //     <img
                //       style={{
                //         width: 20,
                //         position: "absolute",
                //         top: "50%",
                //       }}
                //       src={carouselArrowPrev}
                //     ></img>
                //   )
                // }
                // renderArrowNext={(onClickHandler, hasNext) =>
                //   hasNext && (
                //     <img
                //       style={{
                //         width: 20,
                //         // position: "absolute",
                //         // top: "50%",
                //       }}
                //       src={carouselArrowNext}
                //     ></img>
                //   )
                // }
                showArrows={true}
                showThumbs={false}
                autoPlay={true}
              >
                <div className={styles.cardsContainer}>
                  {/* <div> */}
                  <div className={styles.card}>
                    <div className={styles.userInfo}>
                      <img className={styles.userImg} src={userImg} alt="" />
                      <h3 className={styles.titleText}>UserName</h3>
                    </div>
                    <ReactStars
                      // classNames={styles.rating}
                      count={5}
                      value={4}
                      edit={false}
                      size={40}
                      isHalf={true}
                      activeColor="#ffd700"
                    />
                    <div className={styles.userComment}>
                      <p className={styles.paraText}>
                        Good doctors and staff. Environment there was good. No
                        extra tests were prescribed and they nicely understand
                        their patient's needs.
                      </p>
                    </div>
                  </div>
                  <div className={styles.card}>
                    <div className={styles.userInfo}>
                      <img className={styles.userImg} src={userImg} alt="" />
                      <h3 className={styles.titleText}>UserName</h3>
                    </div>
                    <ReactStars
                      classNames={styles.rating}
                      count={5}
                      value={4}
                      edit={false}
                      size={40}
                      isHalf={true}
                      activeColor="#ffd700"
                    />
                    <div className={styles.userComment}>
                      <p className={styles.paraText}>
                        I have been using their services for different tests and
                        booking appointments, they even collect sample from
                        home. Very efficient service
                      </p>
                    </div>
                  </div>
                </div>
                <div className={styles.cardsContainer}>
                  {/* <div> */}
                  <div className={styles.card}>
                    <div className={styles.userInfo}>
                      <img className={styles.userImg} src={userImg} alt="" />
                      <h3 className={styles.titleText}>UserName</h3>
                    </div>
                    <ReactStars
                      classNames={styles.rating}
                      count={5}
                      value={4}
                      edit={true}
                      size={40}
                      isHalf={true}
                      activeColor="#ffd700"
                    />
                    <div className={styles.userComment}>
                      <p className={styles.paraText}>
                        Good doctors and staff. Environment there was good. No
                        extra tests were prescribed and they nicely understand
                        their patient's needs.
                      </p>
                    </div>
                  </div>
                  <div className={styles.card}>
                    <div className={styles.userInfo}>
                      <img className={styles.userImg} src={userImg} alt="" />
                      <h3 className={styles.titleText}>UserName</h3>
                    </div>
                    <ReactStars
                      classNames={styles.rating}
                      count={5}
                      value={4}
                      edit={true}
                      size={40}
                      isHalf={true}
                      activeColor="#ffd700"
                    />
                    <div className={styles.userComment}>
                      <p className={styles.paraText}>
                        I have been using their services for different tests and
                        booking appointments, they even collect sample from
                        home. Very efficient service
                      </p>
                    </div>
                  </div>
                </div>
              </Carousel>
              {/* <div className={styles.card}>
                <div className={styles.userInfo}>
                  <img className={styles.userImg} src={userImg} alt="" />
                  <h3 className={styles.titleText}>UserName</h3>
                </div>
                <ReactStars
                  classNames={styles.rating}
                  count={5}
                  value={4}
                  edit={true}
                  size={40}
                  isHalf={true}
                  activeColor="#ffd700"
                />
                <div className={styles.userComment}>
                  <p className={styles.paraText}>
                    Good doctors and staff. Environment there was good. No extra
                    tests were prescribed and they nicely understand their
                    patient's needs.
                  </p>
                </div>
              </div>
              <div className={styles.card}>
                <div className={styles.userInfo}>
                  <img className={styles.userImg} src={userImg} alt="" />
                  <h3 className={styles.titleText}>UserName</h3>
                </div>
                <ReactStars
                  classNames={styles.rating}
                  count={5}
                  value={4}
                  edit={true}
                  size={40}
                  isHalf={true}
                  activeColor="#ffd700"
                />
                <div className={styles.userComment}>
                  <p className={styles.paraText}>
                    I have been using their services for different tests and
                    booking appointments, they even collect sample from home.
                    Very efficient service
                  </p>
                </div>
              </div> */}
            </div>
          </div>{" "}
        </section>
        {/* <section className={styles.downloadSection}> */}
        <DownloadSection />
        {/* </section> */}
        <section>
          {/* </div> */}

          {/* <h1 className={styles.bannerTitle}>Doctor Consultation Header</h1> */}
          {/* <div className={styles.searcContainer}>
              <div className={styles.searchContainer}>
                <button className={styles.searchBtn}>Search Location</button>
                <input
                  className={styles.searchInput}
                  placeholder="Search Doctors, Clinics and Lab test"
                  type="text"
                />
                <img className={styles.searchIcon} src={searchIcon} alt="" />
              </div>
            </div> */}
          {/* </div> */}
          {/* <div className={styles.healthIssues}>
            <h3 className={styles.titleText}>Common health issues</h3>
            <div className={styles.issuesContainer}>
              <div className={styles.flexCol}>
                <img
                  className={styles.illustrationImg}
                  src={circleWebTemp}
                  alt=""
                />
                <p>Fever</p>
              </div>
              <div className={styles.flexCol}>
                <img
                  className={styles.illustrationImg}
                  src={circleWebTemp}
                  alt=""
                />
                <p>Cold</p>
              </div>
              <div className={styles.flexCol}>
                <img
                  className={styles.illustrationImg}
                  src={circleWebTemp}
                  alt=""
                />
                <p>Cold</p>
              </div>
              <div className={styles.flexCol}>
                <img
                  className={styles.illustrationImg}
                  src={circleWebTemp}
                  alt=""
                />
                <p>Cold</p>
              </div>
              <div className={styles.flexCol}>
                <img
                  className={styles.illustrationImg}
                  src={circleWebTemp}
                  alt=""
                />
                <p>Cold</p>
              </div>
              <div className={styles.flexCol}>
                <img
                  className={styles.illustrationImg}
                  src={circleWebTemp}
                  alt=""
                />
                <p>Cold</p>
              </div>
              <div className={styles.flexCol}>
                <img
                  className={styles.illustrationImg}
                  src={circleWebTemp}
                  alt=""
                />
                <p>Cold</p>
              </div>
            </div>
          </div> */}
        </section>
        {/* <div className={styles.uspSection}>
              <img src="" alt="" />
              <div className={styles.uspContainer}>
                  <h3>Service's USP</h3>
                  <img src="" alt="" />
                  <p>Cliniker aassured nearest clinic</p>
              </div>
          </div>
        <div className={styles.bookSection}>
          <h3 className={styles.titleText}>How to book?</h3>
          <img src={bookImg} alt="" />
        </div>
        <div className={styles.reviewsSection}>
          <h3 className={styles.titleText}>What users say about it</h3>
          <div className={styles.card}>
            <div className={styles.userInfo}>
              <img src="" alt="" />
              <h3>Name</h3>
            </div>
            <div className={styles.userComment}>
              <p>
                “Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis
                ullamco cillum dolor.Voluptate exercitation incididunt aliquip
                deserunt reprehenderit elit laborum”
              </p>
            </div>
            <div className={styles.userComment}>
              <p>
                “Nulla Lorem mollit cupidatat irure. Laborum magna nulla duis
                ullamco cillum dolor.Voluptate exercitation incididunt aliquip
                deserunt reprehenderit elit laborum”
              </p>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
}

export default ConsultDoctorWeb;
