import React, {useContext, useEffect, useState} from "react";
import firebase from "firebase/app";
import app from "../firebase";
import OtpInput from "react-otp-input";
import logo from "../assets/img/logoWhite.png";
import bglogo from "../assets/img/asset-1.svg";

import styles from "./Login.module.css";
import {render} from "@testing-library/react";
import {AuthContext} from "../auth";
import isMobilePhone from "validator/lib/isMobilePhone";
import {getUser} from "../services/firebaseServices";

function Login({history}) {
  const [code, setCode] = useState();
  const [error, setError] = useState();
  const [phone, setPhone] = useState();
  const [otpSent, setOtpSent] = useState(false);
  const {currentUser, setCurrentUser} = useContext(AuthContext);

  const configureCaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          console.log("Recaptca varified allow signin");
          // onSignInSubmit();
        },
        defaultCountry: "IN",
      }
    );
  };

  const onSignInSubmit = (e) => {
    e.preventDefault();

    const isValidPhone = isMobilePhone(phone.toString(), "en-IN");

    if (isValidPhone === false) {
      setError("Enter a Valid Mobile Number!");
    }

    if (isValidPhone) {
      let phoneNumber;
      if (phone.length === 10) {
        phoneNumber = "+91" + phone;
      }
      if (phone.length === 12) {
        phoneNumber = "+" + phone;
      }
      // console.log("Phone Number", phoneNumber);
      // console.log(typeof phone, phone);
      configureCaptcha();
      // const phoneNumber = "+911234567890";
      // console.log(phoneNumber);
      const appVerifier = window.recaptchaVerifier;

      app
        .auth()
        .signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {
          // SMS sent. Prompt user to type the code from the message, then sign the
          // user in with confirmationResult.confirm(code).
          window.confirmationResult = confirmationResult;
          console.log("OTP has been sent");
          setOtpSent(true);
          // ...
        })
        .then((response) => {
          appVerifier.clear();
        })
        .catch((error) => {
          // Error; SMS not sent
          // ...
          console.log("SMS not sent");
        });
    }
  };

  const onSubmitOTP = async (e) => {
    // console.log("History", history);
    e.preventDefault();
    // let user = {};
    const enteredOtp = code;
    // console.log("Otp Entered by User: ", enteredOtp);

    try {
      const result = await window.confirmationResult.confirm(code);
      const user = result.user;
      const response = await getUser(user.uid);
      if (response) {
        // console.log("user exist", response);
        history.push("/home");
      } else {
        // console.log("user not exist", response);
        history.push("/signup-details");
      }
      // console.log(history);
      // console.log(user);
    } catch (error) {
      console.log(error);
    }
    // window.confirmationResult
    //   .confirm(code)
    //   .then((result) => {
    //     // User signed in successfully.
    //     user = result.user;
    //     console.log(JSON.stringify(user));
    //     console.log("User is verified");
    //     history.push("/home");
    //     // ...
    //   })
    //   .catch((error) => {
    //     // User couldn't sign in (bad verification code?)
    //     // ...
    //     console.log(error);
    //   });
    // setCurrentUser(app.auth().currentUser);
    // console.log(app.auth().currentUser);
  };

  return (
    <>
      {/* <div id = 'sign-in-button'></div> */}
      {otpSent ? (
        <div className={styles.loginContainer}>
          <img className={styles.logo} width={60} src={logo} alt="cliniker" />
          <h1 className={styles.formHeading}>Enter the</h1>
          <h1 className={styles.formHeading}>OTP Received</h1>
          {/* {timer === 0 ? (
             <p className={styles.formSubheading}>Resend OTP</p>
           ) : (
             <p className={styles.formSubheading}>{"00 : " + timer}</p>
           )}
           {timerfunc()} */}
          <form onSubmit={onSubmitOTP} className={styles.formContainer}>
            <OtpInput
              containerStyle={styles.otpFields}
              value={code}
              onChange={(otp) => {
                setCode(otp);
              }}
              numInputs={6}
              isInputNum={true}
              separator={<span className={styles.space}></span>}
              inputStyle={styles.inputOtp}
              shouldAutoFocus={true}
            />

            <button
              // onClick={signIn}
              className={styles.inputBtn}
              type="submit"
            >
              Login
            </button>
          </form>
        </div>
      ) : (
        <div className={styles.loginContainer}>
          <img className={styles.logo} width={60} src={logo} alt="cliniker" />

          <h1 className={styles.formHeading}>Enter Your</h1>
          <h1 className={styles.formHeading}>Mobile Number</h1>
          <p className={styles.formSubheading}>
            You will receive a 6 digit code to verify next
          </p>

          <form onSubmit={onSignInSubmit} className={styles.formContainer}>
            <input
              required={true}
              className={styles.input}
              type="tel"
              name="phone"
              placeholder="Enter Mobile Number"
              // value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />

            <div id="sign-in-button"></div>

            {error ? (
              <p style={{textAlign: "center", color: "orange"}}>{error}</p>
            ) : (
              ""
            )}

            {/* <div id="captcha-container">{appVerifier}</div> */}

            <button
              // onClick={() => signIn(phone)}
              // id="sign-in-button"
              className={styles.inputBtn}
              type="submit"
            >
              Send OTP
            </button>
          </form>
        </div>
      )}
    </>
  );
}

export default Login;

// function Login() {
//   const [phone, setPhone] = useState();
//   const [otp, setOtp] = useState();
//   const [loading, setLoading] = useState();
//   const [otpSent, setOtpSent] = useState(false);
//   const [timer, setTimer] = useState(30);

//   const setupRecaptch = () => {
//     window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
//       "sign-in-button",
//       {
//         size: "invisible",
//         callback: (response) => {
//           // reCAPTCHA solved, allow signInWithPhoneNumber.
//           // onSignInSubmit();
//         },
//       }
//     );
//   };

//   useEffect(() => {}, []);

//   // const appVerifier = window.recaptchaVerifier;

//   async function signIn(phoneNumber, appVerifier) {
//     console.log(phoneNumber);
//     if (phoneNumber.length == 13) {
//       setLoading(true);
//       const confirmation = await app
//         .auth()
//         .signInWithPhoneNumber(phoneNumber, appVerifier);

//       console.log("OTP sent, Confirmation : ", confirmation);
//     } else {
//       console.log("Form filled incorrectly");
//     }
//   }

//   const timerfunc = () => {
//     setTimeout(() => {
//       if (timer !== 0) {
//         setTimer(timer - 1);
//       }
//     }, 1000);
//   };

//   return (
//     <>
//       {otpSent ? (
//         <div className={styles.loginContainer}>
//           {/* <img
//             className={styles.bgLogo}
//             width={60}
//             src={bglogo}
//             alt="cliniker"
//           /> */}

//           <img className={styles.logo} width={60} src={logo} alt="cliniker" />

//           <h1 className={styles.formHeading}>Enter the</h1>
//           <h1 className={styles.formHeading}>OTP Received</h1>
//           {timer === 0 ? (
//             <p className={styles.formSubheading}>Resend OTP</p>
//           ) : (
//             <p className={styles.formSubheading}>{"00 : " + timer}</p>
//           )}
//           {timerfunc()}

//           <form className={styles.formContainer}>
//             <OtpInput
//               value={otp}
//               onChange={(otp) => {
//                 setOtp(otp);
//               }}
//               numInputs={6}
//               isInputNum={true}
//               separator={<span className={styles.space}></span>}
//               inputStyle={styles.inputOtp}
//               shouldAutoFocus={true}
//             />

//             <button
//               // onClick={signIn}
//               className={styles.inputBtn}
//               type="submit"
//             >
//               Login
//             </button>
//           </form>
//         </div>
//       ) : (
//         <div className={styles.loginContainer}>
//           {/* <img
//             className={styles.bgLogo}
//             width={60}
//             src={bglogo}
//             alt="cliniker"
//           /> */}

//           <img className={styles.logo} width={60} src={logo} alt="cliniker" />

//           <h1 className={styles.formHeading}>Enter Your</h1>
//           <h1 className={styles.formHeading}>Mobile Number</h1>
//           <p className={styles.formSubheading}>
//             You will receive a 6 digit code to verify next
//           </p>

//           <form onSubmit={signIn} className={styles.formContainer}>
//             <input
//               className={styles.input}
//               type="tel"
//               name="phone"
//               placeholder="Enter Mobile Number"
//               // value={phone}
//               onChange={(e) => setPhone(e.target.value)}
//             />

//             <div id="recaptcha-container"></div>

//             {/* <div id="captcha-container">{appVerifier}</div> */}

//             <button
//               // onClick={() => signIn(phone)}
//               // id="sign-in-button"
//               className={styles.inputBtn}
//               type="submit"
//             >
//               Send OTP
//             </button>
//           </form>
//         </div>
//       )}
//     </>
//   );
// }

// export default Login;
