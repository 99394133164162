import React from "react";
import styles from "./Contact.module.css";

import doctorHelp from "../assets/img/doctorHelp.svg";

function Contact() {
  return (
    <>
      <div className={styles.contactContainer}>
        <div className={styles.help}>
          <div>
            <h3 className={styles.heading}>Need Help?</h3>
            <p className={styles.paraText}>Try to reach us</p>
          </div>
          <img className={styles.helpImg} src={doctorHelp} alt="" />
        </div>
        <div className={styles.email}>
          <h3 className={styles.heading}>Email Us:</h3>
          <p className={styles.paraText}>cliniker@gmail.com</p>
        </div>
        <div className={styles.call}>
          <h3 className={styles.heading}>Call Us:</h3>
          <p className={styles.paraText}>+91-8826264333</p>
        </div>
      </div>
    </>
  );
}

export default Contact;
