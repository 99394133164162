import React, {useContext, useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";

import isNumeric from "validator/lib/isNumeric";
import {AuthContext} from "../auth";
import {addAddress, editAddress} from "../services/firebaseServices";

import styles from "./AddAddress.module.css";

function AddAddress() {
  const [line1, setLine1] = useState("");
  const [line2, setLine2] = useState("");
  const [line3, setLine3] = useState("");
  const [pincode, setPincode] = useState("");

  const {currentUser} = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      address_line1: line1,
      address_line2: line2,
      address_line3: line3,
      pincode: pincode,
    };

    if (location.state !== undefined) {
      let docId = location.state.address_id;
      editAddress(data, currentUser.uid, docId).then((res) =>
        history.push("/addresses")
      );
    } else {
      addAddress(data, currentUser.uid).then((res) =>
        history.push("/addresses")
      );
    }

    // console.log("submit runs", data);
  };

  useEffect(() => {
    if (location.state !== undefined) {
      const {address_line1, address_line2, address_line3, pincode} =
        location.state;
      setLine1(address_line1);
      setLine2(address_line2);
      setLine3(address_line3);
      setPincode(pincode);
    }
  }, []);

  return (
    <>
      <div className={styles.addContainer}>
        <div className={styles.formContainer}>
          <form onSubmit={handleSubmit}>
            <input
              onChange={(e) => setLine1(e.target.value)}
              className={styles.input}
              type="text"
              value={line1}
              placeholder="Address Line 1"
            />
            <input
              onChange={(e) => setLine2(e.target.value)}
              className={styles.input}
              type="text"
              value={line2}
              placeholder="Address Line 2"
            />
            <input
              onChange={(e) => setLine3(e.target.value)}
              className={styles.input}
              type="text"
              value={line3}
              placeholder="City, State"
            />
            <input
              onChange={(e) => setPincode(e.target.value)}
              className={styles.input}
              type="text"
              value={pincode}
              placeholder="Pincode"
            />

            <button type="submit" className={styles.addBtn}>
              ADD ADDRESS
            </button>
          </form>
        </div>
      </div>
    </>
  );
}

export default AddAddress;
