import React from "react";
import "./PrivacyPolicy.css";

function PrivacyPolicy() {
  return (
    <>
      <div className="privacy-container">
        <h1 className="privacy-heading">Privacy Policy</h1>
        <div className="line"></div>
        <p>
          We value the trust You place in us, so it is important to us that your
          privacy is retained while taking advantage of the services that we
          have to offer.
        </p>
        <br />
        <p>
          Cliniker (hereinafter referred to as{" "}
          <strong>“the Company” / “we” / “us”</strong> ) is committed to
          protecting personally identifiable information including sensitive
          personal data ( <strong>“Personal Information”</strong> ) as would be
          collected by the Company from you in the course of your access to and
          use of the services provided by the Company through the Cliniker
          mobile application and website (collectively referred to as the{" "}
          <strong>“Website”</strong>) and your creation of an account on the
          Website.
        </p>
        <br />
        <p>
          This Privacy Policy applies to the collection, receipt, storage,
          usage, processing, disclosure, transfer and protection of Your
          Personal Information (defined below) when You use the Cliniker website
          available at URL: www.cliniker.*** (where *** represents various
          domain names) operated by [company name] (<strong>“Website”</strong>)
          or mobile application of the brand name <strong>“cliniker”</strong>{" "}
          available for download at Google Play Store, Apple App Store, Windows
          App Store (<strong>“Application”</strong>) operated by Cliniker or
          avail any Services offered by Cliniker through the Website or
          Application.
        </p>
        <br />
        <h3>1. CONSENT:</h3>
        <br />
        <p>
          1.1. You acknowledge that this Privacy Policy is a part of the Terms
          of Use of the website and the other services, by accessing the Website
          or Application or by otherwise providing Us Your Personal Information
          Yourself or through a Primary User or by making use of the Services
          provided by the Website or Application, You unconditionally signify
          Your (i) assent to the Privacy Policy, and (ii) consent to the
          Utilisation of Your Personal Information in accordance with the
          provisions of this Privacy Policy.
        </p>
        <br />
        <p>
          1.2. You acknowledge that You are providing Your Personal Information
          out of Your free will. If You use the Services on behalf of someone
          else (including but not limited to, Your child – minor or major or as
          a legal representative of an individual with mental illness) or an
          entity (such as Your employer), You represent that You are authorized
          by such individual or entity to
        </p>
        <br />
        <p>
          (i) accept this Privacy Policy on such individual’s or entity’s
          behalf, and
          <br />
          <br />
          (ii) consent on behalf of such individual or entity to Our collection,
          use and disclosure of such individual’s or entity’s Personal
          Information as described in this Privacy Policy. Further, You hereby
          acknowledge that the Utilization of Your Personal Information by
          Cliniker is necessary for the purposes identified hereunder. You
          hereby consent that the Utilization of any Personal Information in
          accordance with the provisions of this Privacy Policy shall not cause
          any wrongful loss to You.
        </p>
        <br />
        <p>
          1.3. YOU HAVE THE OPTION NOT TO PROVIDE US THE PERSONAL INFORMATION
          SOUGHT TO BE COLLECTED. YOU WILL ALSO HAVE the OPTION TO WITHDRAW Your
          CONSENT AT ANY POINT, PROVIDED SUCH WITHDRAWAL OF THE CONSENT IS
          INTIMATED TO US IN WRITING. If You do not provide Us with Your
          Personal Information or if You withdraw the consent to provide Us with
          Your Personal Information at any point in time, We shall have the
          option not to fulfil the purposes for which the said Personal
          Information was sought and We may restrict You from using the Website,
          Application or Services.
        </p>
        <br />
        <p>
          1.4. Our Website or Application are not directed at children and We do
          not knowingly collect any Personal Information from children. Please
          contact Us at{" "}
          <a className="email-link" href="mailto:support@cliniker.com">
            support@cliniker.com
          </a>{" "}
          if You are aware that We may have inadvertently collected Personal
          Information from a child, and We will delete that information as soon
          as possible.
        </p>
        <br />
        <h3>2. WHY THIS PRIVACY POLICY?</h3>
        <br />
        <p>
          2.1. This Privacy Policy is published in compliance with, inter alia:
        </p>
        <br />
        <ul>
          <li>Section 43A of the Information Technology Act, 2000;</li>
          <li>
            Regulation 4 of the Information Technology (Reasonable Security
            Practices and Procedures and Sensitive Personal Information) Rules,
            2011 (the <strong>“SPI Rules”</strong> );
          </li>
          <li>
            Regulation 3(1) of the Information Technology (Intermediaries
            Guidelines) Rules, 2011.
          </li>
        </ul>
        <br />
        <p>2.2. This Privacy Policy states the following:</p>
        <br />
        <ul>
          <li>
            The type of information collected from the Users, including Personal
            Information (as defined in paragraph 2 below) and Sensitive Personal
            Data or Information (as defined in paragraph 2 below) relating to an
            individual;
          </li>
          <li>
            The purpose, means and modes of collection, usage, processing,
            retention and destruction of such information; and
          </li>
          <li>How and to whom Cliniker will disclose such information.</li>
        </ul>
        <br />
        <h3>3. PERSONAL INFORMATION COLLECTED: </h3>
        <br />
        <p>
          In order to provide Services to You we might require You to
          voluntarily provide Us certain information that personally identifies
          You or Secondary Users related to You. You hereby consent to the
          collection of such information by Cliniker. The information that We
          may collect from You, about You or Secondary Users related to You, may
          include but are not limited to, the following:
        </p>
        <br />
        <ol>
          <li>Patient/Caregiver/Doctor/Health Care Professional Name,</li>
          <li>Birthdate/age,</li>
          <li>Blood group,</li>
          <li>Gender,</li>
          <li>Address (including country and pin/postal code),</li>
          <li>Location information, including Your GPS location,</li>
          <li>Phone number/mobile number,</li>
          <li>Email address,</li>
          <li>
            Physical, physiological and mental health condition, provided by You
            and/or Your Healthcare Service provider or accessible from Your
            medical records,
          </li>
          <li>Personal medical records and history,</li>
          <li>
            Valid financial information at the time of purchase of
            product/Services and/or online payment,
          </li>
          <li>Cliniker Login ID and password,</li>
          <li>
            User details as provided at the time of registration or thereafter,
          </li>
          <li>Records of interaction with Cliniker representatives,</li>
          <li>
            Your usage details such as time, frequency, duration and pattern of
            use, features used and the amount of storage used,
          </li>
          <li>
            Master and transaction data and other data stored in Your User
            account,
          </li>
          <li>
            Internet Protocol address, browser type, browser language, referring
            URL, files accessed, errors generated, time zone, operating system
            and other visitor details collected in Our log files, the pages of
            our Website or Application that You visit, the time and date of Your
            visit, the time spent on those pages and other statistics ({" "}
            <strong>"Log Data"</strong> ),
          </li>
          <li>
            User’s tracking Information such as, but not limited to the device
            ID, Google Advertising ID and Android ID,
          </li>
          <li>Any other information that is willingly shared by You.</li>
        </ol>
        <br />
        <h3>4. HOW WE COLLECT PERSONAL INFORMATION: </h3>
        <br />
        <p>
          The methods by which We collect Your Personal Information include but
          are not limited to the following:
        </p>
        <br />
        <ol>
          <li>When You register on Our Website or Application.</li>
          <li>When You provide Your Personal Information to Us.</li>
          <li>During the course of Services provided to You by Us.</li>
          <li>When You use the features on Our Website or Application.</li>
          <li>
            Through Your device, once You have granted permissions to Our
            Application.
          </li>
          <li>
            Through HSP pursuant to consultation on the Website or the
            Application.
          </li>
          <li>By the use of cookies (also discussed below).</li>
          <li>
            We collect information that Your browser/app sends whenever You
            visit Our Website or Application, such as, the Log Data. In
            addition, We may use third-party services such as Pixel that
            collect, monitor and analyze this. This information is kept
            completely secure.
          </li>
        </ol>
        <br />
        <h3>5. PURPOSES FOR WHICH YOUR INFORMATION MAY BE USED BY CLINIKER:</h3>
        <br />
        <p>
          5.1 Cliniker will retain User Information only to the extent it is
          necessary to provide Services to the Users. The information which
          Cliniker collects from You may be utilized for various business and/or
          regulatory purposes including for the following purposes:
        </p>
        <br />
        <ol type="i">
          <li>Registration of the User on the Website;</li>
          <li>
            Processing the User’s orders/requests and provision of Services
            (including the provision of safe Services);
          </li>
          <li>
            Completing transactions with Users effectively and billing for the
            products/ services provided;
          </li>
          <li>Technical administration and customization of Website;</li>
          <li>
            Ensuring that the Website content is presented to the Users in an
            effective manner;
          </li>
          <li>
            Delivery of personalized information and target advertisements to
            the User;
          </li>
          <li>
            Improvement of Services, features and functionality of the Website;
          </li>
          <li>
            Research and development and for User administration (including
            conducting User surveys);
          </li>
          <li>
            Non-personally identifiable information, exclusively owned by
            Cliniker may be used in an aggregated or non-personally identifiable
            form for internal research, statistical analysis and business
            intelligence purposes including those for the purposes of
            determining the number of visitors and transactional details, and
            Cliniker may sell or otherwise transfer such research, statistical
            or intelligence data in an aggregated or non-personally identifiable
            form to third parties and affiliates;
          </li>
          <li>
            Dealing with requests, enquiries, complaints or disputes and other
            customer care related activities including those arising out of the
            Users’ request of the Services and all other general administrative
            and business purposes;
          </li>
          <li>
            In case of any contests conducted by Cliniker in which the User
            participates, the User Information may be used for prize fulfilment
            and other aspects of any contest or similar offering;
          </li>
          <li>
            Communicate any changes in our Services or this Privacy Policy or
            the Terms of Use to the Users;
          </li>
          <li>
            Verification of the identity of Users and perform checks to prevent
            frauds; and
          </li>
          <li>
            Investigating, enforcing, resolving disputes and applying our Terms
            of Use and Privacy Policy, either ourselves or through third-party
            service providers.
          </li>
        </ol>
        <br />
        <h3>6. DISCLOSURE AND TRANSFER OF YOUR PERSONAL INFORMATION:</h3>
        <br />
        <p>
          6.1 Cliniker may need to disclose/ transfer User’s Personal
          Information to the following third parties for the purposes mentioned
          in this Privacy Policy and the Terms of Use:
        </p>
        <br />
        <ol type="i">
          <li>
            To Sellers and other service providers appointed by Cliniker for the
            purpose of carrying out services on Cliniker’s behalf under
            contract. Generally, these contractors do not have any independent
            right to share this information, however, certain contractors who
            provide services on the Website, including the providers of online
            communications services, will have the rights to use and disclose
            the Personal Information collected in connection with the provision
            of these services in accordance with their own privacy policies.
          </li>
          <li>
            To our affiliates in India or in other countries who may use and
            disclose Your information for the same purposes as us.
          </li>
          <li>
            {" "}
            To government institutions/ authorities to the extent required a)
            under the laws, rules, and regulations and/ or under orders of any
            relevant judicial or quasi-judicial authority; b) to protect and
            defend the rights or property of Cliniker; c) to fight fraud and
            credit risk; d) to enforce Cliniker's Terms of Use (to which this
            Privacy Policy is also a part); or e) when Cliniker, in its sole
            discretion, deems it necessary in order to protect its rights or the
            rights of others.
          </li>
          <li>
            If otherwise required by an order under any law for the time being
            in force including in response to enquiries by government agencies
            for the purpose of verification of identity, or for prevention,
            detection, investigation including cyber incidents, prosecution, and
            punishment of offences.
          </li>
          <li>
            In the case of contests conducted by Cliniker in which the User
            participates, the concerned User’s information may be disclosed to
            third parties, also be disclosed to third parties to the extent
            necessary for prize fulfilment and other aspects of such contest or
            similar offering.
          </li>
        </ol>
        <br />
        <p>
          6.2 Cliniker makes all User Information accessible to its employees
          and data processors only on a need-to-know basis. All Cliniker
          employees and data processors, who have access to, and are associated
          with the processing of User Information, are obliged to respect its
          confidentiality.
        </p>
        <br />
        <p>
          6.3 Non-personally identifiable information may be disclosed to third
          party ad servers, ad agencies, technology vendors and research firms
          to serve advertisements to the Users. Cliniker may also share its
          aggregate findings (not specific information) based on information
          relating to the User’s internet use to prospective, investors,
          strategic partners, sponsors and others in order to help the growth of
          Cliniker's business.
        </p>
        <br />
        <p>
          6.4 Cliniker may also disclose or transfer the User Information, to
          another third party as part of reorganization or a sale of the assets
          or business of a Cliniker corporation division or company. Any third
          party to which Cliniker transfers or sells its assets will have the
          right to continue to use the Personal Information and/ or other
          information that a User provide to us.
        </p>
        <br />
        <h3>7. SECURITY</h3>
        <br />
        <p>
          7.1. The security of Your Personal Information is important to Us. We
          have adopted reasonable security practices and procedures including
          role-based access, secure communication, password protection,
          encryption, etc. to ensure that the Personal Information collected is
          secure. You agree that such measures are secured and adequate. We
          restrict access to Your Personal Information to Our and Our
          affiliates’ employees, agents, third-party service providers,
          partners, and agencies who need to know such Personal Information in
          relation to the Purposes as specified above in this Policy, provided
          that such entities agree to abide by this Privacy Policy.
        </p>
        <br />
        <p>
          7.2. While We will endeavour to take all reasonable and appropriate
          steps to keep secure any information that We hold about You and
          prevent unauthorized access, You acknowledge that the internet is not
          100% secure and that We cannot guarantee the absolute security of Your
          Personal Information. Further, if You are a Secondary User, You hereby
          acknowledge and agree that Your Personal Information may be freely
          accessible by the Primary User and other Secondary Users and that
          Cliniker will not be able to restrict, control or monitor access by
          Primary User or other Secondary Users to Your Personal Information. We
          will not be liable in any way in relation to any breach of security or
          unintended loss or disclosure of information caused in relation to
          Your Personal Information.
        </p>
        <br />
        <h3>8. SECURITY PRACTICES AND PROCEDURES:</h3>
        <br />
        <p>
          8.1 Cliniker adopts reasonable security practices and procedures to
          include, technical, operational, managerial and physical security
          control measures in order to protect the Personal Information in its
          possession from loss, misuse and unauthorized access, disclosure,
          alteration and destruction.
        </p>
        <br />
        <p>
          8.2 Cliniker takes adequate steps to ensure that third parties to whom
          the Users’ Sensitive Personal Data or Information may be transferred
          adopt a reasonable level of security practices and procedures to
          ensure the security of Personal Information.
        </p>
        <br />
        <p>
          8.3 You hereby acknowledge that Cliniker is not responsible for any
          intercepted information sent via the internet, and You hereby release
          us from any and all claims arising out of or related to the use of
          intercepted information in any unauthorized manner.
        </p>
        <br />
        <h3>9. ACCESS</h3>
        <br />
        <p>
          If You need to update or correct Your Personal Information or have any
          grievance with respect to the processing or use of Your Personal
          Information or request that We no longer use Your Personal Information
          to provide You Services, or opt-out of receiving communications such
          as promotional and marketing-related information regarding the
          Services, for any reason, You may send Us an email at{" "}
          <a className="email-link" href="mailto:support@cliniker.com">
            support@cliniker.com
          </a>{" "}
          and We will take all reasonable efforts to incorporate the changes
          within a reasonable period of time.
        </p>
        <br />
        <h3>10. COMPLIANCE WITH LAWS:</h3>
        <br />
        <p>
          You are not allowed to use the services of the Website or Application
          if any of the terms of this Privacy Policy are not in accordance with
          the applicable laws of Your country.
        </p>
        <br />
        <h3>11. TERM OF STORAGE OF PERSONAL INFORMATION:</h3>
        <br />
        <ol>
          <li>
            Cliniker may keep records of communications, including phone calls
            received and made for making enquiries, orders, feedback or other
            purposes for rendering services effectively and efficiently.
            Cliniker will be the exclusive owner of such data and records.
            However, all records are regarded as confidential. Therefore, will
            not be divulged to any third party, unless required by law.
          </li>
          <li>
            Cliniker shall store Your Personal Information at least for a period
            of three years from the last date of use of the Services, Website or
            Application or for such period as may be required by law.
          </li>
        </ol>
        <br />
        <h3>12. GRIEVANCE OFFICER:</h3>
        <br />
        <p>
          We have appointed a grievance officer, whose details are set out
          below, to address any concerns or grievances that You may have
          regarding the processing of Your Personal Information. If You have any
          such grievances, please write to Our grievance officer at{" "}
          <a className="email-link" href="mailto:support@cliniker.com">
            support@cliniker.com
          </a>{" "}
          and Our officer will attempt to resolve Your issues in a timely
          manner.
        </p>
      </div>
    </>
  );
}

export default PrivacyPolicy;
