import React, {useContext, useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import {subDays, addDays} from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";

import styles from "./Schedule.module.css";
import {useHistory, useLocation} from "react-router-dom";
import {addToBlog, rescheduleCart} from "../services/firebaseServices";
import {AuthContext} from "../auth";

function Reschedule() {
  const [startDate, setStartDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState();

  const [timings, setTimings] = useState();

  const days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

  const location = useLocation();
  const history = useHistory();
  const {oldCart, index} = location.state;

  const {currentUser} = useContext(AuthContext);

  let slots = [];

  useEffect(() => {
    // addToBlog();
    if (location.state) {
      //   console.log(location);
      createSlots(days[startDate.getDay()]);
    }
  }, [startDate, selectedTime]);

  const createSlots = (day) => {
    console.log("day", day);
    if (oldCart[index].type === "labtest") {
      console.log("labtest");
      setSlots(oldCart[index]?.clinic.time);
      setTimings(slots);
      return 1;
    }
    if (oldCart[index].type === "checkup") {
      console.log("cart", oldCart, index);
      console.log(oldCart[index].doctor.clinics?.length);
      for (let x = 0; x < oldCart[index].doctor.clinics?.length; x++) {
        if (oldCart[index].doctor.clinics[x].days.includes(day)) {
          //   console.log("yes");
          setSlots(oldCart[index].doctor.clinics[x].timing);
        }
      }
      setTimings(slots);
      return 1;
    }
  };

  const setSlots = (timing) => {
    let date = new Date();
    let currentTime = date.getHours();
    let nextHour = currentTime + 1;
    let inTime = timing.intime;
    let outTime = timing.outime;

    if (startDate.getDate() === date.getDate()) {
      //   console.log("matched", startDate.getDate(), date.getDate());
      inTime = nextHour;
    } else {
      inTime = timing.intime;
      //   console.log(
      //     "not matched",
      //     startDate.getDate(),
      //     date.getDate(),
      //     timing.intime
      //   );
    }

    let span = timing.outime - inTime;
    for (let x = 0; x < span; x++) {
      slots.push(
        {
          time: `${inTime + x}:00 - ${inTime + x}:10`,
          //   time:
          //     (timing.intime > 12 ? timing.intime + x - 12 : timing.intime + x) +
          //     ":00" +
          //     (timing.intime >= 12 ? "pm" : "am") +
          //     "-" +
          //     (timing.intime > 12 ? timing.intime + x - 12 : timing.intime + x) +
          //     ":10" +
          //     (timing.intime >= 12 ? "pm" : "am"),
          taken: false,
        },
        {
          time: `${inTime + x}:10 - ${inTime + x}:20`,
          taken: false,
        },
        {
          time: `${inTime + x}:20 - ${inTime + x}:30`,
          taken: false,
        },
        {
          time: `${inTime + x}:30 - ${inTime + x}:40`,
          taken: false,
        },
        {
          time: `${inTime + x}:40 - ${inTime + x}:50`,
          taken: false,
        },
        {
          time: `${inTime + x}:50 - ${inTime + x + 1}:00`,
          taken: false,
        }
      );
    }
  };

  const updateCart = () => {
    let newCart = oldCart;
    newCart[index].time = selectedTime;
    newCart[index].date.day = startDate.getDate();
    newCart[index].date.month = startDate.getMonth();
    newCart[index].date.year = startDate.getFullYear();
    newCart[
      index
    ].date.dateString = `${startDate.getUTCFullYear()}-${startDate.getMonth()}-${startDate.getDate()}`;

    rescheduleCart(newCart, currentUser.uid).then((res) =>
      history.push("/cart")
    );
    // console.log(tempCart[index]);
  };

  return (
    <>
      <div className={styles.scheduleContainer}>
        <div className={styles.customDatePickerWidth}>
          <DatePicker
            wrapperClassName="datepicker"
            // onSelect={(item) => console.log("onselect", item)}
            selected={startDate}
            onSelect={(date) => {
              let selectedDate = date;
              setStartDate(selectedDate);
              // let day = days[startDate.getDay()];
              // createSlots(day);
            }}
            minDate={subDays(new Date(), 0)}
            maxDate={addDays(new Date(), 3)}
            inline
          />
          {/* {console.log(startDate.getDate())} */}
        </div>
        <div className={styles.slotsContainer}>
          <h2 className={styles.slotHeader}>Slots</h2>
          <div className={styles.slots}>
            {timings?.map((item, index) => {
              return (
                <button
                  onClick={() => {
                    let pickedTime = item.time;
                    setSelectedTime(pickedTime);
                    // setActiveItem(item);
                    // modifyOrder(pickedTime);
                    // console.log("details: ", selectedTime, startDate);
                  }}
                  key={index}
                  className={
                    item.time === selectedTime
                      ? styles.active
                      : styles.individualSlot
                  }
                >
                  {item.time}
                </button>
              );
            })}
          </div>
          {!timings?.length ? (
            <div
              style={{
                width: "100%",
                textAlign: "center",
                font: "normal normal normal 12px/18px Poppins",
              }}
            >
              Slots are not available for the selected date
            </div>
          ) : (
            ""
          )}
        </div>
        <button onClick={() => updateCart()} className={styles.cartBtn}>
          RESCHEDULE
        </button>
      </div>
    </>
  );
}

export default Reschedule;
