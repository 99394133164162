import React, {useContext, useState} from "react";
import clock from "../assets/img/clock.svg";
import calendar from "../assets/img/calendarPurple.svg";
import edit from "../assets/img/edit.svg";

import styles from "./Cart.module.css";
import {removeItemFromDocCart} from "../services/firebaseServices";
import {Link} from "react-router-dom";
import {AuthContext} from "../auth";

function LabTestForCart({
  item,
  index,
  reload,
  triggerNameModal,
  triggerAddressModal,
  cart,
}) {
  const [radioBtn, setRadioBtn] = useState(
    item?.collection_option.clinic === true ? "clinic" : "home"
  );

  const {currentUser} = useContext(AuthContext);

  // if(collection.home){
  //   setRadioBtn("home")
  // }

  return (
    <>
      {/* {console.log("item", item)} */}
      <div className={styles.dcCard}>
        <div className={styles.flex}>
          <div className={styles.detailsDiv}>
            <h2 className={styles.header}>{item?.labtest_name}</h2>
            <p className={styles.designation}>packageName</p>
          </div>
          <div className={styles.timingDiv}>
            <p>
              <span className={styles.boldHead}>Report In - </span>5 Days
            </p>
          </div>
        </div>
        <div className={styles.slotDetails}>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <h3 className={styles.header}>Opted Slot -</h3>
            <Link
              to={{
                pathname: "/reschedule",
                state: {oldCart: cart, index: index},
              }}
            >
              <img className={styles.editIcon} src={edit} width={15} alt="" />
            </Link>
          </div>
          <div className={styles.detail}>
            <img className={styles.icon} src={clock} width={15} alt="" />
            {/* <span className={styles.para}>03:00pm - 03:15pm</span> */}
            <span className={styles.para}>{item?.time}</span>
          </div>
          <div className={styles.detail}>
            <img className={styles.icon} src={calendar} width={15} alt="" />
            <span className={styles.para}>{item?.date.dateString}</span>
          </div>
        </div>
        <p className={styles.header}>
          Consultation Fee - <b style={{color: "#835CB9"}}>₹ {item?.fees}</b>
        </p>
        <div className={styles.patientName}>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <h3 className={styles.header}>Patient Name</h3>
            <img
              onClick={() => triggerNameModal(index)}
              className={styles.editIcon}
              src={edit}
              width={15}
              alt=""
            />
          </div>
          <p className={styles.para}>{item?.patient_name}</p>
        </div>
        <h3 className={styles.header}>Select collection option</h3>
        <div className={styles.flex}>
          {/* <div onChange={(e) => setRadioBtn(e.target.value)}>
            <input
              type="radio"
              value="home"
              checked={radioBtn === "home"}
              name={`collection ${index}`}
            />{" "}
            Home
            <input
              type="radio"
              value="clinic"
              checked={radioBtn === "clinic"}
              name={`collection ${index}`}
            />{" "}
            Clinic
          </div> */}
          <label className={styles.labelRadio}>
            <input
              type="radio"
              name={`home ${index}`}
              value="home"
              checked={radioBtn === "home"}
              onChange={(e) => setRadioBtn("home")}
            />
            Home
          </label>
          <label className={styles.labelRadio}>
            <input
              type="radio"
              name={`clinic ${index}`}
              value="clinic"
              checked={radioBtn === "clinic"}
              onChange={(e) => setRadioBtn("clinic")}
            />
            Clinic
          </label>
        </div>
        {radioBtn === "home" ? (
          <div className={styles.address}>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              <h3 className={styles.header}>Address</h3>
              <img
                onClick={() => triggerAddressModal(index)}
                className={styles.editIcon}
                src={edit}
                width={15}
                alt=""
              />
            </div>
            <p className={styles.para}>{item?.address.address_line1}</p>
            <p className={styles.para}>{item?.address.address_line2}</p>
            <p className={styles.para}>{item?.address.address_line3}</p>
            <p className={styles.para}>{item?.address.pincode}</p>
          </div>
        ) : (
          ""
        )}

        <button
          onClick={() =>
            removeItemFromDocCart(item, currentUser.uid).then((res) => reload())
          }
          className={styles.removeBtn}
        >
          REMOVE
        </button>
      </div>
    </>
  );
}

export default LabTestForCart;
