import React from "react";

import styles from "./SearchCard.module.css";

function SearchCard({ image, name, subtext, location }) {
  return (
    <>
      <div className={styles.searchCardContainer}>
        <img className={styles.searchCardImg} src={image} alt="" />
        <div className={styles.info}>
          <h2 className={styles.searchCardName}>{name}</h2>
          <p className={styles.searchCardSubText}>{subtext}</p>
          <p className={styles.cardLocation}>{location}</p>
        </div>
      </div>
    </>
  );
}

export default SearchCard;
