import React from "react";

import Stepper from "react-stepper-js";
import "react-stepper-js/dist/index.css";

import whiteCircle from "../assets/img/whiteCircle.svg";
import wallet from "../assets/img/wallet.png";

import styles from "./Cart.module.css";

function CartCheckout() {
  return (
    <>
      {/* {formStep === 2 && (
          <> */}

      <div className={styles.cartContainer}>
        <Stepper
          color="#835cb9"
          fontSize="15px"
          fontColor="#7f8386"
          steps={[
            {label: "Details"},
            {label: "Place Order"},
            {label: "Confirmation"},
          ]}
          currentStep={2}
        />
        <div className={styles.couponSection}>
          <h2 className={styles.header}>Have a Code?</h2>
          <form className={styles.couponForm}>
            <input
              className={styles.couponInput}
              type="text"
              placeholder="Apply Coupon Code"
            />
            <h2 className={styles.formHeader}>Referal by Staff Redeem Here</h2>
            <input
              className={styles.couponInput}
              type="text"
              placeholder="Apply Coupon Code"
            />
          </form>
        </div>

        <div className={styles.walletSection}>
          <img className={styles.walletImg} src={wallet} width={25} alt="" />
          <div className={styles.walletDetails}>
            <h3 className={styles.walletHeader}>Redeem Loyalty Points</h3>
            <p className={styles.walletPara}>
              Balance - <b style={{color: "#835cb9"}}>300</b>
            </p>
          </div>
        </div>
        <p className={styles.additionalText}>
          ⭐ Get 10 Loyalty points for every Rs 100 order
        </p>

        <div className={styles.billSection}>
          <h1 className={styles.header}>Bill Details</h1>
          <div className={styles.gridBill}>
            <h2 className={styles.billHeader}>Doctor consultation</h2>
            <h2 className={styles.billAmount}>₹ 250</h2>
            <h2 className={styles.billHeader}>Lab Test</h2>
            <h2 className={styles.billAmount}>₹ 250</h2>
            <h2 className={styles.billHeader}>Loyalty Points</h2>
            <h2 className={styles.billAmount}>₹ 250</h2>
          </div>
          <div className={styles.line}></div>
          <div className={styles.gridBill}>
            <h2 className={styles.billHeader}>Total</h2>
            <h2 className={styles.billAmount}>₹ 250</h2>
          </div>
        </div>

        {/* </>
        )
    }    */}

        <button className={styles.proceedBtn}>PROCEED</button>
      </div>
    </>
  );
}

export default CartCheckout;
