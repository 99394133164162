import React from "react";
import textLogo from "../../assets/img/text-logo.png";
// import profileImgTemp from "../../assets/img/profileImgTemp.png";
import profileImgTemp from "../../assets/img/profileImg.png";
import heroBanner from "../../assets/img/heroBanner.png";
import searchIcon from "../../assets/img/searchWeb.png";

import heroCD from "../../assets/img/heroCD.png";
import heroLT from "../../assets/img/heroLT.png";
import heroCovid from "../../assets/img/heroCovid.png";
import heroPharmacy from "../../assets/img/heroPharmacy.png";
import heroAbout from "../../assets/img/heroAbout.png";
import heroBusiness from "../../assets/img/heroBusiness.png";

import styles from "./HeroSection.module.css";
import {Link} from "react-router-dom";

function HeroSection() {
  return (
    <>
      <div className={styles.heroContainer}>
        <div className={styles.header}>
          <div className={styles.topNav}>
            <img className={styles.logo} src={textLogo} alt="" />
            <div className={styles.userTab}>
              <img className={styles.userImg} src={profileImgTemp} alt="" />
              <p className={styles.navText}>Login / Signup</p>
            </div>
          </div>
          {/* <div className={styles.bottomNav}>

            </div> */}
          <nav className={styles.navbarParent}>
            <div className={styles.navbar}>
              <Link style={{textDecoration: "none"}} to="/consult">
                <div className={styles.navItem}>
                  <img className={styles.itemImg} src={heroCD} alt="" />
                  <p className={styles.navText}>Consult Doctor</p>
                </div>
              </Link>
              <Link style={{textDecoration: "none"}} to="/book">
                <div className={styles.navItem}>
                  <img className={styles.itemImg} src={heroLT} alt="" />
                  <p className={styles.navText}>Book Lab Test</p>
                </div>
              </Link>
              <Link style={{textDecoration: "none"}} to="/covid">
                <div className={styles.navItem}>
                  <img className={styles.itemImg} src={heroCovid} alt="" />
                  <p className={styles.navText}>Covid-19</p>
                </div>
              </Link>
              <Link style={{textDecoration: "none"}} to="/pharmacy">
                <div className={styles.navItem}>
                  <img className={styles.itemImg} src={heroPharmacy} alt="" />
                  <p className={styles.navText}>Pharmacy</p>
                </div>
              </Link>
              <Link style={{textDecoration: "none"}} to="/about">
                <div className={styles.about}>
                  <img className={styles.itemImg} src={heroAbout} alt="" />
                  <p className={styles.navText}>About Us</p>
                </div>
              </Link>
              <Link style={{textDecoration: "none"}} to="/enquiry">
                <div className={styles.business}>
                  <img className={styles.itemImg} src={heroBusiness} alt="" />
                  <p className={styles.navText}>For Business</p>
                </div>
              </Link>
              <Link style={{textDecoration: "none"}} to="/team">
                <div className={styles.business}>
                  <img className={styles.itemImg} src={heroAbout} alt="" />
                  <p className={styles.navText}>Our Team</p>
                </div>
              </Link>
            </div>
          </nav>
          <div className={styles.contentContainer}>
            <h1 className={styles.heroTitle}>
              Welcome to <span style={{color: "#7549B1"}}>Cliniker</span>{" "}
            </h1>
            <div className={styles.searchContainer}>
              <button className={styles.searchBtn}>Search Location</button>
              <input
                className={styles.searchInput}
                placeholder="Search Doctors, Clinics and Lab test"
                type="text"
              />
              <img className={styles.searchIcon} src={searchIcon} alt="" />
            </div>
            <img className={styles.heroBanner} src={heroBanner} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
