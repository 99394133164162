import React from "react";

import LoggedNav from "./components/LoggedNav";
import Home from "./components/Home";
import DoctorDetails from "./components/DoctorDetails";
import AllClinics from "./components/AllClinics";
import AllPharmacies from "./components/AllPharmacies";
import Login from "./components/Login";
import SignUpDetails from "./components/SignUpDetails";
import ClinicDetails from "./components/ClinicDetails";
import Search from "./components/Search";
import DoctorSearchCard from "./components/DoctorSearchCard";
import DoctorListing from "./components/DoctorListing";
import LabTestListing from "./components/LabTestListing";
import Schedule from "./components/Schedule";
import MyOrders from "./components/MyOrders";
import OrdersUpcoming from "./components/OrdersUpcoming";
import ManageRecords from "./components/ManageRecords";
import AddRecord from "./components/AddRecord";
import BlogsList from "./components/BlogsList";
import BlogDetail from "./components/BlogDetail";
import UserProfile from "./components/UserProfile";
import FamilyProfilesList from "./components/FamilyProfilesList";
import MyAddressesList from "./components/MyAddressesList";
import AddProfile from "./components/AddProfile";
import AddAddress from "./components/AddAddress";
import ProfileAttachments from "./components/ProfileAttachments";
import Notifications from "./components/Notifications";
import Cart from "./components/Cart";
import CartSuccess from "./components/CartSuccess";
import CartFailiure from "./components/CartFailiure";
import NavBarWeb from "./components/Navbar/NavBarWeb";
import ConsultDoctorWeb from "./Pages/ConsultDoctorWeb";
import FooterNew from "./components/Footer/FooterNew";
import BookLabTest from "./Pages/BookLabTest";
import Covid19 from "./Pages/Covid19";
import { AuthContext, AuthProvider } from "./auth";
import PrivateRoute from "./components/PrivateRoute";
import Pharmacy from "./Pages/Pharmacy";
import BlogsListWeb from "./Pages/BlogsListWeb";
import BlogDetailWeb from "./Pages/BlogDetailWeb";
import { ClinicProvider } from "./Context/ClinicContext";
import { UserContext, UserProvider } from "./Context/UserContext";
import BlogInput from "./components/WebComponents/BlogInput";
import { OrdersProvider } from "./Context/OrdersContext";
import ListingPage from "./Pages/ListingPage";
import PartnerEnquiry from "./Pages/PartnerEnquiry";
import CartCheckout from "./components/CartCheckout";
import { CartProvider } from "./Context/CartContext";
import About from "./Pages/About";
import EditUserProfile from "./components/EditUserProfile";
import ScrollToTop from "./services/ScrollToTop";
import Contact from "./components/Contact";
import OurPartners from "./components/OurPartners";
import FAQ from "./components/FAQ";

import styles from "./LimitWidth.module.css";
import { Route } from "react-router-dom";
import Reschedule from "./components/Reschedule";

function LimitWidth() {
	return (
		<>
			<div className={styles.outerContainer}>
				<div
					className={styles.innerContainer}
					//   style={{
					//     maxWidth: 400,
					//     marginLeft: "auto",
					//     marginRight: "auto",
					//     // boxShadow: "0 0 1000px 300px #000000",
					//   }}
				>
					<Route exact path="/login" component={Login} />
					<PrivateRoute exact path="/schedule" component={Schedule} />
					<PrivateRoute exact path="/reschedule" component={Reschedule} />
					<PrivateRoute exact path="/pharmacies" component={AllPharmacies} />
					<PrivateRoute exact path="/clinics" component={AllClinics} />
					<PrivateRoute exact path="/labs" component={LabTestListing} />
					<PrivateRoute exact path="/doctors" component={DoctorListing} />
					<PrivateRoute
						exact
						path="/doctor/:doctorId"
						component={DoctorDetails}
					/>
					<PrivateRoute
						exact
						path="/signup-details"
						component={SignUpDetails}
					/>
					<PrivateRoute
						exact
						path="/clinic/:clinicId"
						component={ClinicDetails}
					/>
					<PrivateRoute exact path="/our-partners" component={OurPartners} />
					<PrivateRoute exact path="/contact" component={Contact} />
					<PrivateRoute exact path="/addresses" component={MyAddressesList} />
					<PrivateRoute exact path="/add-address" component={AddAddress} />
					<PrivateRoute exact path="/profile" component={UserProfile} />
					<PrivateRoute exact path="/add-profile" component={AddProfile} />
					{/* <PrivateRoute exact path="/faq" component={FAQ} /> */}
					<PrivateRoute exact path="/blog-list" component={BlogsList} />
					<PrivateRoute
						exact
						path="/blog-detail/:blogId"
						component={BlogDetail}
					/>
					<PrivateRoute
						exact
						path="/manage-records"
						component={ManageRecords}
					/>
					<PrivateRoute
						exact
						path="/attachments"
						component={ProfileAttachments}
					/>
					<PrivateRoute
						exact
						path="/edit-profile"
						component={EditUserProfile}
					/>
					<PrivateRoute
						exact
						path="/family-profiles"
						component={FamilyProfilesList}
					/>
					<PrivateRoute exact path="/cart" component={Cart} />
					<PrivateRoute exact path="/cart-checkout" component={CartCheckout} />
					<PrivateRoute exact path="/orders" component={MyOrders} />
					<PrivateRoute
						exact
						path="/upcoming-orders"
						component={OrdersUpcoming}
					/>
					<PrivateRoute exact path="/home" component={Home} />
				</div>
			</div>
		</>
	);
}

export default LimitWidth;
