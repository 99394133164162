import React, {useContext} from "react";

import clock from "../assets/img/clock.svg";
import calendar from "../assets/img/calendarPurple.svg";
import edit from "../assets/img/edit.svg";

import styles from "./Cart.module.css";
import {removeItemFromDocCart} from "../services/firebaseServices";
import {Link, useHistory} from "react-router-dom";
import {AuthContext} from "../auth";

function DocConsultForCart({item, index, triggerNameModal, reload, cart}) {
  let history = useHistory();

  const {currentUser} = useContext(AuthContext);

  // const rescheduleCart = () => {
  //   console.log(cart);

  //   // rescheduleCart(cart, userId)
  // };

  return (
    <>
      <div className={styles.dcCard}>
        <h2 className={styles.name}>Dr. {item.doctor.name}</h2>
        <p className={styles.designation}>{item.doctor.specialist[0]}</p>
        <div className={styles.slotDetails}>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <h3 className={styles.header}>Opted Slot -</h3>
            <Link
              to={{
                pathname: "/reschedule",
                state: {oldCart: cart, index: index},
              }}
            >
              <img
                // onClick={() => rescheduleCart()}
                className={styles.editIcon}
                src={edit}
                width={15}
                alt=""
              />
            </Link>
          </div>
          <div className={styles.detail}>
            <img className={styles.icon} src={clock} width={15} alt="" />
            {/* <span className={styles.para}>03:00pm - 03:15pm</span> */}
            {/* <span className={styles.para}>{optedSlot.time}</span> */}
            <span className={styles.para}>{item.time}</span>
          </div>
          <div className={styles.detail}>
            <img className={styles.icon} src={calendar} width={15} alt="" />
            {/* <span className={styles.para}>{optedSlot.day}</span> */}
            <span className={styles.para}>{item.date.dateString}</span>
          </div>
        </div>
        <p className={styles.header}>
          Consultation Fee -{" "}
          <b style={{color: "#835CB9"}}>₹ {item.doctor.fees}</b>
        </p>
        <div className={styles.patientName}>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            <h3 className={styles.header}>Patient Name</h3>
            <img
              onClick={() => triggerNameModal(index)}
              className={styles.editIcon}
              src={edit}
              width={15}
              alt=""
            />
          </div>
          <p className={styles.para}>{item.patient_name}</p>
        </div>

        <button
          onClick={() =>
            removeItemFromDocCart(item, currentUser.uid).then((res) => reload())
          }
          className={styles.removeBtn}
        >
          REMOVE
        </button>
      </div>
    </>
  );
}

export default DocConsultForCart;
